import React, { useState } from "react";
import { dateFormate } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { T } from "../translation";
import CurrentQuotesPageItems from "./CurrentQuotesPageItems";
import { ARROW_ORANGE_ICON } from "../../assets";

/**
 *
 * @param {object} item - display data
 * @returns - jsx element
 */
function CurrentQuotesCard({ item }) {
  const navigate = useNavigate();

  const [viewMore, setViewMore] = useState(false);


  const orderItemArr = item?.items.filter(i => {
    if (i.productCode !== "DELIVERY") {
      return true;
    }

    return false;
  });

  return (
    <>
      <section key={item?.index}>
        <section className={`${orderItemArr.length > 1 ? "current-quotes-card" : "current-quotes-card__"}`}>
          {/* =============== Current Quotes Section 1 ============================== */}
          <section
            className="cq-card1 cursor-pointer"
            onClick={() => {
              navigate(`/order/${item?.reference}`);
            }}
          >
            <div className="current-quotes-card-flex">
              <div className="current-quotes-card-reference">
                <span className="current-quotes-card__reference">
                  {item?.reference || "--"}
                </span>
                <div className="current-quotes-card-dates">
                  <span className="_order">
                    <T strProp="Ordered" />
                  </span>
                  {" : "}
                  <span className="_value">
                    {dateFormate(item?.createdDate)}
                  </span>
                </div>
              </div>
              <div className="current-quotes-card-description">
                <span className="current-quotes-card__description">
                  {item?.description}
                </span>
                <div className="current-quotes-card-dates currquote_width">
                  <span className="_dispatched">
                    <T strProp="Dispatched" />
                  </span>
                  {" : "}
                  <span className="_date">
                    {dateFormate(item?.despatchDate)}
                  </span>
                </div>
              </div>
            </div>
          </section>
          {/* =============== Current Quotes Section 2 ============================== */}
          {orderItemArr.length > 1 ?
            <>
              <CurrentQuotesPageItems item={[orderItemArr[0]]} />
              {viewMore &&
                <div className="border-line-1px_10m" />
              }
              {viewMore &&
                <CurrentQuotesPageItems item={orderItemArr.slice(1)} />
              }
            </>
            : <CurrentQuotesPageItems item={orderItemArr} />
          }
          {orderItemArr.length > 1 &&
            <div className="quotes_dis_fx_mtb">
              <div className="confirm-shipment-view-more cursor-pointer order-mp__" onClick={() => setViewMore(!viewMore)}>
                <span className="quote-details-org-details-view-more-btn">
                  {!viewMore ? <T strProp="quoteViewMore" /> : <T strProp="quoteViewLess" />}
                </span>
                <div className="confirm-shipment__view-more">
                  <img src={ARROW_ORANGE_ICON} alt="iocn" className={`${viewMore && "up-down-scroll"}`} />
                </div>
              </div>
            </div>}
        </section>
      </section>
    </>
  );
}

export default CurrentQuotesCard;
