import { useState, useRef } from "react";
import { languageCode } from '../contexts/i18context';
import { callApi } from "../services";
import { useSearchParams } from "react-router-dom";
import { SortByNews } from "../utils/constants";

/**
 * @param {string} token - for user authentication
 * @param {number} pageNo - for pagination 
 * @param {ref} alertRef - used to hendal error alert popup
 * @param {function} setFilter - for set filter array
 * @param {function} setPageNo - for set page number
 * @param {function} setSelectedArr - for set Selected filter and sortby array
 * @returns - news data ,loading , nextpage call for api of news
 */
const useNEWS = (token, pageNo = 0, alertRef, setFilter, setPageNo, setSelectedArr) => {
    const setData = useRef(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasNextPage, setHasNextPage] = useState(true);
    const initialize = useRef(true);
    const [loadingMore, setLoadingMore] = useState(false);

    async function getNews(isNew = false, Url) {
        !loading && setLoading(true);
        let lng = languageCode ? `&languageCode=${languageCode}` : '';
        let pNo = isNew ? 0 : pageNo;
        if (initialize.current) initialize.current = false;
        setLoadingMore(!isNew);
        setSearchParams(Url ?? "");
        const finalStr = `?${Url}${Url ? '&' : ''}page=${pNo + 1}&pageSize=10${lng || 'en'}`;
        await callApi("news_List", { token, alertRef }, finalStr).then(({ res, sCode }) => {
            setPageNo(pNo + 1);
            if (sCode === 401) { setHasNextPage(false); }
            if (sCode === 200 || sCode === 202) { updateData(res.body, isNew); }
            else { setHasNextPage(false); }
        }).catch((err) => console.log("useNewsDataHookERROR", err)).finally(() => { setLoading(false); })
    };

    function updateData(res, isNew) {
        if (!(res?.page && res?.pageSize)) { setHasNextPage(false) }
        else if (isNew ? res?.items?.length : [...newsData, ...res?.items]?.length < res?.totalCount) { setHasNextPage(true) }
        else { setHasNextPage(false); }
        setLoadingMore(false);

        let tempArr = [];
        let urlArray = [];
        let newsType = searchParams.get("newsType")
        let dateType = searchParams.get("date")
        let sortByType = searchParams.get("sortBy")
        let sortOrderType = searchParams.get("sortOrder")
        let typeC = res?.typeCounts;
        let dateC = res?.dateCounts;
        if (typeC && Object.keys(typeC).length > 0) {
            tempArr.push({ "title": "By Type", "data": [] });
            for (let key in typeC) { tempArr[0].data.push({ "displayName": key, "count": typeC[key] }); }
            for (let key in typeC) { key === newsType && urlArray.push({ "count": typeC[key], "displayName": key, "sortBy": false, "title": "By Type", value: newsType }); }
        }
        if (dateC && Object.keys(dateC).length > 0) {
            tempArr.push({ "title": "By Date", "data": [] });
            for (let key in dateC) { tempArr[1].data.push({ "displayName": key, "count": dateC[key] }); }
            for (let key in dateC) { key === dateType && urlArray.push({ "count": dateC[key], "displayName": key, "sortBy": false, "title": "By Date", value: dateType }); }
        }
        //sortBy=NewsType&sortOrder=Asc
        if (sortByType && sortOrderType) {

            const sortObj = SortByNews.find(i => i.value === `sortBy=${sortByType}&sortOrder=${sortOrderType}`);
            if (sortObj !== undefined) {
                urlArray.push({ sortBy: true, displayName: sortObj?.displayName, value: sortObj.value, title: 'Sort By' })
            }
        }

        if (setData.current) {
            setSelectedArr(urlArray);
            setData.current = false;
        }
        setFilter(tempArr);
        isNew ? setNewsData(res?.items) : setNewsData(p => [...p, ...res?.items]);
    };

    return { newsData, loading, hasNextPage, getNews, loadingMore };
};

export default useNEWS;