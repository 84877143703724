import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { DELET_BG_RED_ICON, EDIT_BLUE_ICON } from "../../assets";
import { GlobalContext, Il8Context } from "../../contexts";
import { callApi } from "../../services";
import { toPrice } from "../../utils/functions";
import DeleteModal from "../DeleteModal";
import SuccessfullAlert from "../Modals/SuccessfullAlert";
import { T } from "../translation";
import { globalActionsTypes } from "../../hooks";

/**
 * @param {object} item - Pass the particular item details
 * @param {boolean} EditItemsOpen - display edit pop up base on that variable
 * @param {number} index - particular item index
 * @param {object} data - all details or particular quote
 * @param {object} setItemData - reset item data after perform any action
 * @param {string} tok - api token
 * @param {function} getQuotationDetails - get the quotation details
 * @param {refrence} alertRef - hendal alert popup on error or warning from api
 * @param {function} signal - for cancle api call
 * @param {boolean} isDisplayUnitPrice - use for whether unit price display or not
 * @returns - jsx element
 */
const QuotesItem = ({
  item,
  EditItemsOpen,
  index,
  data,
  setItemData,
  tok,
  getQuotationDetails,
  alertRef = null,
  signal,
  isDisplayUnitPrice = false,
}) => {
  const { t } = useContext(Il8Context);
  const { dispatch } = useContext(GlobalContext);
  const [delAlert, setDelAlert] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  /**
   * @returns When click on any item in list that time after edit quantity click on save button that time call this function and delete particular item from quote.
   */
  const onDelQuoteItem = async () => {
    setDeleting(true);
    await callApi(
      "deleteQuoteItem",
      { token: tok, alertRef, signal: signal },
      data?.reference,
      item?.id
    )
      .then(({ res, sCode }) => {
        if (sCode === 200 || sCode === 200) {
          dispatch({ type: globalActionsTypes.setScrollToItem, data: true });
          const dataArr = [...data?.items];
          dataArr.splice(index, 1);
          setItemData({ ...data, items: dataArr });
          setIsSuccess(true);
          getQuotationDetails();
          alertRef.current?.onSuccess({
            body: t("quoteDELSuccessfully"),
            btnTxt: t("BtnOK"),
          });
        }
        setDeleting(false);
        setDelAlert(false);
      })
      .catch((err) => {
        console.log(err);
        setDelAlert(false);
        setDeleting(false);
      });
  };
  return (
    <>
      <div className="quotationdetails-item-pd">
        <div className="quotationdetails-item-number">{index + 1}</div>
        <div className="quotationdetails-item__flex-col">
          <div className="edit-shipment-other-item-mb">
            <Link
              to={item?.isEditable && `/product/${item?.productCode}`}
              className={`quotationdetails-item-productCode ${!item?.isEditable && `text-decoreation-none`}`}
            >
              {item?.productCode || "--"}
            </Link>
          </div>
          <div className="quotationdetails-item-description">
            <span>{item?.description || "--"}</span>
          </div>
        </div>

        <div className="quotationdetails-item__price">
          {toPrice(item?.totalPrice)}
        </div>
      </div>
      <div className="quotationdetails-item-flex-row_">
        {isDisplayUnitPrice &&
          <div className="display_column">
            <span className="quotationdetails-item-gray-text">
              <T strProp="quoteUnitPrice" />
            </span>
            <span className="quotationdetails-item-price">
              {toPrice(item?.grossPrice)}
            </span>
          </div>
        }
        {isDisplayUnitPrice && (
          <div className="display_column">
            <span className="quotationdetails-item-gray-text">
              <T strProp="basketItemDiscount" />
            </span>
            <span className="quotationdetails-item-price">
              {item?.discountPercent > 0 ? item?.discountPercent + " %" : "--"}
            </span>
          </div>
        )}
        <div className="display_column">
          <span className="quotationdetails-item-gray-text">
            <T strProp="quoteNetPrice" />
          </span>
          <span className="quotationdetails-item-price">
            {toPrice(item?.netPrice)}
          </span>
        </div>
      </div>
      <div className="quotationdetails-item__flex-row">
        <div className="quotationdetails-item-quantity">
          <span className="_quantity">
            <T strProp="Quantity" />
          </span>
          {" : "}
          <span className="_value">
            {item?.quantity ? item?.quantity : "0"}
          </span>
        </div>
        {item?.isEditable &&
          <div className="quotationdetails-item-icon">
            <img
              src={EDIT_BLUE_ICON}
              alt="icon"
              className="margin-right-8"
              onClick={() => EditItemsOpen(item, index)}
            />
            <img
              src={DELET_BG_RED_ICON}
              alt="icon"
              onClick={() => setDelAlert(true)}
            />
          </div>}
      </div>
      {data?.items?.length > index + 1 && <div className="border__line_mr" />}
      <DeleteModal
        visible={delAlert}
        onClose={setDelAlert}
        onClickDel={onDelQuoteItem}
        isDeleting={deleting}
      />
      <SuccessfullAlert
        onClose={setIsSuccess}
        visible={isSuccess}
        title={t("Success")}
        message={t("QuoteDeletedSuccessfully")}
      />
    </>
  );
};

export default QuotesItem;
