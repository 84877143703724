import React, { forwardRef, useContext, useState } from "react";
import QuotesCard from "./cards/QuotesCard";
// eslint-disable-next-line
import dayjs from "dayjs";
import { callApi } from "../services";
import { Il8Context } from "../contexts";
import { dateFormate } from "../utils";

/**
 *
 * @param {object} item - for specify item for delete
 * @param {function} nav - function for navigation
 * @param {string} tok - api token
 * @param {function} onRefreshQuote - call function after api response
 * @param {referance} alertRef - for display pop up if any issue found in api response
 * @param {referance} ref - for referance
 * @returns use to delete quote process
 */
const QuoteProduct = forwardRef(
  ({ item, nav, tok, onRefreshQuote, alertRef }, ref) => {
    const [deleting, setDeleting] = useState(false);

    const { t } = useContext(Il8Context);
    const onDelQuote = async () => {
      setDeleting(true);
      await callApi(
        "deleteQuote",
        { token: tok, alertRef, noBack: false },
        item?.reference
      )
        .then(({ sCode }) => {
          if (sCode === 200 || sCode === 200) {
            onRefreshQuote(item?.reference);
            alertRef.current?.onSuccess({
              body: t("QuoteDeletedSuccessfully"),
              btnTxt: t("BtnOK"),
            });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setDeleting(false));
    };

    const quoteProductBody = (
      <QuotesCard
        Name={item?.reference}
        Dates={
          item?.createdDate && dateFormate(item?.createdDate)
          // item?.createdDate && dayjs(item?.createdDate).format("DD-MMM-YYYY")
        }
        ItemName={item?.description}
        Location={item?.stockLocationName}
        onClick={() =>
          nav(`/quotationdetails/${item?.reference}`, {
            state: { isbasket: false },
          })
        }
        isDeleteing={deleting}
        onClickDel={onDelQuote}
      />
    );

    const content = ref ? (
      <div ref={ref}>{quoteProductBody}</div>
    ) : (
      <div>{quoteProductBody}</div>
    );

    return content;
  }
);

export default QuoteProduct;
