import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { languageCode } from "../contexts/i18context";
import { SortByEvents } from "../pages/TrainingEvents";
import { callApi } from "../services";

/**
 * 
 * @param  {string} token used to user authentication 
 * @param  {int} pageNo=0 used to pass pageno in pagination for api 
 * @param  {string} dVal used to pass pageno in pagination for api 
 * @param  {function} setFilter used to set filter
 * @param  {function} setSelFilter used to set selecetd filter
 * @param  {object} selFilter used to get selected filter list
 * @param  {function} setSelArr used to set selected array state
 * @param  {ref} alertRef used to error alert popup
 * @return - taring event data ,loading , nextpage call for api of training event
 */
export const useTrainingEventsHook = (token, pageNo = 0, dVal, setFilter, setSelFilter, selFilter, setSelArr, alertRef) => {
    let [searchParams, setSearchParams] = useSearchParams();

    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [apiUri, setApiUri] = useState(null);
    let aUri = apiUri ?? searchParams.toString() ?? ``;
    let searchUrl = dVal ? `${aUri?.length > 0 ? "&" : ``}search=${dVal?.trim()}` : ``;

    const getEvent = async (isNew, newUrl = apiUri, manufacturerId, distanceId, dateIs) => {
        !loading && setLoading(true);

        let searchStr = dVal ? `search=${dVal?.trim()}` : "";
        let isAnd = dVal && manufacturerId ? "&" : "";
        let manufacturer = manufacturerId ? `manufacturer=${manufacturerId}` : "";
        let distance = distanceId ? `&distance=${distanceId}` : "";
        let date = dateIs ? `&date=${dateIs}` : "";

        let url = newUrl ?? `${searchStr}${isAnd}${manufacturer}${distance}${date}`;
        setSearchParams(url ?? "");
        let mId = url?.includes("manufacturer") ? url?.split("manufacturer=")[1]?.split("&")[0] : "";
        let dId = url?.includes("distance") ? url?.split("distance=")[1]?.split("&")[0] : "";
        let dtId = url?.includes("date") ? url?.split("date=")[1]?.split("&")[0] : "";
        let sortOrdId = url?.includes("sortOrder") ? url?.split("sortOrder=")[1]?.split("&")[0] : "";
        let sortEventCol = url?.includes("sortEventColumns") ? url?.split("sortEventColumns=")[1]?.split("&")[0] : "";

        await callApi("trainingEvents", { token, alertRef }, url, pageNo + 1, languageCode).then(({ res, sCode }) => {
            if (sCode === 401) { setHasNextPage(false); }
            if (sCode === 200 || sCode === 202) {
                if (dVal?.trim()?.length > 0 && !isNew) { setHasNextPage(false) }
                else if (events?.length < res?.body?.items?.length && dVal?.trim()?.length < 0/* res?.body?.totalCount */) { setHasNextPage(true) }
                else { setHasNextPage(false); }

                let tempArr = [];
                let filterArr = [];
                let dateC = res?.body?.dateCounts;
                let manuC = res?.body?.manufacturersCount;
                let distC = res?.body?.distanceCount;
                if (dateC && Object.keys(dateC).length > 0) {
                    tempArr.push({ "title": "Date", "data": [] });
                    for (let key in dateC) {
                        tempArr[0].data.push({ title: key, count: dateC[key] });
                        dtId === key && filterArr.push({ "title": 'Date', "value": key, "count": dateC[key] || 0, sortBy: false });
                    }
                }
                if (manuC && Object.keys(manuC).length > 0) {
                    tempArr.push({ "title": "Manufacturers", "data": [] });
                    for (let key in manuC) {
                        tempArr[1].data.push({ "title": key, 'count': manuC[key] });
                        mId === key && filterArr.push({ "title": 'Manufacturers', "value": key, "count": manuC[key] || 0, sortBy: false });
                    }
                }
                if (distC && Object.keys(distC).length > 0) {
                    tempArr.push({ "title": "Distance", "data": [] });
                    for (let key in distC) {
                        tempArr[2].data.push({ "title": key, "count": distC[key] });
                        dId === key && filterArr.push({ "title": 'Distance', "value": key, "count": distC[key] || 0, sortBy: false });
                    }
                }
                if (sortOrdId?.length > 0 && sortEventCol?.length > 0) {
                    let sArr = SortByEvents
                    // find the index of the object by comparing the sortOrdId with the sortOrder property && sortEventCol with the sortEventColumns property
                    let index = sArr.findIndex((obj => obj.sortOrder === sortOrdId && obj.sortEventColumns === sortEventCol));
                    if (index > -1) {
                        let obj = { "title": 'Sort By', "value": sArr[index]?.value || ``, "count": 0, sortBy: true, displayName: sArr[index]?.displayName || `` };
                        filterArr.push(obj);
                    }
                } else { setSelArr([]) }
                setFilter(tempArr);
                selFilter?.length === 0 && setSelFilter(filterArr);

                setHasNextPage(false);
                isNew ? setEvents(res?.body?.items) : setEvents((p) => [...p, ...res?.body?.items]);
            }

        }).catch((err) => console.log("useTrainingEventsHookERROR", err))
            .finally(() => setLoading(false))
    };


    useEffect(() => {
        if (token === null) return;
        getEvent(true, aUri);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, pageNo]);

    useEffect(() => {
        if (token === null) return;

        let newUrlStr = aUri?.includes("search") ? searchUrl : `${aUri}${searchUrl}`;
        getEvent(true, newUrlStr ?? ``);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dVal]);

    return { events, loading, hasNextPage, getEvent, setApiUri };
};