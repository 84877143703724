import { createContext } from 'react';

export const accountObj = {
  "keyAccountContacts": [],
  "accountSummary": {
    "accountName": "", "swindonEnabled": "", "tasCode": "", "discount": null,
    "creditLimit": { "amount": null, "currency": "" },
    "outstandingBalance": { "amount": null, "currency": "" },
    "creditAvailable": { "amount": null, "currency": "" },
    "showDiscount" : false,
  },
  "defaultShippingAddress": {
    "contactName": null, "country": "", "county": "", "organisation": null, "postCode": "", "street": "", "town": "", "contactPhone": null, "countryId": null
  },
  "currency": { "cultureCode": "", "symbol": "" },
  "vat": { "isVatRegistered": true, "vatRegistrations": [{ "country": "", "registrationNo": "" }] },
  "stockLocation": { "id": null, "description": "" },
  "paymentProvider" : "",
}


const GlobalContext = createContext({
  items: [],
  basketBadge: 0,
  rootHistoryPath: '/',
  stockLocID: null,
  stockLocName: "",
  acObj: accountObj,
  loadingAcObj: true,
  countrys: [],
  scrollToItem: false
});


export { GlobalContext };
