import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import '../styles/components/filepicker.css'
import "../styles/components/textinput.css";


const FilePicker = forwardRef(({ fileName, Required, input_Title, type, accept, NoneImg, ClearValue, isLoading, dropdownArrow, onChange = () => { } }, ref) => {
    const refs = useRef();
    useImperativeHandle(ref, () => ({ handleInputBlur }));



    const [showErrIc, setShowErrIc] = useState("");
    const [msg, setMsg] = useState("");
    const inpRef = useRef(null);
    const scrollRef = useRef(null);



    const inputBorder =
        showErrIc === undefined || showErrIc === ""
            ? "filePickerComponent_normal"
            : showErrIc === true
                ? "filePickerComponent_error"
                : "filePickerComponent_normal";
    const errorMgs =
        showErrIc === undefined || showErrIc === ""
            ? ""
            : showErrIc === true
                ? "text-input-error-title"
                : "text-input-success-title";



    const handleInputBlur = (errMsg, msg, isFocus = false) => {
        if (errMsg === undefined || errMsg === "") {
            setMsg(undefined);
            setShowErrIc(undefined);
        }
        if (errMsg === true) {
            setMsg(msg);
            setShowErrIc(true);
            if (isFocus) {
                window.scrollTo({
                    top: scrollRef?.current?.offsetTop - 115,
                    behavior: "smooth",
                });
                inpRef.current?.focus();
            }
        }
        if (errMsg === false) {
            setMsg(msg);
            setShowErrIc(false);
        }
    };




    const selectImage = () => {
        refs.current.click()
    }




    return (
        <>
            <input required={Required} type={type} ref={refs} accept={accept} style={{ display: "none" }} onChange={(e) => onChange(e)} />
            <div className="text-input" ref={scrollRef}>

                <span className="text-input___title" >
                    {input_Title}
                    {Required && " *"}
                </span>
                <div className={inputBorder} ref={inpRef} onClick={selectImage}>
                    <div className='diaplayFileName'>
                        {fileName ? fileName : "No File Chosen"}
                    </div>
                    <button className='fileChooseButton textForFileChoose' >
                        Choose File
                    </button>
                </div>

                {isLoading && (
                    <div className="dropdown-blue-loader text-input-mr" />
                )}
                {errorMgs && <div className={`${errorMgs}`}>{msg}</div>}
            </div >
        </>
    )
})

export default FilePicker