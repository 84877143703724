/**
 * @param  {number} statusCode - of api response
 * @param  {string} errorMessage -  of error message from api response 
 * @param  {string} customMsg='' - if pass any customise message then use it
 * @return - object of statuscode,error emssage ,custom error message 
 */
export const ErrorHandlHook = (statusCode, errorMessage, customMsg = '') => {
    var obj = {
        sCode: '',
        eMessage: '',
        msg: '',
    };
    if (statusCode === 404) {
        obj = { sCode: 404, eMessage: errorMessage, msg: customMsg }
    } else if (statusCode === 401) {
        obj = { sCode: 401, eMessage: 'err401', msg: customMsg }
    } else if (statusCode >= 500) {
        obj = { sCode: 500, eMessage: errorMessage, msg: undefined }
    } else if (statusCode === 403) {
        obj = { sCode: 403, eMessage: 'perDenied', msg: undefined }
    } else if (statusCode === 400) {
        obj = { sCode: 400, eMessage: errorMessage, msg: customMsg }
    } else {
        obj = { sCode: statusCode, eMessage: errorMessage, msg: customMsg }
    }
    return obj;
}