import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config";
import "./styles/general.css";
import RootApp from "./RootApp";

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <RootApp />
  </StrictMode>
);
