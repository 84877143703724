import { useEffect, useRef, useState } from 'react'
import { languageCode } from '../contexts/i18context';
import { callApi } from '../services';

/**
 * 
 * @param {string} token - for user authentication
 * @param {boolean} firstApiCall - for first api call
 * @param {number} pageNo - for pagination 
 * @param {string} pageName - for display page name
 * @param {string} language - for specify language
 * @param {string} debouncedVal - for get or set search terms value
 * @param {function} searchParams - for set browser url
 * @param {number} selectedValue - base on seleceted value specify type is news or product
 * @param {boolean} isFilter - for whether filter display or not
 * @param {object} selDate - for display selected date in filter
 * @param {object} selType - for display selected type in filter
 * @param {function} setDates - for set dates in filter
 * @param {function} setType - for set types in filter
 * @param {object} selArr - for display list of apply filter
 * @param {boolean} isNewslist - for specify whether news list or not
 * @param {function} searchParam - for get parameter from url base on key
 * @param {function} setSelectedType - set type in selected array
 * @param {function} setSelectedDates - set date in selected array
 * @param {function} defSortVal - for 
 * @param {object} SortByNews - for display list in filter
 * @param {ref} alertRef - used to hendal error alert popup
 * @returns news data , loading , nextpage call data for news page
 */
const useNewsHook = (
    token,
    firstApiCall,
    pageNo = 0,
    pageName = 'news',
    language,
    debouncedVal,
    searchParams,
    selectedValue,
    isFilter = false,
    selDate = [],
    selType = [],
    setDates,
    setType,
    selArr = [],
    isNewslist = false,
    searchParam,
    setSelectedType,
    setSelectedDates,
    defSortVal,
    SortByNews,
    alertRef
) => {
    const [newsData, setNewsData] = useState([]);
    const [loadingNews, setLoadingNews] = useState(true);
    const [hasNextPageNews, setHasNextPageNews] = useState(false);
    const loadNews = useRef(false);
    const GetKeyVal = (obj) => {
        return Object.keys(obj).map((key) => {
            return { key: key, value: obj[key] };
        });
    };
    let debouncedValue = debouncedVal?.trim() || debouncedVal;

    // eslint-disable-next-line
    const resultof = searchParam?.get("sortBy") !== null ? "sortBy=" + searchParam?.get("sortBy") + "&sortOrder=" + searchParam?.get("sortOrder") : '';
    let sort = SortByNews?.filter((i) => {
        return (i?.value === resultof)
    })
    const getNews = async (isNew = false,
        typeIs = searchParam?.get("newsType") || '',
        dateIs = searchParam?.get("date") || '',
        sortBy = sort ? sort[0]?.value : selArr ? selArr[0]?.value : '') => {
        !loadingNews && setLoadingNews(true);
        loadNews.current = true;
        let pg = 1;
        var finalString = '?';
        if (language?.length > 0) {
            // finalString = finalString + `languageCode=${language}`
        }

        if (debouncedValue?.length > 0) {
            if (finalString?.length > 1) { finalString = finalString + `&search=${debouncedValue}` }
            else { finalString = finalString + `search=${debouncedValue}` }
        }
        if (typeIs?.length > 0) {
            if (finalString?.length > 1) { finalString = finalString + `&newsType=${typeIs}` }
            else { finalString = finalString + `newsType=${typeIs}` }
        }
        if (dateIs?.length > 0) {
            if (finalString?.length > 1) { finalString = finalString + `&date=${dateIs}` }
            else { finalString = finalString + `date=${dateIs}` }
        }
        if (sortBy?.length > 0) {
            if (finalString?.length > 1) { finalString = finalString + `&${sortBy}` }
            else { finalString = finalString + `${sortBy}` }
        }
        if (finalString?.length > 1) {
            if (isNew) { pg = (pageNo !== undefined && pageNo.toString().length > 0) ? `&page=${1}` : ''; }
            else { pg = (pageNo !== undefined && pageNo.toString().length > 0) ? `&page=${pageNo + 1}` : ''; }
        } else {
            if (isNew) { pg = (pageNo !== undefined && pageNo.toString().length > 0) ? `page=${1}` : ''; }
            else { pg = (pageNo !== undefined && pageNo.toString().length > 0) ? `page=${pageNo + 1}` : ''; }
        }

        const searchIndex = selectedValue === 0 ? "products" : selectedValue === 1 ? "news" : selectedValue === 2 ? "projects" : "products";

        if (isNewslist) { searchParams(finalString); }
        else { searchParams !== undefined && debouncedValue ? searchParams(`?searchtype=${searchIndex}&searchquery=${debouncedValue}`) : searchParams(`?searchtype=${searchIndex}`); }

        await callApi("newsList", { token, alertRef }, finalString, pg, languageCode)
            .then(({ res, sCode }) => {
                if (sCode === 401) { setHasNextPageNews(false); loadNews.current = false; }
                if (sCode === 200 || sCode === 202) {
                    if (debouncedValue?.length > 0 && !isNew) { setHasNextPageNews(false) }
                    else if (newsData?.length < res?.body?.items?.length && debouncedValue?.length < 0 /*  res?.body?.totalCount */) { setHasNextPageNews(true) }
                    else { setHasNextPageNews(false) }
                    // setNewsData((p) => isNew ? res?.body?.items : [...p, ...res?.body?.items]);
                    isNew ? setNewsData(res?.body?.items) : setNewsData((p) => [...p, ...res?.body?.items]);
                    if (!(res?.body?.page && res?.body?.pageSize)) {
                        setHasNextPageNews(false)
                    }
                    else if ((isNew ? newsData?.length : [...res?.body?.items, ...newsData].length) < res?.body?.totalCount) {
                        setHasNextPageNews(true)
                    }

                    if (isFilter) {
                        res?.body?.dateCounts && setDates(GetKeyVal(res?.body?.dateCounts));
                        res?.body?.typeCounts && setType(GetKeyVal(res?.body?.typeCounts));
                    }
                    sortBy?.length > 0 && defSortVal(sortBy);
                    let typeObj = GetKeyVal(res?.body?.typeCounts).find((i) => i.key === typeIs);
                    typeIs?.length > 0 && typeObj !== undefined && setSelectedType([typeObj])
                    let dateObj = GetKeyVal(res?.body?.dateCounts).find((i) => i.key === dateIs);
                    dateIs?.length > 0 && dateObj !== undefined && setSelectedDates([dateObj])
                    loadNews.current = false;
                }
                loadNews.current = false;
                setLoadingNews(false);
            }).catch((err) => {
                setLoadingNews(false);
                loadNews.current = false;
            });

    };
    useEffect(() => {
        if (firstApiCall) return;
        if (token === null) return;
        getNews();
        // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        if (firstApiCall) return;
        if (token === null) return;
        if (pageNo === 0) return;
        getNews();
        // eslint-disable-next-line
    }, [pageNo]);

    useEffect(() => {
        if (firstApiCall) return;
        if (token === null) return;
        getNews(true);
        // eslint-disable-next-line
    }, [debouncedValue]);

    return { newsData, loadingNews, hasNextPageNews, getNews, loadNews, setNewsData }
}
export { useNewsHook }