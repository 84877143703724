import React from "react";
import { Link } from "react-router-dom";
import { DISPATCH_BLUE_ICON } from "../../../assets";
import { T } from "../../translation";

/**
 * @returns - order shipment details jsx element
 */

/**
* 
* @param {string} OrderDispatch - use for display order dispatch date
* @param {string} OrderType - use for display order type
* @param {string} OrderNo - use for display order numbr
* @param {string} OrderDescription - use for display order description
* @param {string} path - use for refirect to particular order details page\
* @returns  order shipment details jsx element
*/
function OrderShipments({
  OrderDispatch,
  OrderType,
  OrderNo,
  OrderDescription,
  path,
}) {
  var classTypename = "white";
  var classTypeText = "twhite";

  if (
    String(OrderType).toLowerCase() === "need to contact" ||
    String(OrderType).toLowerCase() === "undefined"
  ) {
    classTypename = "orange";
    classTypeText = "torange";
  } else if (OrderType === "Awaiting Payment") {
    classTypename = "blue";
    classTypeText = "tblue";
  } else if (OrderType === "Confirmed") {
    classTypename = "green";
    classTypeText = "tgreen";
  } else {
    classTypename = "white";
    classTypeText = "twhite";
  }
  return (
    <Link className={`planned-shipments__card  ${classTypename}`} to={path}>
      <div className="planned-shipments-card-flex">
        <div className="planned-shipments-card-dates">
          <img src={DISPATCH_BLUE_ICON} alt="Dispatch" />
          <span className="planned-shipments-card-dispatch">
            <T strProp="orderOrShipmentsDispatch" />
          </span>
          <span className="planned-shipments-card__date">
            {" : "}
            {OrderDispatch || "-- / -- / --"}
          </span>
        </div>

        <div className={`planned-shipments-card-type ${classTypeText}`}>
          <span>{OrderType || "--  --"}</span>
        </div>
      </div>
      <div className="planned-shipments-card-no">
        <span>{OrderNo || "--  --"}</span>
      </div>
      <div className="planned-shipments-card-description">
        <span>{OrderDescription || "--  --"}</span>
      </div>
    </Link>
  );
}

export default OrderShipments;
