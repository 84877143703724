import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Select, MenuItem, FormControl, FormHelperText } from "@mui/material";
import { DOWN_ARROW_GRAY, DOWN_ARROW_ICON } from "../assets";
import { dateFormate } from "../utils";
import { T } from "./translation";

/**
 *
 * @param {String} title - use for display title
 * @param {Object} data - use for display list of options in dropdown
 * @param {function} onChange - use for call function when change option
 * @param {function} onChangeVal - use for call function on change value
 * @param {string} nameKey - use for display label
 * @param {string} valKey - use for
 * @param {string} placeholder - use for display placeholder
 * @param {string} val - use for display default value
 * @param {boolean} isDisabled - use for display whether display that dropdown or disable dropdown
 * @param {boolean} error - use for whether display error or not
 * @param {string} error_Title - use for display error title
 * @param {boolean} Required - use to specify that this field is required or not
 * @param {function} onChangeObj - use for call function on change select options
 * @param {string} defVal - use of
 * @param {boolean} isLoading - use for display loader or not
 * @param {boolean} orangeIc - use of other css style
 * @param {boolean} isQuote - use for display specified base on this parameter
 * @param {boolean} isAdditem - use for display specified base on this parameter
 * @param {referance} ref - use for referance
 * @returns dropdown selection component & css.
 */
const DropDown = forwardRef(
  (
    {
      title,
      data = [],
      onChange = () => {},
      onChangeVal = () => {},
      nameKey = "label",
      valKey = "value",
      placeholder = "Select",
      val = "",
      isDisabled = false,
      error = false,
      error_Title = "",
      Required,
      onChangeObj = () => {},
      defVal = "",
      isLoading = false,
      orangeIc,
      isQuote = false,
      isAdditem = false,
    },
    ref
  ) => {
    const handleChange = (event) => {
      onChangeVal(event.target.value);
      onChange(event);
    };
    useImperativeHandle(ref, () => ({ handleInputBlur, focusDropDown }));

    const [isErr, setIsErr] = useState(false);
    const [errTitle, setErrTitle] = useState("");
    const [isFocus, setIsFocus] = useState(false);

    const scrollRef = useRef(null);
    const dropDownRef = useRef(null);

    const handleInputBlur = (errMsg = false, msg, isFocus = false) => {
      if (errMsg === true) {
        setIsErr(true);
        setErrTitle(msg);
        if (isFocus) {
          window.scrollTo({
            top: scrollRef?.current?.offsetTop - 115,
            behavior: "smooth",
          });
          dropDownRef.current?.focus();
        }
      }
      if (errMsg === false || errMsg === undefined || errMsg === "") {
        setIsErr(false);
        setErrTitle("");
      }
    };

    const focusDropDown = () => setIsFocus(true);

    return (
      <div className="text-input" key={val} ref={scrollRef}>
        {title && (
          <span className="text-input__title">
            {title}
            {Required && " *"}
          </span>
        )}
        <FormControl error={error} focused={isFocus}>
          <Select
            ref={dropDownRef}
            onBlur={() => setIsFocus(false)}
            sx={colorStyles(isErr ? "#ff7070" : "#D7E8EC")}
            id="drop-down-select"
            value={val}
            placeholder={placeholder}
            className="drop-down-selection"
            onChange={handleChange}
            disabled={isLoading || isDisabled}
            displayEmpty
            IconComponent={(p) =>
              isLoading ? (
                <div
                  className="dropdown-blue-loader"
                  style={{ marginRight: "1rem" }}
                />
              ) : orangeIc ? (
                <DOWN_ARROW_ICON
                  {...p}
                  style={{ marginRight: "10px", backgroundColor: "#fff" }}
                />
              ) : (
                <DOWN_ARROW_GRAY
                  {...p}
                  style={{ marginRight: "10px", backgroundColor: "#fff" }}
                />
              )
            }
          >
            {data?.map((i, idx) => {
              let value = "";
              if (isQuote) {
                let created = String(
                  i?.createdDate &&
                    " - " +
                    dateFormate(i?.createdDate) +
                      ` - ` +
                      i?.description.substr(0, 60)
                );
                if (i?.[valKey] === "Create new") {
                  value = "Create new";
                } else {
                  value = `${i?.[valKey]} ${created}`;
                }
              }

              if(isAdditem){
                let prodCode = i?.productCode || "--";
                let desc = i?.description || "--";
                if(i?.[valKey] === ""){
                  value = <T strProp="quoteSelectPartNO" />;
                }else{
                  value = `${prodCode} / ${desc}`;
                }
              }
              return (
                i?.[valKey && nameKey] && (
                  <MenuItem
                    style={{ whiteSpace: "normal" }}
                    disabled={!i?.[valKey] || i?.[valKey] === defVal}
                    key={idx}
                    defaultValue={idx === 12 && i?.[nameKey]}
                    value={i?.[valKey]}
                    id="selection-text"
                    onClick={() => {
                      onChangeObj(i);
                      setIsFocus(false);
                    }}
                    sx={selectColorStyles}
                  >
                    {isQuote || isAdditem ? value : i?.[nameKey]}
                    {/* {i?.[nameKey]} */}
                  </MenuItem>
                )
              );
            })}
          </Select>
          {error ||
            (isErr && (
              <FormHelperText id="dropdown-text">
                {""}
                {error_Title || errTitle}
              </FormHelperText>
            ))}
        </FormControl>
      </div>
    );
  }
);
export default DropDown;

const selectColorStyles = {
  "&:hover": {
    backgroundColor: "#ebf5f990 !important",
  },
  "&:focus": {
    backgroundColor: "#D7E8EC !important",
  },
};

const colorStyles = (hax = "#D7E8EC") => {
  return {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: hax,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: hax,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: hax,
    },
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: hax,
    },
    "&.Mui-disabled": {
      color: hax,
    },
  };
};
