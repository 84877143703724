import React, { Fragment, Suspense, useContext, useEffect } from "react";
import "../styles/components/navbar/navbar.css";
import { Error404, NavBar, PageLoader, ScrollToTop } from "../components";
import { Routes, Route, useLocation } from "react-router-dom";
import {
  Allmenu,
  Quotes,
  CurrentOrder,
  PlannedShipments,
  Statement,
  Shipments,
  AccountSummary,
  History,
  CurrentReturns,
  RecentInvoices,
  RecentOrder,
  RecentShipments,
  ContactPreferences,
  Setting,
  GeneralSettings,
  AddressBook,
  UserSecurityLevel,
  ChangePassword,
  ChangeStockLocation,
  CurrentInvoice,
  Home,
  Search,
  News,
  ProductCategory,
  ProductGridList,
  StorageSystem,
  Inverters,
  ProductDetails,
  PlaceOrder,
  QuotationDetails,
  AddItem,
  QuickAdd,
  EditQuote,
  EditDeliveryAddress,
  ConfirmOrder,
  CreateShipment,
  ConfirmEditOrder,
  ConfirmCreateShipment,
  ShipmentDetails,
  CardPayment,
  OnLinePayment,
  EditShipment,
  ContactUs,
  Documentation,
  IndustryGuides,
  Login,
  Welcome,
  RegisterSuccess,
  SignIn,
  ForgottenPassword,
  OrderDetails,
  TrainingEvents,
  RegisterCountry,
  Payment,
} from "../pages";
import NewsDetails from "../pages/NewsDetails";
import { GlobalContext } from "../contexts";
import { globalActionsTypes } from "../hooks";
import EditAddressBook from "../pages/setting/EditAddressBook";
import ContentPage from "./ContentPage";
import ShippingAddress from "../pages/ShippingAddress";
import DocumentationDetails from "../pages/documentation/gettingstarted/DocumentationDetail";
import DocumentationDetailMenu from "../pages/documentation/gettingstarted/DocumentationDetailMenu";
import NewReturns from "../pages/NewReturns";
import { sendPageView } from "../utils/googleAnalytics";

function AppRoute({ isLogin }) {
  const { state, dispatch } = useContext(GlobalContext);
  const { pathname, search } = useLocation();
  /**
   * @description This function is used to set the root history path in the local
   * storage and also in the global state so that we can use it in the login page
   * to redirect the user to the page from where he/she came to login page.
   */
  useEffect(() => {
    if (isLogin === false) {
      if (
        state?.rootHistoryPath === "/" &&
        state?.rootHistoryPath !== pathname + search &&
        pathname !== "/login"
      ) {
        localStorage.setItem(
          globalActionsTypes.setRootHistoryPath,
          pathname + search
        );
        dispatch({
          type: globalActionsTypes.setRootHistoryPath,
          data: pathname + search,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);
  useEffect(() => {
    setTimeout(() => {
      sendPageView(pathname, document.title)
    }, 100);
  }, [pathname])

  return (
    <>
      <ScrollToTop />
      <div className="mobile-view">
        <NavBar isLogin={isLogin} />
        <div id="mobile-containt">
          <Suspense fallback={<PageLoader isLoading isSmall />}>
            <Routes>
              {/* if islogin true then this part of code execute */}
              {isLogin ? (
                <Fragment>
                  <Route path="/" element={<Home />} />
                  <Route path="account" element={<Allmenu />} />
                  <Route path="basket" element={<QuotationDetails />} />
                  <Route
                    path="quotationdetails/:quoteRefId"
                    element={<QuotationDetails />}
                  />
                  <Route path="quotes" element={<Quotes />} />
                  <Route
                    path="quotes/:quoteRefId/add_item"
                    element={<AddItem />}
                  />
                  <Route
                    path="order/:quoteRefId/add_item"
                    element={<AddItem />}
                  />
                  <Route path="newreturn" element={<NewReturns />} />
                  <Route path="order" element={<CurrentOrder />} />
                  <Route path="order/:orderRefId" element={<OrderDetails />} />
                  <Route
                    path="recentorder/:orderRefId"
                    element={<OrderDetails />}
                  />
                  <Route
                    path="currnetreturn/:orderRefId"
                    element={<OrderDetails />}
                  />
                  <Route
                    path="plannedshipments"
                    element={<PlannedShipments />}
                  />
                  <Route path="shipments/:shipmentId" element={<Shipments />} />
                  <Route path="statement" element={<Statement />} />
                  <Route path="accountsummary" element={<AccountSummary />} />
                  <Route path="currentinvoice" element={<CurrentInvoice />} />
                  <Route path="history" element={<History />} />
                  <Route path="currentreturns" element={<CurrentReturns />} />
                  <Route path="recentinvoices" element={<RecentInvoices />} />
                  <Route path="contactus" element={<ContactUs />} />
                  <Route path="recentorder" element={<RecentOrder />} />
                  <Route path="recentshipments" element={<RecentShipments />} />
                  <Route
                    path="contactpreferences"
                    element={<ContactPreferences />}
                  />
                  <Route path="setting" element={<Setting />} />
                  <Route path="generalsettings" element={<GeneralSettings />} />
                  <Route path="delivery_addressbook" element={<AddressBook />} />
                  <Route path="addaddressbook" element={<EditAddressBook />} />
                  <Route
                    path="usersecuritylevel"
                    element={<UserSecurityLevel />}
                  />
                  <Route path="changepassword" element={<ChangePassword />} />
                  <Route
                    path="changestocklocation"
                    element={<ChangeStockLocation />}
                  />
                  <Route path="productcategory" element={<ProductCategory />} />
                  <Route path="search" element={<Search />} />
                  <Route path="news" element={<News />} />
                  <Route path="product" element={<ProductGridList />} />
                  <Route
                    path="product/:productId"
                    element={<ProductDetails />}
                  />
                  <Route path="storage_system" element={<StorageSystem />} />
                  <Route path="inverters" element={<Inverters />} />
                  <Route path="placeorder" element={<PlaceOrder />} />
                  <Route path="quick_add/:quoteRefId" element={<QuickAdd />} />
                  <Route
                    path="edit_quote/:quoteRefId"
                    element={<EditQuote quotePage={true} />}
                  />
                  <Route
                    path="edit_basket/:basketRefId"
                    element={<EditQuote />}
                  />
                  <Route
                    path="edit_order/:orderRefId"
                    element={<EditQuote orderPage={true} />}
                  />
                  <Route
                    path="edit_orderlist/:orderRefId"
                    element={<ConfirmEditOrder />}
                  />
                  <Route
                    path="edit_shipment/:shipmentId"
                    element={<EditShipment />}
                  />
                  <Route
                    path="quote_edit_delivery_address/:idx"
                    element={<EditDeliveryAddress />}
                  />
                  <Route
                    path="basket_edit_delivery_address/:idx"
                    element={<EditDeliveryAddress />}
                  />
                  <Route
                    path="order_edit_delivery_address/:idx"
                    element={<EditDeliveryAddress />}
                  />
                  <Route
                    path="shipment_edit_delivery_address/:idx"
                    element={<EditDeliveryAddress />}
                  />
                  <Route
                    path="confirm_order/quote/:quoteRefId"
                    element={<ConfirmOrder />}
                  />
                  <Route
                    path="confirm_order/order/:orderRefId"
                    element={<ConfirmOrder />}
                  />
                  <Route
                    path="confirm_order/quotebasket/:basketRefId"
                    element={<ConfirmOrder />}
                  />
                  <Route
                    path="confirmed_order/:quoteRefId"
                    element={<CreateShipment />}
                  />
                  <Route
                    path="confirm_edit_order/:orderRefId"
                    element={<ConfirmEditOrder />}
                  />
                  <Route
                    path="shipment_details/:shipmentId"
                    element={<ShipmentDetails />}
                  />
                  <Route path="card_payment" element={<CardPayment />} />
                  <Route path="online_payment" element={<OnLinePayment />} />

                  <Route
                    path="confirm_create_shipment/:orderRefId"
                    element={<ConfirmCreateShipment />}
                  />
                  <Route path="*" element={<Error404 />} />
                  <Route path="documentation" element={<Documentation />} />
                  <Route path="documentation/:page" element={<DocumentationDetailMenu />} />
                  <Route path="industry_guides" element={<IndustryGuides />} />
                  <Route path=":mainRoute/details/:subRoute" element={<DocumentationDetails />} />
                  <Route path="news_details/:id" element={<NewsDetails />} />
                  <Route path="training_events" element={<TrainingEvents />} />
                  <Route path="payment" element={<Payment />} />
                  <Route path="about-us" element={<ContentPage />} />
                  <Route path="POPIA" element={<ContentPage />} />
                  <Route path="term-and-conditions" element={<ContentPage />} />
                  <Route path="privacy-policy" element={<ContentPage />} />
                  <Route path="change-shipping-address" element={<ShippingAddress />} />
                </Fragment>
              ) : (
                <Fragment>
                  {/* if islogin false then this part of code execute */}
                  <Route path="/login" element={<Login />} />
                  <Route path="*" element={<Welcome />} />
                  <Route path="contactus" element={<ContactUs FromLogin />} />
                  {/* <Route path="register" element={<Register />} /> */}
                  <Route path="register" element={<RegisterCountry />} />
                  <Route
                    path="register_success"
                    element={<RegisterSuccess />}
                  />
                  <Route path="sign_in" element={<SignIn />} />
                  <Route
                    path="forgotten_password"
                    element={<ForgottenPassword />}
                  />
                </Fragment>
              )}
            </Routes>
          </Suspense>
        </div>
      </div>
    </>
  );
}

export default AppRoute;
