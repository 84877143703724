import React, { useContext } from 'react'
import { Il8Context } from '../../contexts'
import { T } from '../translation';

/**
 * @param {string} comments - comment value 
 * @param {function} setComments - set comment value 
 * @param {boolean} isShipment - use for check is shipment page or not
 * @returns - comment input jsx element
 */
const CommentInp = ({ comments, setComments, isShipment = false }) => {
    const { t } = useContext(Il8Context);
    return (
        <div className="text-input margin_bottom">
            <span className="text-input__title">
                {isShipment ?
                    <T strProp="shipmentComments" /> : <T strProp="quoteComments" />
                }
            </span>
            <textarea
                id="w3review"
                name="w3review"
                rows="4"
                cols="50"
                placeholder={t("Typehere")}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
            />
        </div>
    )
}

export default CommentInp