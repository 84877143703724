import { useEffect, useState } from 'react';
import { callApi } from '../services';

/**
 * 
 * @param {string} token - for user authentication
 * @param {number} pageNo - for pagination 
 * @param {number} selectedCatId - for display page name
 * @param {string} selectedSubCatId - for specify language
 * @param {object} selArr - for display list of apply filter
 * @param {string} debouncedValue - for get or set search terms value
 * @param {function} setSearchParam - for set browser url
 * @param {number} searchBrandId - base on seleceted value specify type is news or product
 * @param {boolean} searchMarket - for whether filter display or not
 * @param {object} searchRange - for display selected date in filter
 * @param {object} searchSortDesc - for display selected type in filter
 * @param {function} setPageNo - for set dates in filter
 * @param {ref} alertRef - used to hendal error alert popup
 * @returns news data , loading , nextpage call data for news page
 */
const useProductListHook = (
    token,
    pageNo,
    selectedCatId,
    selectedSubCatId,
    selArr,
    debouncedValue,
    setSearchParam,
    searchBrandId = '',
    searchMarket = '',
    searchRange = '',
    searchSortDesc = '',
    setPageNo = () => { },
    alertRef = null,
) => {
    const [loadingP, setLoadingP] = useState(false);
    const [dataArr, setDataArr] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const getProducts = async (
        selCatIdIs = selectedCatId,
        isSort = searchSortDesc ? `SortDesc=${searchSortDesc}` : '',
        isNew = false,
        isReset = false,
        brandId = searchBrandId,
        marketId = searchMarket,
        rangeId = searchRange,
    ) => {
        setLoadingP(true);
        const cID = parseInt(selCatIdIs);
        var finalString = '?';
        if (cID > 0) {
            finalString = finalString + `CategoryId=${cID}`
        }
        if (selectedSubCatId > 0) {
            if (finalString?.length > 1) {
                finalString = finalString + `&SubCategoryId=${selectedSubCatId}`;
            } else {
                finalString = finalString + `SubCategoryId=${selectedSubCatId}`;
            }
        }
        var sortVal = '';
        if (selArr[0]?.value) {
            sortVal = sortVal + `${selArr[0]?.value}`;
        }
        if (isSort === undefined) {
            if (finalString?.length > 1) {
                if (sortVal?.length > 0) finalString = finalString + `&${sortVal}`;
            } else finalString = finalString + '';
        } else if (isSort === "clear") {
            finalString = finalString + '';
        }
        else if (typeof isSort !== 'boolean' && typeof isSort === 'string' && isSort?.length > 0 && isSort !== "clear") {
            if (finalString?.length > 1) { finalString = finalString + `&${isSort}`; }
            else { finalString = finalString + `${isSort}`; }
        } else {
            if (finalString?.length > 1) {
                if (selArr[0]?.value) {
                    finalString = finalString + `&${selArr[0]?.value}`;
                } else finalString = finalString + `${sortVal}`;
            }
            else {
                finalString = finalString + `${sortVal}`;
            }
        }
        if (brandId?.length > 0) {
            if (finalString?.length > 1) {
                finalString = finalString + `&BrandId=${brandId}`;
            } else {
                finalString = finalString + `BrandId=${brandId}`;
            }
        }
        let pNum = pageNo + 1;
     
        if (marketId?.length > 0) {
            if (finalString?.length > 1) {
                finalString = finalString + `&Market=${marketId}`;
            } else {
                finalString = finalString + `Market=${marketId}`;
            }
        }
     
        if (rangeId?.length > 0) {
            if (finalString?.length > 1) {
                finalString = finalString + `&Range=${rangeId}`;
            } else {
                finalString = finalString + `Range=${rangeId}`;
            }
        }
        if (debouncedValue?.length > 0) {
            if (finalString?.length > 1) {
                 finalString = finalString + `&searchTerm=${debouncedValue}`;
            } else {
                finalString = finalString + `searchTerm=${debouncedValue}`;
            }
        }
        if (finalString?.length > 2) {
            finalString = finalString + `&`;
        }
        let reSetUri = isReset === true ? `?` : `${finalString}`

        const uri = `search${reSetUri}pageNumber=${pNum}&resultsPerPage=10`;

        var urlchange = '';
        var first = finalString?.substring(0, 1);
        if (first === "?") {
            urlchange = urlchange + finalString?.substring(1);
        } else {
            urlchange = urlchange + finalString;
        }

        var last = urlchange?.slice(-1);
        if (last === "&") {
            urlchange = urlchange?.slice(0, -1);
        }
        await callApi("productList", { token, alertRef }, uri)
            .then(({ res, sCode }) => {
                if (sCode === 401) { setHasNextPage(false); }
                let data = res?.body?.result?.items;
                if ((sCode === 200 || sCode === 202) &&
                    Object?.values(data)?.length !== undefined
                ) {
                    let pCount = res?.body?.result?.pageCount;
                    let pNum = res?.body?.result?.pageNumber;
                    setHasNextPage(pCount > pNum);
                    isNew ? setDataArr(Object?.values(data)) : setDataArr((p) => [...p, ...Object?.values(data)]);
                    setSearchParam(isReset ? '' : `?${urlchange}`)
                }
            })
            .catch((err) => {
                setHasNextPage(false);
                console.log(err);
            }).finally(() => setLoadingP(false))
    };

    useEffect(() => {
        if (token === null) return;
        getProducts();
        // return setDataArr([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, pageNo]);


    useEffect(() => {
        if (token === null) return;
        getProducts(selectedCatId, undefined, true);
        setPageNo(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    return { dataArr, loadingP, hasNextPage, getProducts, setLoadingP };
};
export { useProductListHook }
