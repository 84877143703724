import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Il8Context } from "../contexts";

const createTitle = (title) => {
  return Array.isArray(title)
    ? `${title.join(" - ")}`
    : `${title}`;
};

/**
 * @description Helemet - react-side-effect which is not thread-safe. If you are doing anything asynchronous on the server,
 *  you need Helmet to encapsulate data on a per-request basis, this package does just that.
 * @returns
 */
const Layout = ({ title, translate = true, id, children }) => {
  const { t } = useContext(Il8Context);
  let titleText = translate ? t(title) : title;

  const createdTitleText = createTitle(titleText);
  return (
    <section id={id}>
      <Helmet>
        <title>{createdTitleText}</title>
        <meta name="description" content="Segen" />
        <meta name="theme-color" content="#000" />
        {/* ============================= */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta property="og:title" key="og:title" content={createdTitleText} />
        <meta charSet="utf-8" />
        <meta property="og:locale" key="og:locale" content="en_US" />
        <meta
          property="og:description"
          key="og:description"
          content="Segen_Wholesale"
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:image"
          key="og:image"
          content="./images/SegenTitleLogo.svg"
        />
        <meta name="twitter:title" content="Segen_Wholesale" />
        <meta name="Twitter:description" content="Segen_Wholesale" />
        <meta
          name="twitter:image"
          content="./images/SegenTitleLogo.svg"
        />
      </Helmet>
      {children}
    </section>
  );
};

export default Layout;
