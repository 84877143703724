import { lazy } from "react";
const Welcome = lazy(() => import("./Welcome"));
const Login = lazy(() => import("./Login"));
const Register = lazy(() => import("./Register"));
const RegisterSuccess = lazy(() => import("./RegisterSuccess"));
const SignIn = lazy(() => import("./SignIn"));
const ForgottenPassword = lazy(() => import("./ForgottenPassword"));
const Allmenu = lazy(() => import("./Allmenu"));
const Quotes = lazy(() => import("./Quotes"));
const CurrentOrder = lazy(() => import("./CurrentOrder"));
const PlannedShipments = lazy(() => import("./planned-shipments/PlannedShipments"));
const Statement = lazy(() => import("./Statement"));
const Shipments = lazy(() => import("./planned-shipments/Shipments"));
const AccountSummary = lazy(() => import("./AccountSummary"));
const History = lazy(() => import("./history/History"));
const CurrentReturns = lazy(() => import("./history/CurrentReturns"));
const RecentInvoices = lazy(() => import("./history/RecentInvoices"));
const RecentOrder = lazy(() => import("./history/RecentOrder"));
const RecentShipments = lazy(() => import("./history/RecentShipments"));
const ContactPreferences = lazy(() => import("./ContactPreferences"));
const Setting = lazy(() => import("./setting/Setting"));
const GeneralSettings = lazy(() => import("./setting/GeneralSettings"));
const AddressBook = lazy(() => import("./setting/AddressBook"));
const EditAddressBook = lazy(() => import("./setting/EditAddressBook"));
const UserSecurityLevel = lazy(() => import("./setting/UserSecurityLevel"));
const ChangePassword = lazy(() => import("./setting/ChangePassword"));
const ChangeStockLocation = lazy(() => import("./setting/ChangeStockLocation"));
const CurrentInvoice = lazy(() => import("./CurrentInvoice"));
const CurrentInvoiceData = lazy(() => import("./CurrentInvoice"));
const ContactUs = lazy(() => import("./ContactUs"));
const ProductCategory = lazy(() => import("./products/ProductCategory"));
const ProductSubCategory = lazy(() => import("./products/ProductSubCategory"));
const ProductList = lazy(() => import("./products/ProductList"));
const ProductGridList = lazy(() => import("./products/ProductGridList"));
const StorageSystem = lazy(() => import("./products/StorageSystem"));
const Inverters = lazy(() => import("./products/Inverters"));
const ProductDetails = lazy(() => import("./products/ProductDetails"));
const PlaceOrder = lazy(() => import("./PlaceOrder"));
const Search = lazy(() => import("./Search"));
const QuotationDetails = lazy(() => import("./quotation/QuotationDetails"));
const AddItem = lazy(() => import("./quotation/AddItem"));
const QuickAdd = lazy(() => import("./quotation/QuickAdd"));
const EditQuote = lazy(() => import("./quotation/EditQuote"));
const EditDeliveryAddress = lazy(() => import("./quotation/EditDeliveryAddress"));
const ConfirmOrder = lazy(() => import("./quotation/ConfirmOrder"));
const CreateShipment = lazy(() => import("./quotation/CreateShipment"));
const ConfirmEditOrder = lazy(() => import("./quotation/ConfirmEditOrder"));
const ConfirmCreateShipment = lazy(() => import("./quotation/ConfirmCreateShipment"));
const ShipmentDetails = lazy(() => import("./quotation/ShipmentDetails"));
const CardPayment = lazy(() => import("./quotation/CardPayment"));
const OnLinePayment = lazy(() => import("./quotation/OnLinePayment"));
const EditShipment = lazy(() => import("./quotation/EditShipment"));
const Documentation = lazy(() => import("./documentation/Documentation"));
const GettingStarted = lazy(() => import("./documentation/gettingstarted/DocumentationDetailMenu"));
const IndustryGuides = lazy(() => import("./documentation/gettingstarted/DocumentationDetail"));
const News = lazy(() => import("./News"));
const Home = lazy(() => import("./Home"));
const PlannedShipmentContent = lazy(() => import("./planned-shipments/PlannedShipmentContent"));
const QuickAddCard = lazy(() => import("./quotation/QuickAddCard"));
const NewsContent = lazy(() => import("./NewsContent"));
const OrderDetails = lazy(() => import("./orders/OrderDetails"));
const EditOrder = lazy(() => import("./orders/EditOrder"));
const TrainingEvents = lazy(() => import("./TrainingEvents"));
const RegisterCountry = lazy(() => import("./RegisterCountry"));
const Payment = lazy(() => import("./Payment"));

export {
  Welcome,
  Login,
  Register,
  RegisterSuccess,
  SignIn,
  ForgottenPassword,
  Allmenu,
  Quotes,
  CurrentOrder,
  PlannedShipments,
  Statement,
  Shipments,
  AccountSummary,
  ShipmentDetails,
  OnLinePayment,
  EditShipment,
  ContactUs,
  History,
  CurrentReturns,
  RecentInvoices,
  RecentOrder,
  RecentShipments,
  ContactPreferences,
  Setting,
  GeneralSettings,
  AddressBook,
  EditAddressBook,
  UserSecurityLevel,
  ChangePassword,
  ChangeStockLocation,
  CurrentInvoice,
  CurrentInvoiceData,
  Documentation,
  GettingStarted,
  IndustryGuides,
  TrainingEvents,
  Home,
  ProductSubCategory,
  Search,
  News,
  ProductCategory,
  ProductList,
  ProductGridList,
  StorageSystem,
  Inverters,
  ProductDetails,
  PlaceOrder,
  QuotationDetails,
  AddItem,
  QuickAdd,
  EditQuote,
  EditDeliveryAddress,
  ConfirmOrder,
  CreateShipment,
  ConfirmEditOrder,
  CardPayment,
  ConfirmCreateShipment,
  PlannedShipmentContent,
  QuickAddCard,
  NewsContent,
  OrderDetails,
  EditOrder,
  RegisterCountry,
  Payment,
};