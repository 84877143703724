import React, { Fragment } from "react";
import "../../styles/components/news/newscard.css";

/**
 * @param {object} Row news data
 * @returns - news details card jsx element
 */
const NewsDetailCard = ({ Row }) => {
  const contentRow = (content, ind) => {
    return (
      <div className={`news-detail-content${content?.grid}`} key={ind}>
        {content?.controls?.map((item, index) => {
          return (
            <Fragment key={index}>
              {item?.image?.src ? (
                <img src={item?.image?.src} alt="img" className="news-img" />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: item?.value }} />
              )}
            </Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <section className="news-detail-card">
      {Row?.areas?.map((item, index) => {
        return contentRow(item, index);
      })}
    </section>
  );
};

export default NewsDetailCard;
