import React, { useContext } from "react";
import { Il8Context } from "../../contexts";
import TextInput from "../modual/TextInput";
import { T } from "../translation";

/**
 * @param {refrence} contactRef - for hendal validation warning on contact
 * @param {function} setContactName - for set contact name
 * @param {string} contactName - for contact name
 * @param {string} contactPhone - for contact phone
 * @param {function} setContactPhone - for set contact phone
 * @param {refrence} contactPhoneRef - for hendal validation warning on contactPhone
 * @param {string} input_Title - to dynamic title of input field
 * @param {boolean} isShipment - to dynamic title of input field
 * @returns  - Contact Name and Contact Phone input whith validation
 */
const NamePhoneInp = ({
  contactRef,
  setContactName,
  contactName,
  contactPhone,
  setContactPhone,
  contactPhoneRef,
  input_Title = "",
  isShipment = false,
}) => {
  const { t } = useContext(Il8Context);
  return (
    <>
      <div className="margin_bottom">
        <TextInput
          ref={contactRef}
          input_Title={t("Contact")}
          Required
          value={contactName}
          onChange={(e) => {
            setContactName(e.target.value);
            contectNameValidation(e.target.value, contactRef);
          }}
          Placeholder={t("EnterContact")}
        />
      </div>
      <div className="margin_bottom">
        <TextInput
          ref={contactPhoneRef}
          input_Title={isShipment ? t("EnterShipmentContact") : t(input_Title || "contactPhone")}
          Required
          value={contactPhone}
          onChange={(e) => {
            /*---  below regex allow "+" in front of the number, sign. "" empty string & numbers only ---*/
            const regex = /^[+]?[0-9]*$/;
            if (e.target.value === "" || regex.test(e.target.value)) {
              setContactPhone(e.target.value);
              // contactPhoneRef.current?.handleInputBlur("", "");
              contectphoneValidat(e.target.value, contactPhoneRef);
            }
          }}
          Placeholder={t("EnterPhone")}
          keyBoard_Type="tel"
          maxLength="15"
        />
      </div>
    </>
  );
};

export default NamePhoneInp;

/**
 * @param {string} value - user input value of contact name
 * @param {refrence} ref - handal to validation
 * @returns - bollean (validate = true , not validate = false)
 */
export const contectNameValidation = (value, ref) => {
  if (value?.trim().length <= 0) {
    ref.current?.handleInputBlur(
      true,
      <T strProp="Entercontactpersonname" />,
      true
    );
    return false;
  } else {
    ref.current?.handleInputBlur("", "");
    return true;
  }
};
/**
 * @param {string} value - user input value of contact phone
 * @param {*} ref - handal to validation
 * @returns - bollean (validate = true , not validate = false)
 */
export const contectphoneValidat = (value, ref) => {
  if (value?.trim().length <= 0) {
    ref &&
      ref.current?.handleInputBlur(
        true,
        <T strProp="PleaseEnterPhone" />,
        true
      );
    return false;
  } else {
    /*  var regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    if (!regex.test(value)) {
      ref && ref.current?.handleInputBlur(true, <T strProp="PleaseEnterPhoneValid" />, true);
      return false;
    } else */ if (value?.length <= 4) {
      ref &&
        ref.current?.handleInputBlur(
          true,
          <T strProp="PleaseEnterPhoneDegit" />,
          true
        );
      return false;
    } else {
      ref && ref.current?.handleInputBlur("", "");
      return true;
    }
  }
};
