import React, { useRef, useState, useEffect } from "react";
import "./SegmentedControls.css";

/**
 * 
 * @param {string} name - use for indentofy segment name
 * @param {object} segments - use for pass all segment list
 * @param {function} callback - use for call function when click any segment
 * @param {number} defaultIndex - use for displauy default segment
 * @returns this function display all segment and handle click event.
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-segmented-control-component
 */
const SegmentedControls = ({
  name,
  segments = [],
  callback,
  defaultIndex = 0,
  /* controlRef, */
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);
  const controlRef = useRef();
  const componentReady = useRef();

  // Determine when the component is "ready"
  useEffect(() => {
    componentReady.current = true;
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    const activeSegmentRef = segments[activeIndex].ref;
    const { offsetWidth, offsetLeft } = activeSegmentRef.current;
    const { style } = controlRef.current;

    style.setProperty("--highlight-widths", `${offsetWidth}px`);
    style.setProperty("--highlight-x-poss", `${offsetLeft}px`);
  }, [activeIndex, callback, controlRef, segments]);

  const onInputChange = (value, index) => {
    setActiveIndex(index);
    callback(value, index);
  };

  return (
    <div className="controls-containers" ref={controlRef}>
      <div className={`controlss ${componentReady.current ? "ready" : "idle"}`}>
        {segments?.map((item, i) => {
          return (
            <div
              key={item.value}
              className={`segments ${
                i === activeIndex ? "active" : "inactive"
              }`}
              ref={item.ref}
            >
              <input
                type="radio"
                value={item.value}
                id={item.Icon}
                name={name}
                onChange={() => onInputChange(item.value, i)}
                checked={i === activeIndex}
              />
              <label htmlFor={item.Icon}>{item.Icon}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SegmentedControls;
