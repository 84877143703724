import {deDE } from './de-DE';
import { enGB } from './en-GB';
// When real translations are provided, the following code should be added / uncommented
// import { enZA } from './en-ZA';
// import { deDE } from './de-DE';
// import { arAE } from './ar-AE';

// When real translations are provided, the following code should be removed / commented out
const createLanguagePack = (prefix, data) => {
  return Object.entries(data).reduce((acc, entries) => {
    acc[entries[0]] = `${prefix} ${entries[1]}`;
    return acc;
  }, {});
};
const enZA = createLanguagePack('[en-ZA]', enGB);
// const deDE = createLanguagePack('[de-DE]', DDDD);
const arAE = createLanguagePack('[ar-AE]', enGB);

// NOTE: the imports are structured in such a way to minimise code conflicts during development
// since most stories involve translations

export { enGB, deDE, arAE, enZA };
  