import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import {
  CLOSE_WHITE_ICON,
  DELET_BG_RED_ICON,
  EDIT_BLUE_ICON,
  TRUCK_GREEN_ICON,
  TRUCK_BLACK_ICON,
  PRODUCTSTOCK_ICON,
  RIGHT_ICON,
  QUESTION_MARK_ICON,
  DELETE_RED_ICON,
} from "../../assets";
import { getExpectedResultHAX_COLOR, toLower, toPrice } from "../../utils";
import ButtonModual from "../modual/ButtonModual";
import { T } from "../translation";
// import { Il8Context } from "../../contexts";
import { stockStatus } from "../../utils/stockUtils";
import { shipStatus } from "../../utils/functions";
import QuantityCounter from "../QuantityCounter";
// import BtnSmall from "../BtnSmall";

/**
 * @param {number} index - Use for particular item idex
 * @param {number} filename - use of get details base on from which page user can use this component
 * @param {boolean} isPriceRow - use for display price row section
 * @param {boolean} isAllocatedRow - display allocated details
 * @param {boolean} isExpected - use for display expected section
 * @param {boolean} isTruck - use for display truck icon
 * @param {boolean} isFindAlertnates - use for display find alternates
 * @param {boolean} isEdit - use for display edit and delete options
 * @param {object} item - get particular item
 * @param {object} data - use for get all details
 * @param {boolean} isDeleting - use for display loader
 * @param {boolean} isDeletingYes - use for display loader in Yes button
 * @param {boolean} isStock - use for whether display in stock section or not
 * @param {function} setDelAlert - use for display delete pop up
 * @param {function} setItemIdDelete - use for set particular item id for delete
 * @param {function} setItemIdDeleteIdx - use for set particular item index for delete
 * @param {function} EditItemsOpen - use for set pop up edit model open and close
 * @param {function} setDeleteData - use for set particular item data for delete
 * @param {boolean} isDisplayUnitPrice - use for whether display unit price or not
 * @returns item list jsx elements
 */
const ItemListComponentDetail = ({
  index,
  filename = "",
  isPriceRow = true,
  isAllocatedRow = false,
  isExpected = false,
  isTruck = false,
  isFindAlertnates = true,
  isEdit = false,
  item = {},
  data = {},
  isDeleting = false,
  isDeletingYes = false,
  isStock = false,
  setDelAlert = () => { },
  setItemIdDelete = () => { },
  setItemIdDeleteIdx = () => { },
  EditItemsOpen = () => { },
  setDeleteData = () => { },
  isDisplayUnitPrice = false,
  showButton = false,
  newReturn = false,
  counter,
  setCounter = () => { },
  getClickedIndex,
  selectedProduct,
  isFilled,
  removePart,
  showQuestions
}) => {
  const { quantityInStock } = stockStatus(item?.stockLevels);
  const [showInfo, setShowInfo] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  // eslint-disable-next-line
  const [quePopup, setQuePopup] = useState(false);
  // eslint-disable-next-line
  const [delPopData, setDelPopData] = useState([]);
  const shipmentStatus = toLower(item?.shipmentStatus);
  let expected = isExpected && getExpectedResultHAX_COLOR(item?.expected);

  return (
    <Fragment key={index}>
      {item?.productCode !== "DELIVERY" && (
        <>
          <div className="quotationdetails-item-pd" key={index}>
            <div className="quotationdetails-item-number">{index + 1}</div>
            <div className="quotationdetails-item__flex-col">
              <div className="edit-shipment-other-item-mb">
                {isTruck && (
                  <>
                    <div className="quote_dis_fx">
                      {item?.shipmentId !== null &&
                        item?.shipmentStatus !== null && (
                          <img
                            src={
                              shipStatus(shipmentStatus)
                                ? TRUCK_GREEN_ICON
                                : TRUCK_BLACK_ICON
                            }
                            alt="Car"
                            className="cursor-pointer con-creat-shipm-mr"
                            onClick={() => {
                              setShowInfo(true);
                              setOrderDetails(item);
                            }}
                          />
                        )}
                      <Link
                        to={isEdit && `/product/${item?.productCode}`}
                        className={`quotationdetails-item-productCode ${!isEdit && `text-decoreation-none`}`}
                      >
                        {item?.productCode || "--"}
                      </Link>
                    </div>
                  </>
                )}
                {!isTruck && (
                  <Link
                    to={`/product/${item?.productCode}`}
                    className="quotationdetails-item-productCode "
                  >
                    {item?.productCode || "--"}
                  </Link>
                )}
              </div>
              <div className="quotationdetails-item-description">
                <span>{newReturn ? item.productDescription : item?.description || "--"}</span>
              </div>
            </div>
            <div className="quotationdetails-item__price">
              {newReturn ? toPrice({ ...item.linePrice, amount: counter === 0 ? undefined : item.linePrice.amount * counter }) : toPrice(item?.totalPrice)}
            </div>
          </div>
          {isPriceRow && (
            <div
              className={
                isAllocatedRow
                  ? "quotationdetails-item-flex-row"
                  : "quotationdetails-item-flex-row_"
              }
            >
              {isDisplayUnitPrice &&
                <div className="display_column">
                  <span className="quotationdetails-item-gray-text">
                    <T strProp="quoteUnitPrice" />
                  </span>
                  <span className="quotationdetails-item-price">
                    {toPrice(item?.grossPrice)}
                  </span>
                </div>
              }
              {isDisplayUnitPrice && (
                <div className="display_column">
                  <span className="quotationdetails-item-gray-text">
                    <T strProp="basketItemDiscount" />
                  </span>
                  <span className="quotationdetails-item-price">
                    {item?.discountPercent > 0
                      ? item?.discountPercent + " %"
                      : "--"}
                  </span>
                </div>
              )}
              <div className="display_column">
                <span className="quotationdetails-item-gray-text">
                  {newReturn ? <T strProp="shipmentquantity" /> : <T strProp="quoteNetPrice" />}
                </span>
                <span className="quotationdetails-item-price">
                  {newReturn ? item?.quantity : toPrice(item?.netPrice)}
                </span>
              </div>
              {isAllocatedRow && (
                <>
                  <div className="display_column">
                    <span className="quotationdetails-item-gray-text">
                      {newReturn ? <T strProp="returnQuantity" /> : <T strProp="quoteAllocated" />}
                    </span>
                    <span className="quotationdetails-item-price">
                      {" "}

                      {newReturn ? Math.abs(item?.returnedQuantity) : typeof item?.quantityAllocated === "number"
                        ? item?.quantityAllocated
                        : "--"}
                    </span>
                  </div>
                  <div className="display_column">
                    <span className="quotationdetails-item-gray-text">
                      {newReturn ? <T strProp="UnitPrice" /> : <T strProp="productDelivered" />}
                    </span>
                    <span className="quotationdetails-item-price">
                      {" "}
                      {newReturn ? toPrice(item.linePrice) : typeof item?.quantityDelivered === "number"
                        ? item?.quantityDelivered
                        : "--"}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
          {newReturn &&
            <>
              <div className="quotationdetails-item__flex-row">
                {Number(item.quantity - Math.abs(item.returnedQuantity)) ?
                  <div className="quotationdetails-item-quantity___">
                    <T strProp="returnnQuantity" /> &nbsp; {<QuantityCounter minVal={0} maxVal={item.quantity - Math.abs(item.returnedQuantity)} setVal={(c) => setCounter(c)} val={counter} />}
                  </div>
                  :
                  <div className="quotationdetails-item-quantity_____">
                    <T strProp='itemAlreadyReturned' />
                  </div>
                }
                <div className="delBtnBG___ cursor-pointer" onClick={() => { removePart(item) }}>
                  <img src={DELETE_RED_ICON} alt="Del" />
                </div>
              </div>
              {(counter > 0 && showQuestions) && <div onClick={() => { getClickedIndex(index); selectedProduct(item.productCode) }} className="quotationdetails-item__flex-row qustions_confirm_div" style={{ backgroundColor: !isFilled ? "#EEFCF0" : "#FDF6EA" }} >
                <span>
                  <img src={!isFilled ? RIGHT_ICON : QUESTION_MARK_ICON} alt="icon" />
                </span>
                <span >
                  <T strProp={"SupportingInformation"} />*
                </span>
              </div>}
            </>
          }
          {!newReturn && <div className="quotationdetails-item__flex-row">
            <div className="quotationdetails-item-quantity">
              <T strProp="Quantity" /> :{" "}
              {item?.quantity >= 0 ? item?.quantity : "0"}
            </div>

            {isExpected && (
              <>
                {" "}
                <div className="quote_dis_fx_mr_">
                  <div className="confirm-shipment-exp">
                    <T strProp="quoteExpected" /> :{" "}
                  </div>
                  <span className="current-quotes-card-instock__" style={{ background: `${expected?.bgCol}`, color: `${expected?.color}` }}>{expected?.title || "--"}</span>
                </div>
              </>
            )}

            {isStock && (
              <div className="quote_dis_fx_mr_">
                {quantityInStock === 0 ? (
                  <span className="quotationdetails-item-quantity">
                    Contact us
                  </span>
                ) : item?.stockLevels[0]?.quantity ? (
                  <>
                    <div className="confirm-shipment-exp">
                      <T strProp="quoteExpected" /> :{" "}
                    </div>
                    <div className="stock_succ-box_">
                      <img src={PRODUCTSTOCK_ICON} alt="icon" />
                      <div className="stock_succ-box_text">
                        <span className="_value">
                          {quantityInStock}
                        </span>
                        <span className="_stock">
                          {" "}
                          <T strProp="productStockInStock" />
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <span className="quotationdetails-item-quantity">
                    Contact us
                  </span>
                )}
              </div>
            )}
            {item?.isDiscontinued === true && (
              <div className="quotationdetails-item-discontinued">
                <T strProp="quoteDiscontinued" />
              </div>
            )}
            {isFindAlertnates && filename !== "basket" && !isExpected ? (
              <div className="quotationdetails-item-find-alter">
                <T strProp="quoteFindAlternates" />
              </div>
            ) : null}
          </div>}
          {(isEdit && showButton) && (
            <>
              <div className="quota_di_fx_mrt">
                <img
                  src={EDIT_BLUE_ICON}
                  alt="icon"
                  className="margin-right-8"
                  onClick={() => EditItemsOpen(item, index)}
                />
                <img
                  src={DELET_BG_RED_ICON}
                  alt="icon"
                  onClick={() => {
                    // if (item?.shipmentId !== "" && item?.shipmentId !== null) {
                    //   setQuePopup(true);
                    //   setDeleteData(item);
                    //   setDelPopData(item);
                    //   setItemIdDelete(item?.id);
                    // } else {
                    setDelAlert(true);
                    setDeleteData(item);
                    setItemIdDelete(item?.id);
                    setItemIdDeleteIdx(index);
                    // }
                  }}
                />
              </div>
            </>
          )}
          {filename !== "recentorder" &&
            data?.items?.[index + 1]?.productCode !== "DELIVERY" &&
            data?.items?.[index + 1]?.productCode !== undefined && (
              <div className="border__line_mr" />
            )}
          {data?.items?.[index + 1]?.productCode === "DELIVERY" && (
            <div className="border__line_mr_tb" />
          )}
        </>
      )}
      {item?.productCode === "DELIVERY" && (
        <>
          <div key={index} className="quotationdetails-item-delivery-p">
            <div className="quotationdetails-item-delivery-flex">
              <span className="quotationdetails-item-productCode">
                <T strProp="Delivery" />
              </span>
              <span className="quotationdetails-item__price">
                {toPrice(item?.netPrice)}
              </span>
            </div>
            <div>
              <span className="quotationdetails-item-description">
                {item?.description || "--"}
              </span>
            </div>
          </div>
        </>
      )}

      {/* ==============Details Model :  When click on truck============== */}
      <ButtonModual Open={showInfo} onClose={() => setShowInfo(false)}>
        <div className="popup_event">
          <div className="popup_event-flex">
            <img
              src={CLOSE_WHITE_ICON}
              alt="icon"
              onClick={() => setShowInfo(false)}
              className="cursor-pointer"
            />
          </div>

          <div className="popup_event__text">
            <div>
              <span>
                <span className="popup_event__title"><T strProp="Shipment" /></span> :{" "}
                <Link className="color-black" to={`/shipment_details/${orderDetails?.shipmentId}`}>
                  <span>{orderDetails?.shipmentId}</span>
                </Link>
              </span>
            </div>
            <div>
              <span className="popup_event__title"><T strProp="contactDescription" /></span> :{" "}
              <span>{orderDetails?.description}</span>
            </div>
            <div>
              <span className="popup_event__title"><T strProp="Status" /></span> :{" "}
              <span>{orderDetails?.shipmentStatus}</span>
            </div>
            {/* <div>
              <span className="popup_event__title">Shipping Date</span> :{" "}
              <span>{orderDetails?.shipmentStatus}</span>
            </div> */}
          </div>
        </div>
      </ButtonModual>
      {/* ============== End Model ============== */}
    </Fragment>
  );
};

export default ItemListComponentDetail;
