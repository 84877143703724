import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, Il8Context } from "../../contexts";
import { globalActionsTypes } from "../../hooks";
import { callApi } from "../../services";
import DropDown from "../DropDown";
import { T } from "../translation";

/**
 * @param {string} token - for user authentication
 * @param {function} setCountrySelection - for set contry selected id
 * @param {number} countrySelection - for contry selection id
 * @param {refrence} countryRef - handal to select contry validation warning 
 * @param {function} setCountryName - for set contry selected Name 
 * @returns - country selection dropdown and validation 
 */
const CountryInp = ({
  token,
  setCountrySelection,
  countrySelection,
  countryRef = null,
  setCountryName = () => { },
}) => {
  const { t } = useContext(Il8Context);
  const { state, dispatch } = useContext(GlobalContext);
  // const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);

  /**
   * get country data from api
   */
  const getCountry = async () => {
    !loading && setLoading(true);
    await callApi("country", { token })
      .then(({ res, sCode }) => {
        if ((sCode === 200 || sCode === 202) && res?.body?.result?.length > 0) {
          dispatch({
            type: globalActionsTypes.setCountry,
            // data: res?.body?.result,
            data: ([
              { id: "", name: "Please Select Country" },
              ...res?.body?.result,
            ])
          });
          // setCountryList([
          //   { id: "", name: "Please Select Country" },
          //   ...res?.body?.result,
          // ]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (token === null) return;

    if (state.countrys.length < 1) {
      getCountry();
    }
    // eslint-disable-next-line 
  }, [token]);

  return (
    <div className="margin_bottom">
      <DropDown
        ref={countryRef}
        title={t("Country")}
        data={state.countrys}
        // data={countryList}
        val={state.countrys?.length > 0 ? countrySelection : ""}
        // val={countryList?.length > 0 ? countrySelection : ""}
        placeholder={t("SelectCountry")}
        nameKey="name"
        valKey="id"
        onChangeVal={(e) => {
          setCountrySelection(e);
          countryRef !== null && countryRef.current?.handleInputBlur("", "");
        }}
        onChangeObj={(obj) => setCountryName(obj?.name)}
        isDisabled={state.countrys?.length === 0}
        // isDisabled={countryList?.length === 0}
        Required={countryRef !== null && true}
        isLoading={loading}
      />
    </div>
  );
};

export default CountryInp;

/**
 * @param {number} value - user input value of country id
 * @param {refrence} ref - handal to validation
 * @returns -  bollean (validate = true , not validate = false) 
 */
export const countryValidation = (value, ref) => {
  if (
    value?.toString()?.length === 0 ||
    value === null ||
    value === undefined
  ) {
    ref.current?.handleInputBlur(true, <T strProp="PleaseSelectCountry" />, true);
    return false;
  } else {
    ref.current?.handleInputBlur("", "");
    return true;
  }
};
