import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  BackMenuCard,
  CheckModual,
  SortByModual,
  SegmentedControls,
  MainMenu,
  PageLoader,
  T,
  Layout,
  GridListSegment,
  AlertPopup,
  UpDownScroll
} from "../../components";
import {
  Il8Context,
  productListInitialState,
  ProductReducer,
  setCatId,
  setCheckedFilter,
  setLoadingMore,
  setSearch,
  setSelectedFilter,
  setShowSortby,
  setTabIndex,
  /*  setAddingToQuote, setLoadingQuote, setOpenQuote, setProdCode, setProdQty, setQuoteAdded, setQuotesList, setSelRef, setShowQuote, setShowQuotesList */
} from "../../contexts";
import {
  CLOSE_GRAY_ICON,
  FILTER_BLUE_ICON,
  SEARCH_BLUE_ICON,
  SEARCH_CLEAR_ICON,
} from "../../assets";
import {
  useDebounce,
  useSessionHook,
  useProductGridListHook,
} from "../../hooks";
import "../../styles/components/product/productlist.css";
import ProductListContent from "./ProductListContent";
import MenuContext from "../../contexts/menuContext";
import { useSearchParams } from "react-router-dom";
import { SortByProduct } from "../../fake-data";

/**
 *
 * @param {object} cArr pass selected filter object and base on that create query
 * @returns this function can create product query base on pass object data.
 */
export const getProdQuery = (cArr) => {
  return cArr
    ?.map(
      (i) =>
        (i?.title === "Categories" &&
          `CategoryId=${i?.value}${i?.subCatVal && `&SubCategoryId=${i?.subCatVal}`
          }`) ||
        (i?.title === "Brands" && `BrandId=${i?.value}`) ||
        (i?.title === "Markets" && `Market=${i?.value}`) ||
        (i?.title === "Range" && `Range=${i?.value}`) ||
        (i?.title === "SortBy" && `${i?.value}`)
    )
    .join("&");
};

/**
 *
 * @param {object} cArr pass selected filter object and base on that create query
 * @returns this function can create filter query base on pass object data.
 */
export const getFilterQuery = (cArr) => {
  return cArr
    ?.map(
      (i) =>
        (i?.title === "Categories" &&
          `CategoryId=${i?.value}${i?.subCatVal && `&SubCategoryId=${i?.subCatVal}`
          }`) ||
        // (i?.title === "Categories" && i?.subCatVal !== "" && `SubCategoryId=${i?.value}`) ||
        (i?.title === "Brands" && `BrandId=${i?.value}`) ||
        (i?.title === "Markets" && `Market=${i?.value}`) ||
        (i?.title === "Range" && `Range=${i?.value}`)
    )
    .join("&");
};

/**
 *
 * @returns product list page jsx elements
 */
const ProductListGrid = () => {
  const [searchParam, setSearchParam] = useSearchParams("");
  const searchQuery = searchParam?.get("SearchTerm") || "";
  const alertRef = useRef(null);
  const { t } = useContext(Il8Context);
  const sessionHelpers = useSessionHook();
  const { token } = sessionHelpers.getStatusAndToken();
  const { setOtherMenu, otherMenuData, setOtherMenuData } =
    useContext(MenuContext);
  const [s, disp] = useReducer(ProductReducer, {
    ...productListInitialState,
    searchTxt: searchQuery,
  });
  const {
    data,
    loading,
    searchTxt,
    pageCount,
    pageNumb,
    tabIdx,
    loadingMore,
    loadingFilter,
    checkedFilter,
    selectedFilter,
    filterArr,
    showSortby /* catId, refreshing, quotesList, showQuote, openQuote, quoteAdded, loadingQuote, showQuotesList, selRef, addingToQuote, prodCode, prodQty */,
  } = s;

  const [backmenuName, setBackmenuName] = useState("");
  const [backmenuName3, setBackmenuName3] = useState("");
  const dVal = useDebounce(s.searchTxt, 800);
  const { getProduct } = useProductGridListHook({
    token,
    dVal,
    s,
    disp,
    alertRef,
    searchParam,
    setSearchParam,
  });

  const BackmenuName = () => {
    let catIdx = selectedFilter.findIndex((i) => i.title === "Categories");
    setBackmenuName(catIdx !== -1 ? selectedFilter[catIdx].displayName : "");
    setBackmenuName3(catIdx !== -1 ? selectedFilter[catIdx].subCat : "");
  };

  useEffect(
    () => BackmenuName(),
    // eslint-disable-next-line
    [selectedFilter]
  );

  const intObserver = useRef(null);

  /**
   * @returns used to update page no to pagination
   */
  const lastPostRef = useCallback(
    (post) => {
      if (loading) return;
      if (loadingMore) return;
      if (pageNumb >= pageCount) return;
      if (intObserver.current) intObserver.current.disconnect();
      intObserver.current = new IntersectionObserver((p) => {
        if (p[0].isIntersecting) {
          disp(setLoadingMore(true));
          getProduct(
            false,
            getProdQuery(selectedFilter),
            getFilterQuery(selectedFilter),
            true,
            selectedFilter
          );
        }
      });
      if (post) intObserver.current.observe(post);
    },
    // eslint-disable-next-line
    [
      loading,
      loadingMore,
      pageNumb,
      pageCount,
      getProduct,
      selectedFilter,
      intObserver.current,
    ]
  );

  /**
   *
   * @param {object} item - use for pass list of iem object
   * @param {boolean} isSort - base on this true & false push details in sort selected array
   * @param {string} title - use for pass filter title
   * @param {boolean} isRemove - base on that if any filter will remove then update selected object
   * @param {boolean} isDel - base on that if any filter will remove then update selected object
   * @param {object} subCatItm - base on this subcategory item perform filter process
   * @returns this funcion can perform filter process when select any filter option in side menu and base on different condition update selected object details.
   */
  const onFilter = ({
    item,
    isSort = false,
    title = "",
    isRemove = false,
    isDel = false,
    subCatItm,
  }) => {
    disp(setShowSortby(false));
    const checkArr = [...checkedFilter];
    const selArr = [...selectedFilter];

    const checkI = checkArr.findIndex((i) => i?.title === title);
    let subCatObj = { subData: [], subCatVal: "", subCat: "", subCount: -1 };
    if (isSort) {
      const idx = selArr.findIndex((i) => i.sortBy === true);
      if (idx === -1) {
        selArr.push(item);
        checkArr.push(item);
      } else {
        const checkIdx = checkArr.findIndex((i) => i.sortBy === true);
        selArr[idx] = item;
        checkArr[checkIdx] = item;
      }
    } else if (isDel === true) {
      const idx = selArr.findIndex((i) => i?.title === title);
      if (selArr[idx].subCatVal) {
        selArr[idx] = { ...selArr[idx], ...subCatObj };
        checkArr[checkI] = { ...checkArr[checkI], ...subCatObj };
      } else {
        selArr.splice(idx, 1);
        checkArr.splice(checkI, 1);
      }
    } else {
      if (isRemove === false) {
        const Obj = {
          value: item?.value,
          displayName: item?.displayName,
          count: item?.count,
          sortBy: false,
          title,
          subData: item?.items || [],
          subCatVal: subCatItm?.value || "",
          subCat: subCatItm?.displayName || "",
          subCount: subCatItm?.count || -1,
        };
        if (checkI !== -1) {
          const idx = checkArr.findIndex((i) => i?.value === item.value);

          if (idx !== -1) {
            if (subCatItm !== undefined) {
              checkArr[checkI].subCatVal === subCatItm?.value
                ? (checkArr[checkI] = { ...Obj, ...subCatObj })
                : (checkArr[checkI] = Obj);
            } else {
              checkArr.splice(idx, 1);
            }
          } else {
            checkArr[checkI] = Obj;
          }
          // remove catId when title is Categories
          if (title === "Categories") {
            disp(setCatId(""));
          }
        } else {
          checkArr?.push(Obj);
        }
      } else {
        if (checkI !== -1) {
          checkArr.splice(checkI, 1);
        }
      }
    }
    disp(setCheckedFilter(isDel || isSort ? selArr : checkArr));
    (isDel || isSort) === true && onViewRes({ checkArr: selArr });
  };

  /**
   *
   * @param {object} checkArr - use for pass list of selected checkbox object
   * @param {boolean} isClear - base on that if user click on clear filter button then clear all filter object
   * @returns Base on pass object or props perform action and create search url and filter url.
   */
  const onViewRes = ({ checkArr, isClear = false }) => {
    setOtherMenuData(false);
    setOtherMenu(false);
    scroll();
    let query = ""; // product list query
    let fQuery = ""; // filter query
    let cArr = [...checkArr];

    if (isClear) {
      cArr = [];
      query = "";
      fQuery = "";
      disp(setCheckedFilter([]));
      disp(setSelectedFilter([]));
      disp(setCatId(""));
    } else {
      query = getProdQuery(cArr);
      let tmpArr = [];
      cArr?.forEach((i) => {
        i.sortBy !== true && tmpArr.push(i);
      });
      fQuery = getFilterQuery(tmpArr);
    }
    let searchVal = s.searchTxt?.trim();
    let searchUrl = dVal
      ? `${query?.length > 0 ? "&" : ``}SearchTerm=${searchVal?.trim()}`
      : ``;
    let fSearchUrl = dVal
      ? `${fQuery?.length > 0 ? "&" : ``}SearchTerm=${searchVal?.trim()}`
      : ``;
    let url = `${query?.length > 0 ? `${query}${searchUrl}` : `${searchUrl}`}`;
    let fUrl = `${fQuery?.length > 0 ? `${fQuery}${fSearchUrl}` : `${fSearchUrl}`
      }`;

    disp(setSelectedFilter(cArr));
    getProduct(true, url, fUrl, false, cArr, isClear);
  };

  /**
   * @returns when user click on clear filetr buttton in product list that time clear all filter and then call view result function for further create search url and filter url.
   */
  const onClearFilters = () => {
    onViewRes({ checkArr: [], isClear: true });
    disp(setCatId(""));
  };

  let selSort;
  const getSortFilter = () => {
    const url = searchParam?.get("SortAsc") || "";
    const url1 = searchParam?.get("SortDesc") || "";
    if (url === "" && url1 === "") {
      selSort =
        SortByProduct?.find((i) => i?.sortId === 4) || {};
      // SortByProduct?.find((i) => i?.displayName === "Price low-high") || {};
    } else {
      selSort = selectedFilter?.find((i) => i?.sortBy) || {};
    }
    return selSort;
  };

  const scroll = () =>
    (document.getElementById("navbar-fixed").style.top = "0");

  return (
    <Fragment>
      <div className="complete-page" />
      <Layout title="titleproductList" id="product-list">
        <section className="section-m-b">
          <BackMenuCard
            BackMenu1="Products"
            Path1="/productcategory"
            BackMenuName2={backmenuName}
            BackMenuName3={backmenuName3}
          />
          <div className="searchbar searchbar-border">
            <img src={SEARCH_BLUE_ICON} alt="Search" />
            <input
              maxLength="255"
              type="search"
              className="searchbar-input"
              placeholder={t("productSearchProducts")}
              value={searchTxt}
              onChange={(e) => disp(setSearch(e?.target?.value))}
            />
            {searchTxt?.length > 0 && (
              <img
                alt="Search"
                src={SEARCH_CLEAR_ICON}
                className="edit-shipment-other-item-mr"
                onClick={() => disp(setSearch(""))}
              />
            )}
          </div>
          {/* =================================================== */}
          <div className="filter-flex-m">
            <button
              className="filter-sortby-fn"
              onClick={() => {
                setOtherMenu(true);
                setOtherMenuData(!otherMenuData);
                scroll();
              }}
            >
              <img src={FILTER_BLUE_ICON} alt="Filter" />
              <span>
                <T strProp="newsFilter" />
              </span>
            </button>

            <SortByModual
              selected={getSortFilter()}
              SortData={SortByProduct}
              onClose={() => disp(setShowSortby(!showSortby))}
              visible={showSortby}
              onSort={(item) => onFilter({ item, isSort: true })}
            />

            <div className="containers">
              <SegmentedControls
                name="group-1"
                callback={(i) => (i === tabIdx ? null : disp(setTabIndex(i)))}
                controlRef={useRef()}
                segments={GridListSegment({ isGrid: tabIdx, useRef })}
              />
            </div>
          </div>
          {/* =================================================== */}
          <section className="filter-scroll">
            {selectedFilter?.map((item, index) => {
              return (
                <div key={index} className="filter-flex-m-p">
                  <span>
                    {item?.sortBy ? (
                      <span>
                        <T strProp="Sortby" /> : {item?.displayName}
                      </span>
                    ) : (
                      <span>
                        {item?.subCat || item?.displayName}{" "}
                        {item?.subCat
                          ? item?.subCount >= 0 && `(${item.subCount})`
                          : item?.count >= 0 && `(${item.count})`}
                      </span>
                    )}
                  </span>
                  <img
                    src={CLOSE_GRAY_ICON}
                    alt="icon"
                    className="cursor-pointer"
                    onClick={() =>
                      onFilter({ item, isDel: true, title: item?.title })
                    }
                  />
                </div>
              );
            })}
          </section>
          {/* ======================= */}

          {selectedFilter.length > 0 && (
            <div className="margin_bottom" onClick={onClearFilters}>
              <span className="filter-clear">
                <T strProp="newsClearfilters" />
              </span>
            </div>
          )}
          {loading ? (
            <section className="quote-wrapper">
              <PageLoader isSmall isLoading />
            </section>
          ) : data?.length > 0 ? (
            data?.map((items, index) => {
              return (
                <ProductListContent
                  ref={data.length === index + 1 ? lastPostRef : null}
                  key={index}
                  item={items}
                  index={index}
                  isGrid={tabIdx}
                  categoryName={items?.category}
                  token={token}
                  alertRef={alertRef}
                />
              );
            })
          ) : (
            <p className="no-data-found">
              <T strProp="productNPF" />
            </p>
          )}
          {loadingMore && !loading && (
            <section className="quote-wrapper">
              <PageLoader isSmall isLoading />
            </section>
          )}
        </section>
        <MainMenu
          mainMenuOpen={otherMenuData}
          setMainMenuOpen={setOtherMenuData}
          SideMenuClose={() => {
            setOtherMenuData(false);
            setOtherMenu(false);
            scroll();
          }}
        >
          <div className="filter_height">
            {loadingFilter ? (
              <section className="product-grid-mt-pos">
                <PageLoader isSmall isLoading />
              </section>
            ) : filterArr?.length > 0 ? (
              filterArr?.map((i, idx) => {
                return (
                  <CheckModual
                    selectedData={checkedFilter}
                    selArr={selectedFilter}
                    key={idx}
                    Title={i?.displayName}
                    DataArr={i?.items}
                    onCheck={(itm, tit, subCatItm) =>
                      onFilter({
                        item: itm,
                        title: tit || i?.displayName,
                        subCatItm,
                      })
                    }
                  />
                );
              })
            ) : (
              <p className="no-data-found-sm">
                <T strProp="noFilter" />
              </p>
            )}
          </div>
          {checkedFilter?.length === 0 ? null : (
            <div className="filter-box">
              <div className="border__line_gt" />
              <div className="filter-btn-flex">
                <span onClick={onClearFilters}>
                  <T strProp="newsClearfilters" />
                </span>
                <button
                  className="filter-btn"
                  onClick={() => onViewRes({ checkArr: checkedFilter })}
                >
                  {`${t("productView")} ${""} ${t("productResults")}`}
                </button>
              </div>
            </div>
          )}
        </MainMenu>
        <AlertPopup ref={alertRef} />
        <UpDownScroll />
      </Layout>
    </Fragment>
  );
};

export default ProductListGrid;
