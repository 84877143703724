import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { DOWNARROW_BLUE_ICON, INFO_BLUE_IC } from "../assets";
import { PageLoader } from "./pageLoader";
import { T } from "./translation";

/**
 * 
 * @param {object} issues - for pass object and display list of issues
 * @param {function} handleIssues - call function for hadle click on collaps and expand image
 * @param {boolean} issuesExpand - specify that issue list is collaps or expand
 * @param {boolean} loadingI - for display loader
 * @param {number} issuesLength - for display issue length
 * @returns this function can display list of project issue with collaps or expand functionality.
 */
const ProjectIssues = ({
  issues,
  handleIssues,
  issuesExpand,
  loadingI,
  issuesLength,
}) => {

  const message = (message, productCode) => {
    let newMsg = message;
    productCode?.forEach((item) => { newMsg = newMsg.replace(item, `*#*#${item}*#*#`) })
    return newMsg.split('*#*#')
  }

  return (
    issues?.length >= 0 && (
      <div className="quotationdetails-issues-box">
        <div className="project-flex">
          <div className="quotationdetails-head-ti">
            <img src={INFO_BLUE_IC} alt="iocn" />
            <span className="quotationdetails-head-title">
              <T strProp="quoteissues" />
            </span>
            <div className="issue_count">
              <div className="issue_count__value">
                {issuesLength > 99 ? "99+" : issuesLength}
              </div>
            </div>
          </div>
          <div className="link issues_bg cursor-pointer" onClick={handleIssues}>
            <img
              src={DOWNARROW_BLUE_ICON}
              alt="icon"
              className="project-issue-transform"
              style={{
                transform:
                  issuesExpand === true ? "rotate(-180deg)" : "rotate(0)"
              }}
            />
          </div>
        </div>

        {issuesExpand === true ? (
          loadingI ? (
            <section className="loader_relative">
              <PageLoader isSmall isLoading />
            </section>
          ) : (
            issues?.map((item, index) => {
              let name = item?.category.replace(/([a-z](?=[A-Z]))/g, "$1 ");
              return (
                <Fragment key={index}>
                  <div className="quotationdetails_message">{name}</div>
                  {item?.data?.map((i, idx) => {
                    return (
                      <div className="quotationdetails-message_" key={idx}>
                        <span>
                          <ul>
                            <li>
                              {message(i.message, i.productcode).map((items, index) => (i.productcode).includes(items) ? <Link key={index} className="quotationdetails-message" to={`/product/${items}`}>{items}</Link> : <span key={index}>{items}</span>)}
                            </li>
                          </ul>
                        </span>
                      </div>
                    );
                  })}
                </Fragment>
              );
            })
          )
        ) : null}
      </div>
    )
  );
};

export default ProjectIssues;
