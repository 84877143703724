import React from "react";
import { Link } from "react-router-dom";
import { T } from "../translation";

/**
 * box heading and icon
 * @param {string} Title - title of box
 * @param {string} Img - image of box title (icon)
 * @param {string} Img2 -  image 2 of box title (icon)
 * @param {string} Path - path to click on navigate
 * @param {function} onClick - onClick to call function
 * @returns - box title and icon
 */
function BoxTitle({upparCase=false, Title, Img, Img2, Path, onClick = () => {} }) {
  return (
    <div className="project-flex">
      <div className="quotationdetails-head-ti">
        <img src={Img} alt="iocn" className="icon_set_24"/>
        <span className={`${upparCase?"quotationdetails-head-title_":"quotationdetails-head-title"}`}>
          <T strProp={Title} />
        </span>
      </div>
      {Img2 ? (
        Path ? (
          <Link to={Path} className="link" onClick={onClick}>
            <img src={Img2} alt="icon" />
          </Link>
        ) : (
          <div className="link" onClick={onClick}>
            <img src={Img2} alt="icon" />
          </div>
        )
      ) : null}
    </div>
  );
}

export default BoxTitle;
