import React from "react";
import {
  DELET_BG_RED_ICON,
  EDIT_BLUE_ICON,
  LOCATION_BLUE_ICON,
} from "../../../assets";
import { T } from "../../translation";

/**
 *
 * @param {string} Name - use for display name of user
 * @param {string} street - use for display street details
 * @param {string} organisation - use for display organization details
 * @param {string} postCode - use for display post code
 * @param {string} country - use for display country
 * @returns this function can return card view of address book field.
 */
export function AddressBookCard({
  Name,
  street,
  organisation,
  postCode,
  country,
}) {
  return (
    <section className="address-book-card">
      <div className="address-book-card_text-align">
        <span>{Name || "-- --"}</span>
        <div className="address-book-card-img">
          <img
            src={EDIT_BLUE_ICON}
            alt="Editing"
            className="address-edit-blue_"
          />
          <img src={DELET_BG_RED_ICON} alt="Delet" />
        </div>
      </div>
      <span className="address-book-card-address_">
        {organisation || "-- --"}
      </span>
      <span className="address-book-card-address">{street || "-- --"}</span>
      <div className="address-book-card-location">
        <img src={LOCATION_BLUE_ICON} alt="Location" />
        <span className="address-book-card-location__">
          {country || "-- --"},{" "}
        </span>
        <span className="address-book-card-location_">
          {postCode || "-- --"}
        </span>
      </div>
    </section>
  );
}

/**
 * @param {string} RecentAddress - use for display recent address of user like street details and other things
 * @param {string} RecentLocation - use for display user recent location like county, country, postcode.
 * @returns this function can return card view of user recent address.
 */
export function RecentAddressesCard({ RecentAddress, RecentLocation }) {
  return (
    <section className="recent-addresses-card">
      <span> - - </span>
      <span className="recent-addresses-card-address">{RecentAddress}</span>
      <div className="recent-addresses-card-flex">
        <div className="recent-addresses-card-location">
          <img src={LOCATION_BLUE_ICON} alt="Location" />
          <span>{RecentLocation}</span>
        </div>
        <button className="recent-addresses-card-btn">
          <T strProp="Add" />
        </button>
      </div>
    </section>
  );
}
