import React, { useState } from "react";
import { dateFormate, toPrice } from "../../../../utils";
import { T } from "../../../translation";
import { ARROW_ORANGE_ICON } from "../../../../assets";
import { useNavigate } from "react-router-dom";

/**
 *
 * @param {string} Id - use for recent shioment id
 * @param {string} Dates - use for display shipment date
 * @param {number} DeliveryQuantity - use for shipment item quantity
 * @param {number} DeliveryPrice - use for display delivery price
 * @param {number} DeliveryValue - use for display delivery value
 * @param {string} TotalBillName - use for display total billing name
 * @param {number} TotalBillPrice - use for display total billing price
 * @param {number} TotalBillValue - use for display total billing value
 * @param {string} currency - use for display currency type
 * @param {object} ProductItem - use for pass product list
 * @returns this function can return recent shipment edtails
 */
function RecentShipmentsCard({
  returnData
}) {
  const navigate = useNavigate();
  const [viewMore, setViewMore] = useState(false)
  const restructuredData = JSON.parse(JSON.stringify(returnData));
  restructuredData.shipmentItems.splice(0)
  function recentShipment(obj) {
    let ordercodes = [];
    let filteredArray = [];
    obj.shipmentItems.forEach((items) => {
      const { orderCode } = items
      ordercodes.push(orderCode)
    })
    const set = new Set(ordercodes)
    for (const [value] of set.entries()) {
      filteredArray.push(value)
    }
    filteredArray.forEach((items, index) => {
      const array = []
      returnData?.shipmentItems?.forEach((element, index) => {
        const { orderCode } = element;
        orderCode === items && array.push(element)
      })
      restructuredData.shipmentItems.push({ uniqueOrderCode: items, shipItems: [...array] })
    })
    return filteredArray;
  }
  recentShipment(returnData);
  var total = { "amount": 0, "currency": "" };
  var totalVat = { "amount": 0, "currency": "" }
  return (
    <section className="recent-shipments-card">
      <div className="recent-shipments-card__flex-row">
        <div className="recent-shipments-card-productcode cursor-pointer" onClick={()=>{navigate(`../shipment_details/${restructuredData.id}`)}}><span className="recent-shipments-card-productcode-underline">{restructuredData.id}</span>{" " + restructuredData.description}</div>
        <div className="recent-shipments-card-dates">
          <span className="_date">
            <T strProp="Date" />
          </span>
          {" : "} <span className="_date_value">{dateFormate(restructuredData.shippingDate)}</span>
        </div>
      </div>
      {restructuredData?.shipmentItems?.map((items, index) => {
        const { uniqueOrderCode, shipItems} = items
        if (!viewMore && (index >= 1)) { return "" }
        return (
          <div key={index} style={{ marginTop: "10px" }}>
            {/* <div className="recent-shipments-card-item unique_OrderCode">
              <span className="cursor-pointer recent-shipments-card-productcode-underline" onClick={()=>{navigate(`../recentorder/${uniqueOrderCode}`)}}>{uniqueOrderCode}</span>
            </div> */}
            {shipItems?.map((item, i) => {
              if (i === 0) {
                total = { "amount": 0, "currency": "" };
                totalVat = { "amount": 0, "currency": "" };
              }
              if (!viewMore && (i >= 1)) { return "" }
              // eslint-disable-next-line
              const { orderCode, productCode, productDescription, quantity, linePrice, linePriceIncVat, id, orderedDate } = item
              total = { ...total, amount: total.amount + linePrice.amount, currency: linePrice.currency }
              totalVat = { ...totalVat, amount: totalVat.amount + linePriceIncVat.amount, currency: linePriceIncVat.currency }
              return (
                <div key={i} className={viewMore ? "recent-shipments-card-compo-" : null}>
                  <div className={`recent-shipments-card-item_ ${productCode !== 'DELIVERY' && 'recent-shipments-card-productcode-underline'} cursor-pointer`}>
                    <span onClick={()=>{ productCode !== 'DELIVERY' && navigate(`../product/${productCode}`)}}>{productCode}</span>
                  </div>
                  <div className="recent-shipments-card-description">
                    <span>{productDescription}</span>
                  </div>
                  <div className="recent-shipments-card-flex-row">
                    <div className="recent-shipments-card-flex-column">
                      <span className="recent-shipments-card-text">
                        <T strProp="Quantity" />
                      </span>
                      <span className="recent-shipments-card-pri">{quantity}</span>
                    </div>
                    <div className="recent-shipments-card-flex-column">
                      <span className="recent-shipments-card-text">
                        <T strProp="Price" />
                      </span>
                      <span className="recent-shipments-card-pri">
                        {linePrice ? `${toPrice(linePrice)}` : "--"}
                      </span>
                    </div>
                    <div className="recent-shipments-card-flex-column">
                      <span className="recent-shipments-card-text">
                        <T strProp="IncVAT" />
                      </span>
                      <span className="recent-shipments-card-pri">
                        {linePriceIncVat ? `${toPrice(linePriceIncVat)}` : "--"}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
            {viewMore && <>
            <div className="recent-shipments-card-data5">
              <span className="recent-shipments-card-data5-data1">
                {uniqueOrderCode}
              </span>
              <span className="recent-shipments-card-price">
                {total.amount ? toPrice(total) : "-- --"}
              </span>
              <span className="recent-shipments-card-pri">
                {totalVat.amount ? toPrice(totalVat) : "-- --"}
              </span>
            </div>
            <div className="__border" />
            </>}
          </div>
        )
      })}
      {(restructuredData?.shipmentItems[0]?.shipItems?.length > 1 || restructuredData?.shipmentItems.length > 1)  && 
      <div className="quotes_dis_fx">
        <div className="confirm-shipment-view-more_ cursor-pointer order-rs-mp__" onClick={() => setViewMore(!viewMore)}>
          <span className="quote-details-org-details-view-more-btn">
            {!viewMore ? <T strProp="quoteViewMore" /> : <T strProp="quoteViewLess" />}
          </span>
          <div className="confirm-shipment__view-more">
            <img src={ARROW_ORANGE_ICON} alt="iocn" className={`${viewMore && "up-down-scroll"}`} />
          </div>
        </div>
      </div>}

    </section>
  );
  ;
}

export default RecentShipmentsCard;
