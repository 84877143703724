import React, {
  Fragment,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  CLOSE_GRAY_ICON,
  FILTER_BLUE_ICON,
  MINUS_GRAY_ICON,
  PLUS_GRAY_ICON,
  SEARCH_BLUE_ICON,
  SEARCH_CLEAR_ICON,
} from "../assets";
import {
  AlertPopup,
  BackMenuCard,
  CheckBox,
  GridListSegment,
  Layout,
  MainMenu,
  PageLoader,
  SegmentedControls,
  SortByModual,
  T,
  TrainingEventsCard,
} from "../components";

import { Il8Context, MenuContext } from "../contexts";
import { useDebounce, useSessionHook, useTrainingEventsHook } from "../hooks";

/**
 * @returns this function can display traning & events page content.
 */
function TrainingEvents() {
  const { setOtherMenu, otherMenuData, setOtherMenuData } =
    useContext(MenuContext);
  const { getStatusAndToken } = useSessionHook();
  const { token } = getStatusAndToken();
  const { t } = useContext(Il8Context);
  let [searchParams] = useSearchParams();
  let sSearch = searchParams?.get("search" || "") || "";
  const [selectedValue, setSelectedValue] = useState(0);
  const [filterArr, setFilterArr] = useState([]);
  const [selFilter, setSelFilter] = useState([]);
  const [selArr, setSelArr] = useState([]);
  const [showSortby, setShowSortby] = useState(false);
  const setFilter = (arr) => setFilterArr(arr);
  const alertRef = useRef(null);
  const [pageNo, setPageNo] = useState(0);
  const [searchedValue, setSearchedValue] = useState(sSearch);
  const debouncedVal = useDebounce(searchedValue, 500);
  const { events, loading, hasNextPage, getEvent, setApiUri, loadingList } = useTrainingEventsHook(token, pageNo, debouncedVal, setFilter, setSelFilter, selFilter, setSelArr, alertRef);

  const intObserver = useRef(null);

  /**
   * @returns used to update page no to pagination
   */
  const lastPostRef = useCallback(
    (post) => {
      if (loading) return;
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((data) => {
        if (data[0].isIntersecting && hasNextPage) {
          setPageNo((prev) => prev + 1);
        }
      });

      if (post) intObserver.current.observe(post);
    },
    [loading, hasNextPage]
  );

  /**
   *
   * @param {array} item - use for pass specific selected filter value
   * @param {string} title - for specify title of filter
   * @param {boolean} isRemove - base on value add or remove particular item from selected filter array.
   * @returns this function add or remove particular selected item in array.
   */
  const onSelectFilter = (item, title, isRemove = false) => {
    let obj = {
      title,
      value: item?.title || "",
      count: item?.count || 0,
      sortBy: false,
    };
    let arr = [...selFilter];
    if (isRemove === true) {
      arr = arr?.filter((i) => i?.title !== title);
      item?.sortBy === true &&
        setSelArr((i) => i?.filter((i) => i?.title !== title));
      onViewResult(false, arr);
    } else {
      arr = [obj];
      onViewResult(false, arr);
    }
    setSelFilter(arr);
  };

  /**
   *
   * @param {boolean} isReset - for specify that reset filter value or not
   * @param {array} selectedFilter - use for pass all selected filter values
   * @param {boolean} updateMenu - base on that value set other menu visibality
   * @returns this funciton can perfrom different action base on pass parameters, then set browser url and call "getEvent" function for get details from api.
   */
  const onViewResult = (
    isReset = false,
    selectedFilter = selFilter,
    updateMenu = true
  ) => {
    updateMenu === true && setOtherMenuData(false);
    updateMenu === true && setOtherMenu(false);

    let query = "";
    if (isReset === true) {
      setSelFilter([]);
      setSearchedValue("");
      query = "";
    } else {
      query = selectedFilter
        .map((i) => {
          return (
            (i?.title === "Manufacturers" && `manufacturer=${i?.value}`) ||
            (i?.title === "Distance" && `distance=${i?.value}`) ||
            (i?.title === "Date" && `date=${i?.value}`) ||
            (i?.title === "Sort By" && `${i?.value}`)
          );
        })
        .join("&");
    }
    let dVal = debouncedVal?.trim() || debouncedVal;
    let searchUrl = dVal ? `${query?.length > 0 ? "&" : ``}search=${dVal}` : ``;
    setApiUri(query);
    getEvent(true, `${query}${searchUrl}`);
  };

  /**
   *
   * @param {array} sort - use for pass selected sort value
   * @returns this function can set sort array base on selected sort value and then call "onViewResult" function.
   */
  const setSort = (sort) => {
    const Obj = {
      value: sort.value,
      title: "Sort By",
      displayName: sort.displayName,
      count: 0,
      sortBy: true,
    };
    setSelArr([Obj]);
    setTimeout(() => setShowSortby(false), 120);
    let sortArr = [...selFilter];
    // check if any sortBy is true in sortArr if yes then return index
    let index = sortArr?.findIndex((i) => i?.sortBy === true);
    if (index !== -1) {
      sortArr[index] = Obj;
    } else {
      sortArr.push(Obj);
    }
    setSelFilter(sortArr);
    onViewResult(false, sortArr, false);
  };

  const scroll = () => {
    document.getElementById("navbar-fixed").style.top = "0";
  };

  return (
    <>
      <Layout title="titleTrainingEvents" id="training-events">
        <div className="complete-page" />
        <section className="section-m-b">
          <BackMenuCard BackMenu1="titleTrainingEvents" Path1="" />
          {/* =================================================== */}
          <div className="searchbar searchbar-border">
            <img src={SEARCH_BLUE_ICON} alt="Search" />
            <input
              maxLength="255"
              type="search"
              value={searchedValue}
              onChange={(e) => {
                setSearchedValue(e.target.value);
              }}
              className="searchbar-input"
              placeholder={t("search")}
            />
            {searchedValue?.length > 0 && (
              <img
                src={SEARCH_CLEAR_ICON}
                alt="Search"
                className="edit-shipment-other-item-mr"
                onClick={() => setSearchedValue("")}
              />
            )}
          </div>
          {/* =================================================== */}
          <div className="filter-flex-m">
            <button
              className="filter-sortby-fn"
              onClick={() => {
                setOtherMenu(true);
                setOtherMenuData(!otherMenuData);
                scroll();
              }}
            >
              <img src={FILTER_BLUE_ICON} alt="Filter" />
              <span>
                <T strProp="newsFilter" />
              </span>
            </button>
            <SortByModual
              SortData={SortByEvents}
              visible={showSortby}
              onClose={() => setShowSortby(!showSortby)}
              selected={selArr[0]}
              onSort={setSort}
            />
            <div className="containers">
              <SegmentedControls
                name="group-1"
                callback={setSelectedValue}
                controlRef={useRef()}
                segments={GridListSegment({ isGrid: selectedValue, useRef })}
              />
            </div>
          </div>
          {/* ======================= */}
          <section className="filter-scroll">
            {selFilter?.map((item, index) => (
              <div className="filter-flex-m-p" key={index}>
                <span>
                  {item?.sortBy === false
                    ? `${item?.value} (${item?.count})`
                    : `${item?.title}: ${item?.displayName}`}
                </span>
                <img
                  src={CLOSE_GRAY_ICON}
                  alt="icon"
                  onClick={() => onSelectFilter(item, item?.title, true)}
                />
              </div>
            ))}
          </section>

          {selFilter?.length > 0 && (
            <div className="margin_bottom">
              <span
                className="filter-clear cursor-pointer"
                onClick={() => onViewResult(true)}
              >
                <T strProp="newsClearfilters" />
              </span>
            </div>
          )}
          {!loading ? (
            <>
              {events?.length > 0
                ? events.map((i, idx) => {
                  return events.length === idx + 1 ? (
                    <TrainingEventsCard
                      ref={lastPostRef}
                      key={idx}
                      item={i}
                      isGrid={selectedValue === 0}
                    />
                  ) : (
                    <TrainingEventsCard
                      key={idx}
                      item={i}
                      isGrid={selectedValue === 0}
                    />
                  );
                })
                : !loadingList && (
                  <p className="no-data-found-sm">
                    <T strProp="noDataFound" />
                  </p>
                )}
            </>
          ) : (
            <section className="quote-wrapper">
              <PageLoader isSmall isLoading />
            </section>
          )}
        </section>

        {/* ==== Filter Menu ==== */}

        <MainMenu
          mainMenuOpen={otherMenuData}
          setMainMenuOpen={setOtherMenuData}
          SideMenuClose={() => {
            setOtherMenuData(false);
            setOtherMenu(false);
          }}
        >
          <div className="filter_height">
            <section className="newsfilter">
              {filterArr?.length > 0 ? (
                filterArr.map((i, idx) => {
                  return (
                    <FilterItem
                      key={idx}
                      item={i}
                      onSelect={onSelectFilter}
                      selectedFilter={selFilter}
                    />
                  );
                })
              ) : loading ? (
                <div className="quote-wrapper">
                  <PageLoader isSmall isLoading />
                </div>
              ) : (
                <p className="no-data-found">
                  <T strProp="noFilter" />
                </p>
              )}
            </section>
          </div>
        </MainMenu>
        <AlertPopup ref={alertRef} />
      </Layout>
    </>
  );
}

/**
 *
 * @param {array} item - use for pass item details
 * @param {function} onSelect - use to call function when click on any filter
 * @param {array} selectedFilter - use for display particular filter selected
 * @returns this function can display different filter options and on click function with selected filter value.
 */
const FilterItem = ({ item, onSelect, selectedFilter = [] }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <>
      <div className="filter-flex-padding">
        <div className="filter-flex-row-p" onClick={toggleExpand}>
          <span>{item?.title}</span>
          {isExpanded ? (
            <img src={MINUS_GRAY_ICON} alt="Plus" />
          ) : (
            <img src={PLUS_GRAY_ICON} alt="Plus" />
          )}
        </div>
        {!isExpanded && <div className="border__line_px" />}
        {isExpanded === true
          ? item?.data &&
          item?.data?.map((i, idx) => {
            let isChecked = selectedFilter.find(
              (d) => d?.title === item?.title && d?.value === i?.title
            )
              ? true
              : false;
            return (
              <Fragment key={idx}>
                <div className="con-creat-shipm-ml" key={idx}>
                  <CheckBox
                    filter
                    label={i?.title + (i?.count && ` (${i?.count})`)}
                    checked={isChecked}
                    onChange={() => onSelect(i, item?.title, false)}
                  />
                </div>
                <div className="border__line_px" />
              </Fragment>
            );
          })
          : null}
      </div>
    </>
  );
};

export default TrainingEvents;

export const SortByEvents = [
  {
    sortBy: true,
    displayName: "Date Now - Future",
    checked: false,
    value: "sortOrder=Asc&sortEventColumns=Date",
    sortEventColumns: "Date",
    sortOrder: "Asc",
  },
  {
    sortBy: true,
    displayName: "Date Future - Now",
    checked: false,
    value: "sortOrder=Desc&sortEventColumns=Date",
    sortEventColumns: "Date",
    sortOrder: "Desc",
  },
  {
    sortBy: true,
    displayName: "Distance Ascending",
    checked: false,
    value: "sortOrder=Asc&sortEventColumns=Distance",
    sortEventColumns: "Distance",
    sortOrder: "Asc",
  },
  {
    sortBy: true,
    displayName: "Distance Decending",
    checked: false,
    value: "sortOrder=Desc&sortEventColumns=Distance",
    sortEventColumns: "Distance",
    sortOrder: "Desc",
  },
  {
    sortBy: true,
    displayName: "Town A-Z",
    checked: false,
    value: "sortOrder=Asc&sortEventColumns=Town",
    sortEventColumns: "Town",
    sortOrder: "Asc",
  },
  {
    sortBy: true,
    displayName: "Town Z-A",
    checked: false,
    value: "sortOrder=Desc&sortEventColumns=Town",
    sortEventColumns: "Town",
    sortOrder: "Desc",
  },
];
