import React, { Fragment, useContext, useId, useState } from "react";
import { Il8Context } from "../../../contexts/i18context";
import ButtonModual from "../../modual/ButtonModual";
import { PageLoader } from "../../pageLoader";
import { GlobalContext } from "../../../contexts";
import { globalActionsTypes } from "../../../hooks";
import { callApi } from "../../../services";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import BtnSmall from "../../BtnSmall";
import { T } from "../../translation";
import { stockStatus } from "../../../utils/stockUtils";
import { stockStatuses } from "../../../utils/constants";
import { StockDueItem } from "../../StockMessage";
import { useRef } from "react";
import {
  AlertPopup,
  QuantityCounter,
  QuoteAddPopup,
} from "../../../components";
import {
  CLOSE_WHITE_ICON,
  NO_FOUND_IMG_M,
  PRODUCTSTOCK_ICON,
} from "../../../assets";
import { toPrice } from "../../../utils";

/**
 *
 * @param {string} Img - use for display product image
 * @param {number} isGrid - use for display gid
 * @param {string} ProductTitle - use for display product title
 * @param {number} ProductPrice - use for display product unit price amount
 * @param {number} Productwaith - use for display unit per wat price amount
 * @param {string} currency - use for display product price currency
 * @param {string} categoryName - use for display product category name
 * @param {string} token - use for call api
 * @param {number} stockLevel - use for display product stock level
 * @param {object} data - use for pass product details
 * @param {ref} alertRef - use for display pop up model after api response
 * @returns this function can return list of product in list view.
 */
function ProductListGridCard({
  Img,
  isGrid,
  ProductTitle,
  ProductPrice,
  Productwaith,
  currency,
  categoryName,
  token,
  stockLevel,
  data,
  alertRef,
}) {
  const [quote, setQuote] = useState(false);
  const [basket, setBasket] = useState(false);
  const [quantity, setQuantity] = useState("1");
  const { dispatch } = useContext(GlobalContext);
  const [loadingBbtn, setLoadingBbtn] = useState(false);
  const idx = useId();
  const alertref = useRef(null);
  const { t } = useContext(Il8Context);
  const productData = {
    categoryName,
    productCode: ProductTitle,
  };

  const quoteOpen = () => {
    setQuote(true);
  };

  const basketClose = () => {
    setBasket(false);
  };

  const { status, quantityInStock, stockDue } = stockStatus(stockLevel);
  let defVal = { dueDateTimestamp: null, quantity: null };
  const { dueDateTimestamp, quantity: qty } = Array.isArray(stockDue)
    ? stockDue[0]
    : defVal;
  const secondStockDue = Array.isArray(stockDue) ? stockDue[1] : defVal;

  /**
   *
   * @returns thisfunction can add product in basket when user click on add basket button.
   */
  const addToBasket = async () => {
    if (quantity === 0) {
      alertref.current?.onErr({
        body: <T strProp="PleaseAddquantity" />,
        btnTxt: <T strProp="Close" />,
      });
      return;
    }
    const obj = {
      items: [
        {
          category: categoryName,
          productCode: ProductTitle,
          quantity: quantity || 1,
        },
      ],
    };

    setLoadingBbtn(true);
    await callApi("basketUpdate", { token, alertRef }, JSON.stringify(obj))
      .then(({ sCode }) => {
        if (sCode === 200 || sCode === 202) {
          setQuantity(1);
          setBasket(true);
          updateBasketCount();
          return;
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingBbtn(false));
  };

  /**
   * @returns this function call update basket api for update item count over basket image.
   */
  const updateBasketCount = async () => {
    await callApi("basketList", { token })
      .then(({ res, sCode }) => {
        if (sCode === 200 || sCode === 202) {
          dispatch({
            type: globalActionsTypes.setBasketBadge,
            data: res?.body?.result?.quote ? 1 : 0,
          });
          setLoadingBbtn(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingBbtn(false);
        setBasket(false);
      });
  };

  const BasketModal = useCallback(() => {
    return (
      <ButtonModual Open={basket} onClose={basketClose}>
        <div className="popup_event">
          <div className="popup_event-flex">
            <img src={CLOSE_WHITE_ICON} alt="icon" onClick={basketClose} />
          </div>
          <span className="popup_event-text">
            <T strProp="productAddBasket" />
          </span>
          <div className="popup_event__text">
            <span>
              <T strProp="productyourbasket" />
            </span>
          </div>
          <BtnSmall
            primary
            Title={t("modalCloseButtonText")}
            OnClickBtn={basketClose}
          />
        </div>
      </ButtonModual>
    );
  }, [basket, t]);

  return (
    <Fragment>
      <section className="product-list-card">
        {isGrid && (
          <>
            <Link to={`/product/${ProductTitle}`}>
              <div className="product-list-no-found-img">
                <img
                  className="default-img"
                  src={Img}
                  key={idx}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = NO_FOUND_IMG_M;
                  }}
                  alt="icon"
                />
              </div>
            </Link>
          </>
        )}
        <div className="border__line_px_f5" />
        <Link to={`/product/${ProductTitle}`} className="link">
          <div className="product-list-card-flex-col">
            <div className="product-list-card-flex-row">
              <span className="product-list-card-productcode">
                {ProductTitle}
              </span>
              <span className="product-list-card-product_price">
                {/* {currency ? `${getCurrencySymbol(currency)}` : "--"} */}
                {ProductPrice
                  ? ` ${toPrice(ProductPrice)}`
                  : // ? ` ${parseFloat(ProductPrice).toFixed(2)}`
                  "--"}
              </span>
            </div>
            {isGrid && (
              <div className="product-list-card-flex-row">
                <span className="product-list-card-productcode">
                  {data?.manufacturer}
                </span>
                <span className="product-list-card-product__price">
                  {toPrice(Productwaith)}/W
                  {/* {Productwaith && `(${ createLocalePrice(language, currency, Productwaith)}/W)`} */}
                </span>
              </div>
            )}
            {isGrid ? (
              <div className="product-list-card-description">
                <span>{data?.description}</span>
              </div>
            ) : (
              <div className="product-list-card__flex-start">
                <div className="flexW70">
                  <span className="product-list-card-des__menf">
                    {data?.manufacturer}
                  </span>
                  <div className="product-list-card-des__menf">
                    <span>{data?.description}</span>
                  </div>
                </div>
                {status === stockStatuses.IN_STOCK_NO_MORE_DUE ||
                  (status === stockStatuses.IN_STOCK_MORE_DUE && (
                    <div className="stock_succ-box">
                      <img src={PRODUCTSTOCK_ICON} alt="icon" />
                      <div className="stock_succ-box_text">
                        <span className="_quantity_value">
                          {quantityInStock}
                        </span>{" "}
                        <span className="_quantity_stock">
                          <T strProp="productStock" />
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            {isGrid && (
              <div
                className={
                  status === stockStatuses.IN_STOCK_NO_MORE_DUE
                    ? "product-list-card__flex-sb"
                    : "product-list-card__flex-fe"
                }
              >
                {status === stockStatuses.IN_STOCK_NO_MORE_DUE && (
                  <div className="stock_succ-box">
                    <img src={PRODUCTSTOCK_ICON} alt="icon" />
                    <div className="stock_succ-box_text">
                      <span className="_quantity_value">{quantityInStock}</span>{" "}
                      <span className="_quantity_stock">
                        <T strProp="productStock" />
                      </span>
                    </div>
                  </div>
                )}

                {status === stockStatuses.IN_STOCK_MORE_DUE ? (
                  <div className="productlist-due-date">
                    {status === stockStatuses.IN_STOCK_MORE_DUE ? (
                      <div className="product-list-card-due-date">
                        <div className="stock_succ-box">
                          <img src={PRODUCTSTOCK_ICON} alt="icon" />
                          <div className="stock_succ-box_text">
                            <span className="_quantity_value">
                              {quantityInStock}
                            </span>{" "}
                            <span className="_quantity_stock">
                              <T strProp="productStock" />
                            </span>
                          </div>
                        </div>
                        <StockDueItem
                          quantity={qty}
                          dueDateTimestamp={dueDateTimestamp}
                          contactLink={false}
                        />
                      </div>
                    ) : // OUT_OF_STOCK_MORE_DUE: "Out of stock more due",
                      status === stockStatuses.OUT_OF_STOCK_MORE_DUE ? (
                        <div className="product-list-card-due-date">
                          <div>
                            <StockDueItem
                              quantity={qty}
                              dueDateTimestamp={dueDateTimestamp}
                              contactLink={false}
                            // contactLink={!secondStockDue}
                            />
                          </div>
                          <div>
                            {secondStockDue && (
                              <StockDueItem
                                quantity={secondStockDue?.quantity}
                                dueDateTimestamp={
                                  secondStockDue?.dueDateTimestamp
                                }
                                contactLink={false}
                              />
                            )}
                          </div>
                        </div>
                      ) : null}
                  </div>
                ) : status === stockStatuses.OUT_OF_STOCK_MORE_DUE ? (
                  // ) : status === 'Out of stock more due' ? (
                  <div className="product-list-card-due-date">
                    <div>
                      <StockDueItem
                        quantity={qty}
                        dueDateTimestamp={dueDateTimestamp}
                        contactLink={false}
                      // contactLink={!secondStockDue}
                      />
                    </div>
                    <div>
                      {secondStockDue && (
                        <StockDueItem
                          quantity={secondStockDue?.quantity}
                          dueDateTimestamp={secondStockDue?.dueDateTimestamp}
                          contactLink={false}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="product_text">
                    {status === stockStatuses.IN_STOCK_NO_MORE_DUE ? (
                      <T strProp="contactUsForMore" />
                    ) : (
                      <T strProp="titleContactUs" />
                    )}
                    {/* if(status === stockStatuses.IN_STOCK_MORE_DUE){
                      <T strProp="titleContactUs" />
                    }else{
                      <T strProp="contactUsForMore" />
                    } */}
                  </div>
                )}
              </div>
            )}
          </div>
        </Link>
        <div className="border__line_px_f5" />
        <div className="quantity-flex-row">
          <span className="quantity_title">
            <T strProp="Quantity" />
          </span>
          <QuantityCounter setVal={setQuantity} val={quantity} />
          <div className="display_flex">
            <div className="margin-right-6">
              <BtnSmall
                Title={<T strProp="basket" />}
                secondary
                OnClickBtn={addToBasket}
                isLoading={loadingBbtn}
              />
            </div>
            <BtnSmall
              Title={<T strProp="quote" />}
              primary
              OnClickBtn={quoteOpen}
            />
          </div>
        </div>
      </section>

      {/* ==================== popup Quotes =========================== */}
      <QuoteAddPopup
        productData={productData}
        quoteCounterQuantity={quantity || 1}
        setQuote={setQuote}
        quote={quote}
        token={token}
        alertRef={alertRef}
      />
      {/* ====================================================== */}
      {BasketModal()}
      <PageLoader isLoading={false} isSmall />

      {/* ====================================================== */}
      <AlertPopup ref={alertref} />
    </Fragment>
  );
}

export default ProductListGridCard;
