import React from "react";
import { MINUS_ICON, PLUS_ICON } from "../assets";

/**
 * 
 * @param {number} val - for display quantity value
 * @param {function} setVal - for set new value base on increment or decrement
 * @param {number} maxVal - for set maximum value of counter
 * @returns this function can display quantity section with increment or decrement quantity functionality.
 */
const QuantityCounter = ({ val, setVal = () => { }, maxVal, minVal = 1 }) => {
  const NumberRegex = /^(?!0\d)\d*(\d+)?$/;
  let numVal = parseInt(val);
  let decrementCounter = () => numVal !== minVal && setVal(numVal - 1);
  let incrementCounter = () => numVal < 10000 && setVal(numVal + 1);
  return (
    <div className="quantity_p-m">
      <button
        disabled={numVal <= minVal}
        className={numVal > minVal ? `quantity_plus-btn` : `quantity_minus-btn`}
        onClick={decrementCounter}
      >
        <img src={MINUS_ICON} alt="Minus Icon" className="minus_plus_icon" />
      </button>
      <input
        className="quantity-number"
        type="text"
        pattern="[0-9]*"
        inputMode="numeric"
        // minLength={ }
        value={val}
        onBlur={() => {
          if (numVal >= 0) { return; }
          else { setVal("1"); }
        }}
        onChange={(e) => {
          var t = e.target.value;
          let pT = parseInt(t);
          if (maxVal >= 0) {
            if (t >= maxVal) {
              t = maxVal;
            }
          } else {
            if (pT <= 0) {
              t = "1";
            } else if (parseInt(t) > 10000) {
              t = "10000";
            }
          }
          NumberRegex.test(t) && setVal(t); // set quantity
        }}
      />
      <button
        className={numVal >= maxVal/*  || (maxVal && numVal >= maxVal) */ ? `quantity_minus-btn` : `quantity_plus-btn`}
        // className={numVal < 9999 ? `quantity_plus-btn` : `quantity_minus-btn`}
        onClick={incrementCounter}
        disabled={numVal >= maxVal /* 9999 || (maxVal && numVal >= maxVal) */}
      >
        <img src={PLUS_ICON} alt="Plus Icon" className="minus_plus_icon" />
      </button>
    </div>
  );
};

export default QuantityCounter;
