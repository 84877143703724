import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/authConfig";
import AppConfig from "../config/appConfig";

/**
 * @returns - use to get user authentication token
 */
const useSessionHook = () => {
  const { instance, accounts } = useMsal();
  let b2cConfig = AppConfig().B2cConfig();
  const requestAccessToken = async (account) => {
    const request = {
      ...loginRequest,
      account,
    };
    // const userflow = localStorage.getItem("userflow");
    instance.config.auth.authority = `${b2cConfig.SignUpSignInAuthority}`;
    return await instance?.acquireTokenSilent(request).then((authResult) => {
      return {
        token: authResult.accessToken,
        status: "authenticated",
      }
    }).catch((error) => {
      console.log('errorMSAL', JSON.stringify(error));
      return { token: "null", status: "unauthenticated" }
    });
  };
  const [authStatus, setAuthStatus] = useState({
    token: null,
    status: "unauthenticated",
  });

  useEffect(() => {
    const fetchAccessToken = async () => {
      const authObj = await requestAccessToken(accounts[0]);
      setAuthStatus(authObj);
    };

    accounts && accounts?.length > 0 && fetchAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // refresh token
  useEffect(() => {
    const interval = setInterval(() => {
      const fetchAccessToken = async () => {
        const authObj = await requestAccessToken(accounts[0]);
        setAuthStatus(authObj);
      };
      accounts && accounts?.length > 0 && fetchAccessToken();
    }, 1000 * 60 * 5);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return {
    getStatusAndToken: () => authStatus,
  };
};

export { useSessionHook };
