import { useState, useEffect } from "react";
import { callApi } from "../services";
/**
 * this hook was used to get quote list from api
 * @param  {string} token used to user authentication 
 * @param  {int} pageNo=0 used to pass pageno in pagination for api 
 * @param  {ref} alertRef used to error alert popup
 * @param  {Function} signal used to cancle api
 * @return - quote data, loading ,next page call for quote api
 */
const useQuotesHook = (token, pageNo = 0, alertRef, signal) => {
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(false);

  /**
   * @returns this function can call api and get list of existing quote list and set in quote state.
   */
  const getQuotes = async () => {
    !loading && setLoading(true);
    /* const { res, sCode } = */ await callApi("getExistingQuotes", { token, alertRef, noBack: false, signal: signal }, pageNo + 1)
    .then(({ res, sCode }) => {
      if (sCode === 401) { setHasNextPage(false); }
      else if (sCode === 200 || sCode === 202) {
        let pCount = res?.body?.result?.pageCount;
        let pNum = res?.body?.result?.pageNumber;
        setHasNextPage(pCount > pNum);
        setQuotes((p) => [...p, ...res?.body?.result?.items]);
      }
      setLoading(false);
    })
    .catch((err) => {/* console.log(err) */})
    .finally(() => setLoading(false));
  }

  //   if (res?.error) {
  //     setLoading(false);
  //   } else if (sCode === 401) { setHasNextPage(false); }
  //   else {
  //     if (sCode === 200 || sCode === 202) {
  //       let pCount = res?.body?.result?.pageCount;
  //       let pNum = res?.body?.result?.pageNumber;
  //       setHasNextPage(pCount > pNum);
  //       setQuotes((p) => [...p, ...res?.body?.result?.items]);
  //     }
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    if (token === null) {
      setLoading(true);
      return;
    }
    getQuotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, pageNo]);
  return { quotes, loading, hasNextPage, setQuotes };
};

export { useQuotesHook };
