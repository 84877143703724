import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AlertPopup,
  BackCard,
  CountryInp,
  Layout,
  LookUp,
  ProductBlueBtn,
  T,
  TextInput,
} from "../../components";
import { countryValidation } from "../../components/FormInput/CountryInp";
import {
  postCodeValidation,
  streetValidation,
  townValidation,
} from "../../components/FormInput/LookUp";
import { GlobalContext, Il8Context } from "../../contexts";
import { useSessionHook } from "../../hooks";
import { callApi } from "../../services";
import "../../styles/components/quototation/quotationdetails.css";

/**
 * edit quote page in pop
 * @returns - edit delivery address page jsx elements
 */
function EditAddressBook() {
  const sessionHelpers = useSessionHook();
  const { token } = sessionHelpers.getStatusAndToken();
  var date = new Date();
  date.setDate(date.getDate() + 1);
  const [loadingSave, setLoadingSave] = useState(false);
  const { t } = useContext(Il8Context);
  const { state } = useContext(GlobalContext);

  const alertRef = useRef(null);
  const [postCode, setPostCode] = useState("");
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const addressRef = useRef(null);
  const validateRef = useRef(null);
  const [town, setTwon] = useState("");
  const [street, setStreet] = useState("");
  const [organisation, setOrganisation] = useState("");
  const streetRef = useRef(null);
  const townRef = useRef(null);
  const [InpCounty, setInpCounty] = useState("");
  const [countrySelection, setCountrySelection] = useState("");
  const [description, setDescription] = useState("");
  const countryRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (state?.countrys?.length > 0 && state?.acObj?.defaultShippingAddress.country) {
      let countryId = state?.countrys?.find((item) => item.name === state?.acObj?.defaultShippingAddress.country)?.id || "";
      setCountrySelection(countryId);
    }
  }, [state?.countrys, state?.acObj?.defaultShippingAddress.country]);

  const descValidation = (value, ref) => {
    if (value?.trim().length === 0) {
      ref?.current?.handleInputBlur(true, t("PleaseEnterDesc"), true);
      return false;
    } else {
      ref.current?.handleInputBlur("", "");
      return true;
    }
  };

  /**
   * 
   * @returns This function can save addres details in api.
   */
  const addAddresss = async () => {
    if (descValidation(description, descriptionRef) === false) return;
    if (countryValidation(countrySelection, countryRef) === false) return;
    if (streetValidation(street, streetRef) === false) return;
    if (townValidation(town, townRef) === false) return;
    if (postCodeValidation(postCode, validateRef) === false) return;

    setLoadingSave(true);
    const dataObj = {
      description,
      organisation,
      street,
      town,
      county: InpCounty,
      postCode,
      countryId: countrySelection,
    };

    await callApi("addAddressBook", { token, alertRef }, dataObj)
      .then(({ sCode }) => {
        if (sCode === 202 || sCode === 200) {
          alertRef.current?.onSuccess({
            body: <T strProp="AddAddressSuccess" />,
            btnTxt: t("BtnOK"),
            goBack: true,
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingSave(false));
  };

  return (
    <>
      <Layout title="titleEditDeliveryAddress" id="edit-delivery-address">
        {/* <div className="complete-page" /> */}
        <div className="API-panding-page" />
        <section
          className="editda edit-add-book-section">
          <BackCard Title="AddressDetails" Path={-1} />

          <div className="edit-add-book-main-div">
            <div className="edit-add-book-child-div">
              <TextInput
                Required
                ref={descriptionRef}
                input_Title={t("contactDescription")}
                Placeholder={t("contactDescription")}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onBlue={(e) => {
                  descValidation(e.target.value, descriptionRef);
                }}
              />
            </div>
            <CountryInp
              token={token}
              countrySelection={countrySelection}
              setCountrySelection={setCountrySelection}
              countryRef={countryRef}
            />
            <LookUp
              setPostCode={setPostCode}
              postCode={postCode}
              loading={loading}
              addressRef={addressRef}
              contactRefP={validateRef}
              town={town}
              setTwon={setTwon}
              street={street}
              setStreet={setStreet}
              organisation={organisation}
              setOrganisation={setOrganisation}
              contactRefS={streetRef}
              contactRefT={townRef}
              setInpCounty={setInpCounty}
              InpCounty={InpCounty}
              setCountrySelection={setCountrySelection}
              isAddAddress={true}
            />
            <div className="edit-add-book-add-btn">
              <ProductBlueBtn
                isloading={loadingSave}
                BtnTilte="quotesave"
                onClick={addAddresss}
              />
            </div>
          </div>
        </section>
        <AlertPopup ref={alertRef} />
      </Layout>
    </>
  );
}

export default EditAddressBook;
