import React, { forwardRef } from 'react'
import SearchProductsCard from './cards/searchitem/SearchProductsCard';

/**
 * @param {object} item - use for specific item details
 * @param {referance} ref - for referance
 * @returns return search product card jsx element 
 */
const SearchProduct = forwardRef(({ item }, ref) => {
    const quoteProductBody = (
        <SearchProductsCard
            key={item?.index}
            ProductNo={item?.productCode}
            manufacturer={item?.manufacturer}
            ProductInfo={item?.description}
        />
    );
    const content = ref ? (<div ref={ref}>{quoteProductBody}</div>
    ) : (<div>{quoteProductBody}</div>);

    return content;
});

export default SearchProduct