import { createContext } from "react";
import { translatorWrap, interpolationWrap } from "../i18l";
import { defaultLocale, localeMapping } from "../utils/constants";

const createContextValue = (locale = defaultLocale) => {
  const t = translatorWrap(locale);
  const it = interpolationWrap(locale);
  return {
    t,
    it,
    locale,
    dir: localeMapping[locale].dir,
  };
};
const Arr = ["en-GB", "de-DE"];
const userLanguage = window.navigator.language;

export const language = Arr?.includes(userLanguage)
  ? userLanguage
  : defaultLocale;

const lng = language.split("-");
export const languageCode = lng[0];
const Il8Context = createContext(createContextValue(language));
// ==================================================================================================

// ==================================================================================================
export { Il8Context, createContextValue };
// window.navigator.userLanguage || window.navigator.language
