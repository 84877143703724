import { useEffect, useState } from "react";

/**
* 
* @param {object} allMenus - use for display menu name
* @param {string} thisMenuId - for particular menu id
* @param {string} state - 
* @param {string} children - for display nested menus
* @param {function} SideMenuClose - close side menu in list
* @returns  display menu list
*/
const Menu = ({ allMenus, thisMenuId, state, children, SideMenuClose }) => {
  const [thisMenu, setThisMenu] = useState();
  const [parentMenu, setParentMenu] = useState();

  useEffect(() => {
    setThisMenu(allMenus.find((menu) => menu.id === thisMenuId));
    setParentMenu(null);
    // eslint-disable-next-line 
  }, [allMenus, thisMenuId]);


  return parentMenu !== undefined ? (
    <>
      <nav
        className={`main-menu__nav  ${state[thisMenu?.id]
          ? ` main-menu__nav--open`
          : ` main-menu__nav--closed`
          }`}
      >
        {children}
      </nav>
      <div className="main-menu__nav1" onClick={SideMenuClose} />
    </>
  ) : null;
};

export default Menu;
