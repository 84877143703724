import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { MINUS_GRAY_ICON, PLUS_GRAY_ICON } from "../../../assets";
import { Il8Context } from "../../../contexts/i18context";
import { toPrice } from "../../../utils/functions";
import { stockStatus } from "../../../utils/stockUtils";
import QuantityCounter from "../../QuantityCounter";
import { IT, T } from "../../translation";

// =================================== Product Info ================================
/**
 *
 * @param {object} productData - object of product details
 * @returns - jsx element of product
 */
export function ProductInfoCard({ productData }) {
  return (
    <div className="product-info-card">
      <div className="product-info-card-description">
        <span>{productData?.description || "--"}</span>
      </div>
      <div className="product-info-card-productCode">
        <span className="_partno">
          <T strProp="partNumber" />
          {" : "}
        </span>
        <span className="_value">{productData?.productCode || "--"}</span>
      </div>
      <div className="product-info-card-category">
        <span className="_category">{productData?.category || "--"}</span>
        {" - "}
        <span className="subcategory">{productData?.subCategory || "--"}</span>
      </div>
      {productData?.Rating && (
        <div className="product-info-card-Rating">
          <span>{productData?.Rating}</span>
        </div>
      )}
      {/* =========================== */}
      <div className="border__line_px_ee_m" />
      {/* =========================== */}
      <div className="productinfo-data2">
        <div className="Product-Information-title">
          <span>
            <T strProp="productProductInformation" />
          </span>
        </div>
        <p className="Product-Information-description">
          <span>{productData?.description || "--"}</span>
        </p>
      </div>
    </div>
  );
}
// =================================== Product Quantity ============================
/**
 *
 * @param {object} productData - use for display product data list
 * @param {function} setQuoteCounterQuantity - use for manage quantity incre or desc action process
 * @param {number} quantity - use for display quantity
 * @param {boolean} isDisplayUnitPrice - use for whether display unit price or not
 * @returns this function can return product quantity section
 */
export function ProductQuantity({
  productData,
  setQuoteCounterQuantity,
  quantity,
  isDisplayUnitPrice = false

}) {
  const navigation = useNavigate();
  const { t } = React.useContext(Il8Context);

  const onClickBulkProduct = (i) => {
    navigation(`/product/${i}`, { state: i });
  };

  const { stockDue } = stockStatus(productData?.stockLevels);
  let defVal = { dueDateTimestamp: null, quantity: null };
  const { dueDate } = Array.isArray(stockDue) ? stockDue[0] : defVal;

  return (
    <div className="product-stock-card">
      {/* ============================================= */}
      <div className="product-stock-card-flex">
        <div className="product-stock-card-title">
          <span>
            <T strProp="Quantity" />
          </span>
        </div>
        <QuantityCounter setVal={setQuoteCounterQuantity} val={quantity} />
      </div>
      {/* ================================================ */}
      <div className="product-stock-card-mr">
        <div className="product-stock-card__flex">
          <span className="product-stock-card-pricePerWatt">
            {productData?.pricePerWatt && `(${productData?.pricePerWatt} /W)`}
          </span>
          <span className="product-stock-card-price">
            {toPrice(productData?.unitPrice)}
          </span>
        </div>
        <div className="product-stock-card___flex">
          <div>
            {dueDate &&
              stockDue?.map((item, index) => {
                return (
                  index < 2 && (
                    <span key={index} className="product-stock-card-date">
                      <IT
                        value={item?.quantity || ""}
                        strProp="productStockDueStock"
                        dateStr={item?.dueDate || ""}
                      />
                    </span>
                  )
                );
              })}
          </div>
          {isDisplayUnitPrice &&
            <span className="product-stock-card-discountPercent">
              {productData?.discountPercent > 0
                ? productData?.discountPercent + " %"
                : "--"}{" "}
              {t("offLowercase")} {toPrice(productData?.grossPrice)}
            </span>
          }
        </div>
      </div>
      {/* ================================================= */}
      {productData?.availability?.quantityInStock > 0 && (
        <div className="quote-details-add-item-btn">
          <div className="border__line_px_ee_m" />
          <div className="product-stock-card-inStock">
            <div>
              <span className="_value">
                {productData?.availability?.quantityInStock}
              </span>{" "}
              <span className="_stock">
                <T strProp="productStockInStock" />
              </span>
            </div>
          </div>
        </div>
      )}
      {/* ================================================ */}
      {productData?.bulkOptions?.length > 0 && (
        <>
          {productData?.availability?.quantityInStock > 0 ? null : (
            <div className="border__line_px_ee_m"></div>
          )}
          <div className="product-stock-card-data4">
            <div className="product-stock-card__flex">
              <span className="product-stock-card-bulkbuy">
                <T strProp="productBulkbuy" />
              </span>
              <span className="product-stock-card-bulkbuy">
                <T strProp="productPriceunit" />
              </span>
            </div>
            {productData?.bulkOptions?.map((item, index) => {
              return (
                <div className="product-stock-card__flex" key={index}>
                  <span
                    className="product-stock-card__productCode cursor-pointer"
                    onClick={() =>
                      item?.productCode && onClickBulkProduct(item?.productCode)
                    }
                  >
                    {item?.productCode
                      ? `${item?.quantity} ${t("pack")}`
                      : "--  --"}
                  </span>
                  <span className="product-stock-card__price">
                    {toPrice(item?.unitPrice)}
                  </span>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
// =================================== Product Add Details =========================
/**
 *
 * @param {string} Title - use for display product title
 * @param {object} lastLineCheck - use for display product details list
 * @param {object} children - use for display specific nested info about product.
 * @returns this function can return product info.
 */
export function ProductInfoAdd({ Title, lastLineCheck = [], children }) {
  const [info, setInfo] = useState(false);
  function handleOpen() {
    setInfo(!info);
  }

  return (
    <>
      <div className="filter-flex-row-p" onClick={handleOpen}>
        <span>
          <T strProp={Title} />
        </span>
        {info ? (
          <img src={MINUS_GRAY_ICON} alt="Plus" />
        ) : (
          <img src={PLUS_GRAY_ICON} alt="Plus" />
        )}
      </div>
      {info === true ? children : null}
      {info !== true && lastLineCheck?.length !== 0 ? (
        <div className="border__line_px" />
      ) : null}
    </>
  );
}
// =================================== Product Available Warranties =================
/**
 *
 * @param {object} productData - use for pass product details
 * @returns this function can return product warranty details
 */
export function ProductWarranties({ productData }) {
  return (
    <section className="product-warranties-card">
      <span className="product-warranties-card-title">
        <T strProp="productAvailableWarranties" />
      </span>
      <br />
      <span className="product-warranties-card-standard-Warranty">
        <T strProp="productStandardwarranty" />{" "}
        {productData?.standardWarrantyInYears || " 0 "}{" "}
        <T strProp="productyears" />
      </span>
      <div className="product-warranties-card-sub-title">
        <span>
          <T strProp="productPart" />
        </span>
        <span>
          <T strProp="productAdditionalYears" />
        </span>
      </div>
      {productData?.warranties && productData?.warranties?.length > 0 ? (
        productData?.warranties?.map((item, index) => {
          return (
            <Fragment key={index}>
              <div className="product-warranties-card-body" key={index}>
                <span>{item?.productCode ?? "--  --"}</span>
                <span>{item?.years ?? "--  --"}</span>
              </div>
              {productData?.warranties?.length - 1 !== index ? (
                <div className="border__line_px" />
              ) : null}
            </Fragment>
          );
        })
      ) : (
        <div className="product-warranties-card-body">
          <span>-- --</span>
          <span>-- --</span>
        </div>
      )}
    </section>
  );
}
// =================================== Product Add  =================================
/**
 *
 * @param {string} Title - use for display product title
 * @param {string} Name - use for display product name
 * @param {object} children - use for display specific nested info about product.
 * @param {string} Title1 - use for display product info
 * @param {number} index - use for specify product index
 * @param {number} lineLength - use for display product data length
 * @param {boolean} Space - use for add specific style
 * @returns this function can return details with proper formate.
 */
export function ProductAdd({
  Title,
  Name,
  children,
  Title1,
  index,
  lineLength,
  Space,
}) {
  return (
    <>
      <section className={Space ? "product__-info" : "product__info"}>
        <div className="product__info-flex">
          {Title1 ? <span className="product__info-body">{Title1}</span> : null}
          {Title ? (
            <span
              className={Space ? "product__info_title" : "product__info-title"}
            >
              {Title}
            </span>
          ) : null}
          {Name ? (
            <span
              className="product__info__body product-details"
            >
              {Name}
            </span>
          ) : null}
        </div>
        {children ? (
          <div className="product__info-padding">{children}</div>
        ) : null}
      </section>
      {Space ? null : lineLength - 1 !== index ? (
        <div className="border__line_px" />
      ) : null}
    </>
  );
}
// =================================== Product Add Remote Stock  ====================
/**
 *
 * @param {string} Title - use for display product title
 * @param {boolean} Enabled -
 * @param {string} By - use to display product made by name
 * @param {number} Number - use for dislay product number
 * @param {Array} ArrData - use for pass product data list
 * @param {number} index - use for specify product index
 * @returns this function can return list of remote stock list
 */
export function ProductRemoteStock({
  Title,
  Enabled,
  By,
  Number,
  ArrData,
  index,
}) {
  return (
    <>
      <div className="product-details-padding">
        <span className="product__info__body">{Title}</span>
      </div>
      <div
        className={Number ? `product__info-Remote` : `product__info__Remote1`}
      >
        <div className="product__info-Remote-flex">
          <span className="product__info-title">
            <T strProp="productEnabled" />
          </span>
          <span className="product__info__body">{Enabled}</span>
        </div>
        <div className="product__info-Remote-flex">
          <span className="product__info-title">
            <T strProp="productBy" />
          </span>
          <span className="product__info__body">{By}</span>
        </div>
        {Number ? (
          <div className="product__info-Remote-flex">
            <span className="product__info-title">
              <T strProp="productNumberDays" />
            </span>
            <span className="product__info__body">{Number}</span>
          </div>
        ) : null}
      </div>
      {ArrData.length - 1 === index ? null : (
        <div className="border__line_px" />
      )}
    </>
  );
}
// =================================== Product Botton ===============================
/**
 *
 * @param {string} Img - use for display loading image
 * @param {string} BtnTilte - use for displauy button title
 * @param {boolean} isloading - use for check whether loader will display or not
 * @param {function} onClick - use for specify avtion when click on that buttton
 * @param {boolean} isDesibaled -  use for display whether button display or desible
 * @returns this function can display product blue button.
 */
export function ProductBlueBtn({
  Img,
  BtnTilte,
  isloading,
  onClick = () => { },
  isDesibaled = false,
}) {
  return (
    <button
      className="gradient-button-primary cursor-pointer"
      onClick={onClick}
      disabled={isloading || isDesibaled}
    >
      {!isloading ? (
        <div className="product-img">
          <img src={Img} alt="" />
        </div>
      ) : (
        <div className="white-btn-loader" />
      )}
      <span>
        <T strProp={BtnTilte} />
      </span>
      <div className="product-img"></div>
    </button>
  );
}

/**
 *
 * @param {string} Img - use for display loading image
 * @param {string} BtnTilte - use for displauy button title
 * @param {boolean} isloading - use for check whether loader will display or not
 * @param {function} onClick - use for specify avtion when click on that buttton
 * @param {boolean} isDisabled -  use for display whether button display or desible
 * @returns this function can display product white button.
 */
export function ProductWhiteBtn({
  Img,
  BtnTilte,
  isloading = false,
  onClick = () => { },
  isDisabled = false,
  className = ""
}) {
  return (
    <button
      className="gradient-button-white cursor-pointer"
      onClick={onClick}
      disabled={isloading || isDisabled}
    >
      <div className="product-img">
        {!isloading ? (
          <img src={Img} alt="" className={className} />
        ) : (
          <div className="black-btn-loader" />
        )}
      </div>
      <span>
        <T strProp={BtnTilte} />
      </span>
      <div className="product-img"></div>
    </button>
  );
}
