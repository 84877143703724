import React from "react";
import { ERROR_404_IMG } from "../assets";

/**
 * @returns page not found jsx element
 */
function Error404() {
  return (
    <div className="error404">
      <img src={ERROR_404_IMG} alt="Error404" />
    </div>
  );
}

export default Error404;
