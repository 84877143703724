import { useState, useEffect } from 'react'
import { callApi } from '../services';
/**
 * this hook used to get planned shipment data from api 
 * @param  {string} token - for user authentication
 * @param  {int} pageNo=0 - for pagination 
 * @param  {ref} alertRef - used to hendal error alert popup
 * @returns - plannedShipmentData, loading, hasNextPage 
 */
const usePlannedShipmentHook = (token, pageNo = 0, alertRef) => {
    const [plannedShipmentData, setPlannedShipmentData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasNextPage, setHasNextPage] = useState(false);

    /**
     * @returns this fuction can call api get get list of planned shipment list and set this in planShipmentData state.
     */
    const getplannedShipments = async () => {
        !loading && setLoading(true);
        const { res, sCode } = await callApi("plannedshipments", { token, alertRef }, pageNo + 1);
        if (res?.error) {
            console.log('error', res?.error)
            setLoading(false);
        } else if (sCode === 401) { setHasNextPage(false); }
        else {
            if (sCode === 200 || sCode === 202) {
                let pCount = res?.body?.result?.pageCount;
                let pNum = res?.body?.result?.pageNumber;
                setHasNextPage(pCount > pNum);
                setPlannedShipmentData((p) => [...p, ...res?.body?.result?.items]);
            }; setLoading(false);
        }
    }

    useEffect(() => {
        if (token === null) return;
        getplannedShipments();
        setPlannedShipmentData([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    useEffect(() => {
        if (token === null) return;
        getplannedShipments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNo])


    return { plannedShipmentData, loading, hasNextPage }
}

export { usePlannedShipmentHook }