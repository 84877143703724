import React from "react";
import { Link } from "react-router-dom";
import { DISPATCH_BLUE_ICON, LOCATION_BLACK_ICON } from "../../assets";
import { dateFormate, toPrice } from "../../utils/functions";
import { T } from "../translation";

/**
 * @param {string} OrderDispatch - use for display order dispatch date
 * @param {string} OrderType - use for display order type
 * @param {int} OrderNo - use for display order number and redirect to particular order details page
 * @param {string} OrderDescription - use for display order description
 * @param {string} OrderLocation - use for display location from where order was placed
 * @param {number} OrderPrice - use for display price amount of order
 * @param {string} OrderDay - use for display ordered date
 * @returns - display shipment details elements 
 */
const PlannedShipmentsCard = ({ OrderDispatch, OrderType, OrderNo, OrderDescription, OrderLocation, OrderPrice, OrderDay }) => {
  var classTypename = "white";
  var classTypeText = "twhite";

  if (
    String(OrderType).toLowerCase() === "need to contact" ||
    String(OrderType).toLowerCase() === "undefined"
  ) {
    classTypename = "orange";
    classTypeText = "torange";
  } else if (OrderType === "Awaiting Payment") {
    classTypename = "blue";
    classTypeText = "tblue";
  } else if (OrderType === "Confirmed") {
    classTypename = "green";
    classTypeText = "tgreen";
  } else {
    classTypename = "white";
    classTypeText = "twhite";
  }
  
  return (
    <>
      <Link to={`/shipment_details/${OrderNo}`} className="link">
        <section className={`planned-shipments-card  ${classTypename} `}>
          <div className="planned-shipments-card-flex">
            <div className="planned-shipments-card-dates">
              <img src={DISPATCH_BLUE_ICON} alt="Dispatch" />
              <span className="planned-shipments-card-dispatch">
                <T strProp="Dispatch" />
              </span>
              <span className="planned-shipments-card__date">
                {dateFormate(OrderDispatch)}
              </span>
            </div>
            <div className={`planned-shipments-card-type ${classTypeText}`}>
              <span>{OrderType || "--"}</span>
            </div>
          </div>
          <div className="planned-shipments-card-no">
            <span>{OrderNo || "--"}</span>
          </div>
          <div className="planned-shipments-card-description">
            <span>{OrderDescription || "--"}</span>
          </div>
            <div className="planned-shipments-card-day">
              <span>{OrderDay || "--"}</span>
            </div>
          <div className="planned-shipments-card-flex">
            {OrderLocation ? (
              <div className="planned-shipments-card__location">
                <img src={LOCATION_BLACK_ICON} alt="Location" />
                <span className="planned-shipments-card__location-title">
                  <T strProp="Location" /> :{" "}
                </span>
                <span className="planned-shipments-card__location-name">
                  {OrderLocation || "--"}
                </span>
              </div>
            ) : <div />}
            {OrderPrice?.amount >= 0 ? (
              <div className="planned-shipments-card__location-price">
                {toPrice(OrderPrice)}
              </div>
            ) : null}
          </div>
        </section>
      </Link>
    </>
  );
}
export default PlannedShipmentsCard;
