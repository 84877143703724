import React, { useState } from 'react';
import MenuContext from './menuContext';

const MenuContextData = (props) => {

    const [otherMenu, setOtherMenu] = useState(false);
    const [otherMenuData, setOtherMenuData] = useState(false);
    return (
        <MenuContext.Provider value={{ otherMenu, setOtherMenu, otherMenuData, setOtherMenuData }}>
            {props.children}
        </MenuContext.Provider>
    )
}

export default MenuContextData