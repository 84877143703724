import ReactGA from "react-ga4";
ReactGA.initialize([{ trackingId: process.env.REACT_APP_MEASURE_ID }]);
function sendPageView(path, title) {
    ReactGA.send({ hitType: "Page View", page: path, title });
}

function sendEvents(category, action, data = {}) {
    ReactGA.event({ category, action, ...data });
}


export { sendPageView, sendEvents };