import React, { useRef, useState, useEffect } from "react";
import "./SegmentedControl.css";

/**
 * 
 * @param {string} name - use for indentofy segment name
 * @param {object} segments - use for pass all segment list
 * @param {function} callback - use for call function when click any segment
 * @param {number} defaultIndex - use for displauy default segment
 * @param {referance} controlRef - use for referance
 * @param {boolean} segmentsBIG - use for displauy style base on this props
 * @returns this function display segment component.
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-segmented-control-component
 */
const SegmentedControl = ({
  name,
  segments = [],
  callback,
  defaultIndex = 0,
  controlRef,
  segmentsBIG,
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);
  const componentReady = useRef();

  // Determine when the component is "ready"
  useEffect(() => {
    componentReady.current = true;
  }, []);

  useEffect(() => {
    const activeSegmentRef = segments[activeIndex].ref;
    const { offsetWidth, offsetLeft } = activeSegmentRef.current;
    const { style } = controlRef.current;

    style.setProperty("--highlight-width", `${offsetWidth}px`);
    style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
  }, [activeIndex, callback, controlRef, segments]);

  /**
   * 
   * @param {string} value - use for set value
   * @param {number} index - use for identify segment
   * @returns this fuction can set active index and call callback function.
   */
  const onInputChange = (value, index) => {
    setActiveIndex(index);
    callback(value, index);
  };

  return (
    <div className="controls-container" ref={controlRef}>
      <div
        className={
          segmentsBIG
            ? `controlsbig ${componentReady.current ? "ready" : "idle"}`
            : `controls ${componentReady.current ? "ready" : "idle"}`
        }
      >
        {segments?.map((item, i) => {
          return (
            <div
              key={item.value}
              className={
                segmentsBIG
                  ? `segmentbig ${i === activeIndex ? "active" : "inactive"}`
                  : `segment ${i === activeIndex ? "active" : "inactive"}`
              }
              ref={item.ref}
            >
              <input
                type="radio"
                value={item.value}
                id={item.label}
                name={name}
                onChange={() => onInputChange(item.value, i)}
                checked={i === activeIndex}
              />
              <label htmlFor={item.label}>{item.label}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SegmentedControl;
