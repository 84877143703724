import React, { useContext, useEffect, useState, useRef } from "react";
import { globalActionsTypes, useSessionHook } from "../hooks";
import { GlobalContext, MenuContext } from "../contexts";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../styles/components/navbar/navbar.css";
import { MainMenu } from "./modual/menus";
import { callApi } from "../services";
import { T } from "./translation";
import {
  ACCOUNT_OPEN_ICON,
  BASKET_OPEN_ICON,
  LEFT_ARROW_BLACK_ICON,
  RIGHT_ARROW_GRAY_ICON,
  SEGEN_LOGO,
  SEGEN_SOLAR_LOGO,
  SIDE_MENU_CLOSE_ICON,
  SIDE_MENU_OPEN_ICON,
} from "../assets";
import AlertPopup from "./Modals/AlertPopup";
import { toLower } from "../utils";
let isSA = {
  Path: "/POPIA",
  Title: "POPIA",
  tTitle: "titlePOPIA",
  isExpand: false,
  status: "",
};
/**
 *
 * @param {boolean} isLogin - state to data of user is login or not
 * @returns - nevbar ( header ) jsx element
 */
function NavBar({ isLogin }) {
  const location = useLocation();
  const { state, dispatch } = useContext(GlobalContext);
  const { otherMenu, setOtherMenu, /* otherMenuData */ setOtherMenuData } =
    useContext(MenuContext);
  const navigate = useNavigate();
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [isexpand, setIsExpand] = useState(false);
  // eslint-disable-next-line
  const [provider, setProvioder] = useState("");
  const [logoCode, setLogoCode] = useState("");
  const { getStatusAndToken } = useSessionHook();
  const { token } = getStatusAndToken();

  const MainMenuClose = () => setMainMenuOpen(false);
  const isExpandClose = () => setIsExpand(false);
  const alertRef = useRef(null);
  const _sideMenuData = state?.acObj?.paymentProvider === "Paygate" ? [...SideMenuData, isSA] : SideMenuData;
  useEffect(() => {
    if (token === null) return;
    if (location.pathname !== '/') return;
    const getBasketCount = async () => {
      await callApi("basketList", { token })
        .then(({ res, sCode }) => {
          if (sCode === 200 || sCode === 202) {
            if (res?.body?.result?.quote) {
              dispatch({ type: globalActionsTypes.setBasketBadge, data: 1 });
            } else {
              dispatch({ type: globalActionsTypes.setBasketBadge, data: 0 });
            }
          }
        })
        .catch((err) => console.log(err));
    };
    getBasketCount();

    // eslint-disable-next-line
  }, [token, location.pathname === '/']);

  useEffect(() => {
    if (state?.stockLocID === null) {
      !state?.loadingAcObj &&
        dispatch({ type: globalActionsTypes.setLoadingAcObj, data: true });
      /**
       * @function getAcSummary - fetch contact details from api
       */
      const getAcSummary = async () => {
        await callApi("account", { token })
          .then(({ res, sCode }) => {
            if (sCode === 200 || sCode === 202) {
              let stockLoc = res?.body?.result?.stockLocation;
              let id = stockLoc?.id || null;
              let description = stockLoc?.description || "";
              dispatch({
                type: globalActionsTypes.setStockLoc,
                data: { id, description },
              });
              dispatch({
                type: globalActionsTypes.setAccountObj,
                data: res?.body?.result,
              });
              setProvioder(res?.body?.result?.paymentProvider.toLowerCase());
              setLogoCode(res?.body?.result?.brand?.code);
            }
          })
          .catch((err) => console.log(err))
          .finally(() =>
            dispatch({ type: globalActionsTypes.setLoadingAcObj, data: false })
          );
      };
      getAcSummary();
    }
    // eslint-disable-next-line
  }, [token])
  const basket = () => {
    setMainMenuOpen(MainMenuClose);
    if (state?.basketBadgeCount === 0) {
      alertRef.current?.onWarn({
        titleLocal: "OOPS",
        bodyLocal: "EmptyBasket",
        btnTxt: <T strProp="modalCloseButtonText" />,
        goBack: false,
      });
    } else {
      window.location?.pathname !== "/basket" && navigate(`basket`);
    }
  };
  var prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (currentScrollPos >= 50) {
      if (mainMenuOpen !== true && otherMenu !== true) {
        if (prevScrollpos > currentScrollPos) {
          document.getElementById("navbar-fixed").style.top = "0";
        } else {
          document.getElementById("navbar-fixed").style.top = "-42px";
        }
      }
    }
    prevScrollpos = currentScrollPos;
  };
  const scroll = () => {
    document.getElementById("navbar-fixed").style.top = "0";
  };

  return (

    <>
      <section id="navbar-fixed" onClick={scroll}>
        <header className="navbar-bg-img">
          <nav className={isLogin ? "navbar-heder" : "navbar__heder"}>
            {isLogin ? (
              <div className="main-header_icon1">
                {!otherMenu ? (
                  <div className="navbar__side-menu-icon">
                    <img
                      onClick={() => setMainMenuOpen(!mainMenuOpen)}
                      src={
                        mainMenuOpen
                          ? SIDE_MENU_CLOSE_ICON
                          : SIDE_MENU_OPEN_ICON
                      }
                      alt="NavBar-Left-Icon"
                      className="cursor-pointer"
                      draggable={false}
                    />
                  </div>
                ) : isexpand ? (
                  <>
                    <div
                      className="navbar__side-menu-icon cursor-pointer"
                      onClick={() => {
                        setOtherMenu(false);
                        setOtherMenuData(false);
                        isExpandClose(false);
                      }}
                    >
                      <img
                        src={SIDE_MENU_CLOSE_ICON}
                        alt="NavBar-Left-Icon"
                        draggable={false}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className="navbar__side-menu-icon cursor-pointer"
                    onClick={() => {
                      setOtherMenu(false);
                      setOtherMenuData(false);
                      isExpandClose(false);
                    }}
                  >
                    <img
                      src={SIDE_MENU_CLOSE_ICON}
                      alt="NavBar-Left-Icon"
                      draggable={false}
                    />
                  </div>
                )}
              </div>
            ) : null}
            {!isLogin && (
              <Link
                to="/"
                className="main-header_logo"
                onClick={() => {
                  setMainMenuOpen(MainMenuClose);
                  setOtherMenu(false);
                  isExpandClose(false);
                  setOtherMenuData(false);
                  document.getElementById("navbar-fixed").style.top = "0";
                }}
              >
                <img src={SEGEN_LOGO} alt="Navbar-logo" draggable={false} />
              </Link>
            )}
            <Link
              to="/"
              className="main-header_logo"
              onClick={() => {
                setMainMenuOpen(MainMenuClose);
                setOtherMenu(false);
                isExpandClose(false);
                setOtherMenuData(false);
                document.getElementById("navbar-fixed").style.top = "0";
              }}
            >
              <img src={toLower(logoCode) === "segen" ? SEGEN_LOGO : toLower(logoCode) === "segenpty" || !isLogin ? SEGEN_SOLAR_LOGO : SEGEN_LOGO} alt="Navbar-logo" draggable={false} />
              {/* <img src={isLogin ? logoUrl ? logoUrl : SEGEN_LOGO : !isLogin ? SEGEN_SOLAR_LOGO : SEGEN_LOGO } alt="Navbar-logo" className="logo-height" draggable={false} /> */}
              {/* <img src={provider === "worldpay" ? SEGEN_LOGO : provider === "paygate" || !isLogin ? SEGEN_SOLAR_LOGO : SEGEN_LOGO } alt="Navbar-logo" draggable={false} /> */}
            </Link>

            {isLogin ? (
              <div className="navbar__acc_noti-icon">
                <Link
                  to="/account"
                  className="navbar__account-icon"
                  onClick={() => {
                    setMainMenuOpen(MainMenuClose);
                    setOtherMenu(false);
                    isExpandClose(false);
                    setOtherMenuData(false);
                  }}
                >
                  <img
                    src={ACCOUNT_OPEN_ICON}
                    alt="NavBar-Right-Icon"
                    draggable={false}
                  />
                </Link>
                <div className="navbar__basket-icon" onClick={basket}>
                  <img
                    src={BASKET_OPEN_ICON}
                    alt="NavBar-Right-Icon"
                    draggable={false}
                    onClick={() => {
                      setMainMenuOpen(MainMenuClose);
                      setOtherMenu(false);
                      isExpandClose(false);
                      setOtherMenuData(false);
                    }}
                  />
                  {state?.basketBadgeCount > 0 && (
                    <div className="notification">
                      {state?.basketBadgeCount > 99
                        ? "99+"
                        : state?.basketBadgeCount}
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </nav>
        </header>
      </section>
      <AlertPopup ref={alertRef} />
      {/* =========================================================================== */}
      <MainMenu
        mainMenuOpen={mainMenuOpen}
        setMainMenuOpen={setMainMenuOpen}
        SideMenuClose={MainMenuClose}
      >
        <section className="side-menu-bar">
          <section className="sub-menu-box">
            <section className="sub-menu-box-title">
              <div className="menu-heading-title">
                <span>
                  <T strProp="Menu" />
                </span>
              </div>
            </section>
            {_sideMenuData.map((item, index) => {
              return item.Path === "" ? (
                <div
                  className="sub-menu-"
                  key={index}
                  onClick={() => {
                    setMainMenuOpen(false);
                    setIsExpand(item.isExpand);
                    setOtherMenu(true);
                  }}
                >
                  <div className="sub-menu--contant">
                    {item.status === "panding" && (
                      <div className="panding-page" />
                    )}
                    <span className="sub-menu--name">{item.Title}</span>
                  </div>
                  <div className="menu-icon">
                    <img src={RIGHT_ARROW_GRAY_ICON} alt=">" />
                  </div>
                </div>
              ) : (
                <Link
                  key={index}
                  to={item.Path}
                  className="sub-menu-"
                  onClick={() => setMainMenuOpen(false)}
                >
                  <div className="sub-menu--contant">
                    {item.status === "panding" && (
                      <div className="panding-page" />
                    )}
                    <span className="sub-menu--name">{item.Title}</span>
                  </div>
                </Link>
              );
            })}
          </section>
        </section>
      </MainMenu>
      <MainMenu
        mainMenuOpen={isexpand}
        setMainMenuOpen={setIsExpand}
        SideMenuClose={() => {
          isExpandClose(false);
          setOtherMenu(false);
        }}
      >
        <section className="side-menu-bar">
          <div className="incomplete-page" />
          <section
            onClick={() => {
              setMainMenuOpen(!mainMenuOpen);
              setIsExpand(false);
              setOtherMenu(false);
            }}
          >
            <div className="bg-img__Back-icon">
              <img src={LEFT_ARROW_BLACK_ICON} alt="icon" />
              <span>
                <T strProp="Back" />
              </span>
            </div>
          </section>
          <section className="sub-menu-box">
            <section className="sub-menu-box-title">
              <div className="menu-heading-title">
                <span>
                  <T strProp="titleplaceorder" />
                </span>
              </div>
            </section>
            {SubSideMenuData.map((item, idx) => {
              return (
                <Link
                  key={idx}
                  to={item.Path}
                  className="sub-menu-"
                  onClick={() => {
                    setIsExpand(false);
                    setOtherMenu(false);
                  }}
                >
                  <div className="sub-menu--contant">
                    {item.status === "panding" && (
                      <div className="panding-page" />
                    )}
                    <span className="sub-menu--name">{item.Title}</span>
                  </div>
                </Link>
              );
            })}
          </section>
        </section>
      </MainMenu>
    </>
  );
}

export default NavBar;

const SubSideMenuData = [
  {
    Path: "/placeorder",
    Title: "Create New Order",
    status: "",
  },
  {
    Path: "",
    Title: "Design A PV System",
    status: "",
  },
  {
    Path: "",
    Title: "Quick Quote",
    status: "",
  },
];
let SideMenuData = [
  {
    Path: "/",
    Title: "Home",
    tTitle: 'home',
    isExpand: false,
    status: "",
  },
  {
    Path: "/productcategory",
    Title: "Products",
    tTitle: "Products",
    isExpand: false,
    status: "",
  },
  {
    Path: "/about-us",
    Title: "About Us",
    tTitle: 'aboutUs',
    isExpand: false,
    status: "",
  },
  // HIDE OR BYPASS
  // {
  //   Path: "",
  //   Title: "Place Orders",
  //   isExpand: true,
  //   status: "",
  // },
  {
    Path: "/news",
    Title: "News",
    tTitle: "news",
    isExpand: false,
    status: "",
  },
  {
    Path: "/documentation",
    Title: "Documentation",
    tTitle: "acMenuDocumentation",
    isExpand: false,
    status: "",
  },
  {
    Path: "/training_events",
    Title: "Training & Events",
    tTitle: "titleTrainingEvents",
    isExpand: false,
    status: "",
  },
  {
    Path: "/term-and-conditions",
    Title: "Terms and Conditions",
    tTitle: "titleTearmsNcondition",
    isExpand: false,
    status: "",
  },
  {
    Path: "/privacy-policy",
    Title: "Privacy Policy",
    tTitle: "titlePrivacyPolicy",
    isExpand: false,
    status: "",
  },

  // HIDE OR BYPASS
  // {
  //   Path: "/",
  //   Title: "Supplier",
  //   isExpand: false,
  //   status: "panding",
  // },
  // HIDE OR BYPASS
  // {
  //   Path: "/",
  //   Title: "Configuration Tools",
  //   isExpand: false,
  //   status: "panding",
  // },
];
