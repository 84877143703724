import quote from "./quote/en-GB";

const enGB = {
  ...quote,
  enterPoNumber: "Enter PO NO.",
  isNotFound: "is not found",
  invalidPartNum: "Invalid Part Number",
  doNotScheduleDelivery: "Do not schedule delivery",
  newsFutureDate: "Date Type - Future",
  newsNowDate: "Date Type - Now",
  newsTypeAsc: "News Type - Ascending",
  newsTypeDec: "News Type - Decending",
  quantity: "Quantity",
  basket: "Basket",
  basketItemDiscount: "Discount",
  basketPartNo: "Part no",
  Type: "Type",
  etr: "Enter",
  basketTotalsTotalPrice: "Total price",
  basketTotalsGrossPrice: "Gross price",
  basketCustomerDetailsTitle: "Customer details",
  basketNoDeliveryText: "No delivery information available",
  basketNotFound: "Basket Not Found",
  buyNow: "Buy Now",
  basketUnableToRetrieve: "Unable to retrieve basket",
  cancel: "Cancel",
  categories: "Categories",
  category: "Category",
  clearFilters: "Clear Filters",
  close: "Close",
  confirmOrder: "Confirm order",
  contactForm: "Contact form",
  contactUs: "Contact us",
  delete: "Delete",
  documents: "Documents",
  error: "Error",
  filters: "Filters",
  err401: "Access denied due to invalid crediantals",
  perDenied: "Permission denied",
  errUnAuth: "Unauthorized",
  home: "Home",
  fetchingProduct: "Loading page content",
  keycontactsaccountmanagertext:
    "Your account manager will be able to assist you in using the reseller portal as well as any product or commercial enquiries",
  keycontactsaccountmanagertitle: "Your account manager",
  keycontactsmarketingtext:
    "Talk to us about upcoming events, access to manufacturers and all things marketing",
  keycontactsmarketingtitle: "Your Marketing Enquiries",
  keycontactsorderprocessortext:
    "Your order processor is on hand to help manage your orders and stock queries as well as deliveries and payments.",
  keycontactsorderprocessortitle: "Your Submitted Orders",
  keycontactstechnicaltext:
    "Call us for help before or after installation with your mounting and electrical questions.",
  keycontactstechnicaltitle: "Your Technical Enquiries",
  item: "Item",
  /* Temp Contact data */
  drCard1:
    "Your Account Manager is able to assist you in using the reseller portal as well as any product or commercial enquiries.",
  drCard2:
    "Your Order Processor is on hand to help manage your orders and stock queries as well as deliveries and payments.",
  drCard4:
    "Talk to us about upcoming events, access to manufacturers and all things marketing.",
  /* Remove above */
  listViewManufacturelistViewManufacture: "Manufacture",
  mainHeadingContactForm: "Contact Form placeholder",
  modalClose: "Close modal",
  modalCloseButtonText: "Ok",
  modalErrorHttp400:
    "Something wasn't quite right. Please correct the issues below and try again.",
  modalErrorHttpOther: "Opps something went wrong please try again later",
  partNumber: "Part No",
  searchPartNumber: "Part No : ",
  personalDataAmend: "Personal Data Amendment or Removal",
  privacyPolicy: "Privacy and Cookie Policy",
  productsAddedToBasket: "Products added to basket",
  productsView: "View products",
  quotation: "Quotation",
  quote: "Quote",
  addToQuote: "Add to Quote",
  search: "Search",
  searchFilterGridView: "Grid view",
  searchFilterListView: "List view",
  searchFormLabelText: "Search for products, quotes, orders",
  searchPageHeading: "Search Segen Solar Pty Wholesale Online",
  searchTerm: "Search for products",
  searchViewError: "Unable to load products",
  searchViewErrorNoItems: "No items to display",
  specification: "Specification",
  signIn: "Sign In",
  signOut: "Sign out",
  subCategory: "Sub-category",
  subCategories: "Sub-categories",
  submit: "Submit",
  aboutUs: "About Us",
  "-": "-",
  unableToAddToBasket: "Unable to add to basket",
  "+": "+",
  welcomeToSegen: "Welcome to Segen",
  segenWholeSale: "Segen Solar Pty Wholesale Online",
  offLowercase: "off",
  noFilter: "No filter available yet!",
  orderOrShipmentsDispatch: "Dispatch",
  orderDueTitle: "orders due",
  orders: "Orders",
  shipmentsDueTitle: "shipments due",
  orderOrShipStrap: "in the next 3 working days",
  ordersNoOrders: "No orders in the next 3 working days",
  shipmentsNoOrders: "No shipments in the next 3 working days",
  ordersHasFetchError: "Unable to get order data",
  shipmentsHasFetchError: "Unable to get shipment data",
  shipmentsInvalideOrder: "Invalid Orders",
  productStockInStock: "In stock",
  productUnallocated: "Unallocated",
  productDelivered: "Delivered",
  productNotFound: "Product not found",
  Back: "Back",
  addToOrder: "Add to Order",
  pack: "pack",
  additionalInfoTitlealternates: "Related Parts",
  additionalInfoTitlewarranties: "Additional Warranties",
  additionalInfoNoDataspecification: "No specification data to show",
  additionalInfoNoDataavailability: "No availability data to show",
  additionalInfoNoDatadocuments: "No documents to show",
  additionalInfoNoDataalternates: "No related parts to show",
  additionalInfoNoDatawarranties: "No additional warranties",
  productSpecificationsIntro:
    "If you have any technical queries about this product or wish to report any issues with the information on this page then please use the Contact Form to contact us",
  bulkBuyPrice: "Price/Unit",
  bulkBuyCaption: "Bulk buy",
  segenSolarPortal: "Reseller Portal",
  signUp: "Sign Up",
  warrantiesStandard: "Standard warranty is 10 years",
  welcomeIntro: "The Unique online customer portal offers:",
  welcomeOffering1: "Extensive Product range",
  welcomeOffering2: "Live pricing and stock availability",
  welcomeOffering3: "Detailed technical documents written by experts",
  welcomeOffering4: "Free design tools",
  welcomeOffering5: "Online access 24/7",
  welcomeOffering6: "Dedicated support from the fantastic team",
  welcomeInfo: `The portal has been created with the installer in mind and acts as an
  extension to your business. if you are a PV installer and not already
  a segen customer, click 'Register' above for portal access today.`,
  welcomeforMore: "for more.",
  PhoneNumberPopup: "Please enter phone number with country code",

  // ================================================  NEW =====================================================================

  acMenuCurrentQuotes: "Current Quotes",
  acMenuAccountSummary: "Account Summary",
  acMenuPayments: "Payments",
  acMenuSetting: "Settings",
  acMenuLogOut: "Log Out",
  acMenuAccount: "Account",
  acMenuAllPhotovoltaic: "All Photovoltaic",
  acMenuInverters: "Inverters",
  acMenuStorageSystem: "Storage System",
  acMenuContactUS: "Contact US",
  acMenuDocumentation: "Documentation",
  acMenuPresentationsDownloads: "Presentations & Downloads",
  acMenuTrainingEvents: "Training Events",
  // ===================================
  acHeadingActiveQuote: "Active Quote",
  acHeadingAccountHistory: "Account History",
  acHeadingOutstandingAmountsonAccounts: "Outstanding Amounts on Accounts",
  acHeadingInvoicesList: "Invoices List",
  acHeadingRecentAddresses: "Recent Addresses",
  acHeadingUpdateStockLocation: "Update Stock Location",
  // ===================================
  buttonNameDownloadAccountHistory: "Download Account History",
  buttonNameUpdatePreferences: "Update Preferences",
  buttonNameApplyFilter: "Apply Filter",
  buttonNameUpdate: "Update",

  // ================================== Home =================================
  homeFeaturedProducts: "Featured Products",
  homeLatestNews: "Latest News",
  homeContacts: "Contacts",
  homeUpcoming: "Upcoming",
  homeMostRCO: "Most recent coming orders",
  homeMostRCS: "Most recent coming shipments",
  homeNoOrders: "No Upcoming Orders Available. 📦",
  homeNoShipments: "No upcoming shipments available. 🚚",

  // ================================== Products =================================
  Products: "Products",
  commingSoon: "Comming soon 📢",
  productByProducts: "By Products",
  productByCategory: "By Category",
  productByManufacturer: "By Manufacturer",
  productNPF: "No Products Found. 📦",
  productView: "View",
  productResults: "Results",
  contactUsForMore: "Contact us for more",
  productStock: "In Stock",
  productBuyItNow: "Buy It Now",
  productAddBasket: "Add to Basket",
  productorderlist: "This item has been added to your quote.",
  productNoImageAvailable: "No Image Available",
  productOtherParts: "Other Parts",
  productAvailability: "Availability",
  productProductSpecification: "Product Specification",
  productSuccessfully: "Successfully!",
  productyourbasket: "Your item has been added to your basket.",
  productBulkbuy: "Bulk Buy",
  productPriceunit: "Price/unit",
  productAvailableWarranties: "Available Warranties",
  productStandardwarranty: "Standard warranty is",
  productyears: "years",
  productPart: "Part",
  productAdditionalYears: "Additional Years",
  productEnabled: "Enabled",
  productBy: "By",
  productNumberDays: "Number Of Days",
  productProductInformation: "Product Information",
  productReadMore: "Read More",
  productSearchProducts: "Search Products",
  productInstockfirst: "In stock first",
  productLatest: "Latest",
  productPricehighlow: "Price high-low",
  productPricelowhigh: "Price low-high",
  productAtoZ: "Product Description A-Z",
  productZtoA: "Product Description Z-A",
  manufacturerAtoZ: "Manufacturer A-Z",
  manufacturerZtoA: "Manufacturer Z-A",
  validatedSuccessfull: "Validated Successfully!",
  isValidatedSuccessfull: "is validated successfully!",
  searchNews: "Search news",
  OOPS: "Oops",
  BtnOK: "OK",

  // ================================== palce Order =================================

  palceOrderCreateNewOrder: "Create New Order",
  palceOrderImportantNote: "Important Note",
  palceOrderCreate: "Create",
  palceOrderTemplate: "Template",
  orderNotFound: "Order Not Found",

  // ================================== News =================================

  news: "News",
  newsFilter: "Filter",
  newsClearfilters: "Clear filters",
  newsViewResults: "View Results",
  newsNotFound: "No News Found. 📰",

  // ================================== Login =================================

  loginMrMrs: "Mr/Mrs",
  loginFirstName: "First Name",
  loginSurname: "Surname",
  loginEmailAddress: "Email Address",
  loginTelephoneNumber: "Telephone Number",
  loginCompanyName: "Company Name",
  loginCompanyWebsite: "Company Website",
  loginRoadAddress: "Road Address",
  loginTown: "Town",
  loginProvince: "Province",
  loginPostCode: "Post Code",
  loginRepublic: "Republic",
  loginServicesOffered: "Services Offered",
  loginEnquiryDetails: "Enquiry Details",
  loginEmailfortnightlySegennewsletter:
    "Email me the fortnightly Segen newsletter.",
  loginEmailtrainingevents: "Email me about training events.",
  loginThankyouregistration: "Thank you for registration",
  loginLoginNow: "Login Now",
  loginSegensolarPortal: "Segen solar Portal",
  loginUsername: "Username",
  loginRememberme: "Remember me.",
  loginForgottenPasswordSignin: "Forgotten Password?",
  loginForgettenInfo:
    "Please enter your username and we will email you with instructions on how to reset your password:",
  loginforgottenyourusername:
    "If you have also forgotten your username, please",
  loginContactSegen: "Contact Segen",
  loginrecover: "to recover it.",
  loginpreviouslyregisteredwithsegen:
    "If you have not previously registered with segen please use",
  loginissuedwithpassword: "to be issued with a password.",
  logoutText: "Logout",
  welcomeContactUsMsg:
    "Thank you for your enquiry. We have sent you a confirmation email and will be in contact shortly.",

  // ================================== Contact Us =================================

  contactinfo:
    "To log an enquiry with SegenSolar please complete the form below and press submit.",
  contactContactName: "Contact Name",
  contactEmail: "Email",
  contactPhone: "Phone",
  EnterShipmentContact: "Delivery Site Phone Number",
  contactPriority: "Priority",
  contactSubject: "Subject",
  contactDescription: "Description",
  contactrobot: "I’m a not a robot",
  contactourAddress: "our Address",
  contactHeadOfficeAddress: "SEGEN HEAD OFFICE ADDRESS",
  contactAfricaHeadOfficeAddress: "SEGEN SOLAR HEAD OFFICE ADDRESS",
  Contact: "Contact",
  contactinfosegen: "info@segen.co.uk",
  contactinfosegenAfrica: "info@segensolar.co.za",
  contactinfoNumber:
    "Segen Ltd is registered in England and Wales as company number 5309114",
  contactinfoNumberAfrica:
    "SegenSolar (Pty) Ltd is registered in South Africa as company number 2012/208988/07.",

  // ================================== Web Title =================================
  titleNewReturns: "New Return",
  titleproductCategory: "Product Category",
  titleproductList: "Product List",
  titleproductDetails: "Product Details",
  titleQuotes: "Quotes",
  titleCurrentOrders: "Current Orders",
  titlePlannedShipments: "Planned Shipments",
  titleShipments: "Shipments",
  titleStatement: "Statement",
  titleCurrentInvoices: "Current Invoices",
  titleHistory: "History",
  titlePayment: "Payment",
  titleRecentOrder: "Recent Order",
  titleRecentShipments: "Recent Shipments",
  titleRecentInvoices: "Recent Invoices",
  titleCurrentReturns: "Current Returns",
  titleContactPreferences: "Contact Preferences",
  titleSetting: "Setting",
  titleGeneralSettings: "General Settings",
  titleAddressBook: "Address Book",
  titleUserSecurityLevel: "User Security Level",
  titleChangePassword: "Change Password",
  titleChangeStockLocation: "Change Stock Location",
  titleContactUs: "Contact Us",
  titleplaceorder: "Place Order",

  titleDocumentation: "Doucmentation",
  titleQuotationDetails: "Quotation Details",
  titlenewsdetails: "News Details",
  titleeditQuote: "Edit Quote",
  titleAddItem: "Add Items",
  titleEditDeliveryAddress: "Edit Delivery Address",
  titleConfirmOrder: "Confirm Order",
  titleConfirmedOrder: "Confirmed Order",
  titleCreateShipment: "Create Shipment",
  titleShipmentDetails: "Shipment Details",
  titleCardPayment: "Card Payment",
  titleOnLinePayment: "On-Line Payment",
  titleEditShipment: "Edit Shipment",
  titleEditOrder: "Edit Order",
  titlePOPIA: "POPIA",
  titlePrivacyPolicy: "Privacy Policy",
  titleTearmsNcondition: "Terms and Conditions",
  titleTrainingEvents: "Training & Events",
  titlePresentationsDownload: "Presentations & Download",
  titleOrderDetails: "Order Details",
  titleQuickAdd: "Quick Add",
  titleForgottenPassword: "Forgotten Password",
  titleLogin: "Login",
  titlewelcome: "Welcome",
  confirmOrderShipmentList: "Add to shipment",
  // ================================== Quick Add Msg =================================
  quickValidateFirst: "Please validate first",
  quickPartNotFound: "Part Not Found",
  quoteNotFound: "Quote Not Found",
  success: "Success",
  AddAddress: "+ Add Address",
  AddressDetails: "Address Details",
  shippingAddress: "Shipping Address",

  // ================================== ADD  =================================
  InvNO: "Inv NO.",
  PostinNo: "Postin No.",
  InvValue: "Inv Value",
  VAT: "VAT",
  Gross: "Gross",
  Remaining: "Remaining",
  Date: "Date",
  Quantity: "Quantity",
  Price: "Price",
  IncVAT: "Inc. VAT",
  Delivery: "Delivery",
  DCLDelivery: "DCL Delivery to site",
  Dispatch: "Dispatch : ",
  Location: "Location",
  ShipmentNO: "Shipment NO",
  DeliveryInformation: "Delivery Information",
  DispatchDate: "Dispatch Date",
  DeliveryDate: "Delivery Date",
  NetValue: "Net Value",
  DeliveryCharge: "Delivery Charge",
  Status: "Status",
  RequestDispatch: "Request Dispatch",
  YouNeedToPay: "You need to pay ",
  BeforeYouCan: " before you can",
  MakePayment: "Make a Payment",
  Add: "Add",
  Password: "Password",
  NewPassword: "New Password",
  clickhere: "Click here ",
  CurrentPassword: "Current Password",
  ConfirmPassword: "Confirm New Password",
  Month: "Month",
  Country: "Country",
  Language: "Language",
  ExitingContacts: "Exiting Contacts",
  SecurityLevel: "Security Level",
  EnterContactName: "Enter Contact Name",
  EnterEmailAddress: "Enter Email Address",
  EnterMobileNumber: "Enter Mobile Number",
  EnterPhoneNumber: "Enter Phone Number",
  EnterSubject: "Enter Subject",
  Typehere: "Type here...",
  EnterUsername: "Enter Username",
  SORTBY: "SORT-BY",
  yournamequote: "your name for the quote",
  CreatedOn: "Created On",
  StockLocation: "Stock Location",
  Entername: "Enter name",
  EnterSurname: "Enter Surname",
  EnterEmail: "Enter Email",
  EnterNumber: "Enter Number",
  EnterCompanyName: "Enter Company Name",
  Website: "Website",
  EnterPostCode: "Enter Post Code",
  Offered: "Offered",
  TotalOutstanding: "Total Outstanding",
  OpeningBalance: "Opening Balance",
  Sortby: "Sort by",
  TypehereC: "Type here..( Max 1000 characters )",
  shipmentNotFound: "Shipment Not Found",
  Done: "Done",
  Download: "Download",
  Ordered: "Ordered",
  Dispatched: "To Dispatch",
  OtherAccountSettings: "Other Account Settings",
  DeleteQt: "Delete Quote",
  DeleteItem: "Delete Item",
  RequestDispatchShipmentSuucess: "Shipment Dispatch Successfully!",
  DeleteShipmentSuucess: "Shipment Item Deleted Successfully!",
  EditShipmentSuucess: "Shipment Line Item Updated Successfully!",
  EmailProformaSuucess: "Email Proforma Sent Successfully!",
  yousuredelete: "Are you sure, you want to delete this Item?",
  yousuredeleteQ: "Are you sure, you want to delete this Quote?",
  yousuredeleteShipments: "Are you sure, you want to delete this Shipment ?",
  Menu: "Menu",
  Shipment: "Shipment",
  ShipmentDate: "Shipment Date",
  LooksGood: "Looks Good!",
  SelectManufacturer: "Select Manufacturer",
  SelectCategory: "Select Category",
  EnterQuantity: "Enter Quantity",
  Thistestname: "This is test name",
  EnterOrganisation: "Enter Organisation",
  EnterStreet: "Enter Street",
  EnterTown: "Enter Town",
  EnterCountry: "Enter Country",
  EnterAmount: "Enter Amount",
  EnterRefernece: "Enter Refernece",
  EnterDate: "Enter Date",
  EnterPONO: "Enter PO NO.",
  Usechangeyourpassword: "Use the form below to change your password.",
  Noitemsbasket: "No items in basket. 🛒",
  Noactivequotes: "No active quotes. 📝",
  NoRecentOrder: "No recent order",
  noProjFound: "No Projects Found. 📦",
  PartNumbers: "Enter Part Number",
  NoPlannedShipment: "No planned shipment",
  EnterContact: "Enter Contact",
  SelectCountry: "Select Country",
  EnterPhone: "Enter Phone",
  noDoc: "No Documents Found",
  Newcharactersinlength:
    "New passwords are required to be a minimum of 6 characters in length.",
  noInfoAvailable: "Information not available yet!",
  editQuoteSuccessfully: "Delivery Details updated successfully!",
  editShipmentSuccessfully: "Shipment updated successfully!",
  AddAddressSuccess: "Address added successfully!",
  EmptyBasket:
    "You do not have anything in your Basket at the moment. Please add some items to continue.",
  seeAll: "See All",
  totalBalance: "Total Balance :",
  noCategories: "No categories found",
  selectTime: "Select Time",
  accountName: "Account Name",
  segenCustomerCode: "Segen Customer Code",
  discountLevel: "Discount Level",
  creditLimit: "Credit Limit",
  creditAvailable: "Credit Available",
  outstandingBalance: "Outstanding Balance :",
  address: "Address",
  changethisaddress: "Change this address",
  noRecentShip: "No recent shipments available yet!",
  noRecentInvoices: "No recent invoices available yet!",
  noCurrentReturn: "No current returns available yet!",
  noAddress: "No address found yet.",
  key: "Key",
  overdue: "Overdue",
  nearlyDue: "Nearly Due",
  ContactPreferencesinfo: "Contact Preferences info",
  emailmethefortnightlySegennewsletter:
    "Email me the fortnightly Segen newsletter",
  emailmeabouttrainingevents: " Email me about training events",
  donotcallme: " Do not call me",
  donotemailme: "Do not email me",
  infoCP:
    "These settings will NOT effect emails you receive in relation to any orders you have placed.",
  iCB: "Invoice created before",
  infoRI: "Invoices with a remaining amount will always be shown",
  infoGS:
    "Select the number of months for which you want to keep the old quotes",
  emailOptOut: "Email Opt Out",
  infoUSL: "Select contact and security level to update",
  infpCSL: "View Stock info",
  project: "Projects",
  Strength: "Strength",
  waek: "Waek",
  medium: "Medium",
  eventMilesAway: "Miles Away",
  logoutinfo: "Are you sure, you want to log out your account?",
  No: "No",
  Yes: "Yes",
  dfmNoDatainNews: "No Data in News. 📰",
  unableToBuy: "Unable to buy, something went wrong.",
  SomethingWentWrong: "Something went wrong.",
  noDataFound: "No Data Found",
  noItemDataFound: "No Item Found",
  noDocumentationFound: "No Documentation Available",
  noOrd: "No Orders Available",
  deliveryAddressBook: "Delivery Address Book",
  hReadLess: "Read Less",
  shippingAddressSuccess: "Shipping Address Updated Successfully!",
  // ============================== Validation MSG =============================
  PleaseSelectOneItem: "One item from the list should be checked out.",
  PleaseEnterFirstName: "Please enter first name",
  PleaseEnterSurname: "Please enter surname",
  PleaseEnterEmailAddress: "Please enter email address",
  PleaseEnterValidEmail: "Please enter valid email",
  PleaseEnterEmail: "Please enter email",
  PleaseEnterTelephoneNumber: "Please enter telephone number",
  PleaseEnterPhoneValid: "Please enter valid phone",
  PleaseSelectPriority: "Please select priority",
  PleaseEnterPhone: "Please enter phone number",
  PleaseSelectType: "Please select type",
  PleaseEnterSubject: "Please enter subject",
  PleaseEnterDesc: "Please enter description",
  PleaseEnterCompanyName: "Please enter company name ",
  PleaseEnterCompanyWebsite: "Please enter company website ",
  PleaseEnterRoadAddress: "Please enter road address",
  PleaseEnterPhoneDegit: "Phone number length at least 5 digit",
  PleaseEnterServicesOffered: "Please enter services offered ",
  PleaseEnterAddressType: "Please select address type",
  PleaseSelectDeliveryPrefrence: "Please select delivery prefrence",
  NotMatchingDeliveryMethod: "We Cannot find any matching delivery method for your date",
  PleaseSelectDeliveryMethod: "Please select delivery method",
  SelectDeliveryMethod: "Select Delivery Method",
  Pleaseselectmanufacturercategory: "Please select manufacturer or category",
  PleaseEnterquantity: "Please enter quantity",
  PleaseEnterpartnumber: "Please enter part number",
  qtyMorethenZero: "Quantity must be greater than zero.",
  PleaseAddquantity: "Please add quantity",
  PleaseEnterStreet: "Please enter street",
  PleaseEnterTown: "Please enter town",
  PleaseEnterPostCode: "Please enter post code",
  Entercontactpersonname: "Please enter contact person name",
  Entercontactname: "Please enter contact name",
  PleaseSelectCountry: "Please select country",
  PleaseAddyourTown: "Please add your town",
  pleaseSelectQuote: "Please select quote",
  PleaseEnterPassword: "Please enter password",
  PleaseEnterFutureDate: "Please enter future date",
  // ==========================================================
  //============= Shipment Message ===============
  EnableDispMsgPre:
    "You cannot Request Dispatch on this shipment yet as some items still have not been allocated from stock.",
  EnableDispMsgPost:
    "to contact us about the shipment and see if we can find some stock for you.",
  KnownAddress: "Known Address",
  SelectKnownAddressPlaceholder: "Select a known address",
  IncorrectDispatchDateMsg: "This order has an incorrect dispatch date. Please select a date no sooner than",
  WhenItemComeInstockMsg: "when your items will be in stock and then create your shipment.",
  addToNewShip: "Add to a new shipment",
  termsandconditionPt1: "Please click to confirm you've read and understood",
  termsandconditionPt2: "trading Terms & Conditions. Our Terms & Conditions can be found",
  termsandconditionPt3: "here",
  termsandconditionPt4: "and are subject to change as mentioned in the document.",
  please: "Please",
  contactUsSm: "contact us",
  setupTradingAccount: "to set up a Trading Account.",

  confirmation1: "I Confirm I Have Read The Latest",
  confirmation2: "Customers Returns Policy And Terms And Conditions",
  returnDetails: "Return Details",
  shippedItems: "Shipped Items",
  confirmReturn: "Confirm Return",
  cancelOrder: "Cancel Order",
  reason: "Reason",
  returningProdSelection: "Returning Prod Selection",
  returning: "Returning",
  returnsQuestionsandAnswers: "Returns Questions and Answers",
  serialNo: "Serial number of failty item(s)",
  dateOfInst: "Date of installation",
  dateOfFail: "Date of failure",
  desOfFalut: "Describe the fault",
  checkForTrouble: "Has documentation been checked for troubleshooting?",
  typeAndNumber: "Type and number of batteries (e.g. US2000 X 4)",
  typesOfPv: "Type of PV panels ( e.g. 450W jinko 72 cell)",
  PvConfig: "PV configuration (e.g. Residential/pumps,geysers)",
  maxLoad: "Maximum load in watts (e.g. 3000W)",
  dcAndAc: "Is DC and Ac lightning protection istalled?",
  photoInld: "Is photo of installed equipment and  COC (certificate  of compliance) uploaded (including AC and DCsurge protection) ?",
  selectShipment: "Select Shipment",
  imageField: "Upload Helpful Image not more than 2MB in size",
  fillField: "Required",
  pvEquReturn: "PV Equipment Return",
  solarTermalEquReturn: "Solar Thermal Equipment Return",
  imageSizeValidate: "Image Size should be less than 2MB",
  pleaseSelectImage: "Please Select Image",
  shipmentquantity: "Shipment Quantity",
  returnQuantity: "Returned Quantity",
  returnnQuantity: "Return Quantity",
  UnitPrice: "Unit Price",
  confirmationTitle: "Agreements",
  selectPartanddesc: "Select Part No / Description",
  fromDate: "From Date",
  toDate: "To Date",
  ShipmentId: "Shipment",
  OrderCode: "Order Ref",
  PartNumber: "Product",
  go: "Go",
  returnsQuestions: "Return Reasons For",
  save: "Save",
  Manufacturer: "Manufacturer",
  returnCreatedSuccessfully: "Return Created Successfully",
  notFoundPart: "No Part No / Descriptopn Found",
  aggreeTandC: "Please Agree Customers Returns Policy And Terms And Conditions To proceed",
  fillAnswers: `Please provide Supporting Information for `,
  reasonSelect: "Please select reason",
  pleaseSelectShipment: "Please select shipment",
  pleaseSelectPartNo: "Please select at least one line item with a proper quantity",
  SerialNumber: "Serial No",
  etrShipId: "Enter Shipment ID",
  etrManufacturerName: "Enter Manufacturer Name",
  etrOrdRefNo: "Enter Order Reference Number",
  etrProdNo: "Enter Product Number",
  etrSerialNo: "Enter Serial Number",
  plsSelectDate: "Select dates to continue",
  noShip: "No shipments found yet",
  selectFromDate: "Select From Date",
  selectToDate: "Select To Date",
  selectRtnQunL: "Please select return quantity for",
  closeOrder: "Close Order",
  orderClosed: "Order successfully closed",
  alreadyReturned: "Item(s) Returned",
  canReturn: "Open to Return",
  itemAlreadyReturned: "Item Already Returned",
  SupportingInformation: "Supporting Information"
};
//


export const data = 123;
//export default enGB;
export { enGB };


