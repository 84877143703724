import React, { Fragment, useState } from "react";
import {
  CLOSE_WHITE_ICON,
  DELETE_RED_ICON,
  // eslint-disable-next-line
  DELET_BG_RED_ICON,
  EDIT_BLUE_ICON,
  TRUCK_BLACK_ICON,
  TRUCK_GREEN_ICON,
} from "../../assets";
import { toLower, toPrice } from "../../utils";
import { T } from "../translation";
import { Link } from "react-router-dom";
import ButtonModual from "../modual/ButtonModual";
import { shipStatus } from "../../utils/functions";

/**
 *
 * @param {number} index - use for display count and specific key in every time display that component
 * @param {object} item - use for display item details
 * @param {boolean} NoTdescription - use for specify that display description or not
 * @param {object} data - use for display other details then item
 * @param {boolean} isEdit - use for display display edit and delete icon or not
 * @param {string} Ddesctiption - use for display item description
 * @param {boolean} isTruck - use whether display truck image or not
 * @param {function} OpenCreate - use for display delete pop up when click on delete image
 * @param {function} OpenEdit - use for display edit pop up when click on edit image
 * @param {object} shipmentData - use for display shipment details
 * @param {boolean} isDeleteing - use for display loader while deleting item
 * @returns this component used to display list of item.
 */
const ItemListComponent = ({
  index,
  item = {},
  NoTdescription = false,
  data = {},
  isEdit = false,
  Ddesctiption = "",
  isTruck,
  OpenCreate = () => { },
  OpenEdit = () => { },
  shipmentData = {},
  isDeleteing = false,
}) => {
  const shippingDataObj = {
    shippingId: data?.id,
    shippingStatus: data?.shippingStatus,
    // shippingDate : data?.shippingDate,
  };
  const [showInfo, setShowInfo] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const shipmentStatus = toLower(item?.shipmentStatus);

  return (
    <Fragment key={index}>
      {item?.productCode !== "DELIVERY" && (
        <>
          {/* ==================================== */}
          <div className="quotationdetails-item-pd">
            <div className="quotationdetails-item-number">{index + 1}</div>
            {/* ========================= */}
            <div className="quotationdetails-item__flex-col">
              <div className="edit-shipment-other-item-mb">
                <div className="quote_dis_fx">
                  {isTruck &&
                    item?.shipmentId !== null &&
                    item?.shipmentStatus !== null && (
                      <img
                        src={
                          shipStatus(shipmentStatus)
                            ? TRUCK_GREEN_ICON
                            : TRUCK_BLACK_ICON
                        }
                        alt="Car"
                        className="cursor-pointer con-creat-shipm-mr"
                        onClick={() => {
                          setShowInfo(true);
                          setOrderDetails(item);
                        }}
                      />
                    )}
                  {item?.productCode ? (
                    <Link
                      className={`quotationdetails-item-productCode ${!isEdit && `text-decoreation-none`}`}
                      to={isEdit && `/product/${item?.productCode}`}
                    >
                      {item?.productCode}
                    </Link>
                  ) : (
                    "--"
                  )}
                  {/* {!NoTdescription &&
                        <>
                            {" - "} {item?.orderDescription || " --"}
                        </>
                    } 
                  */}
                </div>
              </div>
              <div className="quotationdetails-item-description">
                <span>{item?.productDescription || "--"}</span>
              </div>
            </div>
            <div className="quotationdetails-item__price">
              {toPrice(item?.lineTotalAfterDiscount)}
            </div>
          </div>
          <div className="quotationdetails-item__flex-row">
            <div className="quotationdetails-item-quantity">
              <T strProp="Quantity" /> :{" "}
              {item?.quantity >= 0 ? item?.quantity : "0"}
            </div>
            {isEdit && (
              <div className="quotationdetails-item-icon">
                <img
                  src={EDIT_BLUE_ICON}
                  alt="icon"
                  className="margin-right-8"
                  onClick={() => OpenEdit(item, index)}
                />
                {/* <img src={DELET_BG_RED_ICON} alt="icon" onClick={() => OpenCreate(item)} /> */}
                <div className="delBtnBG cursor-pointer" onClick={() => OpenCreate(item)}>
                  {isDeleteing === true ? (
                    <div className="red-btn-loader" />
                  ) : (
                    <img src={DELETE_RED_ICON} alt="Del" />
                  )}
                </div>
              </div>
            )}
          </div>
          {/* ==================================== */}
          {data?.items?.length > index + 1 ? (
            <div className="border__line_mr" />
          ) : shipmentData?.items?.length === index ? (
            <div className="last__line_mr" />
          ) : null}
          {/* {data?.items[index + 1]?.productCode !== "DELIVERY" && (
            <div className="border__line_mr" />
          )} */}
          {data?.items[index + 1]?.productCode === "DELIVERY" && (
            <div className="border__line_mr_tb" />
          )}
        </>
      )}
      {item?.productCode === "DELIVERY" && (
        <>
          <div className="quotationdetails-item-delivery-p">
            <div className="quotationdetails-item-delivery-flex">
              <span className="quotationdetails-item-productCode">
                <T strProp="Delivery" />
              </span>
              <div className="quotationdetails-item__price">
                {toPrice(item?.lineTotalAfterDiscount)}
              </div>
            </div>
            <div>
              <span className="quotationdetails-item-description">
                {Ddesctiption || "--"}
              </span>
            </div>
          </div>
        </>
      )}

      {/* ==============Details Model :  When click on truck============== */}
      <ButtonModual Open={showInfo} onClose={() => setShowInfo(false)}>
        <div className="popup_event">
          <div className="popup_event-flex">
            <img
              src={CLOSE_WHITE_ICON}
              alt="icon"
              onClick={() => setShowInfo(false)}
              className="cursor-pointer"
            />
          </div>

          <div className="popup_event__text">
            <div>
              <span>
                <span className="popup_event__title">
                  <T strProp="Shipment" />
                </span>{" "}
                : <Link className="color-black" to={`/shipment_details/${shippingDataObj?.shippingId}`}>
                  <span>{shippingDataObj?.shippingId}</span>
                </Link>
              </span>
            </div>
            <div>
              <span className="popup_event__title">
                <T strProp="contactDescription" />
              </span>{" "}
              : <span>{orderDetails?.productDescription}</span>
            </div>
            <div>
              <span className="popup_event__title">
                <T strProp="Status" />
              </span>{" "}
              : <span>{shippingDataObj?.shippingStatus}</span>
            </div>
            {/* <div>
              <span className="popup_event__title">Shipping Date</span> :{" "}
              <span>{orderDetails?.shipmentStatus}</span>
            </div> */}
          </div>
        </div>
      </ButtonModual>
      {/* ============== End Model ============== */}
    </Fragment>
  );
};

export default ItemListComponent;
