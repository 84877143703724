import React, { useContext } from "react";
import { Il8Context } from "../../contexts";
import TextInput from "../modual/TextInput";
import { T } from "../translation";

/**
 *
 * @param {string} email - email value
 * @param {function} setEmail - set email value in state
 * @param {refrence} emailRef - handal to email validation warning
 * @returns - email input field and validation
 */
const EmailInp = ({ email, setEmail, emailRef }) => {
  const { t } = useContext(Il8Context);
  const emailVelidationChk = (value) => {
    if (value?.trim()?.length <= 0) {
      emailRef.current?.handleInputBlur(true, <T strProp="PleaseEnterEmail" />, true);
    } else {
      emailRef.current?.handleInputBlur("", "");
    }
  };
  return (
    <>
      <div className="margin_bottom">
        <TextInput
          input_Title={t("contactEmail")}
          Placeholder={t("EnterEmail")}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            emailVelidationChk(e.target.value);
          }}
          ref={emailRef}
          keyBoard_Type="email"
          Required
        />
      </div>
    </>
  );
};

export default EmailInp;

/**
 * @param {string} value - user input value of email
 * @param {refrence} ref - handal to validation
 * @returns - handal to email validation (check = null , email formate) ,
 *  bollean (validate = true , not validate = false)
 */
export const emailVelidation = (value, ref) => {
  if (value?.trim()?.length <= 0) {
    ref.current?.handleInputBlur(true, <T strProp="PleaseEnterEmail" />, true);
    return false;
  } else {
    var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!mailformat.test(value)) {
      ref.current?.handleInputBlur(
        true,
        <T strProp="PleaseEnterValidEmail" />,
        true
      );
      return false;
    } else {
      ref.current?.handleInputBlur("", "");
      return true;
    }
  }
};
