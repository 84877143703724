import React, { Fragment, useEffect, useRef, useState } from "react";
import { NO_FOUND_IMG, NO_FOUND_SM_IMG } from "../../../assets";
import { AlertPopup, BackMenuCard, Layout, PageLoader, T, UpDownScroll } from "../../../components";
import "../../../styles/components/documentation/documentation.css";
import { refactoring } from "../../../utils/functions";
import { documentationApi } from "../../../utils";
import { useSessionHook } from "../../../hooks";
import { useParams } from "react-router-dom";

import useDocumentLoader from "../../../hooks/useDocumentLoader";

/**
 * @returns - IndustryGuides jsx elements / page
 */
function DocumentationDetails({ path }) {
  const { mainRoute, subRoute } = useParams();
  const sessionHelpers = useSessionHook();
  const { token } = sessionHelpers.getStatusAndToken();
  const [loading, setLoading] = useState(true);
  const [documentData, setDocumentData] = useState({});
  const alertRef = useRef(null);
  useDocumentLoader(alertRef);
  useEffect(() => {
    async function fetchData() {
      if (token === null) return;
      if (!loading) setLoading(true);
      setDocumentData(
        await documentationApi(token, alertRef, path ? path : subRoute).finally(() => setLoading(false))
      );
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  let arr = [];
  let arr2 = [];

  if (mainRoute) {
    if (mainRoute?.split('-').length > 1) {
      mainRoute?.split('-').forEach((item) => {
        arr2.push(item.charAt(0).toUpperCase() + item.slice(1, item.length));
      })
    } else {
      mainRoute.split(/(?=[A-Z])/).forEach(str => arr2.push(str))
    }
  }

  if (path) {
    if (path?.split('-').length > 1) {
      path?.split('-').forEach((item) => {
        arr.push(item.charAt(0).toUpperCase() + item.slice(1, item.length));
      });
    } else {
      path.split(/(?=[A-Z])/).forEach(str => arr.push(str))
    }
  } else {
    if (subRoute?.split('-').length > 1) {
      subRoute?.split('-').forEach((item) => {
        arr.push(item.charAt(0).toUpperCase() + item.slice(1, item.length));
      });
    } else {
      subRoute.split(/(?=[A-Z])/).forEach(str => arr.push(str))
    }
  }
  let pageString = arr.join(" ");
  let pageString2 = arr2.join(" ");


  let finalData = refactoring(documentData);
  let a = 0;
  return (
    <>
      <Layout title={pageString || ""} id={path || mainRoute} translate={false}>
        {loading ? (
          <PageLoader isSmall isLoading={loading} />
        ) : (
          <>
            <div className="complete-page" />
            <section className="section-m-b">
              {path && <BackMenuCard
                fromDocs={true}
                BackMenu1="acMenuDocumentation"
                Path1="/documentation"
                BackMenu2={path ? pageString : subRoute}
                Path2={!path && `/documentation/${mainRoute}`}
                BackMenu3={!path && pageString}
              />}
              {mainRoute && <BackMenuCard
                fromDocs={true}
                BackMenu1="acMenuDocumentation"
                Path1="/documentation"
                BackMenu2={pageString2}
                Path2={`/documentation/${mainRoute}`}
                BackMenu3={pageString}
              />}
              {/* ========================================================== */}
              {finalData?.content?.length > 0 ? (
                <>
                  <section className="menu-heading-title">
                    <span>{finalData?.title || "--"}</span>
                  </section>
                  {/* ========================================================== */}
                  {finalData.content.map((items, i) => {
                    const { columns, content } = items;
                    let boolValueArray = [];
                    let finalizedArray = [];
                    columns === 3 && content.forEach((items) => {
                      items.forEach((items) => {
                        const { contentType } = items
                        boolValueArray.push(contentType === "mediaDownloadBlock" || contentType === "mediaLinkBlock" ? true : false)
                      })
                    })
                    const boolValueSet = new Set(boolValueArray)
                    boolValueArray.splice(0);
                    for (let value of boolValueSet) {
                      boolValueArray.push(value)
                    }
                    boolValueArray.forEach((items) => {
                      (boolValueArray.length === 1 && items === true) && finalizedArray.push(items)
                    })
                    return (
                      finalizedArray[0] === true ? (
                        <div className="doc_detail_main" key={i}>
                          <p className="microgeneration-title">{items.title}</p>
                          {items.content.map((items, ii) => {
                            return (
                              <div className="background-box___" key={ii}>
                                {items.map((items, iii) => {
                                  if (!items) { return "" }
                                  const { contentType } = items;
                                  switch (contentType) {

                                    case "mediaDownloadBlock":
                                      return (
                                        <Fragment key={iii}>
                                          <a href={items?.url || items?.downloadContent?.src} target="_blank" rel="noreferrer">
                                            <img

                                              className={"documentation-img____"}
                                              src={items?.image?.src}
                                              alt="img"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = NO_FOUND_IMG;
                                              }}
                                            />
                                          </a>
                                          {/* // link not working because url prefix is missing in api response */}
                                          {items?.downloadContent && (
                                            <div>
                                              <a
                                                className={columns !== 3 ? "media_content_block_link" : null}
                                                href={items?.downloadContent?.src}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {items?.title}
                                              </a>
                                            </div>
                                          )}
                                        </Fragment>
                                      )
                                    case "mediaLinkBlock":
                                      return (
                                        <Fragment key={iii}>
                                          <a href={items?.url || items?.downloadContent?.src} target="_blank" rel="noreferrer">
                                            <img

                                              className={"documentation-img____"}
                                              src={items?.image?.src}
                                              alt="img"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = NO_FOUND_IMG;
                                              }}
                                            />
                                          </a>
                                          {/* // link not working because url prefix is missing in api response */}
                                          {items?.downloadContent && (
                                            <div>
                                              <a
                                                className={columns !== 3 ? "media_content_block_link" : null}
                                                href={items?.downloadContent?.src}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {items?.title}
                                              </a>
                                            </div>
                                          )}
                                        </Fragment>
                                      )
                                    default: return <Fragment key={iii} />;
                                  }
                                })}
                              </div>
                            )
                          })}
                        </div>
                      ) : (
                        <div className="margin_bottom___" key={i}>
                          <p className="microgeneration-title">{items.title}</p>
                          {items.content.map((items, ii) => {
                            // let columns = items.length
                            return (
                              <div className="background-box" key={ii}>
                                {items.map((items, iii) => {
                                  a++;
                                  if (!items) { return <Fragment key={iii} /> }
                                  const { contentType, body } = items;
                                  switch (contentType) {
                                    // ====================================
                                    case "textBlock":
                                      return (<span key={iii} className="industryguides-body" dangerouslySetInnerHTML={{ __html: body, }} />)

                                    // ====================================

                                    case "mediaLinkBlock":
                                      return (
                                        <Fragment key={iii}>
                                          <a
                                            className="media_download_block"
                                            href={items?.url}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={items.image?.src}
                                              alt="img"
                                              className={columns === 3 || columns === 4 ? "documentation-img__" : "documentation-img"}
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = NO_FOUND_SM_IMG;
                                              }}
                                            />
                                          </a>
                                          {items?.downloadContent && (
                                            <a href={items?.downloadContent?.src} target="_blank" rel="noreferrer">{items?.title}</a>
                                          )}
                                        </Fragment>
                                      )
                                    // ====================================
                                    case "embeddedVideo":
                                      return (
                                        <div className="iframeDiv" key={iii}>
                                          <iframe frameBorder="0" className="iframeTag" src={items.url} title={items.title} allowFullScreen></iframe>
                                        </div>
                                      )

                                    // ====================================

                                    case "textLinkBlock":
                                      return (
                                        <div key={iii}>
                                          <a
                                            href={items?.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="industryguides-text"
                                            dangerouslySetInnerHTML={{
                                              __html: items?.text,
                                            }}
                                          />
                                        </div>
                                      )


                                    // ====================================

                                    case "mediaDownloadBlock":
                                      return (
                                        <div key={iii} className='margin_bottom___'>
                                          {(items?.downloadContent && items?.title) && (
                                            <div className={a % 2 ? "media_content_block_link_color_blue" : "media_content_block_link_color_orange"}>
                                              <a
                                                style={{ color: "white", padding: "2px 5px" }}
                                                className={"media_content_block_link"}
                                                href={items?.downloadContent?.src}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {items?.title}
                                              </a>
                                            </div>
                                          )}
                                          <a href={items?.url || items?.downloadContent?.src} className="media_download_block" target="_blank" rel="noreferrer" >
                                            <img
                                              className={(columns === 3 || columns === 4) ? "documentation-img__" : "documentation-img"}
                                              src={items?.image?.src}
                                              alt="img"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = NO_FOUND_IMG;
                                              }}
                                            />
                                          </a>
                                          {/* // link not working because url prefix is missing in api response */}

                                        </div>
                                      )
                                    // ====================================
                                    case "imageBlock":
                                      return (
                                        <a href={items?.downloadContent?.src} target="_blank" rel="noreferrer" key={iii}>
                                          <img className={items?.downloadContent?.src ? "documentation-img cursor-pointer" : "documentation-img"}
                                            src={items?.image?.src}
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src = NO_FOUND_IMG;
                                              currentTarget.style.aspectRatio = "4/1";
                                            }}
                                            alt="img"
                                          />
                                        </a>
                                      )
                                    // ====================================
                                    default: return (<Fragment key={iii} />);
                                  }
                                })}
                              </div>
                            )
                          })}
                        </div>
                      )
                    )
                  })}
                </>
              ) : (
                <p className="no-data-found-sm">
                  <T strProp="noDocumentationFound" />
                </p>
              )}
            </section>
          </>
        )}
        <UpDownScroll />
        <AlertPopup ref={alertRef} />
      </Layout>
    </>
  );
}

export default DocumentationDetails;