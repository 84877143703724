import { useState, useEffect, useRef } from "react";
import { callApi } from "../services";

/**
 * 
 * @param  {string} token used to user authentication 
 * @param  {int} pageNo=0 used to pass pageno in pagination for api 
 * @param  {string} debounceVal used for get search terms
 * @param  {function} setCngUrl used to set url
 * @param  {number} selectedValue base on searchvalue set search index
 * @param  {function} setProductPageNo used to set page number for pagination
 * @param  {ref} alertRef used to error alert popup
 * @param  {boolean} firstApiCall used to call api first time
 * @return - search data, nextpage call ,loading from api
 */
const useSearchProductHook = (
  token,
  pageNo = 0,
  debounceVal,
  setCngUrl,
  selectedValue,
  setProductPageNo,
  alertRef,
  firstApiCall
) => {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingProdData, setLoadingProdData] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  
  const loadProd = useRef(false);

  /**
   * @param {boolean} isNew - use for specify whether is news or product
   * @return this function can call api and set search result in product state.
   */
  const getSearchProduct = async (isNew = false) => {
    pageNo === 0 ? !loadingProdData && setLoadingProdData(true) : !loading && setLoading(true);
    loadProd.current = true;
    let trimedTrm = debounceVal?.trim();
    const searchIndex = selectedValue === 0 ? "products" : selectedValue === 1 ? "news" : selectedValue === 2 ? "projects" : "products";
    setCngUrl !== undefined && trimedTrm ? setCngUrl(`?searchtype=${searchIndex}&searchquery=${trimedTrm}`) : setCngUrl(`?searchtype=${searchIndex}`);

    const { res, sCode } = await callApi("searchProducts", { token, alertRef }, trimedTrm, pageNo + 1);
    if (res.error) {
      console.log("error", res.error);
      setLoading(false);
      setLoadingProdData(false);
      loadProd.current = false;
    } else if (sCode === 401) { setHasNextPage(false); loadProd.current = false; } else {
      if (sCode === 200 || sCode === 202) {
        let pCount = res?.body?.result?.pageCount;
        let pNum = res?.body?.result?.pageNumber;
        setHasNextPage(pCount > pNum);
        let arr = Object.values(res?.body?.result?.items);
        isNew ? setProduct(arr) : setProduct((p) => [...p, ...arr]);
        loadProd.current = false;
      }
      loadProd.current = false;
      setLoading(false);
      setLoadingProdData(false);
    }
  };

  useEffect(() => {
    if (firstApiCall) return;
    if (token === null) return;
    getSearchProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, pageNo]);

  useEffect(() => {
    if (firstApiCall) return;
    if (token === null) return;
    setProduct([]);
    setProductPageNo(0);
    getSearchProduct(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceVal]);

  return { product, loading, hasNextPage, getSearchProduct, loadingProdData, loadProd };
};

export { useSearchProductHook };
