import React, { forwardRef } from "react";
import { ProductListGridCard } from "../../components";

/**
* 
* @param {object} item - use for pass etails of particular product
* @param {number} index - use for identify or add uniqueness in every product
* @param {number} isGrid - use for display product in grid view
* @param {string} categoryName - use for display category name
* @param {string} token - for user authentication
* @param {ref} alertRef - used to hendal error alert popup
* @param {referance} ref - use for referance
* @returns product list content display product list card.
*/
const ProductListContent = forwardRef(
  ({ item, index, isGrid, categoryName, token, alertRef }, ref) => {
    const plannedShipmentBody = (
      <ProductListGridCard
        key={index}
        isGrid={isGrid === 0}
        Img={item?.productImageUrl}
        data={item}
        ProductTitle={item?.productCode}
        ProductPrice={item?.unitPrice}
        Productwaith={item?.unitPricePerWatt}
        currency={item?.grossPrice?.currency}
        ProductStock={item?.stockLevels[0]?.quantity}
        stockLevel={item?.stockLevels}
        categoryName={categoryName}
        token={token}
        alertRef={alertRef}
      />
    );
    const content = ref ? (
      <span ref={ref}>{plannedShipmentBody}</span>
    ) : (
      <span>{plannedShipmentBody}</span>
    );
    return content;
  }
);

export default ProductListContent;
