import React, { useEffect, useRef } from "react";
import {
  AlertPopup,
  BackCard,
  Layout,
  NewsDetailCard,
  PageLoader,
} from "../components";
import { languageCode } from "../contexts/i18context";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useSessionHook } from "../hooks";
import { callApi } from "../services";

/**
 * @returns news screen jsx elements
 */
const NewsDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [newsDetailsData, setNewsDetailsData] = useState({});
  const sessionHelpers = useSessionHook();
  const { token } = sessionHelpers.getStatusAndToken();
  const alertRef = useRef(null);

  useEffect(() => {
    if (token === null) return;
    getNewsDetails(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  /**
   * 
   * @param {number} newsid - use for get specific news details
   * @returns this function can get particular news details from api and set that details in newsDetailaData state.
   */
  const getNewsDetails = async (newsid) => {
    !loading && setLoading(true);
    await callApi(
      "newsDetails",
      { token, alertRef, msg404: "newsNotFound" },
      newsid,
      languageCode
    )
      .then(({ res, sCode }) => {
        if (sCode === 200 || sCode === 202) setNewsDetailsData(res?.body);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  return (
    <>
      {loading ? (
        <PageLoader isSmall isLoading />
      ) : (
        <>
          <div className="complete-page" />
          <Layout title="titlenewsdetails" id="news-details">
            <BackCard Path={-1} />
            <div className="news-details-box">
              <div className="news-detail-card1">
                {newsDetailsData?.title || "--"}
              </div>
              {newsDetailsData?.body?.sections?.map((item) => {
                return item?.rows?.map((itemRow, indexRow) => {
                  return <NewsDetailCard Row={itemRow} key={indexRow} />;
                });
              })}
            </div>
          </Layout>
        </>
      )}
      <AlertPopup ref={alertRef} />
    </>
  );
};

export default NewsDetails;
