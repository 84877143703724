import React from "react";
import { T } from "..";

/**
 * 
 * @param {string} Overdue - use for display any ramain due is available or not
 * @param {string} DueToday - use for display any today due remain or not
 * @returns this function can return or display Outstanding Amounts on Accounts.
 */
export default function CurrentInvoiceCard({ Overdue, DueToday }) {
  return (
    <section className="current-invoice-box">
      <div className="current-invoice-box-title">
        <span>
          <T strProp="key" />
        </span>
      </div>
      <div className="current-invoice-border">
        <span className="current-invoice-title">
          <T strProp="overdue" />
        </span>
        <span className="current-invoice-body">{Overdue}</span>
      </div>
      <div className="current-invoice-border">
        <span className="current-invoice-title">
          <T strProp="overdue" />
        </span>
        <span className="current-invoice-body">{DueToday}</span>
      </div>
      <div className="currentinvoice__none">
        <span className="current-invoice-title">
          <T strProp="nearlyDue" />
        </span>
        <span className="current-invoice-body"> - </span>
      </div>
    </section>
  );
}
