import React, { useReducer } from "react";
import { MsalProvider } from "@azure/msal-react";
import { globalReducer, initialGlobalState } from "./hooks";
import { BrowserRouter } from "react-router-dom";
import { GlobalContext } from "./contexts";
import { msalInstance } from "./";
import App from "./App";


const RootApp = () => {
  const [state, dispatch] = useReducer(globalReducer, initialGlobalState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MsalProvider>
    </GlobalContext.Provider>
  );
};

export default RootApp;
