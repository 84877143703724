import React, { useEffect, useRef, useState } from "react";
import "../../../styles/components/allMenu/planned-shipments/plannedshipments.css";
import { Link } from "react-router-dom";
import { ARROW_SEE_ALL } from "../../../assets";
import { useSessionHook } from "../../../hooks";
import { callApi } from "../../../services";
import OrederShipments from "../../modual/segmentedcontrol/OrderShipments";
import SegmentedControl from "../../modual/segmentedcontrol/SegmentedControl";
import { PageLoader } from "../../pageLoader";
import { T } from "../../translation";
import { dateFormate } from "../../../utils";

/**
 * 
 * @param {function} signal - use for cancle api call
 * @returns this component display upcoming Order data list.
 */
function UpcomingOrderCard({ signal }) {
  const sessionHelpers = useSessionHook();
  const { token } = sessionHelpers.getStatusAndToken();
  const [upcomingOrders, setUpcomingOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingA, setLoadingA] = useState(true);
  const [selectedValue, setSelectedValue] = useState("Orders");
  const [shipment, setShipment] = useState([]);

  useEffect(() => {
    if (token === null) return;
    const getUpcomingOrders = async () => {
      await callApi("upcomingOrders", { token, signal: signal })
        .then(({ res, sCode }) => {
          (sCode === 200 || sCode === 202) &&
            setUpcomingOrders(res?.body?.result?.items || []);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    };
    const getShipments = async () => {
      await callApi("shipmentsDueForDispatch", { token, signal: signal })
        .then(({ res, sCode }) => {
          (sCode === 200 || sCode === 202) &&
            setShipment(res?.body?.result?.items);
        })
        .catch((e) => console.log("error", e))
        .finally(() => setLoadingA(false));
    };

    getUpcomingOrders();
    getShipments();
    // eslint-disable-next-line
  }, [token]);

  function Segment() {
    return (
      <div className="container1">
        <SegmentedControl
          name="group-1"
          callback={(val) => {
            setSelectedValue(val);
          }}
          controlRef={useRef()}
          segments={[
            {
              label: <T strProp="orders" />,
              value: "Orders",
              ref: useRef(),
            },
            {
              label: <T strProp="titleShipments" />,
              value: "Shipments",
              ref: useRef(),
            },
          ]}
        />
      </div>
    );
  }
  return (
    <section className="upcoming-order-card">
      <div className="upcoming-order-card-data">
        <div className="upcoming-order-card-title">
          <span className="_title">
            <T strProp="homeUpcoming" />
          </span>
          {Segment()}
        </div>
        <div className="upcoming-order-card-sub-title">
          <span>
            <T
              strProp={
                selectedValue === "Orders" ? "homeMostRCO" : "homeMostRCS"
              }
            />
          </span>
        </div>
        {selectedValue === "Orders" ? (
          loading ? (
            <section className="loader_relative">
              <PageLoader isSmall isLoading />
            </section>
          ) : upcomingOrders?.length > 0 ? (
            upcomingOrders?.map((item, index) => {
              return (
                <OrederShipments
                  key={index}
                  OrderDispatch={
                    (item?.dispatchDate &&
                      dateFormate(item?.dispatchDate)) ||
                    ""
                  }
                  OrderType={item?.orderStatus}
                  OrderNo={item?.reference}
                  OrderDescription={item?.description}
                  path={`/order/${item?.reference}`}
                />
              );
            })
          ) : (
            <p className="no-data-found-sm">
              <T strProp="homeNoOrders" />
            </p>
          )
        ) : loadingA ? (
          <section className="loader_relative">
            <PageLoader isSmall isLoading />
          </section>
        ) : shipment?.length > 0 ? (
          shipment?.map((item, i) => {
            return (
              <OrederShipments
                key={i}
                OrderDescription={item?.deliveryMethodDescription || ""} //"Need to Contact"
                OrderDispatch={
                  (item?.dispatchDate &&
                    dateFormate(item?.dispatchDate)) ||
                  ""
                }
                OrderType={item?.shippingStatus}
                OrderNo={item?.id}
                // path={`/shipments/${item?.id}`}
                path={`/shipment_details/${item?.id}`}
              />
            );
          })
        ) : (
          <p className="no-data-found-sm">
            <T strProp="homeNoShipments" />
          </p>
        )}
      </div>
      {selectedValue === "Shipments" && shipment?.length > 0 && (
        <div className="upcoming-order-card-icon">
          <Link
            to={"/plannedshipments"}
            className="upcoming-order-card-See-all link"
          >
            <span>
              <T strProp="seeAll" />
            </span>
            <img src={ARROW_SEE_ALL} alt="See All" />
          </Link>
        </div>
      )}
      {selectedValue === "Orders" && upcomingOrders?.length > 0 && (
        <div className="upcoming-order-card-icon">
          <Link to={"/order"} className="upcoming-order-card-See-all link">
            <span>
              <T strProp="seeAll" />
            </span>
            <img src={ARROW_SEE_ALL} alt="See All" />
          </Link>
        </div>
      )}
    </section>
  );
}

export default UpcomingOrderCard;
