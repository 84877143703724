import { useContext, useReducer, useRef } from "react";
import { Il8Context } from "../contexts";
import { useSessionHook } from "./useSessionHook";


const useNewReturnHook = () => {
    const { t } = useContext(Il8Context);
    const sessionHelpers = useSessionHook();
    const { token } = sessionHelpers.getStatusAndToken();
    const returnTypeArray = [
        { label: "Select Type Of Return", value: '' },
        { label: t("pvEquReturn"), value: 'PV Equipment Return' },
        // { label: t('solarTermalEquReturn'), value: 'Solar Thermal Equipment Return' }
    ]

    const radioValueObject = {
        id: "ShipmentId",
        label: 'etrShipId'
    }

    const newReturnIntialState = {

        //Show Shipment from popup states
        selectShipmentPopup: false,
        shipmentData: [],
        selectedFromDate: null,
        selectedToDate: null,
        selectedRadioValue: radioValueObject,
        selectPartNoSearchedValue: '',
        shipmentDataLoading: false,
        selectedShipment: [],
        //Part No and Discription Popup
        selectedPartNo: [],
        tempShipments: [],
        //Questions and answers
        questionsAndAnswers: {},
        tempQuestionsAndAnswers: {},
        returnTypeArray,
        reasonsData: [],
        reasonsLoading: false,
        selectedReason: '',
        selectedReasonObject: {},
        checkbox: false,
        delay: false,
        expand: false,
        shipDetailsExpand: false,
        shipItemsExpand: false,
        type: "",
        returnDetailDescription: "",
        confirmationDetailData: null,
        confirmationDataLoading: true,
        productSelectionLoading: false,
        selectedProduct: '',
        selectedProductObject: {},
        productListData: [],
        questionsData: [],
        questionIsLoading: false,
        slectedImage: {},
        isAllQuestionFilled: null,
        counter: {},
        confirmationExpand: true,
        selectPartNoPopup: false,
        showQuestionPopup: false,
        lineItems: []
    }

    const newReturnActions = {

        //Shipment PopUp Actions
        SET_SHIPMENT_DATA: "SET_SHIPMENT_DATA",
        SET_SELECTED_RADIO_VALUE: "SET_SELECTED_RADIO_VALUE",
        SET_SELECTED_PART_SEARCHED_VALUE: "SET_SELECTED_PART_SEARCHED_VALUE",
        SET_SELECTED_FROM_DATE: "SET_SELECTED_FROM_DATE",
        SET_SELECTED_TO_DATE: "SET_SELECTED_TO_DATE",
        SET_SELECTSHIPMENTPOPUP: "SET_SELECTSHIPMENTPOPUP",
        SET_SHIPMENT_DATA_LOADING: "SET_SHIPMENT_DATA_LOADING",
        SET_TEMP_QUESTIONS_AND_ANSWERS: "SET_TEMP_QUESTIONS_AND_ANSWERS",
        SET_LINE_ITEMS: "SET_LINE_ITEMS",

        SET_REASONSDATA: "SET_REASONSDATA",
        SET_REASONSLOADING: "SET_REASONSLOADING",
        SET_SELECTEDREASON: "SET_SELECTEDREASON",
        SET_SELECTEDRESONOBJECT: "SET_SELECTEDRESONOBJECT",
        SET_CHECKBOX: "SET_CHECKBOX",
        SET_DELAY: "SET_DELAY",
        SET_EXPAND: "SET_EXPAND",
        SET_SHIPDETAILSEXPAND: "SET_SHIPDETAILSEXPAND",
        SET_SHIPITEMSEXPAND: "SET_SHIPITEMSEXPAND",
        SET_TYPES: "SET_TYPES",
        SET_RETURNDETAILDESCRIPTION: "SET_RETURNDETAILDESCRIPTION",
        SETCONFIRMATIONDETAILDATA: "SETCONFIRMATIONDETAILDATA",
        SET_CONFIRMATIONDATALOADING: "SET_CONFIRMATIONDATALOADING",
        SET_PRODUCTSELECTIONLOADING: "SET_PRODUCTSELECTIONLOADING",
        SET_SELECTEDPRODUCT: "SET_SELECTEDPRODUCT",
        SET_SELECTEDPRODUCTOBJECT: "SET_SELECTEDPRODUCTOBJECT",
        SET_PRODUCTLISTDATA: "SET_PRODUCTLISTDATA",
        SET_QUESTIONSDATA: "SET_QUESTIONSDATA",
        SET_QUESTIONISLOADING: "SET_QUESTIONISLOADING",
        SET_QUESTIONSANDANSWERS: "SET_QUESTIONSANDANSWERS",
        SET_SELECTEDIMAGE: "SET_SELECTEDIMAGE",
        SET_ISALLQUESTIONFILLED: "SET_ISALLQUESTIONFILLED",
        SET_COUNTER: "SET_COUNTER",
        SET_CONFIRMATION_EXPAND: "SET_CONFIRMATION_EXPAND",
        SET_SELECTPARTNOPOPUP: "SET_SELECTPARTNOPOPUP",
        SET_SHOW_QUESTION_POPUP: "SET_SHOW_QUESTION_POPUP",
        SET_SELECTED_PART_VALUE: "SET_SELECTED_PART_VALUE",
        SET_SELECTED_SHIPMENT: "SET_SELECTED_SHIPMENT",
        SET_TEMP_SHIPMENTS: "SET_TEMP_SHIPMENTS"
    }


    //Shipment popup functions

    const setShipmentData = (shipmentData) => ({ "type": newReturnActions.SET_SHIPMENT_DATA, "data": shipmentData })
    const setShipmentDataLoading = (shipmentDataLoading) => ({ "type": newReturnActions.SET_SHIPMENT_DATA_LOADING, "data": shipmentDataLoading })
    const setTempQuestionsAndAnswers = (tempQuestionsAndAnswers) => ({ "type": newReturnActions.SET_TEMP_QUESTIONS_AND_ANSWERS, "data": tempQuestionsAndAnswers })



    const setReasonsData = (reasonsData) => ({ "type": newReturnActions.SET_REASONSDATA, "data": reasonsData })
    const setReasonsLoading = (reasonsLoading) => ({ "type": newReturnActions.SET_REASONSLOADING, "data": reasonsLoading })
    const setSelectedReason = (selectedReason) => ({ "type": newReturnActions.SET_SELECTEDREASON, "data": selectedReason })
    const setSelectedReasonObject = (selectedReasonObject) => ({ "type": newReturnActions.SET_SELECTEDRESONOBJECT, "data": selectedReasonObject })
    const setCheckBox = (checkbox) => ({ "type": newReturnActions.SET_CHECKBOX, "data": checkbox })
    const setDelay = (delay) => ({ "type": newReturnActions.SET_DELAY, "data": delay })
    const setExpand = (expand) => ({ "type": newReturnActions.SET_EXPAND, "data": expand })
    const setshipDetailsExpand = (shipDetailsExpand) => ({ "type": newReturnActions.SET_SHIPDETAILSEXPAND, "data": shipDetailsExpand })
    const setshipItemsExpand = (shipItemsExpand) => ({ "type": newReturnActions.SET_SHIPITEMSEXPAND, "data": shipItemsExpand })
    const setType = (type) => ({ "type": newReturnActions.SET_TYPES, "data": type })
    const setReturnDetailDescription = (returnDetailDescription) => ({ "type": newReturnActions.SET_RETURNDETAILDESCRIPTION, "data": returnDetailDescription })
    const setConfirmationDetailData = (confirmationDetailData) => ({ "type": newReturnActions.SETCONFIRMATIONDETAILDATA, "data": confirmationDetailData })
    const setConfirmationDataLoading = (confirmationDataLoading) => ({ "type": newReturnActions.SET_CONFIRMATIONDATALOADING, "data": confirmationDataLoading })
    const setProductSelectionLoading = (productSelectionLoading) => ({ "type": newReturnActions.SET_PRODUCTSELECTIONLOADING, "data": productSelectionLoading })
    const setSelectedProduct = (selectedProduct) => ({ "type": newReturnActions.SET_SELECTEDPRODUCT, "data": selectedProduct })
    const setSelectedProductObject = (selectedProductObject) => ({ "type": newReturnActions.SET_SELECTEDPRODUCTOBJECT, "data": selectedProductObject })
    const setProductListData = (productListData) => ({ "type": newReturnActions.SET_PRODUCTLISTDATA, "data": productListData })
    const setQuestionsData = (questionsData) => ({ "type": newReturnActions.SET_QUESTIONSDATA, "data": questionsData })
    const setQuestionIsLoading = (questionIsLoading) => ({ "type": newReturnActions.SET_QUESTIONISLOADING, "data": questionIsLoading })
    const setQuestionAndAnswers = (questionsAndAnswers) => ({ "type": newReturnActions.SET_QUESTIONSANDANSWERS, "data": questionsAndAnswers })
    const setSelectedImage = (slectedImage) => ({ "type": newReturnActions.SET_SELECTEDIMAGE, "data": slectedImage })
    const setisAllQuestionFilled = (isAllQuestionFilled) => ({ "type": newReturnActions.SET_ISALLQUESTIONFILLED, "data": isAllQuestionFilled })
    const setCounter = (counter) => ({ "type": newReturnActions.SET_COUNTER, "data": counter })
    const setConfirmationExpand = (confirmationExpand) => ({ "type": newReturnActions.SET_CONFIRMATION_EXPAND, "data": confirmationExpand })
    const setSelectShipmentPopup = (selectShipmentPopup) => ({ "type": newReturnActions.SET_SELECTSHIPMENTPOPUP, "data": selectShipmentPopup })
    const setSelectPartNoPopup = (selectShipmentPopup) => ({ "type": newReturnActions.SET_SELECTPARTNOPOPUP, "data": selectShipmentPopup })
    const setSelectedFromDate = (selectedFromDate) => ({ "type": newReturnActions.SET_SELECTED_FROM_DATE, "data": selectedFromDate })
    const setSelectedToDate = (selectedToDate) => ({ "type": newReturnActions.SET_SELECTED_TO_DATE, "data": selectedToDate })
    const setSelectedRadioValue = (selectedRadioValue) => ({ "type": newReturnActions.SET_SELECTED_RADIO_VALUE, "data": selectedRadioValue })
    const setSelectPartNoSearchedValue = (selectPartNoSearchedValue) => ({ "type": newReturnActions.SET_SELECTED_PART_SEARCHED_VALUE, "data": selectPartNoSearchedValue })
    const setShowQuestionPopup = (showQuestionPopup) => ({ "type": newReturnActions.SET_SHOW_QUESTION_POPUP, "data": showQuestionPopup })
    const setSelectedPartNo = (selectedPartNo) => ({ "type": newReturnActions.SET_SELECTED_PART_VALUE, "data": selectedPartNo })
    const setSelectedShipment = (selectedShipment) => ({ "type": newReturnActions.SET_SELECTED_SHIPMENT, "data": selectedShipment })
    const setTempShipments = (tempShipments) => ({ "type": newReturnActions.SET_TEMP_SHIPMENTS, "data": tempShipments })
    const setLineItems = (lineItems) => ({ "type": newReturnActions.SET_LINE_ITEMS, "data": lineItems })




    const newReturnReducer = (state = newReturnIntialState, action) => {
        switch (action.type) {

            //Shipment popup cases
            case newReturnActions.SET_SHIPMENT_DATA:
                return { ...state, shipmentData: action.data };
            case newReturnActions.SET_SHIPMENT_DATA_LOADING:
                return { ...state, shipmentDataLoading: action.data };
            case newReturnActions.SET_LINE_ITEMS:
                return { ...state, lineItems: action.data };



            case newReturnActions.SET_REASONSDATA:
                return { ...state, reasonsData: action.data };
            case newReturnActions.SET_REASONSLOADING:
                return { ...state, reasonsLoading: action.data };
            case newReturnActions.SET_SELECTEDREASON:
                return { ...state, selectedReason: action.data };
            case newReturnActions.SET_SELECTEDRESONOBJECT:
                return { ...state, selectedReasonObject: action.data };
            case newReturnActions.SET_CHECKBOX:
                return { ...state, checkbox: action.data };
            case newReturnActions.SET_DELAY:
                return { ...state, delay: action.data };
            case newReturnActions.SET_EXPAND:
                return { ...state, expand: action.data };
            case newReturnActions.SET_SHIPDETAILSEXPAND:
                return { ...state, shipDetailsExpand: action.data };
            case newReturnActions.SET_SHIPITEMSEXPAND:
                return { ...state, shipItemsExpand: action.data };
            case newReturnActions.SET_TYPES:
                return { ...state, type: action.data };
            case newReturnActions.SET_RETURNDETAILDESCRIPTION:
                return { ...state, returnDetailDescription: action.data };
            case newReturnActions.SETCONFIRMATIONDETAILDATA:
                return { ...state, confirmationDetailData: action.data };
            case newReturnActions.SET_CONFIRMATIONDATALOADING:
                return { ...state, confirmationDataLoading: action.data };
            case newReturnActions.SET_PRODUCTSELECTIONLOADING:
                return { ...state, productSelectionLoading: action.data };
            case newReturnActions.SET_SELECTEDPRODUCT:
                return { ...state, selectedProduct: action.data };
            case newReturnActions.SET_SELECTEDPRODUCTOBJECT:
                return { ...state, selectedProductObject: action.data };
            case newReturnActions.SET_PRODUCTLISTDATA:
                return { ...state, productListData: action.data };
            case newReturnActions.SET_QUESTIONSDATA:
                return { ...state, questionsData: action.data };
            case newReturnActions.SET_QUESTIONISLOADING:
                return { ...state, questionIsLoading: action.data };
            case newReturnActions.SET_SELECTEDIMAGE:
                return { ...state, slectedImage: action.data };
            case newReturnActions.SET_ISALLQUESTIONFILLED:
                return ({ ...state, isAllQuestionFilled: action.data });
            case newReturnActions.SET_COUNTER:
                return ({ ...state, counter: action.data });
            case newReturnActions.SET_CONFIRMATION_EXPAND:
                return ({ ...state, confirmationExpand: action.data });
            case newReturnActions.SET_SELECTSHIPMENTPOPUP:
                return ({ ...state, selectShipmentPopup: action.data });
            case newReturnActions.SET_SELECTPARTNOPOPUP:
                return ({ ...state, selectPartNoPopup: action.data });
            case newReturnActions.SET_SELECTED_FROM_DATE:
                return ({ ...state, selectedFromDate: action.data });
            case newReturnActions.SET_SELECTED_TO_DATE:
                return ({ ...state, selectedToDate: action.data });
            case newReturnActions.SET_SELECTED_RADIO_VALUE:
                return ({ ...state, selectedRadioValue: action.data });
            case newReturnActions.SET_SELECTED_PART_SEARCHED_VALUE:
                return ({ ...state, selectPartNoSearchedValue: action.data });
            case newReturnActions.SET_SHOW_QUESTION_POPUP:
                return ({ ...state, showQuestionPopup: action.data });
            case newReturnActions.SET_SELECTED_PART_VALUE:
                return ({ ...state, selectedPartNo: action.data });
            case newReturnActions.SET_SELECTED_SHIPMENT:
                return ({ ...state, selectedShipment: action.data });
            case newReturnActions.SET_TEMP_SHIPMENTS:
                return ({ ...state, tempShipments: action.data });
            case newReturnActions.SET_QUESTIONSANDANSWERS:
                return { ...state, questionsAndAnswers: action.data };
            case newReturnActions.SET_TEMP_QUESTIONS_AND_ANSWERS:
                return { ...state, tempQuestionsAndAnswers: action.data };
            default:
                return state;
        }

    }

    const [state, dispatch] = useReducer(newReturnReducer, newReturnIntialState)

    // const [counter, setCounter] = useState(0)



    // ===============refs=============
    const typeRef = useRef(null);
    const descriptionRef = useRef(null);
    const validationRef = useRef();
    const imageRef = useRef();
    const reasonRef = useRef();
    const returnRef = useRef();
    const shipmentRef = useRef();
    const partNoRef = useRef();
    const termsRef = useRef(null);



    // ====================Validation Functions=======================
    const checkType = (value, ref) => {
        if (value?.trim()?.length <= 0 || value === undefined) {
            if (state?.expand === false) {
                dispatch(setExpand(true))
            }
            setTimeout(() => {
                ref.current?.handleInputBlur(true, t("PleaseSelectType"), true);
            }, 0);
            return false;
        } else {
            ref.current?.handleInputBlur("", "");
            return true;
        }
    };

    const checkTerms = (value, ref) => {
        if (value === false) {
            ref.current?.focus();
            return false;
        } else {
            // ref.current?.handleInputBlur("", "");
            return true;
        }
    };

    const checkShipmentSelection = (value, ref) => {
        if (value?.trim()?.length <= 0 || value === undefined) {
            ref.current?.handleInputBlur(true, t("pleaseSelectShipment"), true);
            return false;
        } else {
            ref.current?.handleInputBlur("", "");
            return true;
        }
    };

    const checkPartNoSelection = (value, ref) => {
        if (value?.trim()?.length <= 0 || value === undefined) {
            ref.current?.handleInputBlur(true, t("pleaseSelectPartNo"), true);
            return false;
        } else {
            ref.current?.handleInputBlur("", "");
            return true;
        }
    };

    const descriptionValidation = (value, ref) => {
        if (value?.trim()?.length <= 0) {
            if (state?.expand === false) {
                dispatch(setExpand(true))
            }
            setTimeout(() => {
                ref.current?.handleInputBlur(true, t("PleaseEnterDesc"), true);
            }, 0);
            return false;
        } else {
            ref.current?.handleInputBlur("", "");
            return true;
        }
    };

    const questionValidation = (value, ref) => {
        if (value?.trim()?.length <= 0) {
            if (state?.expand === false) {
                dispatch(setExpand(true))
            }
            setTimeout(() => {
                ref.current?.handleInputBlur(true, t("fillField"));
            }, 0);
            return false;
        } else {
            ref.current?.handleInputBlur("", "");
            return true;
        }
    }


    const imageValidation = (value, ref) => {
        if (value === undefined) {
            if (state?.expand === false) {
                dispatch(setExpand(true))
            }
            setTimeout(() => {
                ref.current?.handleInputBlur(true, t("pleaseSelectImage"));
            }, 0);
            return false;
        }
        else if (value > 2097152 || value === undefined) {
            if (state?.expand === false) {
                dispatch(setExpand(true))
            }
            setTimeout(() => {
                ref.current?.handleInputBlur(true, t("imageSizeValidate"));
            }, 0);
            return false;
        } else {
            ref.current?.handleInputBlur("", "");
            return true;
        }
    }



    const reasonValidation = (value, ref) => {
        if (value?.trim()?.length <= 0) {
            if (state?.expand === false) {
                dispatch(setExpand(true))
            }
            setTimeout(() => {
                ref.current?.handleInputBlur(true, t("reasonSelect"), true);
            }, 0);
            return false;
        } else {
            ref.current?.handleInputBlur("", "");
            return true;
        }
    }

    const returnValidation = (value, ref) => {
        if (value?.trim()?.length <= 0) {
            if (state?.expand === false) {
                dispatch(setExpand(true))
            }
            setTimeout(() => {
                ref.current?.handleInputBlur(true, t("fillField"), true);
            }, 0);
            return false;
        } else {
            ref.current?.handleInputBlur("", "");
            return true;
        }
    }

    return ({
        setShipmentData, setShipmentDataLoading, setTempQuestionsAndAnswers, checkShipmentSelection, shipmentRef,
        setSelectedShipment, setTempShipments,
        setSelectedPartNo,
        setSelectPartNoSearchedValue,
        setShowQuestionPopup,
        setSelectedRadioValue,
        setSelectedToDate,
        setSelectedFromDate,
        setSelectPartNoPopup,
        setSelectShipmentPopup,
        setConfirmationExpand,
        returnValidation,
        reasonValidation,
        setisAllQuestionFilled,
        questionValidation,
        setSelectedImage,
        setQuestionAndAnswers,
        setQuestionIsLoading,
        setQuestionsData,
        setProductSelectionLoading,
        setSelectedProduct,
        setSelectedProductObject,
        setProductListData,
        setSelectedReasonObject,
        setSelectedReason,
        setConfirmationDetailData,
        setConfirmationDataLoading,
        setshipDetailsExpand,
        setReasonsData,
        setReasonsLoading,
        setExpand,
        setDelay,
        setCheckBox, setCounter, checkPartNoSelection, partNoRef, termsRef, checkTerms,
        descriptionRef, typeRef, validationRef, imageRef, reasonRef, returnRef,
        setReturnDetailDescription,
        setType,
        setshipItemsExpand,
        checkType, descriptionValidation, imageValidation,
        token,
        state, dispatch, setLineItems
    })
}


export default useNewReturnHook