import React from "react";
import Box from "@mui/material/Box";
import { Modal } from "@mui/material";
import { T } from "../translation";
import {
  CLOSE_BGWHITE_ICON,
  ROUND_CHECK_ICON,
  SORT_BY_ICON,
} from "../../assets";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  outline: "none",
};

/**
 * 
 * @param {object} SortData - use for pass sort list
 * @param {function} onSort - use for call function click on sort item in list
 * @param {boolean} visible - use for whether display sort modal or not
 * @param {function} onClose - use for call function when click on close
 * @param {object} selected - use for display selected sort filter item in modal
 * @param {boolean} isI18nKey - use for whether display I18nKey or not 
 * @param {string} i18key - use for dynamic I18nKey 
 * @returns sort by value jsx element
 */
export default function SortByModual({
  SortData,
  onSort,
  visible,
  onClose,
  selected,
  isI18nKey,
  i18key
}) {
  return (
    <>
      <button className="filter-sortby-fn" onClick={onClose}>
        <img src={SORT_BY_ICON} alt="Filter" />
        <span>
          <T strProp="Sortby" />
        </span>
      </button>
      <Modal
        open={visible}
        onClose={onClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="modual-box" sx={{ ...style, width: 300 }}>
          <div className="Sort-by">
            <div className="sort-by-title">
              <T strProp="SORTBY" />
            </div>
            <img
              src={CLOSE_BGWHITE_ICON}
              alt="CloseIcon"
              className="cursor-pointer"
              onClick={onClose}
            />
          </div>
          <div className="border-line-1_5px" />
          {SortData?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <section
                  key={index}
                  className="modual"
                  onClick={() => onSort(item)}
                >
                  <div className="Sort-by-flex cursor-pointer">
                    <span>{(isI18nKey && i18key) ? item[i18key] : item?.displayName}</span>
                    {selected?.displayName === item?.displayName && (
                      <img src={ROUND_CHECK_ICON} alt="check" />
                    )}
                  </div>
                </section>
                {SortData?.length - 1 === index ? null : (
                  <div className="border-line-none-m" />
                )}
              </React.Fragment>
            );
          })}
        </Box>
      </Modal>
    </>
  );
}
