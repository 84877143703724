import React from "react";
import { Link } from "react-router-dom";
import { LEFT_ARROW_BLACK_ICON } from "../../assets";
import { T } from "../translation";

/** 
 * @param {string} Title - back menu title 
 * @param {string} Path - navigate on this path 
 * @param {string} mainClass - class for dynamic style
 * @param {function} OnClick - click function
 * @param {boolean} replace - 
 * @returns - back menu element
 */
function BackCard({ Title = "", mainClass = "", Path, OnClick, replace = false }) {
  return (
    <div className={`backmenu ${mainClass}`} >
      <div className="back__text">
        <Link
          to={Path}
          className="back__text link"
          onClick={OnClick}
          replace={replace}
        >
          <img src={LEFT_ARROW_BLACK_ICON} alt="icon" />
          <span>
            <T strProp="Back" />
          </span>
        </Link>
        <div className="back-text__title">
          <T strProp={Title} />
        </div>
      </div>
    </div>
  );
}

export default BackCard;
