import React from "react";
// import { Link } from "react-router-dom";
import { T } from "..";
import { toPrice } from "../../utils";
import { Link } from "react-router-dom";

/**
 * 
 * @param {string} AccountName - use for display user account name
 * @param {string} CustomerName - use for display customer name
 * @param {number} DiscountLevel - use for display % discount level
 * @param {number} CreditLimit - use for display user credit limit
 * @param {number} CreditAvailable - use for display user available credit
 * @param {number} OutstandingBalance  - use for display user outstanding balance
 * @param {boolean} isDisplayUnitPrice  - use for whether display unit price or not
 * @returns this functiona can display accounr summary card.
 */
export function AccountSummaryCard({
  AccountName,
  CustomerName,
  DiscountLevel,
  CreditLimit,
  CreditAvailable,
  OutstandingBalance,
  isDisplayUnitPrice = false,
}) {

  return (
    <section className="account-summar-card">
      <div className="account-summar-card__border">
        <span className="account-summar-card__title">
          <T strProp="accountName" />
        </span>
        <span className="account-summar-card__body">
          {AccountName || "--"}{" "}
        </span>
      </div>
      <div className="account-summar-card__border">
        <span className="account-summar-card__title">
          <T strProp="segenCustomerCode" />
        </span>
        <span className="account-summar-card__body">
          {CustomerName || "--"}
        </span>
      </div>
      {isDisplayUnitPrice && (
        <div className="account-summar-card__border">
          <span className="account-summar-card__title">
            <T strProp="discountLevel" />
          </span>
          <span className="account-summar-card__body">
            {DiscountLevel ? DiscountLevel + " %" : "--"}
          </span>
        </div>
      )}
      <div className="account-summar-card__border">
        <span className="account-summar-card__title">
          <T strProp="creditLimit" />
        </span>
        <span className="account-summar-card__body">
          {toPrice(CreditLimit)}
        </span>
      </div>
      <div className="account-summar-card__border">
        <span className="account-summar-card__title">
          <T strProp="creditAvailable" />
        </span>
        <span className="account-summar-card__body">
          {toPrice(CreditAvailable)}
        </span>
      </div>
      <div className="account-summar-card__price">
        {/* <Link className="account-summar-card__link" to="/recentinvoices"> */}
        <span className="account-summar-card__title clr-orng">
          <div className="shipping-address-card__title">
            <T strProp="outstandingBalance" />
          </div>
        </span>
        {/* </Link> */}
        <span className="account-summar-card__pr">
          {toPrice(OutstandingBalance)}
        </span>
      </div>
    </section>
  );
}

/* ================  Shipping Address Card =========== */

/**
 * 
 * @param {string} Address - use for display user shipping address
 * @returns this functiona can display user shipping address in card view.
 */
export function ShippingAddressCard({ Address, name }) {
  return (
    <section className="shipping-addres-card">
      <div className="shipping-address-card__title">
        <T strProp="address" />
      </div>
      {name && <div className="shipping-address-card__address">{name}</div>}
      <div className="shipping-address-card__address">{Address || "--"}</div>
      <Link className="shipping-address-card__link " to="/change-shipping-address">
        <div className="shipping-address-card__title">
          <T strProp="changethisaddress" />
        </div>
      </Link>
    </section>
  );
}
