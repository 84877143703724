import dayjs from 'dayjs';
import { T } from '../components';
import { getCurrency } from '../utils/utils';
import {
    DateStringRegex,
    MMM,
    TimeStringRegex,
    currencyCodes,
    currencySymbol,
    defaultCurrency,
    mediumPassword,
    strongPassword
} from './constants';
import { stockStatus } from './stockUtils';



const filterLineItems = (array) => {
    let canReturn = { id: 0, title: "canReturn", data: [] };
    let alreadyReturned = { id: 1, title: "alreadyReturned", data: [] };
    array?.forEach((lineItem, index) => {
        if (Number(lineItem.quantity - Math.abs(lineItem.returnedQuantity))) {
            canReturn.data.push(lineItem)
        } else {
            alreadyReturned.data.push(lineItem)
        }
    })
    return [canReturn, alreadyReturned]
}
/**
 * we have a fair bit of data in the product details page
 * availability is added here as we use a couple of times
 * [see test data]
 */
const sortProductData = (response, productNo) => {
    const upper = productNo?.toUpperCase();
    let result = response?.body?.result?.items[upper] || response?.body?.result?.items[productNo];

    // const { stockLevels, unitPrice, alternates } = result;
    let stockLevels = result?.stockLevels || ''
    let unitPrice = result?.unitPrice || ''
    let alternates = result?.alternates || ''
    const { currency, amount } = unitPrice;
    // flatten the data a bit to save lookups
    // filter alternatives as sometimes the data is null which causes a
    // a problem
    return {
        ...result,
        availability: stockStatus(stockLevels),
        alternates: alternates?.filter((add) => add?.alternateProduct !== null),
        currency,
        amount,
        locale: getCurrency(currency).locale
    };
};

/**
 * @param  {string} currencyCode
 * @returns - currency symbol
 */
function getCurrencySymbol(currencyCode = "") {
    if (typeof currencyCode !== 'string') {
        return undefined;
    };

    const code = currencyCode?.toUpperCase();

    if (!Object.prototype?.hasOwnProperty.call(currencySymbol, code)) {
        return undefined;
    };

    return currencySymbol[code];
};


const refactorEditDetailsData = (data) => {
    let arr = [];
    if (!data || !data.length) return arr;

    data?.forEach((i) => {
        if (i?.isEditable === undefined) {
            (i?.productCode !== "DELIVERY") && arr.push(i)
        } else {
            ((i?.productCode !== "DELIVERY") && (i?.isEditable === true)) && arr.push(i)
        }
    });
    data?.forEach((i) => {
        if (i?.isEditable === undefined) return;
        ((i?.productCode !== "DELIVERY") && (i?.isEditable === false)) && arr.push(i);
    });
    data?.forEach((i) => {
        (i?.productCode === "DELIVERY") && arr.push(i)
    });

    return arr;
};


const refactoring = (fetchedData) => {
    if (!fetchedData) {
        return
    }
    const obj = JSON.parse(JSON.stringify(fetchedData));
    obj?.content?.forEach((items, index) => {
        items?.content?.splice(0)
    })
    let contentArray = [];
    fetchedData?.content?.forEach((items, index) => {
        let { columns, content } = items;
        let modulor = content?.length / columns;
        let array = [];
        for (let i = 0; i <= columns - 1; i++) {
            let newArray = [];
            let n = i;
            for (let j = 0; j < Math.ceil(modulor); j++) {
                if (content.length > n) {
                    newArray?.push(content[n])
                }
                n += columns
            }
            array.push(newArray)
        }
        contentArray?.push(array)
    })
    obj?.content?.forEach((items, index) => {
        contentArray?.forEach((item, i) => {
            index === i && obj?.content[index]?.content?.push(...contentArray[i])
        })
    })
    // console.log("refactor obj", obj)
    return (obj)
}

/**
 * check password strength
 * @param  {string} passStr- password
 * @returns password strength
 */
function StrengthChecker(passStr = "") {
    if (passStr?.length === 0) {
        return 'def';
    } else if (strongPassword?.test(passStr)) {
        return 'strong';
    } else if (mediumPassword?.test(passStr)) {
        return 'medium';
    } else {
        return 'waek';
    }
}

/**
 * @param  {string} d1 - date of user input
 * @param  {string} d2 - date of current day
 * @returns - create input date and current date formate
 */
const isPostDate = (d1, d2 = dayjs()) => {
    var date1 = dayjs(d1).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    var date2 = dayjs(d2).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    return date1?.isAfter(date2);
};

/**
 * used to extract object of address
 * @param  {object} address
 * @returns value of object
 */
const extractAddress = (address) => {
    return {
        "contactName": address?.contactName || '--',
        "country": address?.country || '--',
        "county": address?.county || '--',
        "organisation": address?.organisation || '--',
        "postCode": address?.postCode || '--',
        "street": address?.street || '--',
        "town": address?.town || '--',
        "contactPhone": address?.contactPhone || '--',
        "countryId": address?.countryId || '--',
    }
}
/**
 * @param {string} street
 * @param {string} town
 * @param {string} postCode
 * @param {string} country
 * @returns - formated address
 */
const formatedAddress = (street, town, postCode, country) => {
    return street || town || postCode ? (
        <>
            {street}{", "} {town && town + ","} {postCode}{", "} {country}
        </>
    ) : (
        "--"
    )
}

/**
 *
 * @param {string} provider - provider type
 * @param {string} btnNm - clicked button name
 * @returns - link of payment method used in shipment details payment buttons (base on country and debitcard/notify method)
 */
const getPaymentLink = (provider, btnNm) => {
    if (provider === "worldpay") {
        if (btnNm === "DebitCard") window.open("https://portal.segen.co.uk/Reseller/Payment/Payments?layout=MobileWeb");
        if (btnNm === "NotifyPayment") window.open("https://portal.segen.co.uk/Reseller/Payment/NotifyPayment?layout=MobileWeb");
    }
    if (provider === "paygate") {
        if (btnNm === "DebitCard") window.open("https://portal.segensolar.co.za/Reseller/Payment/Payments?layout=MobileWeb");
        if (btnNm === "NotifyPayment") window.open("https://portal.segensolar.co.za/Reseller/Payment/NotifyPayment?layout=MobileWeb");
    }
}

/**
 *
 * @param {string} num - use for convert particular enter amount in decimal formate
 * @returns this function can convert pass number to decimal and display only 2 degits after point.
 */
const toDecimal = (num, code) => {
    let Num = typeof num === "string" ? parseFloat(num) : num;
    // decimal to 2 places with comma using Intl
    // let lngTag = navigator.language || navigator.userLanguage;
    const code1 = code?.toUpperCase();
    let lanLocale = currencyCodes[code1] || currencyCodes[defaultCurrency];
    let locale = lanLocale?.locale;
    let nf = new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    // let nf = new Intl.NumberFormat(' , { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return nf.format(Num);
};


const toNum = (num, maximumFractionDigits = 2, minimumFractionDigits) => {
    return " " + new Intl.NumberFormat('en-US', {
        maximumFractionDigits,
        minimumIntegerDigits: num === 0 ? 2 : undefined,
        minimumFractionDigits/* , notation: 'compact' */
    }).format(num);
};


/**
 *
 * @param {array} p - use for display proper formate
 * @returns this function can return price elements with proper decimal formate and also display currency symbol before price/ amount.
 */
const toPrice = (p, lng) => {
    if (/* p?.currency &&  */p?.amount !== undefined && p?.amount !== null) {
        let num = typeof p?.amount === "string" ? p?.amount === "" ? 0 : parseFloat(p?.amount) : p?.amount;

        const codeUp = typeof p?.currency === "string" ? p?.currency?.toUpperCase() : defaultCurrency;
        let { locale } = currencyCodes[codeUp] || currencyCodes[defaultCurrency];
        let nf = new Intl.NumberFormat(lng || locale, {
            style: "currency", currency: codeUp, currencyDisplay: "narrowSymbol"
        });
        return nf.format(num);
    } else {
        return "-- --";
    }
};

// const toPrice = (p) => {
//   if (p?.currency && p?.amount !== undefined && p?.amount !== null) {
//     // let lngTag = RNLocalize.getLocales()[0].languageTag;
//     var lngTag = navigator.language || navigator.userLanguage;
//     let nf = new Intl.NumberFormat(lngTag, { style: "currency", "currency": p?.currency });
//     let num = typeof p?.amount === "string" ? p?.amount === "" ? 0 : parseFloat(p?.amount) : p?.amount;
//     return nf.format(num);
//   } else {
//     return "--";
//   }
// };
// const toPrice = (p) => {
//   // return p?.currency && p?.amount >= 0 ? `${getCurrencySymbol("zar")} ${toDecimal("123456789", "Zar")}` : "--";
//   return p?.currency && p?.amount >= 0 ? `${getCurrencySymbol(p?.currency)} ${toDecimal(p?.amount, p?.currency)}` : "-- --";
// };

/**
 *
 * @param {string} cur - use for display currency
 * @param {string} price - use for display proper formate
 * @returns this function can return product price elements with proper decimal formate and also display currency symbol before price/ amount.
 */
const toProdPrice = (cur, price) => {
    return cur && price >= 0 ? `${getCurrencySymbol(cur)} ${toDecimal(price, cur)}` : "--";
};

/**
 *
 * @param {string} c - use for display currency
 * @param {array} p - use for display proper formate
 * @returns this function can return tax price elements with proper decimal formate and also display currency symbol before price/ amount.
 */
const toTexPrice = (c, p) => {
    return c && p?.value >= 0 ? `${getCurrencySymbol(c)} ${toDecimal(p?.value, c)}` : "--";
};

/**
 *
 * @param {string} str - use for convert string to lower letter
 * @returns this function can get sting and return string in lower case letter.
 */
const toLower = (str) => {
    return typeof str === "string" ? str?.toLowerCase() : str;
}

const dateFormate = (date) => {
    return dayjs(date).format("DD-MMM-YYYY");
};

// Hde or Show  Add to shipment dropdown
// 1. If all their line items(Not delivery line item) are "inStock": true
// 2. If user have at least one open shipment - (Planned shipment api results > 0)  && ("shippingStatus": "Awaiting Customer", && "shippingStatus": "Awaiting Payment")
// check if all line items are inStock
// use const { quantityInStock } = stockStatus(item?.stockLevels); and check if quantityInStock === item?.quantity
const isAllInStock = (dataObj) => {
    let arr = dataObj?.items?.filter((item) => item?.productCode !== "DELIVERY");
    let issAllInStock = arr?.every((item) => {
        const { quantityInStock } = stockStatus(item?.stockLevels);
        return quantityInStock > 0;
    });
    return issAllInStock;
};


function isDate(dateStr) {
    if (dateStr) {
        // check dateStrOrNot is date or not if it date then return true and formated data liek "DD-MMM-YYYY" else return false and as it is
        let date = new Date(String(dateStr).toString());

        let dateArr = dateStr?.split("/");
        let datee = dateArr[1] + "-" + MMM[parseInt(dateArr[0]) - 1] + "-" + dateArr[2];

        if (date instanceof Date && !isNaN(date.getTime())) {
            return { isDate: true, date: dayjs(date).format("DD-MMM-YYYY") };
        }
        // check using regex if it is date then return true and formated data liek "DD-MMM-YYYY" else return false and as it is
        // date format like "MM/DD/YYYY", "DD/MM/YYYY", "DD-MM-YYYY", "MM-DD-YYYY"
        else if (dateStr.match(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/)) {
            return { isDate: true, date: datee };
        }
        // above regex is not working for date like "8/8/2022", "8/22/2022", "12/2/2022" means "M/D/YYYY", "M/DD/YYYY", "MM/D/YYYY" so check it manually
        else if (dateStr.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/)) {
            return { isDate: true, date: datee };
        }
        return { isDate: false, date: dateStr };
    } else return { isDate: false, date: dateStr };
};

function convertDateInString(str) {
    let date = str.match(/(\d{1,2})\/(\d{1,2})\/(\d{4})/);
    if (date) {
        let datee = date[2] + "-" + MMM[parseInt(date[1]) - 1] + "-" + date[3];
        return str.replace(DateStringRegex, datee).replace(TimeStringRegex, "").replace(/(AM|PM)/g, "");
    } else return str;
};

/**
 *
 * @param {string} expected Based on that string value display expected field value.
 * @returns This function can return expected string value, text color & background color.
 */

function getExpectedResultHAX_COLOR(expected) {
    if (expected) {
        if (expected === "IN STOCK") {
            return { title: <T strProp="productStockInStock" />, color: "#57AA43", bgCol: "#E0F8DA" };
        } else if (expected === "Completed") {
            return { title: expected, color: "#57AA43", bgCol: "#E0F8DA" };
        }
        else if (expected === "") {
            return { title: <T strProp="productUnallocated" />, color: "#00AEE1", bgCol: "#E5F4F9" };
        }
        // currently expected are "IN STOCK", "", so it can be "11/29/2022", "10 x Unallocated", "DELIVERED", "1 DUE 11/23/2022 12:00:00 AM",
        else if (expected === "DELIVERED") {
            return { title: <T strProp="productDelivered" />, color: "#696969", bgCol: "#F2F2F2" };
        } else if (isDate(expected).isDate) {
            return { title: isDate(expected).date, color: "#F49600", bgCol: "#FFF9F0" };
        } else if (expected?.match(/(\d{1,2})\/(\d{1,2})\/(\d{4})/)) {
            return { title: convertDateInString(expected), color: '#FFA500', bgCol: '#FFF2CC' };
        } else if (expected.includes("x Unallocated")) {
            return { title: expected, color: "#008FC3", bgCol: "#E2F1FD" };
        } else if (expected.includes("DUE")) {
            return { title: expected, color: "#FFA500", bgCol: "#FFF2CC" };
        } else {
            return { title: expected || <T strProp="productUnallocated" />, color: "#00AEE1", bgCol: "#E5F4F9" };
        }
    } else return { title: expected || <T strProp="productUnallocated" />, color: "#00AEE1", bgCol: "#E5F4F9" };
};

/**
 *
 * @param {array} issues - for display issuees
 * @returns this function can use for ddisplay project issue with proper formate like display issue by category wise.
 */
const displayIssues = (issues) => {
    // let newerArr = [];
    // issues.forEach((issue) => {
    //   let idx = newerArr.findIndex((item) => item.category === issue?.category);
    //   let dataObj = {
    //     productCode: issue?.affectedProducts?.[0]?.productCode || "",
    //     message: issue?.message,
    //   };
    //   if (idx === -1) {
    //     let obj = {};
    //     obj.category = issue?.category;
    //     obj.data = [dataObj];
    //     issue?.message && newerArr.push(obj);
    //   } else {
    //     issue?.message && newerArr[idx].data.push(dataObj);
    //   }
    // });
    // return newerArr;
    let newArr = [];
    let finalArr = [];
    issues?.forEach((items) => {
        const { category } = items;
        !newArr.includes(category) && newArr.push(category)
    });
    newArr.forEach((items) => {
        let obj = { category: items, data: [] };
        issues.forEach((i) => {
            let { category: ctgry, affectedProducts, message, alternateProducts } = i;
            let object = {}
            if (ctgry === items) {
                let arr = []
                affectedProducts?.forEach((item) => {
                    const { productCode } = item;
                    arr.push(productCode);
                })
                alternateProducts?.forEach((item) => {
                    const { productCode } = item;
                    arr.push(productCode);
                })
                object = { ...object, message: message, productcode: [...arr] }
                obj.data.push(object)
            }
        })
        finalArr.push(obj)
    })
    return finalArr;
};

/**
 *
 * @param {string} earlyDate for varify early dispatch date
 * @param {string} dispatchDate for varify dispatch date
 * @returns For check whether early dispatch date after dispacth date or not.
 */
const checkDispatchDate = (earlyDate, dispatchDate) => {
    let result = earlyDate > new Date(dispatchDate);
    return result;
}

/**
 *
 * @param {string} dispatchDate for check dispatch date
 * @returns this function can check dispatch date with tomorrow date.
 */
const checkTommorowDate = (dispatchDate) => {
    let tommorowDate = dayjs().format("YYYY/MM/DD");
    // let tommorowDate = dayjs().add(1, "day").format("YYYY/MM/DD");
    let result = new Date(dispatchDate) >= new Date(tommorowDate);
    return result;
}

/**
 *
 * @param {string} provider for check condition base on provider.
 * @returns this funciton can check discount base on provider and return result.
 */
const checkDiscount = (provider) => {
    let result = provider === "paygate" ? false : true;
    return result;
}

function toPriceObj(v, creditLimit) {
    return creditLimit?.currency ? (toPrice({ 'currency': creditLimit?.currency, 'amount': v })) : toNum(v)
};

function shipStatus(str) {
    if ((toLower(str) === "request picking") || (toLower(str) === "picking") || (toLower(str) === "dispatched") || (toLower(str) === "completed")) return true
    return false
};

export {
    sortProductData, getCurrencySymbol, StrengthChecker, isPostDate, extractAddress,
    formatedAddress, getPaymentLink, toPrice, toTexPrice, toProdPrice, isAllInStock,
    toLower, dateFormate, isDate, getExpectedResultHAX_COLOR, displayIssues,
    checkDispatchDate, checkTommorowDate, checkDiscount, toNum, toPriceObj, refactoring, refactorEditDetailsData, shipStatus, filterLineItems
};
