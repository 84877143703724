import React from "react";
import { Link } from "react-router-dom";
import {
  ACCOUNT_MANAGER,
  MARKETING_ENQUIRIES_ICON,
  PHONE_BLACK_IC,
  SUBMITTEDORDERS_ICON,
  TECHNICAL_ENQUIRIES_ICON,
} from "../../../assets";
import { T } from "../../translation";

/**
 *
 * @param {string} Title - use for display contact title
 * @param {string} Name - use for display contact name
 * @param {number} Contact - use for display contact number
 * @param {string} Info - use for display contact information
 * @param {string} Type - use for display contact type
 * @returns this component return contact card list
 */
function ContactsCard({ Title, Name, Contact, Info, Type }) {

  var ImgSrc = ACCOUNT_MANAGER;
  var redirectLink = "/contactus?type=GeneralEnquiry";
  var infoString = "drCard1";

  if (Type === "Marketing Enquiries") {
    ImgSrc = MARKETING_ENQUIRIES_ICON;
    redirectLink = "/contactus?type=MarketingEnquiry";
    infoString = "drCard4";
  } else if (Type === "Technical Enquiries") {
    ImgSrc = TECHNICAL_ENQUIRIES_ICON;
    redirectLink = "/contactus?type=TechnicalEnquiry";
    infoString = "keycontactstechnicaltext";
  } else if (Type === "Account Manager") {
    ImgSrc = ACCOUNT_MANAGER;
    redirectLink = "/contactus?type=GeneralEnquiry";
    infoString = "drCard1";
  } else if (Type === "Submitted Orders") {
    ImgSrc = SUBMITTEDORDERS_ICON;
    redirectLink = "/contactus?type=OrderEnquiry";
    infoString = "drCard2";
  } else {
  }

  return (
    <section className="contacts-card">
      <div className="contacts-card-flex-row">
        <div className="contacts-card-image">
          <img src={`${ImgSrc}`} alt="AMC" />
        </div>
        <div className="contacts-card-flex-col">
          <span className="contacts-card-title">{Title}</span>
          <span className="contacts-card-name">{Name}</span>

          {Contact && (
            <div
              className="contacts-card-flex cursor-pointer"
              onClick={() => {
                window.open(`tel:${Contact}`, "_self");
              }}
            >
              <img src={PHONE_BLACK_IC} alt="phone" />
              <span className="contacts-card-phone">{Contact}</span>
            </div>
          )}
        </div>
      </div>
      <Link to={redirectLink} className="contacts-card-button cursor-pointer">
        {/* <Link to="/contactus" className="contacts-card-button cursor-pointer"> */}
        <T strProp="Contact" />
      </Link>
      <span className="contacts-card-info">
        <T strProp={infoString /* `keycontacts${Info}text` */} />
      </span>
    </section>
  );
}

export default ContactsCard;
