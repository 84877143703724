import React from "react";
import { TOP_ARROW } from "../assets";

/**
 * 
 * @param {boolean} BottomSize Use for add css base on this parameter
 * @returns this function can return or display up & down arrow.
 */

function UpDownScroll({ BottomSize }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <div className={BottomSize ? "scroll_view_" : "scroll_view"}>
      <button onClick={scrollToTop} className="btn-scroll">
        <img src={TOP_ARROW} alt="" />
      </button>
      <button onClick={scrollToBottom} className="btn-scroll">
        <img src={TOP_ARROW} alt="" className="up-down-scroll" />
      </button>
    </div>
  );
}

export default UpDownScroll;
