import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SEARCH_BLUE_ICON, SEARCH_CLEAR_ICON } from "../../assets";

/**
 * search icon component used to search bar 
 * this props are used to reusable component 
 * @param {string} searchValue - search value in this state
 * @param {function} setSearchValue - used to set search value  
 * @param {string} placeHolder - placeholder value to set place holder in search bar  
 * @param {function} handleNavigate -  this function used to navigate after user enter search value 
 * @param {boolean} border - used to dynamic css of box border 
 * @param {boolean} M10 - used to dynamic css of margin (left - right) on page design    
 * @returns - seach bar element to user search
 */
function SearchIcon({
  searchValue = "",
  setSearchValue = () => {},
  placeHolder,
  handleNavigate,
  border,
  M10,
}) {
  const navigate = useNavigate();
  const [searchVal, setSearchVal] = useState(searchValue);
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      let resultTrm = searchVal.trim();
      setSearchVal(resultTrm);
       navigate(`search?searchtype=products&searchquery=${resultTrm}`);
    }
  }

  return (
    <div className={M10 ? "search_h" : "search-h"}>
      <section className={border ? "searchbar searchbar-border" : "searchbar "}>
        <img src={SEARCH_BLUE_ICON} alt="Search" />
        <input
          maxLength="255"
          type="search"
          className="searchbar-input"
          placeholder={
            placeHolder || "Search for products, quotes, orders, shipments..."
            // placeHolder || "Search products, news and projects"
          }
          value={searchVal}
          onKeyDown={handleNavigate || handleKeyDown}
          onChange={(e) => {
            setSearchVal(e.target.value);
            setSearchValue(e.target.value);
          }}
        />
        {searchVal.length > 0 && (
          <img
          /**
           * clear search value icon 
           */
            src={SEARCH_CLEAR_ICON}
            alt="Search"
            className="edit-shipment-other-item-mr"
            onClick={() => {
              setSearchVal("");
              setSearchValue("");
            }}
          />
        )}
      </section>
    </div>
  );
}

export default SearchIcon;
