import { useState, useEffect } from 'react'
import { callApi } from '../services';
/**
 * this hook used to get orderlist data from api
 * @param  {string} token -for user authentication 
 * @param  {int} pageNo=0 - for page number to pagination 
 * @param  {ref} alertRef - used to error alert popup
 */
const useCurrentOrdersHook = (token, pageNo = 0, alertRef) => {
    const [quotes, setQuotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasNextPage, setHasNextPage] = useState(false);

    const getCurrentQuotes = async () => {
        !loading && setLoading(true);
        const { res, sCode } = await callApi("orderList", { token, alertRef }, pageNo + 1);
        if (res?.error) {
            setLoading(false);
        } else if (sCode === 401) { setHasNextPage(false); setLoading(false); }
        else {
            if (sCode === 200 || sCode === 202) {
                let pCount = res?.body?.result?.pageCount;
                let pNum = res?.body?.result?.pageNumber;
                setHasNextPage(pCount > pNum);
                setQuotes((p) => [...p, ...res?.body?.result?.items]);
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        if (token === null) { return; }
        getCurrentQuotes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, pageNo])
    return { quotes, loading, hasNextPage };
}

export { useCurrentOrdersHook }