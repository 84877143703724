import React, { forwardRef } from "react";
import CurrentQuotesCard from "./CurrentQuotesCard";

/**
 * @param {Array} item - use for display item details
 * @param {referance} ref - use for reffer particular item
 * @returns - current quote card comopnent / jsx
 */
const CurrentQuotesPageCard = forwardRef(({ item }, ref) => {

    const quoteProductBody = (
        <CurrentQuotesCard item={item} />
      );    
      const content = ref ? (
        <div ref={ref}>{quoteProductBody}</div>
      ) : (
        <div>{quoteProductBody}</div>
      );    
      return content;
    });

export default CurrentQuotesPageCard