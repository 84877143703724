import React /* useState */ from "react";
// import { ManufacturerImgData } from "./ManufacturerImgData";
import { /* Link, Navigate, */ useNavigate } from "react-router-dom";
import { NO_FOUND_SM_IMG } from "../../../assets";
import { T } from "../../translation";

/**
 *
 * @param {function} onCard - use for redirect to particular product category list page
 * @param {string} category - use for display category name
 * @param {string} imgUrl - use for display image of category
 * @returns this component display list of category list
 */
export default function ProductCard({ onCard, category, imgUrl }) {
  return (
    <div className="square__box" onClick={onCard}>
      <div className="square-box-data">
        <img
          src={imgUrl}
          alt="Product"
          className="square-box_data"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = NO_FOUND_SM_IMG;
          }}
        />
      </div>
      <p className="square-box-title">{category}</p>
    </div>
  );
}

/**
 * 
 * @param {array} ManufacturerData use for display manufacturer data list
 * @returns this function can display manufacturer data list
 */
export function ManufacturerCard({ ManufacturerData }) {
  // HIDE/BYPASS temporary
  // const [data, setData] = useState([] //ManufacturerImgData);
  const navigate = useNavigate();
  const onManufactore = (id) => {
    navigate(`/product?BrandId=${id}`);
    // navigate(`/product?BrandId=${id}&SortAsc=Price`);
  };

  return (
    <>
      {ManufacturerData && ManufacturerData.length > 0 ? (
        <section className="square-box">
          {ManufacturerData?.map((AElement, index) => {
            const { logoUrl, id } = AElement;
            return (
              <div
                key={index}
                className="square__box"
                onClick={() => onManufactore(id)}
              >
                <div className="square-box-data">
                  <img
                    src={logoUrl}
                    alt="Product"
                    className="square-box_data__m"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = NO_FOUND_SM_IMG;
                    }}
                  />
                  {/* <img src={logoUrl} alt={manufacturerName} /> */}
                </div>
              </div>
            );
          })}
        </section>
      ) : (
        <p className="no-data-found">
          {/* <T strProp="commingSoon" /> */}
          <T strProp="noDataFound" />
        </p>
      )}
    </>
  );
}

/**
 * @param {string} Img -  use for display image of product category
 * @param {string} Title - use for display product category title
 * @param {number} Total - use for display total product category count
 * @param {number} subcategoryVal - use for set cub category value
 * @param {function} openProductList - use for redirect to particular product category list page
 * @returns this component can return product category card (img,title,subcategory,etc..).
 */
export function ProductCategoryCard({
  Img,
  Title,
  Total,
  subcategoryVal,
  openProductList,
}) {
  return (
    <section
      className="Product-sub-category-card"
      onClick={() => openProductList(subcategoryVal)}
    >
      <div className="Product-sub-category-card-flex">
        <div className="Product-sub-category-card-data2">
          <img src={Img} alt="icon" />
        </div>
        <div className="Product-sub-category-card-title">
          <span>{Title}</span>
          <div className="Product-sub-category-card-body">
            <img src="../images/ProductsBox_icon.svg" alt="Boxicon" />
            <span>
              {Total} <T strProp="Products" />
            </span>
          </div>
        </div>
      </div>
      <div className="Product-sub-category-card-data5">
        <img src="../images/Product_Arr_icon.svg" alt="Arr" />
      </div>
    </section>
  );
}
