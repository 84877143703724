import React, { useContext, useEffect, useState } from "react";
import { CLOSE_WHITE_ICON, PRODUCTSTOCK_ICON } from "../assets";
import { GlobalContext, Il8Context } from "../contexts";
import { callApi } from "../services";
import { toPrice } from "../utils/functions";
import BtnSmall from "./BtnSmall";
// import DropDown from "./DropDown";
import ButtonModual from "./modual/ButtonModual";
import QuantityCounter from "./QuantityCounter";
import { T } from "./translation";
import { globalActionsTypes } from "../hooks";

/**
 * @param {boolean} visible - visible state use to show this editquoteItem or not
 * @param {function} onClose - use to close this component
 * @param {object} selectedItem - in this object store selected item values
 * @param {number} counter - use for quantity
 * @param {function} setCounter - use for update quantity
 * @param {number} selIdx - selected item index
 * @param {string} token - for user authentication
 * @param {object} quoteDetail - for user quote details value
 * @param {function} setQuoteDetail - set quote details
 * @param {string} quoteRefId - quotation refrence id
 * @param {function} getQuotationDetails - get the quotation details
 * @param {refrence} alertRef - hendal alert popup on error or warning from api
 * @param {boolean} isOrder - edit quote item is open in quote screen or order screen then use this state
 * @param {boolean} isDisplayUnitPrice - use for whether display unit price or not
 * @returns - edit quote item jsx element
 */
function EditQuoteItem({
  visible,
  onClose,
  selectedItem,
  counter,
  setCounter,
  selIdx,
  token,
  quoteDetail,
  setQuoteDetail,
  quoteRefId,
  getQuotationDetails = () => { },
  alertRef,
  isOrder = false,
  isShipment = false,
  isDisplayUnitPrice = false,
  shipmentId
}) {
  const { dispatch } = useContext(GlobalContext);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quePopup, setQuePopup] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmData, setConfirmData] = useState([]);
  const { t } = useContext(Il8Context);
  const pCode = selectedItem?.productCode;
  useEffect(() => {
    selectedItem?.shipmentId !== null && selectedItem?.shipmentId !== ""
      ? setConfirmPopup(true)
      : setConfirmPopup(false);
  }, [selectedItem]);

  useEffect(() => {
    if (token === null) return;
    if (pCode === undefined || pCode === null) return;
    const fetchProductDetails = async () => {
      !loading && setLoading(true);
      await callApi("productNo", { token, alertRef }, pCode)
        ?.then(({ res, sCode }) => {
          if (sCode === 200 || sCode === 202) {
            setProductDetails(Object.values(res?.body?.result?.items)[0]);
          }
        })
        .catch((err) => console.log("err", err))
        .finally(() => setLoading(false));
    };
    fetchProductDetails();
    // eslint-disable-next-line
  }, [token, pCode]);
  /**
   * @returns this function can save details of quote items.
   */
  const onClickEditQuoteSave = async () => {
    const obj = { quantity: counter };
    await callApi(
      "editQuotes",
      { token, alertRef, msg404: "quoteNotFound" },
      quoteRefId,
      obj,
      selectedItem?.id
    )
      .then(({ res, sCode }) => {
        if (sCode === 200 || sCode === 202) {
          dispatch({ type: globalActionsTypes.setScrollToItem, data: true });
          const quntityIs = res?.body?.result?.quantity;
          let newArr = [...quoteDetail?.items];
          newArr[selIdx].quantity = quntityIs;
          setQuoteDetail({ ...quoteDetail, items: newArr });
          getQuotationDetails();
        }
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        onClose(false);
        setLoadingBtn(false);
      });
  };




  const SaveEdit = async (editItem) => {
    !loadingBtn && setLoadingBtn(true);
    let obj = {
      id: selectedItem?.id,
      quantity: counter,
    };
    await callApi(
      "editShipmentItem",
      { token, alertRef, msg404: "shipmentNotFound" },
      shipmentId,
      obj,
      false
    )
      .then(({ sCode }) => {
        if (sCode === 200 || sCode === 202) {
          dispatch({ type: globalActionsTypes.setScrollToItem, data: true });
          alertRef.current?.onSuccess({
            body: t("EditShipmentSuucess"),
            btnTxt: t("BtnOK"),
          });
        }
        getQuotationDetails();
      })
      .catch((err) => console.log("error editShipmentItem", err))
      .finally(() => setLoadingBtn(false));
    onClose(false)
    // setEdit(false);
  };




  /**
   * @param {boolean} ifEditShip - for specify whether update both shipment item anr order items or not.
   * @returns Edit Order item Quantity base on condition like if user in confirm box click yes then edit quantity in both order and shipment and user click no then only change quantity in order partial
   */
  const onClickEditOrderSave = async (ifEditShip = false) => {
    !loadingBtn && setLoadingBtn(true);
    const obj = { quantity: counter };
    await callApi(
      "editOrderEditItem",
      { token, alertRef, msg404: "orderNotFound" },
      quoteRefId,
      obj,
      selectedItem?.id,
      ifEditShip
    )
      .then(({ res, sCode }) => {
        if (sCode === 200 || sCode === 202) {
          dispatch({ type: globalActionsTypes.setScrollToItem, data: true });
          const quntityIs = res?.body?.result?.quantity;
          let newArr = [...quoteDetail?.items];
          newArr[selIdx].quantity = quntityIs;
          setQuoteDetail({ ...quoteDetail, items: newArr });
          getQuotationDetails();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        onClose(false);
        setLoadingBtn(false);
      });
  };
  return (
    <>
      <ButtonModual Open={visible} onClose={!loadingBtn ? onClose : undefined}>
        <div className="complete-page" />
        <section className="padding20">
          <div className="edit-quote-item-flex">
            <h3>
              <T strProp="quoteEditItems" />
            </h3>
            <img
              src={CLOSE_WHITE_ICON}
              alt="icon"
              onClick={!loadingBtn ? onClose : undefined}
              className="cursor-pointer"
            />
          </div>
          <div className="margin_bottom">
            <EditQuoteItemDiv val={productDetails?.manufacturer} title={"quoteManufacturer"} isLoading={loading} />
            {/* <DropDown
              title={t("quoteManufacturer")}
              data={[
                { manu: productDetails?.manufacturer },
                { manu: "Loading" },
              ]}
              val={productDetails?.manufacturer}
              nameKey="manu"
              valKey="manu"
              isDisabled={true}
              orangeIc
              isLoading={loading}
            /> */}
          </div>
          <div className="margin_bottom">
            <EditQuoteItemDiv val={productDetails?.subCategory} title={"quoteSubCategory"} isLoading={loading} />

            {/* <DropDown
              title={t("quoteSubCategory")}
              data={[
                { manu: productDetails?.subCategory },
                { manu: "Loading" },
              ]}
              val={productDetails?.subCategory}
              nameKey="manu"
              valKey="manu"
              isDisabled={true}
              orangeIc
              isLoading={loading}
            /> */}
          </div>
          <div className="margin_bottom">
            <EditQuoteItemDiv val={productDetails?.productCode} title={"partNumber"} isLoading={loading} />
            {/* <DropDown
              title={t("partNumber")}
              data={[{ manu: productDetails?.productCode }, { manu: "Loading" }]}
              val={productDetails?.productCode}
              nameKey="manu"
              valKey="manu"
              isDisabled={true}
              orangeIc
              isLoading={loading}
            /> */}
          </div>
          <div className="margin_bottom">
            <EditQuoteItemDiv val={productDetails?.description} title={"contactDescription"} isLoading={loading} />
            {/* <DropDown
              title={t("quotePrice")}
              data={[{ manu: productDetails?.price }, { manu: "Loading" }]}
              val={productDetails?.price}
              nameKey="manu"
              valKey="manu"
              isDisabled={true}
              orangeIc
              isLoading={loading}
            /> */}
          </div>
          <div className="quotationdetails-item-flex-row__popup quota_mb_mt">
            {isDisplayUnitPrice && (
              <div className="display_column">
                <span className="quotationdetails-item-gray-text">
                  <T strProp="quoteUnitPrice" />
                </span>
                <span className="quotationdetails-item-price">
                  {toPrice(selectedItem?.grossPrice)}
                </span>
              </div>
            )}
            {isDisplayUnitPrice && (
              <div className="display_column">
                <span className="quotationdetails-item-gray-text">
                  <T strProp="basketItemDiscount" />
                </span>
                <span className="quotationdetails-item-price">
                  {selectedItem?.discountPercent > 0
                    ? selectedItem?.discountPercent + " %"
                    : "--"}{" "}
                </span>
              </div>
            )}
            <div className="display_column">
              <span className="quotationdetails-item-gray-text">
                <T strProp="quoteNetPrice" />
              </span>
              <span className="quotationdetails-item-price">
                {isShipment ? productDetails?.price ? productDetails?.price : "-- --" : toPrice(selectedItem?.netPrice)}
              </span>
            </div>
            {selectedItem?.stockLevels?.length > 0 &&
              selectedItem?.stockLevels[0]?.quantity > 0 && (
                <div className="stock_succ-box">
                  <img src={PRODUCTSTOCK_ICON} alt="icon" />
                  <div className="stock_succ-box_text">
                    <span className="_value">
                      {selectedItem?.stockLevels[0]?.quantity}
                    </span>
                    <span className="_stock">
                      {" "}
                      <T strProp="productStockInStock" />
                    </span>
                  </div>
                </div>
              )}
          </div>
        </section>
        <div className="border__line_px_f5" />
        <section className="edit-quote-mr-p">
          <div className="quantity-flex-row">
            <span className="quantity_title">
              <T strProp="Quantity" />
            </span>
            <QuantityCounter setVal={setCounter} val={counter} />
            <div className="edit-quote-dwm">
              <div className="edit-quote-margin">
                <BtnSmall
                  primary
                  Title={t("quotesave")}
                  isLoading={loadingBtn}
                  OnClickBtn={() => {
                    if (isShipment) {
                      SaveEdit();
                    } else
                      if (confirmPopup) {
                        if (counter <= selectedItem?.quantity) {
                          onClickEditOrderSave(false)
                        } else {
                          setQuePopup(true);
                          setConfirmData(selectedItem);
                        }
                      } else {
                        if (isOrder) {
                          onClickEditOrderSave(false);
                        } else {
                          onClickEditQuoteSave();
                        }
                      }
                  }}
                />
              </div>
              <div className="edit-quote-wmr">
                <BtnSmall
                  white
                  Title={t("cancel")}
                  OnClickBtn={!loadingBtn ? onClose : undefined}
                />
              </div>
            </div>
          </div>
        </section>
      </ButtonModual>

      {/* ========== Confirm box for update order and shipment =============*/}
      <ButtonModual Open={quePopup}>
        <div className="popup_event">
          <div className="popup_event-flex">
            <img
              src={CLOSE_WHITE_ICON}
              alt="icon"
              onClick={() => setQuePopup(false)}
              className="cursor-pointer"
            />
          </div>
          <span className="popup_event-text">
            <T strProp="quoteConfirmShipmentUpdate" />
          </span>
          <div className="popup_event__text">
            <span className="_shipment_id">
              <T strProp="quoteDoyouwantshipment" />{" "}
            </span>
            <span className="_shipment_value">
              {confirmData?.shipmentId},{" "}
            </span>
            <T strProp="quotetobeautomaticallyupdated" />
          </div>
          <div>
            <button
              onClick={() => {
                onClickEditOrderSave(true);
                setQuePopup(false);
              }}
              className="small-btn-blue cursor-pointer"
            >
              <T strProp="Yes" />
            </button>

            <button
              onClick={() => {
                onClickEditOrderSave(false);
                setQuePopup(false);
              }}
              className="small-btn-white cursor-pointer"
            >
              <T strProp="No" />
            </button>
          </div>
        </div>
      </ButtonModual>
    </>
  );
}

export default EditQuoteItem;




const EditQuoteItemDiv = ({ val, title, isLoading }) => {
  const { t } = useContext(Il8Context);
  return (
    <div className="display_column Edit_QuoteItemDiv">
      <div className="edit_item_title">
        {t(title)}
      </div>
      <div className="edit_item_description">
        {isLoading ? "Loading..." : val}
      </div>
    </div>
  )
}
