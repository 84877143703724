import React, { useImperativeHandle, useRef, useState } from "react";
import { forwardRef } from "react";
import {
    ERROR_RED_ICON,
    RIGHT_GREEN_ICON,
} from "../../assets";
import "../../styles/components/textinput.css";

/**
 *
 * @param {string} input_Title - use for display input title
 * @param {boolean} Required - use for specify that field required or not
 * @param {string} Placeholder - use for display placeholder
 * @param {string} value - use for display initial value
 * @param {function} onChange - use for call function while change value
 * @param {boolean} NoneImg - use for display image
 * @param {function} onBlue - use for call function while on blur
 * @param {boolean} isDisabled - use for display that unable or disable
 * @param {object} mainSty - use for style main field
 * @param {object} rComp - use for display selected sort filter item in modal
 * @param {object} inpSty - use for style inner input field
 * @param {referance} ref - use for display referance validation message
 * @param {number} Row - use for display row count
 * @param {number} Col - use for display column count
 * @returns text input field component and validation css
 */
const TextAreaInput = forwardRef(
    (
        {
            input_Title,
            Required,
            Placeholder,
            value,
            onChange,
            NoneImg,
            onBlue,
            isDisabled = false,
            mainSty = {},
            rComp,
            inpSty = {},
            Row,
            Col
        },
        ref
    ) => {
        const [showErrIc, setShowErrIc] = useState("");
        const [msg, setMsg] = useState("");
        const inpRef = useRef(null);
        const scrollRef = useRef(null);

        const inputBorder =
            showErrIc === undefined || showErrIc === ""
                ? "text-input-normal"
                : showErrIc === true
                    ? "text-input-error_"
                    : "text-input-success";
        const rightIc =
            showErrIc === undefined || showErrIc === ""
                ? ""
                : showErrIc === true
                    ? ERROR_RED_ICON
                    : RIGHT_GREEN_ICON;
        const errorMgs =
            showErrIc === undefined || showErrIc === ""
                ? ""
                : showErrIc === true
                    ? "text-input-error-title"
                    : "text-input-success-title";

        useImperativeHandle(ref, () => ({ handleInputBlur }));

        const handleInputBlur = (errMsg, msg, isFocus = false) => {
            if (errMsg === undefined || errMsg === "") {
                setMsg(undefined);
                setShowErrIc(undefined);
            }
            if (errMsg === true) {
                setMsg(msg);
                setShowErrIc(true);
                if (isFocus) {
                    window.scrollTo({
                        top: scrollRef?.current?.offsetTop - 115,
                        behavior: "smooth",
                    });
                    inpRef.current?.focus();
                }
            }
            if (errMsg === false) {
                setMsg(msg);
                setShowErrIc(false);
            }
        };
        return (
            <div className="text-input" style={mainSty} ref={scrollRef}>
                {input_Title && (
                    <span className="text-input__title">
                        {input_Title}
                        {Required && " *"}

                    </span>
                )}
                <div className="input-row">
                    <div className={`${inputBorder}`} style={inpSty}>
                        <textarea
                            ref={inpRef}
                            className="user-input-normal text-area-resize"
                            onBlur={onBlue}
                            placeholder={Placeholder}
                            value={value}
                            onChange={onChange}
                            disabled={isDisabled}
                            rows={Row}
                            cols={Col}
                        />
                        {NoneImg
                            ? null
                            : rightIc && <img src={`${rightIc}`} alt="Validation Icon" />}
                    </div>
                    {rComp}
                </div>
                {errorMgs && <div className={`${errorMgs}`}>{msg}</div>}
            </div>
        );
    }
);

export default TextAreaInput;
