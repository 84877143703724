import React from "react";
import { NO_FOUND_SM_IMG } from "../../../assets";
import { useNavigate } from "react-router-dom";
import "../../../styles/components/search/searchcard.css";
import { dateFormate } from "../../../utils";

/**
 * 
 * @param {string} Images - use for display news image
 * @param {string} Title - use for display news title
 * @param {string} Dates - use for display news date
 * @param {number} id - use for display news id
 * @param {object} item - use for pass news item
 * @param {number} selectedValue - use for check selected value
 * @returns this function can return card view of search news.
 */
function SearchNewsCard({ Images, Title, Dates, id, item, selectedValue }) {
  const navigate = useNavigate();

  return (
    <section
      className="search-news-card cursor-pointer"
      onClick={() => navigate(`/news_details/${id}`)}
    >
      {selectedValue === 0 ? (
        <div className="search-news-card-img">
          <img
            src={Images}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.style.objectFit = "cover";
              currentTarget.src = NO_FOUND_SM_IMG;
            }}
            alt="News"
          />
        </div>
      ) : null}
      <div className="searchnews-data1">
        <span className="search-news-card-title">{Title}</span>

        <span className="search-news-card-date">{dateFormate(Dates)}</span>
        <span className="search-news-card-summary">{item?.summary}</span>
      </div>
    </section>
  );
}

export default SearchNewsCard;
