const productListInitialState = {
    data: [], // ProductList array
    loading: true, // loading state
    searchTxt: '', // search term
    pageCount: 1, // default total number of pages
    pageNumb: 0, // default current page number
    hasMore: true, // default has more data
    tabIdx: 0, // default tab index for grid view and list view
    refreshing: false, // default refreshing state for pull to refresh
    filterArr: [], // default filter array
    loadingMore: false, // default loading more state for infinite scroll
    loadingFilter: true, // default loading state for filter
    checkedFilter: [], // default checked filter for drawer menu filters
    selectedFilter: [], // default selected sort & filter to render widget
    showSortby: false, // to show sort by modal/popup
    catId: '', // selected category id
    backMenuName: "", // basket Menu Name 
    /*--- -:Add to quote:-  ---*/
    showQuote: false, // to show Add to quote modal/popup
    openQuote: false, // to navigate to quote details screen
    quoteAdded: false, // to show success message after adding to quote
    loadingQuote: false, // to show loading state for add to quote
    showQuotesList: false, // to show quote list modal/popup
    selRef: 'new', // selected product reference
    addingToQuote: false, // to show loading state for add to quote
    prodCode: '', // selected product code
    prodQty: 1, // selected product quantity
    quotesList: [] // Quotes list array
};


/*--- -:Product Actions:-  ---*/
const prodAct = {
    SET_PRODUCT: "SET_PRODUCT",
    SET_LOADING: "SET_LOADING",
    SET_SEARCH: "SET_SEARCH",
    SET_PAGE_COUNT: "SET_PAGE_COUNT",
    SET_PAGE_NUMBER: "SET_PAGE_NUMBER",
    SET_HAS_MORE: "SET_HAS_MORE",
    SET_TAB_INDEX: "SET_TAB_INDEX",
    SET_REFRESHING: "SET_REFRESHING",
    SET_FILTER_ARR: "SET_FILTER_ARRAY",
    SET_LOADING_MORE: "SET_LOADING_MORE",
    SET_LOADING_FILTER: "SET_LOADING_FILTER",
    SET_CHECKED_FILTER: "SET_CHECKED_FILTER",
    SET_SELECTED_FILTER: "SET_SELECTED_FILTER",
    SET_SHOW_SORTBY: "SET_SHOW_SORTBY",
    SET_CAT_ID: "SET_CATEGORY_ID",
    SET_BACKMENU_NAME: "SET_BACKMENU_NAME",
    /*--- -:Add to quote:-  ---*/
    SET_SHOW_QUOTE: "SET_SHOW_QUOTE",
    SET_OPEN_QUOTE: "SET_OPEN_QUOTE",
    SET_QUOTE_ADDED: "SET_QUOTE_ADDED",
    SET_LOADING_QUOTE: "SET_LOADING_QUOTE",
    SET_SHOW_QUOTES_LIST: "SET_SHOW_QUOTES_LIST",
    SET_SEL_REF: "SET_SEL_REF",
    SET_ADDING_TO_QUOTE: "SET_ADDING_TO_QUOTE",
    SET_PROD_CODE: "SET_PRODUCT_CODE",
    SET_PROD_QTY: "SET_PRODUCT_QTY",
    SET_QUOTES_LIST: "SET_QUOTES_LIST",
};

const setProd = (data) => ({ "type": prodAct.SET_PRODUCT, data });
const setLoading = (loading) => ({ "type": prodAct.SET_LOADING, data: loading });
const setSearch = (searchTxt) => ({ "type": prodAct.SET_SEARCH, data: searchTxt });
const setPageCount = (pageCount) => ({ "type": prodAct.SET_PAGE_COUNT, data: pageCount });
const setPageNumber = (pageNumb) => ({ "type": prodAct.SET_PAGE_NUMBER, data: pageNumb });
const setHasMore = (hasMore) => ({ "type": prodAct.SET_HAS_MORE, data: hasMore });
const setTabIndex = (tabIdx) => ({ "type": prodAct.SET_TAB_INDEX, data: tabIdx });
const setRefreshing = (refreshing) => ({ "type": prodAct.SET_REFRESHING, data: refreshing });
const setFilterArr = (filterArr) => ({ "type": prodAct.SET_FILTER_ARR, data: filterArr });
const setLoadingMore = (loadingMore) => ({ "type": prodAct.SET_LOADING_MORE, data: loadingMore });
const setLoadingFilter = (loadingFilter) => ({ "type": prodAct.SET_LOADING_FILTER, data: loadingFilter });
const setCheckedFilter = (checkedFilter) => ({ "type": prodAct.SET_CHECKED_FILTER, data: checkedFilter });
const setSelectedFilter = (selectedFilter) => ({ "type": prodAct.SET_SELECTED_FILTER, data: selectedFilter });
const setShowSortby = (showSortby) => ({ "type": prodAct.SET_SHOW_SORTBY, data: showSortby });
const setCatId = (catId) => ({ "type": prodAct.SET_CAT_ID, data: catId });
const setBackMenuName = (name) => ({ "type": prodAct.SET_BACKMENU_NAME, backMenuName: name });
/*--- -:Add to quote:-  ---*/
const setShowQuote = (showQuote) => ({ "type": prodAct.SET_SHOW_QUOTE, data: showQuote });
const setOpenQuote = (openQuote) => ({ "type": prodAct.SET_OPEN_QUOTE, data: openQuote });
const setQuoteAdded = (quoteAdded) => ({ "type": prodAct.SET_QUOTE_ADDED, data: quoteAdded });
const setLoadingQuote = (loadingQuote) => ({ "type": prodAct.SET_LOADING_QUOTE, data: loadingQuote });
const setShowQuotesList = (showQuotesList) => ({ "type": prodAct.SET_SHOW_QUOTES_LIST, data: showQuotesList });
const setSelRef = (selRef) => ({ "type": prodAct.SET_SEL_REF, data: selRef });
const setAddingToQuote = (addingToQuote) => ({ "type": prodAct.SET_ADDING_TO_QUOTE, data: addingToQuote });
const setProdCode = (prodCode) => ({ "type": prodAct.SET_PROD_CODE, data: prodCode });
const setProdQty = (prodQty) => ({ "type": prodAct.SET_PROD_QTY, data: prodQty });
const setQuotesList = (quotesList) => ({ "type": prodAct.SET_QUOTES_LIST, data: quotesList });


const ProductReducer = (state = productListInitialState, action) => {
    switch (action.type) {
        case prodAct.SET_PRODUCT:
            return { ...state, data: action.data };
        case prodAct.SET_LOADING:
            return { ...state, loading: action.data };
        case prodAct.SET_SEARCH:
            return { ...state, searchTxt: action.data };
        case prodAct.SET_PAGE_COUNT:
            return { ...state, pageCount: action.data };
        case prodAct.SET_PAGE_NUMBER:
            return { ...state, pageNumb: action.data };
        case prodAct.SET_TAB_INDEX:
            return { ...state, tabIdx: action.data };
        case prodAct.SET_REFRESHING:
            return { ...state, refreshing: action.data };
        case prodAct.SET_FILTER_ARR:
            return { ...state, filterArr: action.data };
        case prodAct.SET_LOADING_MORE:
            return { ...state, loadingMore: action.data };
        case prodAct.SET_LOADING_FILTER:
            return { ...state, loadingFilter: action.data };
        case prodAct.SET_CHECKED_FILTER:
            return { ...state, checkedFilter: action.data };
        case prodAct.SET_SELECTED_FILTER:
            return { ...state, selectedFilter: action.data };
        case prodAct.SET_SHOW_SORTBY:
            return { ...state, showSortby: action.data };
        case prodAct.SET_CAT_ID:
            return { ...state, catId: action.data };
        case prodAct.SET_BACKMENU_NAME:
            return { ...state, backMenuName: action.data };
        /*--- -:Add to quote:-  ---*/
        case prodAct.SET_SHOW_QUOTE:
            return { ...state, showQuote: action.data };
        case prodAct.SET_OPEN_QUOTE:
            return { ...state, openQuote: action.data };
        case prodAct.SET_QUOTE_ADDED:
            return { ...state, quoteAdded: action.data };
        case prodAct.SET_LOADING_QUOTE:
            return { ...state, loadingQuote: action.data };
        case prodAct.SET_SHOW_QUOTES_LIST:
            return { ...state, showQuotesList: action.data };
        case prodAct.SET_SEL_REF:
            return { ...state, selRef: action.data };
        case prodAct.SET_ADDING_TO_QUOTE:
            return { ...state, addingToQuote: action.data };
        case prodAct.SET_PROD_CODE:
            return { ...state, prodCode: action.data };
        case prodAct.SET_PROD_QTY:
            return { ...state, prodQty: action.data };
        case prodAct.SET_QUOTES_LIST:
            return { ...state, quotesList: action.data };
        case prodAct.SET_HAS_MORE:
            return { ...state, hasMore: action.data };
        default:
            return state;
    }
};

export default ProductReducer;
export {
    productListInitialState, prodAct, setProd, setLoading, setSearch,
    setPageCount, setPageNumber, setTabIndex, setRefreshing, setFilterArr,
    setLoadingMore, setLoadingFilter, setCheckedFilter, setSelectedFilter,
    setShowSortby, setCatId, setShowQuote, setOpenQuote, setQuoteAdded,
    setLoadingQuote, setShowQuotesList, setSelRef, setAddingToQuote,
    setProdCode, setProdQty, setQuotesList, setHasMore, setBackMenuName
};