import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { NO_FOUND_IMG_M } from "../../../assets";
import { T } from "../../../components";
import { toPrice } from "../../../utils";
import { GlobalContext } from "../../../contexts";

/**
 *
 * @param {string} productCode - use for redirect to particular product page
 * @param {string} img - use for display product image
 * @param {number} price - use for display product price
 * @param {number} discountPercent - use for display product discount percentage
 * @returns this component return fetured product list.
 */
function FeaturedProducts({ productCode, img, price, discountPercent }) {
  const { state } = useContext(GlobalContext);
  const navigate = useNavigate();
  return (
    <>
      <section className="sub-menu-box-title">
        <div className="menu-heading-title">
          <span className="_title">
            <T strProp="homeFeaturedProducts" />
          </span>
        </div>
      </section>
      <section
        className="featured-products-card"
        onClick={() => {
          navigate(`/product/${productCode}`, {
            state: productCode,
          });
        }}
      >
        <div className="featured-products-card-img">
          <img
            src={img}
            alt="PI"
            className="default-img"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = NO_FOUND_IMG_M;
            }}
          />
        </div>
        <div className="featured-products-card-productcode">
          <span>{productCode}</span>
        </div>
        {state?.acObj?.paymentProvider.toLowerCase() === "paygate" ? <div style={{ marginTop: "10px" }} /> :
          <div className="featured-products-card-price">
            <span>
              {toPrice(price)}
              {` / Unit (with ${discountPercent} %) `}
            </span>
          </div>}
      </section>
    </>
  );
}

export default FeaturedProducts;
