import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { callApi } from '../services';
import { Il8Context, languageCode } from '../contexts/i18context';
import { useSessionHook } from '../hooks';
import { BackCard, Layout, PageLoader } from '../components';


function ContentPage() {
  const { pathname } = useLocation();
  let pathArr = pathname.split("/");
  // console.log("object", pathArr)
  const sessionHelpers = useSessionHook();
  const { token } = sessionHelpers.getStatusAndToken();
  const [title, setTitle] = useState("");
  const [headerTitle, setHeaderTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token === null) return;
    getPageContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, pathArr[1]]);

  const { t } = useContext(Il8Context);

  const getPageContent = async () => {
    let pageName = pathArr[1] === 'about-us' ? t("aboutUs") : pathArr[1] === 'term-and-conditions' ? "Our Terms" : pathArr[1] === 'privacy-policy' ? "Privacy Policy" : pathArr[1] === 'POPIA' ? "POPIA" : t("aboutUs");
    setLoading(true);
    await callApi("pages", { token }, pageName, languageCode)
      .then(({ res, sCode }) => {
        if (sCode === 200 || sCode === 202) {
          setTitle(res?.body?.title);
          setContent(res?.body?.content);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    switch (pathname) {
      case '/POPIA':
        setHeaderTitle(t('titlePOPIA'))
        break;
      case '/about-us':
        setHeaderTitle(t('aboutUs'))
        break;
      case '/term-and-conditions':
        setHeaderTitle(t('titleTearmsNcondition'))
        break;
      case '/privacy-policy':
        setHeaderTitle(t('titlePrivacyPolicy'))
        break;
      default:
        setHeaderTitle(t(''))
        break;
    }
    // eslint-disable-next-line
  }, [pathname])

  return (
    loading ? (
      <PageLoader isLoading isSmall />
    ) : <>
      <Layout title={headerTitle} translate={false}>
        <section>
          <BackCard mainClass={"back_nav-bg"} Path={-1} />
        </section>
        <div className='content-mp'>
          <h4>{title}</h4>
          <div dangerouslySetInnerHTML={{
            __html: `${content}`
          }}></div>
        </div>
      </Layout>
    </>

  )
}

export default ContentPage
