import { stockStatus } from "../utils/stockUtils";
import { IT, T } from "./translation";
import { stockStatuses } from "../utils/constants";

/**
 * 
 * @param {string} children - for stock message
 * @param {string} cssClass - for style calss
 * @param {string} cssLinkClass - for nested style
 * @returns display diffrent diffrent stock messages (ex - product list)
 */
const ContactFormLink = ({ children, cssClass, cssLinkClass }) => {
  return (
    <span className={cssClass}>
      <div className={cssLinkClass}>{children}</div>
    </span>
  );
};

/**
 * 
 * @param {string} quantityInStock - for display message
 * @param {object} additionalClass - for other style
 * @returns display diffrent diffrent stock details
 */
const StockPill = ({ quantityInStock, additionalClass }) => {
  const cssClasses = additionalClass
    ? `pill pill--stock ${additionalClass}`
    : "pill pill--stock";
  return (
    <p className={cssClasses}>
      <svg className="svg-in-stock">
        <use href="#svg-in-stock" />
      </svg>
      <IT value={quantityInStock} strProp="productStockInStock" />
    </p>
  );
};

/**
 * 
 * @param {number} quantity - for display quantity
 * @param {string} dueDateTimestamp - for display due date time
 * @param {object} cssClass - for other style
 * @param {boolean} contactLink - for whether display contact link or not
 * @returns display stock due items.
 */
const StockDueItem = ({
  quantity,
  dueDateTimestamp,
  cssClass,
  contactLink = true,
}) => {
  return (
    <div className="display_column">
      <span className="product_text">
        <IT
          value={quantity || ""}
          strProp="productStockDueStock"
          dateStr={dueDateTimestamp || ""}
        />
      </span>
      {contactLink && (
        <ContactFormLink cssLinkClass="product_text" cssClass={cssClass}>
          <T strProp="contactUsForMore" />
        </ContactFormLink>
      )}
    </div>
  );
};

/**
 * 
 * @param {object} cssClass - for other style
 * @param {string} strProp - for display title
 * @returns display out of stock item.
 */
const OutOfStock = ({ cssClass, strProp = "contactUs" }) => {
  return (
    <ContactFormLink cssLinkClass="link-small-and-black" cssClass={cssClass}>
      <T strProp={strProp} />
    </ContactFormLink>
  );
};

/**
 * 
 * @param {number} stock - for display stock
 * @param {object} cssClass - for other style
 * @param {boolean} pillOnly - for specific field base on that condition
 * @returns display Stock message.
 */
const StockMessage = ({ stock, cssClass, pillOnly = false }) => {
  const { status, quantityInStock, stockDue } = stockStatus(stock);

  if (status === stockStatuses.IN_STOCK_NO_MORE_DUE) {
    return (
      <div className="product-stock__col">
        <StockPill quantityInStock={quantityInStock} />{" "}
        {!pillOnly && <OutOfStock strProp="contactUsForMore" />}
      </div>
    );
  } else if (status === stockStatuses.IN_STOCK_MORE_DUE) {
    const { dueDateTimestamp, quantity } = stockDue[0];
    return (
      <div className="product-stock__col">
        <StockPill quantityInStock={quantityInStock} />
        {!pillOnly && (
          <StockDueItem
            quantity={quantity}
            dueDateTimestamp={dueDateTimestamp}
          />
        )}
      </div>
    );
  } else if (status === stockStatuses.OUT_OF_STOCK_MORE_DUE) {
    const { dueDateTimestamp, quantity } = stockDue[0];
    const secondStockDue = stockDue[1];

    return (
      <div className="product-stock__col">
        {!secondStockDue && <div className="empty-spacer"/>}
        <StockDueItem quantity={quantity} dueDateTimestamp={dueDateTimestamp} />
        {secondStockDue && (
          <StockDueItem
            quantity={secondStockDue.quantity}
            dueDateTimestamp={secondStockDue.dueDateTimestamp}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="product-stock__col">
        <div />
        <OutOfStock cssClass={cssClass} />
      </div>
    );
  }
};

export { StockMessage, StockPill, StockDueItem, OutOfStock };
