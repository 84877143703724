import React, { Fragment, useState } from "react";
import { MINUS_GRAY_ICON, PLUS_GRAY_ICON } from "../../assets";
import CheckBox from "../Modals/CheckBox";
import { useEffect } from "react";

/**
 * 
 * @param {string} Title - use for dipsplay filter name
 * @param {object} DataArr - use for pass all filter list
 * @param {function} onCheck - use for call function when click.
 * @param {object} selectedData - use for display selected item in filter
 * @param {object} selArr - use for pass all selected items list
 * @param {boolean} isNews - use for check whether new or not
 * @param {string} selKey - use for dynamic value key
 * @param {string} nameKey - use for dynamic key
 * side menu open on click filter menu (news,product ,etc...)
 * @returns check box ,filtername in filter section / jsx element.
 */
function CheckModual({
  Title,
  DataArr = [],
  onCheck,
  selectedData = [],
  selArr = [],
  isNews = false,
  selKey = "value",
  nameKey = "key"
}) {
  const [info, setInfo] = useState(false);
  const [sub, setSub] = useState(false);
  function handleOpen() {
    setInfo(!info);
  }
  const showSubCat = () => {
    if (selArr.length > 0) {
      selArr?.forEach((item) => {
        if (item?.title === "Categories") {
          setSub(true);
        }
      })
    } else setSub(false);
  };

  useEffect(() => {
    showSubCat();
    // eslint-disable-next-line
  }, [])

  return (
    <section className="newsfilter">
      <div className="filter-flex-padding">
        <div className="filter-flex-row-p" onClick={handleOpen}>
          <span>{Title}</span>
          {info ? (
            <img src={MINUS_GRAY_ICON} alt="Plus" />
          ) : (
            <img src={PLUS_GRAY_ICON} alt="Plus" />
          )}
        </div>
        {info === true ? (
          <div>
            {DataArr?.length === 0 ? (
              <Fragment>
                <div className="filter-data-no">No Data</div>
              </Fragment>
            ) : (
              DataArr.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <div className="check_modual" key={index}>
                      {isNews ? (
                        <Fragment>
                          <CheckBox
                            filter
                            onChange={() => onCheck(item, Title)}
                            label={item[nameKey] + ` (${item[selKey]})`}
                            checked={selectedData.find((data) => data[nameKey] === item[nameKey]) ? true : false}
                          />
                        </Fragment>
                      ) : sub &&
                        item?.items &&
                        item?.items?.length > 0 ? (
                        item?.items?.map((i, idx) => (
                          <Fragment key={idx}>
                            <CheckBox
                              filter
                              label={
                                i?.displayName +
                                (i?.count && ` (${i?.count})`) +
                                " " +
                                (selectedData[0]?.data?.subCatVal || "")
                              }
                              checked={
                                selectedData.find(
                                  (data) => data?.subCatVal === i?.value
                                )
                                  ? true
                                  : false
                              }
                              onChange={() => onCheck(item, Title || "", i)}
                            />
                            {item?.items?.length - 1 !== idx && (
                              <div
                                className="border__line_px check-module-ml"
                              />
                            )}
                          </Fragment>
                        ))
                      ) : (
                        <Fragment>
                          <CheckBox
                            filter
                            label={
                              item?.displayName +
                              (item?.count && ` (${item?.count})`)
                            }
                            checked={
                              selectedData.find(
                                (data) =>
                                  data?.title === Title &&
                                  data?.value === item?.value
                              )
                                ? true
                                : false
                            }
                            onChange={() => onCheck(item, Title)}
                          />
                        </Fragment>
                      )}
                    </div>
                    {DataArr.length - 1 === index ? null : (
                      <div className="border__line_px" />
                    )}
                  </Fragment>
                );
              })
            )}
          </div>
        ) : null}
        <div className="border__line_px" />
      </div>
    </section>
  );
}

export default CheckModual;
