import { useContext, useEffect, useState } from 'react';
import { useSessionHook } from './useSessionHook';
import { globalActionsTypes } from './globalReducer';
import { GlobalContext } from '../contexts';
import { callApi } from '../services';

const useDocumentLoader = (alertRef) => {
    const { state: { documentation }, dispatch } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const sessionHelpers = useSessionHook();
    const { token } = sessionHelpers.getStatusAndToken();

    useEffect(() => {
        async function getDocumentation() {
            await callApi("getDocumentation", { token, alertRef }).then(({ res: { body }, sCode }) => {
                if (sCode === (200 || 202)) {
                    setLoading(false); dispatch({ 'type': globalActionsTypes.setDocumentation, 'data': body });
                } else { setLoading(false) }
            }).catch(e => { console.log(e); setLoading(false); });
        };
        if (documentation.length === 0) { token && getDocumentation() } else (loading && setLoading(false));
        // eslint-disable-next-line
    }, [token]);
    return { loading, documentation };
};

export default useDocumentLoader;