import React from "react";
import {
  SEGMENTED_BLUE_IC,
  SEGMENTED_BLUE_ICON,
  SEGMENTED_GRAY_IC,
  SEGMENTED_GRAY_ICON,
} from "../../../assets";

/**
 * 
 * @param {referance} useRef - use for referance
 * @param {number} isGrid - base on grid count display image
 * @returns this function can display grid list segment
 */
const GridListSegment = ({ useRef, isGrid }) => {
  return [
    {
      Icon: (
        <img
          src={isGrid === 0 ? SEGMENTED_BLUE_ICON : SEGMENTED_GRAY_ICON}
          alt="icon"
        />
      ),
      value: 0,
      ref: useRef(),
    },
    {
      Icon: (
        <img
          src={isGrid === 1 ? SEGMENTED_BLUE_IC : SEGMENTED_GRAY_IC}
          alt="icon"
        />
      ),
      value: 1,
      ref: useRef(),
    },
  ];
};

export default GridListSegment;
