import React from "react";
import styledComponents from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { NO_FOUND_SM_IMG } from "../../assets";
import { useNavigate } from "react-router-dom";
import { WebsiteRegex } from "../../utils";

/**
 * imahe slider card used to slide image in home
 * @param {array} banner - banner aaray to display image in slider
 * @returns - slide image element
 */
const ImgSlider = ({ banner }) => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dotsClass: "slider_dots",
  };

  const navigate = useNavigate();
  const handleClick = (item) => {
    if(item?.urlAnchor){
      let isWeb = WebsiteRegex.test(item?.urlAnchor);
      if(isWeb){
        window.open(item?.urlAnchor, "_blank");
      }else{
        navigate(`${item?.urlAnchor}`);
      }
    }
  }

  return (
    <Carousel {...settings}>
      {banner?.map((item, index) => {
        return (
          <Wrap key={index}>
            <p className="banner-img">
              <img
                src={item?.image?.src}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.style.objectFit = "contain";
                  currentTarget.src = NO_FOUND_SM_IMG;
                }}
                alt="img"
                onClick={() => handleClick(item)}
                // onClick={() => window.open(item?.externalUrl, "_blank")}
              />
            </p>
          </Wrap>
        );
      })}
    </Carousel>
  );
};

const Carousel = styledComponents(Slider)``;

const Wrap = styledComponents.div`
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  p {
    cursor: pointer;
    display: block;
    position: relative;
    padding: 0;
    img {
      width: 100%;
    height: 100px;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 2%) 0px 10px 5px -5px,
      rgb(0 0 0 / 5%) 0px 10px 5px -5px;
    background-color:  transparent;
    }
  }
`;

export default ImgSlider;
