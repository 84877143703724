import { useEffect, useState } from 'react'
import { callApi } from '../services';

/**
 * this hook was used to get recent order list from api
 * @param  {string} token used to user authentication 
 * @param  {int} pageNo=0 used to pass pageno in pagination for api 
 * @param  {ref} alertRef used to error alert popup
 * @return - quote recentOrderData, loading ,next page call for quote api
 */
const useRecentOrder = (token, pageNo = 0, alertRef) => {
    const [recentOrderData, setRecentOrderData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasNextPage, setHasNextPage] = useState(false);

    /**
   * @returns this function can call api and get list of recent order list and set in recent order state.
   */
    const getRecentOrder = async () => {
        !loading && setLoading(true);
        const { res, sCode } = await callApi("getRecentOrder", { token, alertRef }, 365, pageNo + 1);
        if (res?.error) {
            setLoading(false);
        } else if (sCode === 401) { setHasNextPage(false); }
        else {
            if (sCode === 200 || sCode === 202) {
                let pCount = res?.body?.result?.pageCount;
                let pNum = res?.body?.result?.pageNumber;
                setHasNextPage(pCount > pNum);
                setRecentOrderData((p) => [...p, ...res?.body?.result?.items]);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (token === null) {
            setLoading(true);
            return;
        }
        getRecentOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, pageNo]);
    return { recentOrderData, loading, hasNextPage };
};

export { useRecentOrder }