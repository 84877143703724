import React, { useContext } from "react";
import { DOWNLOAD_BLUE_ICON, EMAIL_ORANGE_ICON } from "../../../../assets";
import { dateFormate } from "../../../../utils";
import { T } from "../../../translation";
import {toPriceObj } from "../../../../utils/functions";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../contexts";

/**
 *
 * @param {number} InvNo - use for invoice number
 * @param {number} ListNo - use for invoice product code
 * @param {string} ListName - use for invoice description
 * @param {string} ListDate - use for invoice date
 * @param {string} BillNo - use for display bill number
 * @param {string} BillPostingNo - use for bill post number
 * @param {number} ListValue - use for display list value
 * @param {number} ListVAT - use for display VAT
 * @param {number} ListGross - use for display gross amount
 * @param {number} ListRemaining - use for display remaining value
 * @param {string} currency - use for display currency type
 * @returns this function can get all parameter and display invoice details.
 */
function RecentInvoicesCard({
  id,
  InvNo,
  ListNo,
  ListName,
  ListDate,
  BillNo,
  BillPostingNo,
  ListValue,
  ListVAT,
  ListGross,
  ListRemaining,
  currency,
  invoiceDate
}) {
  const navigate = useNavigate();
  
  const {state:{acObj: { accountSummary: { creditLimit } }}} = useContext(GlobalContext);

  return (
    <section className="recent-invoices-card">
      <div className="recent-invoices-card-invno">
        <div className="_invoiceNo _col_orange" /* onClick={()=>{navigate(`../shipment_details/${id}`)}} */>
          {/* <span className="_InvNO">
            <T strProp="InvNO" />
          </span> */}
          <span className="_value">{id}</span>
        </div>
        <div className="recent-invoices-card-invno-img">
          <img src={EMAIL_ORANGE_ICON} alt="Email" />
          <img src={DOWNLOAD_BLUE_ICON} alt="Download" />
        </div>
      </div>
      <span className="recent-invoices-card-productcode">{ListNo}</span>
      <div className="recent-invoices-card-flex">
        <span className="recent-invoices-card-description">{ListName}</span>
        <span className="recent-invoices-card-Date">
          {dateFormate(ListDate)}
        </span>
      </div>
      <div className="recent-invoices-card-flex">
        <span className="recent-invoices-card-billno cursor-pointer _underline" onClick={()=>{navigate(`../recentorder/${BillNo}`)}}>{BillNo}</span>
        {/* <span className="recent-invoices-card-posting-no">
          <T strProp="PostinNo" /> {BillPostingNo}
        </span> */}
      </div>
      <div className="recent-invoices-card-flex-row">
        <div className="recent-invoices-card-title">
          <span className="recent-invoices-card-body">
            <T strProp="InvValue" />
          </span>
          <span className="__price-size">
            {toPriceObj(ListValue, creditLimit)}
          </span>
        </div>
        <div className="recent-invoices-card-title">
          <span className="recent-invoices-card-body">
            <T strProp="VAT" />
          </span>
          <span className="__price-size">
            {toPriceObj(ListVAT, creditLimit)}
          </span>
        </div>
        <div className="recent-invoices-card-title">
          <span className="recent-invoices-card-body">
            <T strProp="Gross" />
          </span>
          <span className="__price-size">
            {toPriceObj(ListGross, creditLimit)}
          </span>
        </div>
        <div className="recent-invoices-card-title">
          <span className="recent-invoices-card-body">
            <T strProp="Remaining" />
          </span>
          <span className="__price-size">
            {toPriceObj(ListRemaining, creditLimit)}
          </span>
        </div>
      </div>
    </section>
  );
}

export default RecentInvoicesCard;
