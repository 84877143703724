import React, { Fragment, useContext } from 'react'
import { getExpectedResultHAX_COLOR, toPrice } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../contexts';
import { T } from '../translation';
import { TRUCK_BLACK_ICON, TRUCK_GREEN_ICON } from '../../assets';
import { shipStatus } from '../../utils/functions';

function CurrentQuotesPageItems({ item }) {
    const { state: account } = useContext(GlobalContext);
    const navigate = useNavigate();
    return (
        item?.map((i, idx) => {
            let expected = getExpectedResultHAX_COLOR(i?.expected);
            return (
                <Fragment key={idx}>
                    {i?.productCode !== "DELIVERY" && (
                        <section
                            key={idx}
                            className="p-bottom-0 cursor-pointer"
                            onClick={() => navigate(`/product/${i?.productCode}`)}
                        >
                            <div className="current-quotes-card-flex">
                                <div className="current-quotes-card-productCode shipment_currentorder_truck">

                                    <span className="current-quotes-card__productCode shipment_currentorder_truck">
                                        {(i?.shipmentId === null && i?.shipmentStatus === null) ? null : (
                                            <img
                                                src={
                                                    shipStatus(i?.shipmentStatus)
                                                        ? TRUCK_GREEN_ICON
                                                        : TRUCK_BLACK_ICON
                                                }
                                                alt="Car"
                                                className="cursor-pointer con-creat-shipm-mr"
                                            />
                                        )}
                                        {i?.productCode || "--"}
                                    </span>
                                    <span className="current-quotes-card-price">
                                        {account.acObj?.accountSummary?.showDiscount === true ? toPrice(i?.grossPrice) : toPrice(i?.netPrice)}
                                    </span>
                                </div>
                                <div className="current-quotes-card___description">
                                    <p>{i?.description || "--"}</p>
                                </div>
                                <div className="current-quotes-card-quantity">
                                    <span>
                                        <T strProp="quantity" /> :{" "}
                                        {i?.quantity ? i?.quantity : "--"}
                                    </span>
                                    <div>
                                        <span className="current-quotes-card-instock" style={{ background: `${expected?.bgCol}`, color: `${expected?.color}` }}>{expected?.title || "--"}</span>
                                    </div>
                                </div>
                                {/* ---------------------------  */}
                                {/* // HIDE OR BYPASS TEMPORARY */}
                                {/* {OrderLocation ? (
                            <div className="current-quotes-card-location">
                              <img src={LOCATION_BLACK_ICON} alt="Location" />
                              <span className="current-quotes-card-location-">
                                Location :
                              </span>
                              <span className="current-quotes-card-location_name">
                                {OrderLocation}
                              </span>
                            </div>
                          ) : null} */}
                                {/* --------------------------- */}
                            </div>
                        </section>
                    )}
                    {item[idx + 1]?.productCode !== "DELIVERY" &&
                        item[idx + 1]?.productCode !== undefined && (
                            <div className="border-line-1px_10m" />
                        )}
                </Fragment>
            )
        })
    )
}

export default CurrentQuotesPageItems
