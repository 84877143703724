import React, { useState } from "react";
import { DELETE_RED_ICON, LOCATION_BLUE_ICON } from "../../assets";
import DeleteModal from "../DeleteModal";
import { T } from "../translation";

/**
 *
 * @param {string} Name - use for display quote name
 * @param {string} Dates - use for display quote date
 * @param {string} ItemName - use for display quote item description
 * @param {string} Location - use for display stock location name
 * @param {function} onClick - use for call function when click
 * @param {function} onClickDel  - use for call a function when click on delete image
 * @param {boolean} isDeleteing  - use for display loader
 * @param {function} fetchBasket  - use for call fetch basket details function
 * @returns this functiona can display quote details in card view.
 */
export default function QuotesCard({
  Name,
  Dates,
  ItemName,
  Location,
  onClick,
  onClickDel = () => { },
  isDeleteing = false,
  fetchBasket = () => { },
}) {
  const [delAlert, setDelAlert] = useState(false);

  return (
    <section className="quotes-card cursor-pointer">
      <div className="quotes-card-flex-col" onClick={onClick}>
        <div className="quotes-item">
          <span className="quotes-card-reference-code cursor-pointer">{Name || "--"}</span>
          <div className="quotes-card-dates">
            <span className="_createon">
              <T strProp="CreatedOn" />
            </span>
            {" : "}
            <span className="_date">{Dates || "--"}</span>
          </div>
        </div>
      </div>
      <div className="quotes-item-info">
        <section className="quotes-decription cursor-pointer" onClick={onClick}>
          <span>{ItemName || "--"}</span>
          {Location && (
            <div className="quotes-location">
              <img src={LOCATION_BLUE_ICON} alt="Location" />
              <span className="quotes-location-text">
                <T strProp="StockLocation" /> :{" "}
              </span>
              <span className="quotes-location__text">{Location || "--"}</span>
            </div>
          )}
        </section>
        <div className="delBtnBG cursor-pointer" onClick={() => setDelAlert(true)}>
          {isDeleteing === true ? (
            <div className="red-btn-loader" />
          ) : (
            <img src={DELETE_RED_ICON} alt="Del" />
          )}
        </div>
      </div>
      <DeleteModal
        visible={delAlert}
        onClose={setDelAlert}
        onClickDel={() => {
          setDelAlert(false);
          onClickDel();
        }}
        fetchBasket={fetchBasket}
        title="DeleteQt"
        subTitle="yousuredeleteQ"
      />
    </section>
  );
}
