import { useEffect, useRef, useState } from "react";

import { setCheckedFilter, setFilterArr, setLoading, setLoadingFilter, setLoadingMore, setPageCount, setPageNumber, setProd, setRefreshing, setSelectedFilter } from "../contexts";
import { SortByProduct } from "../fake-data";
import { getFilterQuery, getProdQuery } from "../pages/products/ProductGridList";
import { callApi } from '../services';

/**
 * this hook used to get planned shipment data from api 
 * @param  {string} token - for user authentication
 * @param  {string} dVal - for search terms
 * @param  {object} s - for pass multiple value
 * @param  {function} disp - for set state using reducer
 * @param  {ref} alertRef - used to hendal error alert popup
 * @param  {function} searchParam - used for get parameter from url base on key
 * @param  {function} setSearchParam - used for set parameter from url base on key
 * @returns - for set product filter, set url and call api for set product list and set filter array. 
 */
export const useProductGridListHook = ({ token, dVal, s, disp, alertRef, searchParam, setSearchParam }) => {
    const catId = searchParam?.get("CategoryId") || "";
    const subCatId = searchParam?.get("SubCategoryId") || "";
    const brandId = searchParam?.get("BrandId") || "";
    const marketId = searchParam?.get("Market") || "";
    const rangeId = searchParam?.get("Range") || "";
    const sortDesc = searchParam?.get("SortDesc") || "";
    const sortAsc = searchParam?.get("SortAsc") || "";
    // eslint-disable-next-line
    const [apiUri, setApiUri] = useState(null);
    let aUri = apiUri ?? searchParam.toString() ?? ``;
    const isMounted = useRef(true);

    const getProduct = (isRef = false, Url = "", fUrl = "", paging = false, cArr = [], isClear = false) => {
        let pNo = isRef ? 0 : s.pageNumb;
        setSearchParam(Url);
        if (Url.indexOf("SortAsc") === -1 && Url.indexOf("SortDesc") === -1) { Url ? Url += "&SortAsc=Price" : Url += "SortAsc=Price"; }
        let pUrl = `search?${Url}${Url ? '&' : ''}PageNumber=${pNo + 1}&ResultsPerPage=10`;
        let fUri = fUrl ? `?${fUrl}` : "";
        let opts = { token, alertRef };
        // const otherArr = [cArr];
        (isRef && disp(setLoading(true)));
        // setSearchParam(Url);
        !s.loadingFilter && disp(setLoadingFilter(true));
        if (paging) {
            callApi("productList", opts, pUrl).then(({ res, sCode }) => {
                updateProdData(res.body, sCode, isRef); /*--- -:PRODUCT DATA:- ---*/
            }).catch(e => console.log("errOR:", e)).finally(() => onOff())
        } else {
            disp(setProd([]));
            Promise.all([callApi("productList", opts, pUrl), callApi("Filters", opts, fUri)]).then(([{ res: pRes, sCode: pCode }, { res, sCode }]) => {
                updateFilterData(res.body, sCode, fUrl, cArr, isClear); /*--- -:FILER DATA:- ---*/
                updateProdData(pRes.body, pCode, isRef); /*--- -:PRODUCT DATA:- ---*/
            }).catch(e => console.log("errOR:", e)).finally(() => onOff())
        };
    };

    const updateProdData = (res, code, isRef) => {
        if (code === 200 || code === 202 /* && Object?.values(res?.result?.items)?.length > 0 */) {
            let newArr = Object.values(res?.result?.items);
            if (isRef) { disp(setProd(newArr)); }
            else { disp(setProd([...s.data, ...newArr])); }
            disp(setPageCount(res?.result?.pageCount)); disp(setPageNumber(res?.result?.pageNumber));
        } else if (code === 400) { disp(setProd([])); disp(setPageCount(0)); disp(setPageNumber(0)); }
        else { console.log("ERROR:", res); }
    }

    const updateFilterData = (res, code, fUrll, cArr, isClear) => {
        if (code === 200 || code === 202) {
            let itm = res?.result?.items || [];
            disp(setFilterArr(itm));
            var clone = [...s.selectedFilter];
            // we need to create a new array of selected filter based on the fUrll 
            // we are going to find categorie, subcategorie, brand, market, range from itm 
            // and then we are going to find the same in clone and then we are going to update the clone array
            // and then we are going to set the clone array to selectedFilter
            // lets start...
            if (isMounted.current === true) {
                let catIdx = itm.findIndex(i => i.displayName === "Categories");
                if (catIdx > -1) { // if found then set default selected category id
                    const idx = itm[catIdx]?.items?.findIndex(f => f?.value === catId);
                    if (idx !== -1) {
                        const obj = { ...itm[catIdx]?.items[idx] };
                        const idex = clone.findIndex(f => f?.value === catId);
                        let subCatData = { subCat: "", subCatVal: "", subCount: -1 };
                        if (subCatId !== "") {
                            const subIdx = obj?.items?.findIndex(f => f?.value === subCatId);
                            if (subIdx !== -1) {
                                subCatData = { subCat: obj?.items[subIdx]?.displayName, subCatVal: subCatId, subCount: obj?.items[subIdx]?.count };
                            } else { subCatData = { subCat: "", subCatVal: "", subCount: -1 }; }
                        };
                        let cloneObj = { 'value': obj.value, 'displayName': obj.displayName, 'count': obj?.count || 0, 'sortBy': false, 'title': "Categories", "subData": obj?.items || [], ...subCatData }
                        if (idex === -1) { clone?.push(cloneObj); }
                        else { clone[idex] = cloneObj; }
                    }
                };

                const findData = (arr, id, title) => {
                    const indx = arr.findIndex(i => i.displayName === title);
                    if (indx > -1) { // if found then set default selected category id
                        const idx = arr[indx]?.items?.findIndex(f => f?.value === id);
                        if (idx !== -1) {
                            const obj = { ...arr[indx]?.items[idx] };
                            const idex = clone.findIndex(f => f?.value === id);
                            let cloneObj = { 'value': obj.value, 'displayName': obj.displayName, 'count': obj?.count || 0, 'sortBy': false, 'title': title, "subData": [], "subCat": "", "subCatVal": "", "subCount": -1 }
                            if (idex === -1) { clone?.push(cloneObj); }
                            else { clone[idex] = cloneObj; }
                        };
                    };
                };

                findData(itm, brandId, "Brands");
                findData(itm, marketId, "Markets");
                findData(itm, rangeId, "Range");

                let sort = `${(sortDesc !== "" && "SortDesc=" + sortDesc) || (sortAsc !== "" && "SortAsc=" + sortAsc)}`;
                if (sortDesc || sortAsc) {
                    const sortIdx = SortByProduct.findIndex(f => f?.value === sort);
                    const idex = clone.findIndex((i) => i.sortBy === true);
                    if (sortIdx !== -1) {
                        const obj = { ...SortByProduct[sortIdx] };
                        // eslint-disable-next-line
                        let cloneObj = { 'value': obj.value, 'displayName': obj.displayName, 'count': obj?.count || 0, 'sortBy': true, 'title': "SortBy", "subData": [], "subCat": "", "subCatVal": "", "subCount": -1 }
                        // if (idex === -1) { clone?.push(cloneObj); }
                        // else { clone[idex] = cloneObj; }
                    } else { if (idex !== -1) { clone.splice(idex, 1); } }
                }
                disp(setSelectedFilter(clone)); disp(setCheckedFilter(clone));
                isMounted.current = false
            } // convert above code in a function that returns the clone array
            else {
                let parameter = 0;
                let updatedCount = "";
                let updatedItem = [];
                if (isClear) {
                    disp(setSelectedFilter(cArr));
                    disp(setCheckedFilter(cArr));
                } else if (cArr?.length > 0) {
                    cArr?.forEach((item, index) => {
                        let idx = itm?.findIndex((i) => i.displayName === item?.title);
                        if (idx !== -1) {
                            if (item?.title === 'Markets') {
                                let marketIdx = itm?.[2]?.items.findIndex((i) => i?.displayName === item?.displayName);
                                parameter = marketIdx;
                            } else {
                                parameter = 0;
                            }
                            if (item?.subCat !== "") {
                                updatedCount = itm[idx].items?.[parameter]?.items?.[0].count || 0;
                                updatedItem = { ...item, subCount: updatedCount };
                            } else {
                                updatedCount = itm[idx].items?.[parameter]?.count || 0;
                                updatedItem = { ...item, count: updatedCount };
                            }
                            cArr.splice(index, 1, updatedItem);
                        }
                    });
                    disp(setSelectedFilter(cArr));
                    disp(setCheckedFilter(cArr));
                } else {
                    clone = [...cArr];
                    disp(setSelectedFilter(clone));
                }
            }
        }
    };

    const onOff = () => {
        disp(setRefreshing(false)); disp(setLoadingMore(false)); disp(setLoading(false)); disp(setLoadingFilter(false));
    };

    useEffect(() => {
        if (token === null) return;
        let query = getProdQuery(s.selectedFilter);
        let fQuery = getFilterQuery(s.selectedFilter);
        let searchTrm = s.searchTxt?.trim();
        let searchUrl = dVal ? `${query?.length > 0 ? "&" : ``}SearchTerm=${searchTrm?.trim()}` : ``;
        let fSearchUrl = dVal ? `${fQuery?.length > 0 ? "&" : ``}SearchTerm=${searchTrm?.trim()}` : ``;
        let url = `${query?.length > 0 ? `${query}${searchUrl}` : `${searchUrl}`}`;
        let fUrl = `${fQuery?.length > 0 ? `${fQuery}${fSearchUrl}` : `${fSearchUrl}`}`;
        url = (isMounted.current /* && url === "" */) ? aUri || `` : url;
        // check is SortDesc available in url if yes then remove that with value 
        let tempUrlArray = url.split("&"); // Split url into an array of substrings
        for (let i = 0; i < tempUrlArray.length; i++) {
            if (tempUrlArray[i].includes("SortDesc")) {
                tempUrlArray.splice(i, 1); // Remove the SortDesc parameter from the array
            }
        }
        fUrl = tempUrlArray.join("&"); // Join the array of substrings back into a single string
        getProduct(true, url, fUrl, false, s.selectedFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dVal, token]);

    return { getProduct }
}