import TextInput from './modual/TextInput';
import useNewReturnHook from '../hooks/useNewReturnHook';
import { memo } from 'react';


const DynamicQuestions = ({
    index,
    validationIndex,
    title,
    trigger,
    value,
    onChange = () => { },
    placeholder }
) => {
    const { validationRef, questionValidation } = useNewReturnHook();
    if (trigger && (validationIndex === index)) {
        questionValidation(value, validationRef)
    }
    return (
        <div className="margin_bottom">
            <TextInput
                Required
                ref={validationRef}
                input_Title={title}
                Placeholder={placeholder}
                value={value}
                onChange={(e) => {
                    onChange(e)
                    questionValidation(e.target.value, validationRef);
                }}
            />
        </div>
    )
}
export default memo(DynamicQuestions)