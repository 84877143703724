import { accountObj } from "../contexts/globalContext";

const initialGlobalState = {
  basket: null,
  initialBasketFetch: false,
  initialBasketFetchError: null,
  basketLoading: false,
  productSortType: false,
  productViewType: "list",
  searchTerm: "",
  modalType: "",
  modalMessage: "",
  modalData: "",
  accountData: null,
  basketBadgeCount: 0,
  rootHistoryPath: "/",
  stockLocName: "",
  stockLocID: null,
  acObj: accountObj,
  countrys: [],
  loadingAcObj: true,
  scrollToItem: false,
  quoteItems:[],
  documentation:[]
};

const globalActionsTypes = {
  closeModal: "CLOSE_MODAL",
  initialBasketFetchedSuccess: "INITIAL_BASKET_FETCHED_SUCCESS",
  initialBasketFetchedFailure: "INITIAL_BASKET_FETCHED_FAILURE",
  basketLoading: "BASKET_LOADING",
  productAddedSuccess: "PRODUCT_ADDED_SUCCESS",
  productAddedError: "PRODUCT_ADDED_ERROR",
  setModal: "SET_MODAL",
  setProductViewType: "SET_PRODUCT_VIEW_TYPE",
  setProductSortType: "SET_PRODUCT_SORT_TYPE",
  updateSearchTerm: "UPDATE_SEARCH_TERM",
  setAccountInfo: "SET_ACCOUNT_INFO",
  setBasketBadge: "SET_BASKET_BADGE_COUNT",
  setRootHistoryPath: "SET_ROOT_HISTORY_PATH",
  setCountry: "SET_COUNTRY_LIST",
  setStockLoc: "SET_STOCK_LOC",
  setAccountObj: "SET_ACCOUNT_OBJECT",
  setLoadingAcObj: "SET_LOADING_ACCOUNT_OBJECT",
  setScrollToItem: "SET_SCROLL_TO_ITEM",
  setQuoteItems: "SET_QUOTE_ITEMS",
  setDocumentation: "SET_DOCUMENTATION"
};

/**
 * ONLY PUT TRULY GLOBAL STATE IN HERE NOT EVERY API CALL ETC
 * ONLY THINGS THAT ARE GOING TO BE RE-USED ON DIFFERENT PAGES
 * OR WHEN YOU RETURN TO A PAGE IE MENU OPEN ETC
 *
 * simple reducer at the moment but we might need to
 * make it a little more complicated with nested state or deeper state
 * objects
 */
function globalReducer(state, action) {
  switch (action.type) {
    case globalActionsTypes.setDocumentation:
      return {
        ...state,
        documentation: action.data,
      };
    case globalActionsTypes.setQuoteItems:
      return {
        ...state,
        quoteItems: action.data,
      };
    case globalActionsTypes.setBasketBadge:
      return {
        ...state,
        basketBadgeCount: action.data,
      };
    case globalActionsTypes.setRootHistoryPath:
      return {
        ...state,
        rootHistoryPath: action.data,
      };
    case globalActionsTypes.closeModal:
      return {
        ...state,
        modalType: "",
        modalMessage: "",
        modalData: "",
      };
    case globalActionsTypes.setModal:
      return {
        ...state,
        ...action.data,
      };

    case globalActionsTypes.updateSearchTerm:
      return {
        ...state,
        searchTerm: action.data.searchTerm,
      };

    case globalActionsTypes.productAddedSuccess:
      return {
        ...state,
        basket: action.data.result,
        mostRecentAction: action.type,
      };
    // just keep data the same
    case globalActionsTypes.productAddedError:
      return {
        ...state,
        mostRecentAction: action.type,
      };
    case globalActionsTypes.initialBasketFetchedSuccess:
      return {
        ...state,
        basket: action.data.result,
        initialBasketFetch: true,
        initialBasketFetchError: false,
        basketLoading: false,
      };
    case globalActionsTypes.initialBasketFetchedFailure:
      return {
        ...state,
        basket: null,
        initialBasketFetch: true,
        initialBasketFetchError: true,
        basketLoading: false,
      };

    case globalActionsTypes.basketLoading:
      return {
        ...state,
        basket: null,
        initialBasketFetch: false,
        initialBasketFetchError: false,
        basketLoading: true,
      };
    case globalActionsTypes.setProductViewType:
      return { ...state, productViewType: action.data, };
    case globalActionsTypes.setProductSortType: {
      const { productSortType } = state;
      let updatedProductSortType;
      if (!productSortType) {
        updatedProductSortType = "SortAsc";
      } else {
        updatedProductSortType =
          productSortType === "SortAsc" ? "SortDesc" : "SortAsc";
      }
      return { ...state, productSortType: updatedProductSortType, };
    }
    case globalActionsTypes.setStockLoc:
      return {
        ...state,
        stockLocName: action.data.description,
        stockLocID: action.data.id,
      };
    case globalActionsTypes.setAccountInfo:
      return { ...state, accountData: action.data, };
    case globalActionsTypes.setAccountObj:
      return { ...state, acObj: action.data, };
    case globalActionsTypes.setCountry:
      return { ...state, countrys: action.data, };
    case globalActionsTypes.setLoadingAcObj:
      return { ...state, loadingAcObj: action.data };
    case globalActionsTypes.setScrollToItem:
      return { ...state, scrollToItem: action.data }
    default:
      return state;
  }
}

export { globalReducer, initialGlobalState, globalActionsTypes };
