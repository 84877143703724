import React, { useCallback, useContext, useState } from "react";
import { useSessionHook, useDebounce } from "../../hooks";
import { GlobalContext, Il8Context } from "../../contexts";
import TextInput from "../modual/TextInput";
import { callApi } from "../../services";
import { T } from "../translation";
import ButtonModual from "../modual/ButtonModual";
import {
  CLOSE_WHITE_ICON,
  LEFT_ARROW_BLACK_ICON,
  RIGHT_ARROW_GRAY_ICON,
} from "../../assets";
import { useEffect } from "react";
import { PageLoader } from "../pageLoader";
import DropDown from "../DropDown";

/**
 *
 * @param {string} postCode - postCode value
 * @param {function} setPostCode - set postCode value in state
 * @param {boolean} loading - loading the component
 * @param {refrence} addressRef - handal to address dropdown validation warning
 * @param {string} town - town value
 * @param {function} setTwon - set town value in state
 * @param {refrence} contactRefP - handal to postcode validation warning
 * @param {string} street - street value
 * @param {function} setStreet - set street value in state
 * @param {string} organisation - organisation value
 * @param {function} setOrganisation - set organisation value in state
 * @param {refrence} contactRefS - handal to street validation warning
 * @param {refrence} contactRefT - handal to town validation warning
 * @param {string} InpCounty - county value
 * @param {function} setInpCounty - set county value in state
 * @param {function} setLine1 - set line1 value in state
 * @param {function} setLine2 - set line2 value in state
 * @param {function} setPostKey - set postkey value in state
 * @param {function} setSubDivCode - set subdivision code value in state
 * @param {function} setNorthing - set northing value in state
 * @param {function} setLocatedId - set located id value in state
 * @param {function} setEasting - set easting value in state
 * @param {function} setCountrySelection - Set country in country dropdown
 * @param {function} setCountryName - Set country name
 * @returns - postcode ,lookup button , organization field ,
 *  street field , address dropdown , town field , county field and validation
 */
const LookUp = ({
  postCode,
  setPostCode = () => { },
  loading = false,
  addressRef = null,
  town = "",
  setTwon = () => { },
  contactRefP = null,
  street = "",
  setStreet = () => { },
  organisation = "",
  setOrganisation = () => { },
  contactRefS = null,
  contactRefT = null,
  InpCounty = "",
  setInpCounty = () => { },
  setLine1 = () => { },
  setLine2 = () => { },
  setPostKey = () => { },
  setSubDivCode = () => { },
  setNorthing = () => { },
  setLocatedId = () => { },
  setEasting = () => { },
  setCountrySelection = () => { },
  setCountryName = () => { },
  isAddAddress = true
}) => {
  const { t } = useContext(Il8Context);
  const { state } = useContext(GlobalContext);
  const sessionHelpers = useSessionHook();
  const { token } = sessionHelpers.getStatusAndToken();
  const [lookupLoading, setLookupLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [knownAddLoading, setKnownAddLoading] = useState(false);
  const [lookUp, setLookUp] = useState(postCode);
  const [knownAddData, setknownAddData] = useState([]);
  const [knownAddDataDef, setKnownAddDataDef] = useState([]);
  const [selectKnownAdd, setSelectKnownAdd] = useState("");
  const [showSelectP, setShowSelectP] = useState(false);
  const [showSelectList, setShowSelectList] = useState(false);
  const [addressData, setAddressIdData] = useState([
    {
      description: "",
      highlight: "",
      id: "",
      text: "Select Address (Try 'Lookup' once)",
      type: "text",
    },
  ]);
  const [searchArr, setSearchArr] = useState([]);
  const dVal = useDebounce(lookUp, 800);
  /**
   * this function used to set street ,organization , town after selecting address
   * @param {string} value - of address dropdown input to user selected
   */
  const setAddressDetails = (value) => {
    if (value?.text?.includes(",")) {
      const result = value?.text?.split(",");
      setOrganisation(result[0] || "");
      const streetadd = result.filter((i, index) => {
        return index !== 0;
      });
      setStreet(streetadd ? streetadd?.join(",").trimStart() : "");
      streetValidation(
        streetadd ? streetadd?.join(",").trimStart() : "",
        contactRefS
      );
    } else {
      setStreet(value?.text);
      streetValidation(value?.text, contactRefS);
      setOrganisation("");
    }
    const des = value?.description?.split(",");
    setTwon(des ? des[0] : "");
    townValidation(des ? des[0] : "", contactRefT);
    postCodeValidation(des ? des[0] : "", contactRefP);
    getAddress(value?.id);
    setSearchArr([]);
    setAddressIdData([]);
    setShowSelectList(false);
    setShowSelectP(false);
  };

  /**
   *
   * @param {string} id Pass id as input
   * @returns Pass the id and base on api response set different feilds values
   */
  const getAddress = async (id) => {
    await callApi("addressFind", { token }, id)
      .then(({ res, sCode }) => {
        if (sCode === 200 || sCode === 202) {
          let i = res?.body?.result;
          setInpCounty(i?.county);
          setLine1(i?.line1);
          setLine2(i?.line2);
          setPostKey(i?.postKey);
          setSubDivCode(i?.subDivisionCode);
          setNorthing(i?.northing);
          setLocatedId(i?.loqateId);
          setEasting(i?.easting);
          setPostCode(i?.postCode);
          setCountryName(i?.country);
          setCountrySelection(i?.countryId);
        }
      })
      .catch((err) => console.log(err));
  };

  const getAddressList = async () => {
    !knownAddLoading && setKnownAddLoading(true);
    if (state.countrys.length === 0) return;
    const { res, sCode } = await callApi("getAddessList", { token });
    if (res?.error) {
      setKnownAddLoading(false);
    }
    else {
      if (sCode === 200 || sCode === 202) {
        let tempData = [...res?.body?.result];
        // sort by id desc
        tempData.sort((a, b) => b.id - a.id);
        setKnownAddDataDef(tempData);
        let arr = [{ id: "", name: t("SelectKnownAddressPlaceholder") }];
        tempData.forEach((item) => {
          // let countryName = state.countrys.find((e) => e.id === item?.countryId);
          let data = { id: item?.id, name: item?.description };
          // let data = { id: item?.id, name: item?.street + ", " + item?.town + ", " + item?.postCode + ", " + countryName?.name + "." };
          arr.push(data);
        })
        setknownAddData(arr);
        // setknownAddData(res?.body?.result);
      }
      setKnownAddLoading(false);
    }
  }

  useEffect(() => {
    if (token === "") return;
    isAddAddress && getAddressList();
    // eslint-disable-next-line
  }, [token, state.countrys]);

  const storeAddData = (obj) => {
    let addressId = obj?.id;
    let addressData = knownAddDataDef.find((e) => e.id === addressId);
    setLookUp(addressData?.postCode);
    setOrganisation(addressData?.organisation);
    setStreet(addressData?.street);
    setTwon(addressData?.town);
    setInpCounty(addressData?.county);
    setCountrySelection(addressData?.countryId);
    setPostCode(addressData?.postCode);
  }

  const knownAddress = useCallback(() => {
    return (
      <>
        <div className="margin_bottom">
          <DropDown
            // ref={}
            title={t("KnownAddress")}
            data={knownAddData}
            val={selectKnownAdd}
            placeholder={t("SelectKnownAddressPlaceholder")}
            nameKey="name"
            valKey="id"
            onChangeVal={(e) => { setSelectKnownAdd(e) }}
            onChangeObj={(obj) => { storeAddData(obj) }}
            isDisabled={knownAddData?.length === 0}
            isLoading={knownAddLoading}
          />
        </div>
      </>
    )
    // eslint-disable-next-line
  }, [knownAddData, knownAddLoading, state.countrys, selectKnownAdd])

  /**
   * @returns this function can return address (Lookup) field
   */
  const postcodeCall = useCallback(() => {
    return (
      <TextInput
        input_Title={t("address")}
        key={postCode}
        value={lookUp}
        onChange={(e) => {
          setLookUp(e.target.value);
        }}
        Placeholder={"Select Address (Try 'Lookup' once)"}
        NoneImg
        rComp={
          <button
            disabled={loading}
            className="confirm-order-lookup cursor-pointer"
            onClick={() => {
              setSearchArr([]);
              getAddressId();
              setShowSelectP(true);
            }}
          >
            {lookupLoading ? (
              <div className="lookup-loader" />
            ) : (
              <T strProp="quoteLookup" />
            )}
          </button>
        }
        inpSty={{ width: "100%" }}
      />
    );
    // eslint-disable-next-line
  }, [
    addressRef,
    loading,
    lookupLoading,
    organisation,
    postCode,
    lookUp,
    t,
    token,
    town,
    showSelectList,
    showSelectP,
  ]);

  /**
   *
   * @param {string} lookTerm value that set in address field and inner model search field
   * @returns that functiona can get lookup tems as input and get address list from api call.
   */
  const getAddressId = async (lookTerm = lookUp) => {
    if (lookTerm?.trim()?.length <= 0 || lookTerm?.trim()?.length === "") {
      // contactRefL.current?.handleInputBlur(true, "Please enter Address");
      setSearchArr([]);
      return;
    }
    // contactRefL.current?.handleInputBlur("", "");
    else !lookupLoading && setLookupLoading(true);
    if (lookTerm?.trim()?.length > 0 || lookTerm?.trim()?.length !== "") {
      await callApi("addressFindId", { token }, lookTerm)
        .then(({ res, sCode }) => {
          if (sCode === 200 || sCode === 202) {
            let itm = res?.body?.result?.items;
            setSearchArr(itm);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLookupLoading(false));
    }
  };

  useEffect(() => {
    if (showSelectP === false) {
      return;
    }
    getAddressId();
    // eslint-disable-next-line
  }, [dVal]);

  /**
   *
   * @param {string} lookupTerm Look up address term
   * @param {string} id Particular addresses id
   * @param {string} org organization name
   * @param {string} townn town name
   * @returns that function can fetch details base on term and particular if and give multiple address list
   */
  const getAddressIdList = async (lookupTerm, id, org, townn) => {
    !listLoading && setListLoading(true);
    await callApi("addressFindId", { token }, lookupTerm, id)
      .then(({ res, sCode }) => {
        if (sCode === 200 || sCode === 202) {
          let i = res?.body?.result?.items;
          setAddressIdData("");
          setAddressIdData([...i]);
          addressRef.current?.focusDropDown();
          setListLoading(false);
          if (i && i?.length > 0) {
            // eslint-disable-next-line no-unused-vars
            let idx =
              i?.findIndex(
                (ii) =>
                  ii.text.split(",")[0] === org &&
                  ii.description.split(",")[0] === townn
              ) || -1;
          }
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * @returns That functiona can return organization fields and streent fields.
   */
  const streetCall = useCallback(() => {
    return (
      <>
        <div className="margin_bottom">
          <TextInput
            input_Title={<T strProp="quoteOrganisation" />}
            Placeholder="Enter Organisation"
            value={organisation}
            onChange={(e) => setOrganisation(e.target.value)}
          />
        </div>
        <div className="margin_bottom">
          <TextInput
            ref={contactRefS}
            input_Title={t("quoteStreet")}
            Placeholder={t("EnterStreet")}
            Required
            value={street}
            onChange={(e) => {
              setStreet(e.target.value);
              streetValidation(e.target.value, contactRefS);
            }}
          />
        </div>
      </>
    );
  }, [contactRefS, organisation, setOrganisation, setStreet, street, t]);

  /**
   * @returns this function can return town field.
   */
  const townCall = useCallback(() => {
    return (
      <div className="mini-inputtext">
        <TextInput
          ref={contactRefT}
          validation_Mgs={t("PleaseAddyourTown")}
          input_Title={t("loginTown")}
          Required
          value={town}
          onChange={(e) => {
            setTwon(e.target.value);
            townValidation(e.target.value, contactRefT);
          }}
          Placeholder={t("EnterTown")}
          NoneImg
        />
      </div>
    );
  }, [contactRefT, setTwon, t, town]);

  return (
    <>
      {isAddAddress && <div>{knownAddress()}</div>}
      <div className="confirm-order-lookup-m">{postcodeCall()}</div>
      {streetCall()}
      <div className="confirm_dis_fx_mb">
        {townCall()}
        <div className="mini-inputtext">
          <TextInput
            input_Title={t("quoteCounty")}
            Placeholder={t("enterCounty")}
            value={InpCounty}
            onChange={(e) => setInpCounty(e.target.value)}
          />
        </div>
      </div>
      <div className="margin_bottom">
        <TextInput
          ref={contactRefP}
          input_Title={t("loginPostCode")}
          Required
          value={postCode}
          maxLength={15}
          onChange={(e) => {
            /*---  below regex allow Alphabets, "" empty string & numbers only,& also allow max 15 character length ---*/
            const regex = /^[\w- ]*$/;
            if (e.target.value === "" || regex.test(e.target.value)) {
              setPostCode(e.target.value);
              postCodeValidation(e.target.value, contactRefP);
            }
          }}
          Placeholder={t("EnterPostCode")}
          NoneImg
          inpSty={{ width: "100%" }}
        />
      </div>
      <ButtonModual Open={showSelectP}>
        {showSelectList ? (
          <section className="lookup-back-height">
            <div className="Sort-by">
              <div
                className="back__text cursor-pointer"
                onClick={() => {
                  setShowSelectList(false);
                  setAddressIdData([]);
                }}
              >
                <img
                  src={LEFT_ARROW_BLACK_ICON}
                  alt="CloseIcon"
                  className="cursor-pointer "
                />
                <span>
                  <T strProp="Back" />
                </span>
              </div>
              <div className="lookup-margin">
                <T strProp="quoteSelectAddress" />
              </div>
              <img
                src={CLOSE_WHITE_ICON}
                alt="CloseIcon"
                className="cursor-pointer"
                onClick={() => {
                  setShowSelectP(false);
                  setShowSelectList(false);
                }}
              />
            </div>
            {listLoading ? (
              <PageLoader isSmall isLoading />
            ) : (
              <div className="lookup_list_card">
                <div className="lookup_list_scroll_">
                  {addressData.map((item, index) => {
                    let countryName = item?.id.split("|");
                    return (
                      <section
                        key={index}
                        className="padding10"
                        onClick={() => setAddressDetails(item)}
                      >
                        <div className="modual">
                          <div className="Sort-by-flex cursor-pointer">
                            <span>
                              {countryName[0]}
                              {" - "}
                              {item?.text}
                            </span>
                          </div>
                        </div>
                        {addressData?.length > index + 1 ? (
                          <div className="border__line_px" />
                        ) : null}
                      </section>
                    );
                  })}
                </div>
              </div>
            )}
          </section>
        ) : (
          <section className="lookup-height">
            <div className="Sort-by">
              <div className="add-item-model">
                <T strProp="quoteSelectAddress" />
              </div>
              <img
                src={CLOSE_WHITE_ICON}
                alt="CloseIcon"
                className="cursor-pointer"
                onClick={() => setShowSelectP(false)}
              />
            </div>
            <div className="add-item-padding-10">
              <TextInput
                Required
                value={lookUp}
                onChange={(e) => {
                  setLookUp(e.target.value);
                }}
                Placeholder={t("quoteEnterAddress")}
                NoneImg
                maxLength="255"
                inpSty={{ width: "100%" }}
                ClearValue={() => setLookUp("")}
              />
            </div>
            {lookupLoading ? (
              <PageLoader isSmall isLoading />
            ) : (
              <div className="lookup_list_scroll">
                {searchArr?.length > 0
                  ? searchArr?.map((item, index) => {
                    let countryName = item?.id?.split("|");
                    return (item?.type === "Postcode" ||
                      item?.type === "BuildingNumber" ||
                      item?.type === "Street" ||
                      item?.type === "District") ? (
                      <section
                        key={index}
                        className="padding10"
                        onClick={() => {
                          getAddressIdList(item?.text, item?.id);
                          setShowSelectList(true);
                        }}
                      >
                        <div className="modual">
                          <div className="Sort-by-flex cursor-pointer">
                            <>
                              <span>
                                {countryName[0]}
                                {" - "}
                                {item?.description}
                              </span>
                              <img
                                src={RIGHT_ARROW_GRAY_ICON}
                                alt="check"
                              />
                            </>
                          </div>
                        </div>
                        {searchArr?.length > index + 1 ? (
                          <div className="border__line_px" />
                        ) : null}
                      </section>
                    ) : item?.type === "Address" && (
                      <section
                        key={index}
                        className="padding10"
                        onClick={() => setAddressDetails(item)}
                      >
                        <div className="modual">
                          <div className="Sort-by-flex cursor-pointer">
                            <span>
                              {countryName[0]}
                              {" - "}
                              {item?.text}
                            </span>
                          </div>
                        </div>
                        {searchArr?.length > index + 1 ? (
                          <div className="border__line_px" />
                        ) : null}
                      </section>
                    );
                  }) : !lookupLoading && (
                    <p className="no-data-found-sm">
                      <T strProp="noDataFound" />
                    </p>
                  )}
              </div>
            )}
          </section>
        )}
      </ButtonModual>
    </>
  );
};

export default LookUp;

/**
 * @param {string} value - user input value of street
 * @param {refrence} ref - handal to validation
 * @returns -  bollean (validate = true , not validate = false)
 */
export const streetValidation = (value, ref) => {
  if (value?.trim().length <= 0) {
    ref.current?.handleInputBlur(true, <T strProp="PleaseEnterStreet" />, true);
    return false;
  } else {
    ref.current?.handleInputBlur("", "");
    return true;
  }
};

/**
 * @param {string} value - user input value of town
 * @param {refrence} ref - handal to validation
 * @returns - bollean (validate = true , not validate = false)
 */
export const townValidation = (value, ref) => {
  if (value?.trim()?.length <= 0) {
    ref.current?.handleInputBlur(true, <T strProp="PleaseEnterTown" />, true);
    return false;
  } else {
    ref.current?.handleInputBlur("", "");
    return true;
  }
};

/**
 * @param {string} value - user input value of postcode
 * @param {refrence} ref - handal to validation
 * @returns - bollean (validate = true , not validate = false)
 */
export const postCodeValidation = (value, ref) => {
  if (value?.trim().length <= 0) {
    ref.current?.handleInputBlur(
      true,
      <T strProp="PleaseEnterPostCode" />,
      true
    );
    return false;
  } else {
    ref.current?.handleInputBlur("", "");
    return true;
  }
};
