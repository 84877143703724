import React, { useImperativeHandle, useRef, useState } from "react";
import { forwardRef } from "react";
import {
  DOWN_ARROW_ICON,
  ERROR_RED_ICON,
  INFO_FILL_ICON,
  RIGHT_GREEN_ICON,
  SEARCH_CLEAR_ICON,
} from "../../assets";
import "../../styles/components/textinput.css";

/**
 *
 * @param {string} input_Title - use for display input title
 * @param {boolean} Required - use for specify that field required or not
 * @param {string} Placeholder - use for display placeholder
 * @param {string} value - use for display initial value
 * @param {function} onChange - use for call function while change value
 * @param {boolean} NoneImg - use for display image
 * @param {function} onBlue - use for call function while on blur
 * @param {boolean} isDisabled - use for display that unable or disable
 * @param {object} mainSty - use for style main field
 * @param {string} keyBoard_Type - use for specify input type attribute
 * @param {number} maxLength - use for specify maximum length of input character
 * @param {object} rComp - use for display selected sort filter item in modal
 * @param {object} inpSty - use for style inner input field
 * @param {number} max - use for specify max value of input field
 * @param {number} min - use for specify min value of input field
 * @param {function} ClearValue - use for clear input field value
 * @param {boolean} CoutactUs - use for display field base condition
 * @param {function} InfoClick - use for perform action when click on image
 * @param {boolean} isLoading - use for display loader
 * @param {boolean} dropdownArrow - use for display dropdown arrow
 * @param {referance} ref - use for display referance validation message
 * @returns text input field component and validation css
 */
const TextInput = forwardRef(
  (
    {
      accept,
      input_Title,
      Required,
      Placeholder,
      value,
      onChange,
      NoneImg,
      onBlue,
      isDisabled = false,
      mainSty = {},
      keyBoard_Type,
      maxLength,
      rComp,
      inpSty = {},
      max,
      min,
      ClearValue,
      CoutactUs = false,
      InfoClick,
      isLoading = false,
      dropdownArrow = false,
      dropDown = false
    },
    ref
  ) => {
    const [showErrIc, setShowErrIc] = useState("");
    const [msg, setMsg] = useState("");
    const inpRef = useRef(null);
    const scrollRef = useRef(null);

    const inputBorder =
      showErrIc === undefined || showErrIc === ""
        ? "text-input-normal"
        : showErrIc === true
          ? "text-input-error"
          : "text-input-success";
    const rightIc =
      showErrIc === undefined || showErrIc === ""
        ? ""
        : showErrIc === true
          ? ERROR_RED_ICON
          : RIGHT_GREEN_ICON;
    const errorMgs =
      showErrIc === undefined || showErrIc === ""
        ? ""
        : showErrIc === true
          ? "text-input-error-title"
          : "text-input-success-title";

    useImperativeHandle(ref, () => ({ handleInputBlur }));

    const handleInputBlur = (errMsg, msg, isFocus = false) => {
      if (errMsg === undefined || errMsg === "") {
        setMsg(undefined);
        setShowErrIc(undefined);
      }
      if (errMsg === true) {
        setMsg(msg);
        setShowErrIc(true);
        if (isFocus) {
          window.scrollTo({
            top: scrollRef?.current?.offsetTop - 115,
            behavior: "smooth",
          });
          inpRef.current?.focus();
        }
      }
      if (errMsg === false) {
        setMsg(msg);
        setShowErrIc(false);
      }
    };
    return (
      <div className="text-input" style={mainSty} ref={scrollRef}>
        {input_Title && (
          <span className="text-input__title">
            {input_Title}
            {Required && " *"}
            {CoutactUs && (
              <img
                src={INFO_FILL_ICON}
                alt="icon"
                onClick={InfoClick}
                className="cursor-pointer text-input-mb"
              />
            )}
          </span>
        )}
        <div className="input-row">
          <div className={`${inputBorder}`} style={inpSty}  >
            {dropDown ?
              <button id="button__">
                <span>
                  {isLoading ? <span>{Placeholder}</span> : value.length < 1 ? Placeholder : value}
                </span>
              </button>
              :
              <input
                ref={inpRef}
                type={keyBoard_Type || "text"}
                className="user-input-normal"
                onBlur={onBlue}
                placeholder={Placeholder}
                value={value ? value : ''}
                onChange={onChange}
                disabled={isDisabled}
                maxLength={maxLength}
                max={max}
                min={min}
                accept={accept}
              />}
            {NoneImg
              ? null
              : rightIc && <img src={`${rightIc}`} alt="Validation Icon" />}
            {ClearValue && value?.length > 0 && (
              <img
                /**
                 * clear search value icon
                 */
                src={SEARCH_CLEAR_ICON}
                alt="Search"
                className="edit-shipment-other-item-mr"
                onClick={ClearValue}
              />
            )}
            {isLoading && (
              <div className="dropdown-blue-loader text-input-mr" />
            )}
            {rightIc
              ? null
              : dropdownArrow && (
                <DOWN_ARROW_ICON
                  style={{ marginRight: "10px", backgroundColor: "#fff" }}
                />
              )}
          </div>
          {rComp}
        </div>
        {errorMgs && <div className={`${errorMgs}`}>{msg}</div>}
      </div>
    );
  }
);

export default TextInput;