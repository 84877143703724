import { languageCode } from '../contexts/i18context';
import { callApi } from '../services';
/**
 * this function used to call all documentation related apis
 * @param  {string} token - for user authentication
 * @param  {refrence} alertRef - for hendal error or wrning from api
 * @param  {string} inpKey - pass api key to call (ex. "case-studies","back-to-basics",etc..)
 * @returns - api response 
 */
const documentationApi = async (token, alertRef, inpKey) => {
    const { res, sCode } = await callApi("pageDocumentation", { token, alertRef }, inpKey, languageCode)
    if (sCode === 200 || sCode === 202) return res?.body
}

export default documentationApi