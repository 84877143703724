import React from "react";
import "../styles/components/btnsmall.css";

/**
 * 
 * @param {String} Title - use for display button title
 * @param {boolean} primary - use for specify button style or loader style like primary, info, warninf etc...
 * @param {boolean} secondary - use for specify button style like primary, info, warninf etc...
 * @param {function} OnClickBtn - use for call function click on that button
 * @param {boolean} white - use for specify button style or loader style like primary, info, warninf etc...
 * @param {boolean} gray - use for specify button style or loader style like primary, info, warninf etc...
 * @param {boolean} isLoading - use for specify that display loader over button or not
 * @param {Object} sty - use for style button
 * @returns small button component / jsx.
 */
function BtnSmall({
  Title,
  primary,
  secondary,
  OnClickBtn,
  white,
  gray,
  isLoading,
  sty,
}) {
  const btnClass = primary
    ? "primary-btn-small"
    : secondary
    ? "secondary-btn-small"
    : white
    ? "white-btn-small"
    : gray
    ? "gray-btn-small"
    : "primary-btn-small";
  const btnloader =
    primary || secondary
      ? "white-btn-loader"
      : white || gray
      ? "blue-btn-loader"
      : "blue-btn-loader";
  return (
    <button
      onClick={OnClickBtn}
      className={`${btnClass} btn-small`}
      disabled={isLoading}
      style={sty}
    >
      {isLoading ? <div className={btnloader}/> : Title}
    </button>
  );
}

export default BtnSmall;
