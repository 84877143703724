import "../../styles/components/checkBox.css";

/**
* 
* @param {boolean} filter - use for display title
* @param {string} textClass - for add dynamic style in checkbox
* @param {string} label - display label over checkbox
* @param {string} checked - display whether checkbox checked or unchacked
* @param {function} onChange - use for call function when on change check box from checked or unchecked
* @returns  display check box
*/
function Checkbox({ topValue, filter, textClass, label, checked, onChange, newReturnList }) {
  return (
    <div className={newReturnList ? "Check_box_pm_" : "Check_box_pm"}>
      <label className={filter ? "check_label_filter" : newReturnList ? "check_label_defult_" : "check_label_defult"}>
        {label && <span className={textClass || "text_defult"}>{label}</span>}
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          name={label}
          value={label}
        />
        <span
          style={{ top: `${topValue}` }}
          className={filter ? "check_mark_filter" : "check_mark_defult"}
        ></span>
      </label>
    </div>
  );
}

export default Checkbox;
