export const StatementData = [
  {
    No: 730974,
    Dates: "2022/07/01",
    Type: "I",
    Email: "Email Invoice",
    Price: 768.35,
    currency: "Gbp",
    TotalPrice: 8351.33,
  },
  {
    No: 730974,
    Dates: "2022/07/01",
    Type: "I",
    Email: "Email Invoice",
    Price: 768.35,
    currency: "Gbp",
    TotalPrice: 8351.33,
  },
  {
    No: 730974,
    Dates: "2022/07/01",
    Type: "I",
    Email: "Email Invoice",
    Price: 768.35,
    currency: "Gbp",
    TotalPrice: 8351.33,
  },
  {
    No: 730974,
    Dates: "2022/07/01",
    Type: "I",
    Email: "Email Invoice",
    Price: 768.35,
    currency: "Gbp",
    TotalPrice: 8351.33,
  },
  {
    No: 730974,
    Dates: "2022/07/01",
    Type: "I",
    Email: "Email Invoice",
    Price: 768.35,
    currency: "Gbp",
    TotalPrice: 8351.33,
  },
];
