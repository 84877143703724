/**
 * files for the endpoint urls
 * also returns additional info for the fetch
 * and an optional callback to filter/manipulate the data
 */

const base = process.env.REACT_APP_ORDERING_API_URL;
const baseV1 = process.env.REACT_APP_CONTENT_API_URL;
const urls = {

  getLineItems: (query) => {
    return {
      url: `${base}/api/v2/Returns/shipmentWithItems${query}`,
      opts: {
        method: "GET",
      }
    }
  },

  createReturn: (returnObject) => {
    return {
      url: `${base}/api/v2/Returns`,
      opts: {
        method: "POST",
        body: returnObject,
      },
      additionHeaders: {
        "Content-Type": "application/json",
      },
    }
  },

  uploadImage: (lineItemId, imageData) => {
    let formdata = new FormData();
    formdata.append('file', imageData);
    return {
      url: `${base}/api/v2/Returns/uploadImage/${lineItemId}`,
      opts: {
        method: "POST",
        body: formdata,
      },
    }
  },

  shipmentAvailableToReturn: (query) => {
    return {
      url: `${base}/api/v2/Returns/shipments${query}`,
      opts: {
        method: "GET"
      }
    }
  },

  shipmentAvailableToReturnForSerialNo: (query) => {
    return {
      url: `${base}/api/v2/Returns/shipments/SerialNumber${query}`,
      opts: {
        method: "GET"
      }
    }
  },

  getReturnQuestions: (productType) => {
    return {
      url: `${base}/api/v2/Returns/questions?productType=${productType}`,
      opts: {
        method: "GET"
      }
    }
  },

  getProductSelection: () => {
    return {
      url: `${base}/api/v2/Returns/producttype`,
      opts: {
        method: "GET"
      }
    }
  },

  getConfirmationDesc: () => {
    return {
      url: `${baseV1}/api/v1/Page/returns`,
      opts: {
        method: "GET"
      }
    }
  },

  getNewReturnReason: () => {

    return {
      url: `${base}/api/v2/Returns/reasons`,
      opts: {
        method: "GET"
      }
    }

  },

  setenableTandCPrompt: () => {
    return {
      url: `${base}/api/v2/Account/viewedDialog?dialogType=SeenTandCPrompt`,
      opts: {
        method: "POST"
      }
    }
  },

  getDocumentationDetails: (page) => {
    return {
      url: `${baseV1}/api/v1/Page/documentation/${page}`,
      opts: {
        method: "GET",
      },
    }
  },

  getDocumentation: () => {
    return {
      url: `${baseV1}/api/v1/Page/documentation`,
      opts: {
        method: "GET",
      },
    }
  },


  // gets all items in authenticated users basket
  basket: () => {
    return {
      url: `${base}/api/v2/Basket`,
      opts: {
        method: "GET",
      },
    };
  },

  getAddessList: () => {
    return {
      url: `${base}/api/v2/Account/addressbook`,
      opts: {
        method: "GET",
      },
    };
  },

  getRecentReturns: (pgNo) => {
    return {
      url: `${base}/api/v2/Returns?pageNumber=${pgNo}&resultsPerPage=10`,
      opts: {
        method: "GET",
      },
    };
  },

  getRecentOrder: (overNumOfDay, pgNo) => {
    return {
      url: `${base}/api/v2/Order/recent/${overNumOfDay}?pageNumber=${pgNo}&resultsPerPage=10`,
      opts: {
        method: "GET",
      },
    };
  },

  getRecentInvoice: (months = 6) => {
    return {
      url: `${base}/api/v2/Invoice/recent?months=${months}`,
      opts: {
        method: "GET",
      }
    }
  },

  getRecentShipments: (pgNo) => {
    return {
      url: `${base}/api/v2/Shipment/recent?pageNumber=${pgNo}&resultsPerPage=10`,
      opts: {
        method: "GET",
      },
    };
  },


  addAddressBook: (data) => {
    return {
      url: `${base}/api/v2/Account/addressbook`,
      opts: {
        method: "POST",
        body: JSON.stringify(data),
      },
      additionHeaders: {
        "Content-Type": "application/json",
      },
    };
  },

  deleteQuoteItem: (quoteCode, itemId) => {
    return {
      url: `${base}/api/v2/Quote/${quoteCode}/item/${itemId}`,
      opts: {
        method: "DELETE",
      },
      additionHeaders: {
        "Content-Type": "application/json",
      },
    };
  },
  // not implemented yet might be different
  deleteBasket: (basketNo) => {
    return {
      url: `${base}/api/v2/Basket/${basketNo}`,
      opts: {
        method: "DELETE",
      },
    };
  },
  deleteQuote: (quoteCode) => {
    return {
      url: `${base}/api/v2/Quote/${quoteCode}`,
      opts: {
        method: "DELETE",
      },
    };
  },

  // adds an item to the users basket
  basketUpdate: (bodyData) => {
    return {
      url: `${base}/api/v2/Basket/item`,
      opts: {
        method: "POST",
        body: bodyData,
      },
      additionHeaders: {
        "Content-Type": "application/json",
        // "X-Originator": "MOBILESITE",
      },
    };
  },
  search: (searchValue, pageNo, sortBy, perPage = 5) => {
    return {
      url: `${base}/api/v2/Product/search?${searchValue ? searchValue : ""
        }&pageNumber=${pageNo}&resultsPerPage=${perPage}${sortBy ? sortBy : ""}`,
      opts: {
        method: "GET",
      },
      callback: (items) => {
        return Object.values(items);
      },
    };
  },
  categories: () => {
    return {
      url: `${base}/api/v2/ProductCategory`,
      opts: {
        method: "GET",
      },
    };
  },
  manufacturerCategory: () => {
    return {
      url: `${base}/api/v2/ProductManufacturer`,
      opts: {
        method: "GET",
      },
    };
  },
  // gets product details
  productNo: (productNo) => {
    return {
      url: `${base}/api/v2/Product?productCodes=${productNo}&includeSpecification=true&includeAlternates=true&includeDependencies=true`,
      opts: {
        method: "GET",
      },
    };
  },
  filters: (queryString = "") => {
    return {
      url: `${base}/api/v2/Filter${queryString}`,
      opts: {
        method: "GET",
      },
    };
  },
  shipmentsDueForDispatch: () => {
    return {
      url: `${base}/api/v2/Shipment/planned?pageNumber=1&resultsPerPage=3`,
      opts: {
        method: "GET",
      },
    };
  },
  quote: (quoteReference) => {
    return {
      url: `${base}/api/v2/quote?quoteCodes=${quoteReference}`,
      opts: {
        method: "GET",
      },
    };
  },
  // not sure that this is correct?
  // we need to product no that is to be removed?
  updateQuote: (quoteReference, data) => {
    return {
      url: `${base}/api/v2/quote/${quoteReference}/items`,
      opts: {
        method: "PATCH",
        body: JSON.stringify(data),
      },
    };
  },

  newQuote: (data) => {
    const { productCode, quantity } = data;
    return {
      url: `${base}/api/v2/Quote/item`,
      opts: {
        method: "POST",
        body: JSON.stringify({
          designItem: {
            productCode,
            quantity,
          },
        }),
      },
      additionHeaders: {
        "Content-Type": "application/json",
      },
    };
  },

  addItemToQuote: (data) => {
    const { quoteCode, productCode, quantity } = data;
    return {
      url: `${base}/api/v2/Quote/${quoteCode}/item`,
      opts: {
        method: "POST",
        body: JSON.stringify({
          designItem: {
            productCode,
            quantity,
          },
        }),
      },
      additionHeaders: {
        "Content-Type": "application/json",
      },
    };
  },
  getExistingQuotes: (pgNo) => {
    return {
      url: `${base}/api/v2/Quote?resultsPerPage=15&pageNumber=${pgNo}`,
      opts: {
        method: "GET",
      },
    };
  },
  projectIssue: (projectId) => {
    return {
      url: `${base}/api/v2/ProjectIssue?projectId=${projectId}`,
      opts: {
        method: "GET",
      },
    };
  },
  deliveryMethodPreference: () => {
    return {
      url: `${base}/api/v2/DeliveryMethodPreference`,
      opts: {
        method: "GET",
      },
    };
  },
  countries: () => {
    return {
      url: `${base}/api/v2/Country`,
      opts: {
        method: "GET",
      },
    };
  },
  confirmOrder: (quoteOrderId, data) => {
    return {
      url: `${base}/api/v2/Order/${String(quoteOrderId).toString()}/confirm`,
      opts: {
        method: "POST",
        body: JSON.stringify(data),
      },
      additionHeaders: {
        "Content-Type": "application/json",
      },
    };
  },
  order: (quoteOrderRef, isNotIncludeCompletedItems = true) => {
    return {
      url: `${base}/api/v2/Order?orderCodes=${quoteOrderRef}&isNotIncludeCompletedItems=${isNotIncludeCompletedItems}`,
      opts: {
        method: "GET",
      },
    };
  },
  productList: (url) => {
    return {
      url: `${base}/api/v2/Product/${url}`,
      opts: {
        method: "GET",
      },
    };
  },
  basketList: () => {
    return {
      url: `${base}/api/v2/Basket`,
      opts: {
        method: "GET",
      },
    };
  },
  account: () => {
    return {
      url: `${base}/api/v2/Account`,
      opts: {
        method: "GET",
      },
    };
  },
  country: () => {
    return {
      url: `${base}/api/v2/Country`,
      opts: {
        method: "GET",
      },
    };
  },
  upcomingOrders: () => {
    return {
      url: `${base}/api/v2/Order/duefordispatch`,
      opts: {
        method: "GET",
      },
    };
  },
  Filters: (uri = "") => {
    return {
      url: `${base}/api/v2/Filter${uri}`,
      opts: {
        method: "GET",
      },
    };
  },
  quotationDetails: (quoteCodes) => {
    return {
      url: `${base}/api/v2/Quote?quoteCodes=${quoteCodes}`,
      opts: {
        method: "GET",
      },
    };
  },
  plannedshipments: (pageno) => {
    return {
      url: `${base}/api/v2/Shipment/planned?pageNumber=${pageno}&resultsPerPage=10`,
      opts: {
        method: "GET",
      },
    };
  },
  plannedShipmentList: () => {
    return {
      url: `${base}/api/v2/Shipment/planned`,
      opts: {
        method: "GET",
      },
    };
  },
  /*   deliverypreference: () => {
      return {
        url: `${base}/api/v2/DeliveryMethodPreference`,
        opts: {
          method: "GET",
        },
      };
    }, */
  orderList: (pageno) => {
    return {
      url: `${base}/api/v2/Order?pageNumber=${pageno}&resultsPerPage=10&isNotIncludeCompletedItems=true`,
      opts: {
        method: "GET",
      },
    };
  },
  searchProducts: (searchTerm, pageno) => {
    var finalstring = '?';
    if (searchTerm?.length > 0) {
      finalstring = finalstring + `searchTerm=${searchTerm}&`
    }
    return {
      url: `${base}/api/v2/Product/search${finalstring}pageNumber=${pageno}&resultsPerPage=10`,
      opts: {
        method: "GET",
      },
    };
  },
  getProjectList: (searchTerm, pageno) => {
    var finalstring = '?';
    if (searchTerm?.length > 0) {
      finalstring = finalstring + `searchTerm=${searchTerm}&`
    }
    return {
      url: `${base}/api/v2/Project/search${finalstring}PageNumber=${pageno}&ResultsPerPage=10`,
      opts: {
        method: "GET",
      },
    };
  },
  getPartNo: (url) => {
    return {
      url: `${base}/api/v2/Product/${url}`,
      opts: {
        method: "GET",
      },
    };
  },
  quotesList: () => {
    return {
      url: `${base}/api/v2/Quote`,
      opts: {
        method: "GET",
      },
    };
  },
  addToQuote: (selReference, bodyData, isArr = false) => {
    const urlIs = selReference === "Create new" ? "Quote/item" : `Quote/${selReference}/item`;
    // const originator = selReference === "Create new" ? "MOBILESITE" : null;
    return {
      url: `${base}/api/v2/${urlIs}`,
      opts: {
        method: "POST",
        body: JSON.stringify({ "items": isArr === false ? [bodyData] : bodyData }),
      },
      additionHeaders: {
        "Content-Type": "application/json",
        // "X-Originator" : originator,
      },
    };
  },
  addToOrder: (selReference, bodyData, isArr = false) => {
    return {
      url: `${base}/api/v2/Order/${selReference}/item`,
      opts: {
        method: "POST",
        body: JSON.stringify({ "items": isArr === false ? [bodyData] : bodyData }),
      },
      additionHeaders: {
        "Content-Type": "application/json",
      },
    };
  },
  editQuotes: (quoteReference, data, itemId) => {
    return {
      url: `${base}/api/v2/quote/${quoteReference}/item/${itemId}`,
      opts: {
        method: "PATCH",
        body: JSON.stringify(data),
      },
      additionHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  },
  news_List: (finalString) => {
    return {
      url: `${baseV1}/api/v1/News${finalString}`,
      opts: { method: "GET" }
    };
  },
  newsList: (finalString, pg, language, searchQuery, type, date, sortBy) => {
    return {
      url: `${baseV1}/api/v1/News${finalString}${pg}&pageSize=10&languageCode=${language}`,
      // url: `${baseV1}/api/v1/News?search=${str}&newsType=${pageName}&languageCode=${lan}`,
      opts: {
        method: "GET",
      },
    };
  },
  homeNews: (language) => {
    return {
      url: `${baseV1}/api/v1/News/home?languageCode=${language}`,
      opts: {
        method: "GET",
      },
    };
  },
  newsBanner: (language) => {
    return {
      url: `${baseV1}/api/v1/News/banners?originator=MobileWeb&languageCode=${language}`,
      opts: {
        method: "GET",
      },
    };
  },
  homeAlert: (alertCode, language) => {
    return {
      url: `${baseV1}/api/v1/Alert/${alertCode}?languageCode=${language}`,
      opts: {
        method: "GET",
      },
    };
  },
  newsDetails: (newsid, language) => {
    return {
      url: `${baseV1}/api/v1/News/${newsid}?languageCode=${language}`,
      opts: {
        method: "GET",
      },
    };
  },
  shipmentsDetails: (shipmentId) => {
    return {
      url: `${base}/api/v2/Shipment/${shipmentId}`,
      opts: {
        method: "GET",
      },
    };
  },
  welcome: (language) => {
    return {
      url: `${baseV1}/api/v1/Page/welcome?languageCode=${language}`,
      opts: {
        method: "GET",
      },
    };
  },
  trainingEvents: (url, page, language) => {
    let lng = language ? `&languageCode=${language}` : '';
    return {
      url: `${baseV1}/api/v1/Event?${url}${url ? '&' : ''}page=${page}&pageSize=10${lng}`,
      opts: {
        method: "GET",
      },
    };
  },
  updateDeliveryQuote: (quoteReference, data) => {
    return {
      url: `${base}/api/v2/Quote/${quoteReference}`,
      opts: {
        method: "PATCH",
        body: JSON.stringify(data),
      }, additionHeaders: {
        'Content-Type': 'application/json-patch+json',
      },
    };
  },
  updateShipmentDeliveryDetails: (shipmentId, data) => {
    return {
      url: `${base}/api/v2/Shipment/${shipmentId}/address`,
      opts: {
        method: "PATCH",
        body: JSON.stringify(data),
      }, additionHeaders: {
        'Content-Type': 'application/json-patch+json',
      },
    };
  },
  addressFindId: (addressId, id) => {
    return {
      url: `${base}/api/v2/Address/find?search=${addressId}${id ? `&container=${id}` : ''}`,
      opts: {
        method: "GET",
      },
    };
  },
  addressFind: (addressId) => {
    return {
      url: `${base}/api/v2/Address/shippingAddress/${addressId}`,
      opts: {
        method: "GET",
      },
    };
  },
  pageDocumentation: (pageRoute, language) => {
    return {
      url: `${baseV1}/api/v1/Page/documentation/${pageRoute}${language ? `?languageCode=${language}` : ''}`,
      opts: {
        method: "GET",
      },
    };
  },
  homeFutureProduct: () => {
    return {
      url: `${base}/api/v2/Product/featured?pageNumber=1&resultsPerPage=1`,
      opts: {
        method: "GET",
      },
    };
  },
  editOrderEditItem: (orderId, data, itemId, isEditShipment) => {
    return {
      url: `${base}/api/v2/Order/${orderId}/item/${itemId}?updateShipment=${isEditShipment}`,
      opts: {
        method: "PATCH",
        body: JSON.stringify(data),
      }, additionHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  },
  deleteOrderItem: (orderId, ItemId) => {
    return {
      url: `${base}/api/v2/Order/${orderId}/item/${ItemId}`,
      opts: {
        method: "DELETE",
      },
    };
  },
  editOrder: (orderId, data, updateShipments = false) => {
    return {
      url: `${base}/api/v2/Order/${orderId}?updateShipments=${updateShipments}`,
      opts: {
        method: "PATCH",
        body: JSON.stringify(data),
      }, additionHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  },
  orderDispatchDate: (orderId) => {
    return {
      url: `${base}/api/v2/Order/${orderId}/earliestdespatchdate`,
      opts: {
        method: "GET",
      },
    };
  },
  orderEstimateDeliveryDate: (orderId) => {
    return {
      url: `${base}/api/v2/Order/${orderId}/estimatedDeliveryDate`,
      opts: {
        method: "GET",
      },
    };
  },
  revertOrderToQuote: (orderId) => {
    return {
      url: `${base}/api/v2/Order/${orderId}/revertOrderToQuote`,
      opts: {
        method: "POST",
      },
    };
  },
  quoteDispatchDate: (quoteId) => {
    return {
      url: `${base}/api/v2/Quote/${quoteId}/earliestdespatchdate`,
      opts: {
        method: "GET",
      },
    };
  },
  createShipment: (data) => {
    return {
      url: `${base}/api/v2/Shipment`,
      opts: {
        method: "POST",
        body: JSON.stringify(data),
      }, additionHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  },

  addItemToShipment: (shipmentId, bodyData) => {
    // const { productCode, quantity } = data;
    return {
      url: `${base}/api/v2/Shipment/${shipmentId}/item`,
      // url: `${base}/api/v2/Shipment/${shipmentId}`,
      opts: {
        method: "POST",
        body: JSON.stringify({ "items": bodyData }),
        // body: JSON.stringify({ "lines": isArr === false ? [bodyData] : bodyData }),
      },
      additionHeaders: {
        "Content-Type": "application/json",
      },
    };
  },

  editShipmentItem: (shipmentId, bodyData, isArr = false/*  itemId, data */) => {
    return {
      url: `${base}/api/v2/Shipment/${shipmentId}`,
      // url: `${base}/api/v2/Shipment/${shipmentId}/item/${itemId}`,
      opts: {
        method: "PATCH",
        // body: JSON.stringify(data),
        body: JSON.stringify({ "lines": isArr === false ? [bodyData] : bodyData })
      }, additionHeaders: {
        'Content-Type': 'application/json-patch+json; charset=utf-8',
        // 'Content-Type': 'application/json; charset=utf-8',
      },
    };
  },
  deleteShipmentItem: (shipmentId, itemId) => {
    return {
      url: `${base}/api/v2/Shipment/${shipmentId}/item/${itemId}`,
      opts: {
        method: "DELETE",
      }, additionHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  },
  changePassword: (pass) => {
    return {
      url: `${base}/api/v2/ChangePassword?newPassword=${pass}`,
      opts: {
        method: "POST",
      },
    };
  },
  emailProforma: (shipmentId) => {
    return {
      url: `${base}/api/v2/Shipment/${shipmentId}/proforma/generate?action=Email`,
      opts: {
        method: "GET",
      },
    };
  },
  deleteShipment: (shipmentId) => {
    return {
      url: `${base}/api/v2/Shipment/${shipmentId}`,
      opts: {
        method: "DELETE",
      },
    };
  },
  readytoShipOtherItem: () => {
    return {
      url: `${base}/api/v2/LineItem/readytoship`,
      opts: {
        method: "GET",
      },
    };
  },
  shipmentDispatch: (shipmentId) => {
    return {
      url: `${base}/api/v2/Shipment/${shipmentId}/despatch`,
      opts: {
        method: "POST",
      },
    };
  },
  deliveryMethod: (data) => {
    return {
      url: `${base}/api/v2/DeliveryMethod`,
      opts: {
        method: "POST",
        body: JSON.stringify(data),
      }, additionHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  },
  addressType: () => {
    return {
      url: `${base}/api/v2/Address/type`,
      opts: {
        method: "GET",
      },
    };
  },

  contactUs: (data) => {
    return {
      url: `${base}/api/v2/ContactUs`,
      opts: {
        method: "POST",
        body: JSON.stringify(data),
      }, additionHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  },

  authenticatedContactUs: (data) => {
    return {
      url: `${base}/api/v2/ContactUs/account`,
      opts: {
        method: "POST",
        body: JSON.stringify(data),
      }, additionHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  },

  pages: (pageName, language) => {
    return {
      url: `${baseV1}/api/v1/Page/${pageName}?languageCode=${language}`,
      opts: {
        method: "GET",
      },
    };
  },

  updateShippingAddress: (data) => {
    return {
      url: `${base}/api/v2/account/shippingaddress`,
      opts: {
        method: "POST",
        body: JSON.stringify(data),
      }, additionHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  },

  closeOrder: (orderReference) => {
    return {
      url: `${base}/api/v2/Order/${orderReference}/close`,
      opts: {
        method: "PATCH",
      },
      additionHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  },

};

export { urls };
