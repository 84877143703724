import React from "react";
import { Link } from "react-router-dom";
import { BACK_MENU_ICON, HOME_BLACK_ICON } from "../../assets";
import { T } from "../translation";

/**
 * backmenu navigation component
 * @param {string} BackMenu1 -title of backManu1 (it's static - define in string localization)
 * @param {string} BackMenu2 -title of backManu2 (it's static - define in string localization)
 * @param {string} BackMenu3 -title of backManu3 (it's static - define in string localization)
 * @param {string} BackMenuName1 -title of backManuName1 used if title was dynamic then it's not define in string localization 
 * @param {string} BackMenuName2 -title of backManuName2 used if title was dynamic then it's not define in string localization 
 * @param {string} BackMenuName3 -title of backManuName3 used if title was dynamic then it's not define in string localization
 * @param {string} Path1 - click on backMenu1 then navigate on this path
 * @param {string} Path2 - click on backNenu2 then navigate on this path
 * @param {function} onClick2 - onClick2 used to defaul click on backNenu2 (if path2 not pass)
 * @returns - back menus and navigation
 */
export default function BackMenuCard({
  BackMenu1,
  BackMenu2,
  BackMenu3,
  BackMenuName1,
  BackMenuName2,
  BackMenuName3,
  Path2,
  Path1,
  onClick2,
  fromDocs = false
}) {
  return (
    <>
      <div className="bread_crumb">
        <Link to="/">
          <img src={HOME_BLACK_ICON} alt="Home-icon" />
        </Link>
        <img src={BACK_MENU_ICON} alt="Account-icon" />
        <Link to={Path1} className="breadcrumb">
          <span className="breadcrumb">
            {BackMenu1 ? <T strProp={BackMenu1} /> : BackMenuName1}
          </span>
        </Link>
        {BackMenu2 || BackMenuName2 ? (
          <>
            {Path2 ? (
              <>
                <img src={BACK_MENU_ICON} alt="-icon" />
                <Link to={Path2} className="breadcrumb">
                  <span className="breadcrumb-title">
                    {fromDocs ? BackMenu2 :BackMenu2 ? <T strProp={BackMenu2} /> : BackMenuName2}
                  </span>
                </Link>
              </>
            ) : (
              <>
                <img src={BACK_MENU_ICON} alt="-icon" />
                <div className="breadcrumb" onClick={onClick2}>
                  <span className="breadcrumb-title">
                    {fromDocs ? BackMenu2 :BackMenu2 ? <T strProp={BackMenu2} /> : BackMenuName2}
                  </span>
                </div>
              </>
            )}
          </>
        ) : null}
        {BackMenu3 || BackMenuName3 ? (
          <>
            <img src={BACK_MENU_ICON} alt="-icon" />
            <span className="breadcrumb-title">
              {fromDocs ? BackMenu3 : BackMenu3 ? <T strProp={BackMenu3} /> : BackMenuName3}
            </span>
          </>
        ) : null}
      </div>
    </>
  );
}
