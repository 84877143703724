import React, { useCallback, useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CheckBox } from ".";
import { CLOSE_WHITE_ICON } from "../assets";
import { GlobalContext, Il8Context } from "../contexts";
import { callApi } from "../services";
import BtnSmall from "./BtnSmall";
import DropDown from "./DropDown";
import ButtonModual from "./modual/ButtonModual";
import { T } from "./translation";
import { globalActionsTypes } from "../hooks";

/**
 * // this function is popup to add quote
 *
 * @param {string} token define to user authentication
 * @param {int} quoteCounterQuantity define quote counter quantity
 * @param {object} productData is object of product details
 * @param {function} setQuote used to set open or close state(popup)
 * @param {boolean} quote used to  open(true) or close(false) popup
 * @param {referance} alertRef used to  display pop up if nay issue or error find in api response
 * @returns element of popup
 */
function QuoteAddPopup({
  token,
  quoteCounterQuantity = 1,
  productData,
  setQuote,
  quote,
  alertRef,
}) {
  const [loading, setLoading] = useState(true);
  const [selReference, setSelReference] = useState("");
  const [openQuote, setOpenQuote] = useState(true);
  const { t } = useContext(Il8Context);
  const [loadingQbtn, setLoadingQbtn] = useState(false);
  const [showQuote, setShowQuote] = useState(false);
  const navigate = useNavigate();
  const quoteRef = useRef(null);
  const { state: { quoteItems }, dispatch } = useContext(GlobalContext);

  useEffect(() => {
    const getQuotes = async () => {
      setLoading(true);
      await callApi("quotesList", { token })
        .then(({ res, sCode }) => {
          if (sCode === 200 || sCode === 202) {
            dispatch({
              type: globalActionsTypes.setQuoteItems, data: [
                { reference: "Create new" },
                ...res?.body?.result?.items
              ]
            });
            setSelReference("Create new");
          } else {
            dispatch({ type: globalActionsTypes.setQuoteItems, data: [{ reference: "Create new" }] });
            setSelReference("Create new");
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    };


    if (quoteItems.length > 0) {
      setSelReference("Create new");
      setLoading(false)
    } else {
      quote && getQuotes()
    }

    
    // eslint-disable-next-line
  }, [quote]);

  const quoteClosee = () => {
    quoteRef.current?.handleInputBlur("", "");
    setSelReference("Create new");
    setQuote(false);
  };

  /**
   * 
   * @returns this function can add product to quote.
   */
  const addToQuote = async () => {
    if (selReference === "") {
      quoteRef.current?.handleInputBlur(
        true,
        <T strProp="pleaseSelectQuote" />, true
      );
      return;
    } else quoteRef.current?.handleInputBlur("", "");

    const newObj = {
      category: productData?.categoryName,
      productCode: productData?.productCode,
      quantity: quoteCounterQuantity,
    };
    if (quoteCounterQuantity !== 0 && selReference !== "") {
      setLoadingQbtn(true);
      await callApi("addToQuote", { token, alertRef }, selReference, newObj)
        .then(({ res, sCode }) => {
          if (sCode === 200 || sCode === 202) {
            setQuote(false);
            openQuote
              ? navigate(`/quotationdetails/${res?.body?.result?.reference}`)
              : setShowQuote(true);
            setSelReference("Create new");
            setOpenQuote(true);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingQbtn(false));
    }
  };

  const quoteCall = useCallback(() => {
    return (
      <div className="quick-add-margin">
        <DropDown
          data={quoteItems}
          val={selReference}
          nameKey="reference"
          valKey="reference"
          onChangeVal={(c) => {
            setSelReference(c);
          }}
          isDisabled={quoteItems?.length === 0}
          ref={quoteRef}
          isLoading={loading}
          isQuote={true}
        />
      </div>
    );
    // eslint-disable-next-line
  }, [selReference, loading]);

  const quoteClose = () => setShowQuote(false);

  const QuoteModal = useCallback(() => {
    return (
      <ButtonModual Open={showQuote} onClose={quoteClose}>
        <div className="popup_event">
          <div className="popup_event-flex">
            <img src={CLOSE_WHITE_ICON} alt="icon" onClick={quoteClose} />
          </div>
          <span className="popup_event-text">
            <T strProp="quote" />
          </span>
          <div className="popup_event__text">
            <span>
              <T strProp="productorderlist" />
            </span>
          </div>
          <BtnSmall
            primary
            Title={t("modalCloseButtonText")}
            OnClickBtn={quoteClose}
          />
        </div>
      </ButtonModual>
    );
  }, [showQuote, t]);

  return (
    <>
      <ButtonModual Open={quote}>
        <div className="popup_event">
          <div className="popup_event-flex">
            <img src={CLOSE_WHITE_ICON} alt="icon" onClick={quoteClosee} />
          </div>
          <span className="popup_event-text">
            <T strProp="addToQuote" />
          </span>
          <div className="popup_event__text filter-flex-padding">
            <span>{<T strProp="quoteAddPopUpTitle" />}</span>
          </div>
          {quoteCall()}
          <div className="popup_event-text quick-add-margin-model">
            <CheckBox
              textClass="check-box-text"
              label={<T strProp="quoteOpenQuote" />}
              checked={openQuote}
              onChange={() => setOpenQuote(!openQuote)}
            />
          </div>
          <BtnSmall
            OnClickBtn={addToQuote}
            primary
            isLoading={loadingQbtn}
            Title={t("Add")}
          />
        </div>
      </ButtonModual>

      {QuoteModal()}
    </>
  );
}

export default QuoteAddPopup;
