import React, { useEffect, useState } from "react";
import { useSessionHook } from "../../hooks";
import { callApi } from "../../services";
import DropDown from "../DropDown";
import { T } from "../translation";

/**
 * @param {string} addressType - value of address type 
 * @param {function} setAddressType - set address type value in state
 * @param {referance} addRef - use for referance particular address
 * @returns - address type dropdown and validation
 */
const AddressTypeInp = ({ addressType = "", setAddressType = () => { }, addRef }) => {
  const sessionHelpers = useSessionHook();
  const { token } = sessionHelpers.getStatusAndToken();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (token === null) return;
    const getAddressType = async () => {
      await callApi("addressType", { token })
        .then(({ res, sCode }) => {
          if (sCode === 200 || sCode === 202) createObjArray(res?.body?.result)
        })
        .catch((err) => console.log(err))
        .finally(() => { } /*  setLoadingPrefrence(false) */);
    };
    const createObjArray = (items) => {
      let arr = [];
      items?.map((i) => {
        return arr.push({ value: i });
      });
      setData(arr);
      setTimeout(() => {
        setAddressType("Domestic");
      }, 1500);
    };
    getAddressType();
    // eslint-disable-next-line
  }, [token]);

  return data?.length > 0 ? (
    <div className="margin_bottom">
      <DropDown
        title={<T strProp="quoteAddressType" />}
        data={data}
        val={addressType}
        nameKey="value"
        valKey="value"
        onChangeVal={(e) => {
          setAddressType(e);
          addRef !== null && addRef.current?.handleInputBlur("", "");
        }}
        isDisabled={data?.length === 0}
        Required={addRef !== null && true}
        ref={addRef}
      />
    </div>
  ) : null;
};

export default AddressTypeInp;

/**
 * @param {string} value - user input value of address type
 * @param {refrence} ref - handal to validation
 * @returns - bollean (validate = true , not validate = false) 
 */
export const addressTypeValidation = (value, ref) => {
  if (value?.length === 0) {
    ref.current?.handleInputBlur(true, <T strProp="PleaseEnterAddressType" />, true);
    return false;
  } else {
    ref.current?.handleInputBlur("", "");
    return true;
  }
};
