import React, { useEffect, useState } from "react";
import { useSessionHook } from "../../../hooks";
import { callApi } from "../../../services";
import { useNavigate } from "react-router-dom";
import { PageLoader } from "../../pageLoader";
import { NO_FOUND_SM_IMG } from "../../../assets";
import { languageCode } from "../../../contexts/i18context";
import { T } from "../../translation";
import { dateFormate } from "../../../utils";

/**
 * 
 * @param {function} setIsNews - use for set whether news available or not
 * @param {function} newsArrow - use for redirect to news page
 * @param {function} signal - use for cancle api call
 * @returns this component display list of letest news in page.
 */
function LatestNews({ setIsNews, newsArrow, signal }) {
  const sessionHelpers = useSessionHook();
  const { token } = sessionHelpers.getStatusAndToken();
  const [homeNews, setHomeNews] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (token === null) return;
    const getNews = async () => {
      !loading && setLoading(true);
      await callApi("homeNews", { token, signal: signal }, languageCode)
        .then(({ res, sCode }) => {
          if (sCode === 200 || sCode === 202) {
            setHomeNews(res?.body);
            setIsNews(res?.body?.length !== 0);
          }
        })
        .catch((err) => console.log("err", err))
        .finally(() => setLoading(false));
    };

    getNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      <section
        className={
          homeNews?.length > 0 ? "lates-news-heading" : "latesnews__heading"
        }
      >
        <span className="lates-news-heading-title">
          <T strProp="homeLatestNews" />
        </span>
        {homeNews?.length > 0 && newsArrow()}
      </section>
      {loading ? (
        <section className="quote-wrapper lates-news-card-loding">
          <PageLoader isSmall isLoading />
        </section>
      ) : homeNews?.length > 0 ? (
        <section className="scroll">
          {homeNews?.map((item, index) => {
            return (
              <div
                className="lates-news-card"
                key={index}
                onClick={() => {
                  item?.id && navigate(`/news_details/${item?.id}`);
                }}
              >
                <div className="lates-news-card-flex">
                  <img
                    src={item?.thumbnail?.src}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.style.objectFit = "cover";
                      currentTarget.src = NO_FOUND_SM_IMG;
                    }}
                    alt="lates news"
                  />
                </div>
                <div className="lates-news-card-flex-col">
                  <span className="lates-news-title">{item?.title}</span>
                  <span className="lates-news-date">
                    {dateFormate(item?.displayDate)}
                  </span>
                  <span className="lates-news-info">{item?.summary}</span>
                </div>
              </div>
            );
          })}
        </section>
      ) : (
        <p className="no-data-found-sm">
          <T strProp="newsNotFound" />
        </p>
      )}
    </>
  );
}

export default LatestNews;
