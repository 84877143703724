import React from 'react';
import { CLOSE_WHITE_ICON } from '../../assets';
import BtnSmall from '../BtnSmall';
import ButtonModual from '../modual/ButtonModual';

/**
 * @param {boolean} visible - visible state use to show this editquoteItem or not
 * @param {function} onClose - use to close this component
 * @param {string} title - Title of model
 * @param {string} message - Message of model
 * @returns - success alert jsx element 
 */
const SuccessfullAlert = ({ visible = false, onClose, title, message }) => {
    return (
        <ButtonModual Open={visible}>
            <div className="popup_event">
                <div className="popup_event-flex">
                    <img
                        src={CLOSE_WHITE_ICON}
                        alt="icon"
                        onClick={() => onClose(false)}
                    />
                </div>
                <span className="popup_event-text">{title}</span>
                <div className="popup_event__text">
                    <span>{message}</span>
                </div>
                <BtnSmall OnClickBtn={() => onClose(false)} primary Title="OK" />
            </div>
        </ButtonModual>
    )
}

export default SuccessfullAlert