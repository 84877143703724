import React, { useContext } from "react";
import { CLOSE_WHITE_ICON } from "../assets";
import { Il8Context } from "../contexts";
import BtnSmall from "./BtnSmall";
import ButtonModual from "./modual/ButtonModual";
import { T } from "./translation";

/**
 * @param {boolean} visible - visible state use to show this DeleteModel or not
 * @param {boolean} onClose - Set the action perform after close this model
 * @param {boolean} onClickDel - Call particular function when click on that button
 * @param {boolean} isDeleting - Display loader base on that variable value
 * @param {string} title - Display model title
 * @param {string} subTitle - Display model sub title
 * 
 * @returns - delete popup module / jsx element 
 */
const DeleteModal = ({ visible = false, onClose, onClickDel, isDeleting, title, subTitle }) => {
  const { t } = useContext(Il8Context);
  return (
    <ButtonModual Open={visible} onClose={!isDeleting ? onClose : undefined}>
      <div className="popup_event">
        <div className="popup_event-flex ">
          <img
            className="cursor-pointer"
            src={CLOSE_WHITE_ICON}
            alt="icon"
            onClick={() => { onClose(!isDeleting ? false : true) }}
          />
        </div>
        <span className="popup_event-text">
          {title ? <T strProp={title} /> : <T strProp="DeleteItem" />}
        </span>
        <div className="popup_event__text">
          <span>
            {subTitle ? <T strProp={subTitle} /> : <T strProp="yousuredelete" />}
          </span>
        </div>
        <div className="display_flex">
          <BtnSmall
            OnClickBtn={onClickDel}
            Title={t("delete")}
            secondary
            isLoading={isDeleting}
            sty={{ marginRight: "10px" }}
          />
          <BtnSmall
            OnClickBtn={() => { onClose(!isDeleting ? false : true) }}
            Title={t("cancel")}
            sty={{ marginRight: "10px" }}
            gray
          />
        </div>
      </div>
    </ButtonModual>
  );
};

export default DeleteModal;
