
// import { Link } from "react-router-dom";
import { memo } from "react";
import { DOWN_ARROW_IC } from "../assets";
// import { PageLoader } from "./pageLoader";
import { T } from "./translation";

const ChevronWrapper = ({
    expand,
    handleClick,
    title,
    textColor = "#E56606",
    children,
    applyPadding = true,
    disable = false
}) => {
    return (
        <>
            <div className={applyPadding ? "quotationdetails-issues-box" : "quotationdetails-issues-box__"}>
                <div className="project-flex" onClick={handleClick}>
                    <div className="quotationdetails-head-ti">
                        <span className="chevron_text_title" style={{ color: textColor }}>
                            <T strProp={title} />
                        </span>
                    </div>
                    <div className="link chevron_aerrow_bg cursor-pointer" >
                        <img
                            src={DOWN_ARROW_IC}
                            alt="icon"
                            className="chevron-aerrow-transform"
                            style={{
                                transform:
                                    expand ? "rotate(-180deg)" : "rotate(0)"
                            }}
                        />
                    </div>
                </div>
                {expand && children}
            </div>

        </>

    )
}

export default memo(ChevronWrapper);