import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "none",
};

/**
 * 
 * @param {boolean} Open - use for dipsplay model or not
 * @param {object} children - use for 
 * @param {function} onClose - use for call function when close model
 * @returns this function display pop up box module jsx element.
 */
export default function ButtonModual({ Open, children, onClose = () => {} }) {
  return (
    <Modal
      open={Open}
      onClose={() => onClose(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={style} className="modual-box">
        {children}
      </Box>
    </Modal>
  );
}
