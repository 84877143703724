import { T } from "../components";

export const SortByProduct = [
  {
    sortBy: true,
    displayName: <T strProp="productInstockfirst" />,
    value: "SortDesc=StockAvailability",
    title: "SortBy",
    count: 0,
    subData: [],
    subCat: "",
    sortId: 1,
  },
  {
    sortBy: true,
    displayName: <T strProp="productLatest" />,
    value: "SortDesc=Recent",
    title: "SortBy",
    count: 0,
    subData: [],
    subCat: "",
    sortId: 2,
  },
  {
    sortBy: true,
    displayName: <T strProp="productPricehighlow" />,
    value: "SortDesc=Price",
    title: "SortBy",
    count: 0,
    subData: [],
    subCat: "",
    sortId: 3,
  },
  {
    sortBy: true,
    displayName: <T strProp="productPricelowhigh" />,
    value: "SortAsc=Price",
    title: "SortBy",
    count: 0,
    subData: [],
    subCat: "",
    sortId: 4,
  },
  {
    sortBy: true,
    displayName: <T strProp="productAtoZ" />,
    value: "SortAsc=Product",
    title: "SortBy",
    count: 0,
    subData: [],
    subCat: "",
    sortId: 5,
  },
  {
    sortBy: true,
    displayName: <T strProp="productZtoA" />,
    value: "SortDesc=Product",
    title: "SortBy",
    count: 0,
    subData: [],
    subCat: "",
    sortId: 6,
  },
  {
    sortBy: true,
    displayName: <T strProp="manufacturerAtoZ" />,
    value: "SortAsc=Manufacturer",
    title: "SortBy",
    count: 0,
    subData: [],
    subCat: "",
    sortId: 7,
  },
  {
    sortBy: true,
    displayName: <T strProp="manufacturerZtoA" />,
    value: "SortDesc=Manufacturer",
    title: "SortBy",
    count: 0,
    subData: [],
    subCat: "",
    sortId: 8,
  },
];

// { sortBy: true, Title: "Product A-Z", checked: false, quantity: 0 },
// { sortBy: true, Title: "Product Z-A", checked: false, quantity: 0 },
// { sortBy: true, Title: "PartNo Z-A", checked: false, quantity: 0 },
// { sortBy: true, Title: "Manufacturer A-Z", checked: false, quantity: 0 },
// { sortBy: true, Title: "Manufacturer Z-A", checked: false, quantity: 0 },

// { sortBy: true, Title: "In Stock First", checked: false, value: "SortAsc=Recent" },
// { sortBy: true, displayName: "Old", checked: false, value: "SortAsc=Recent" },
// { sortBy: true, displayName: "Latest", checked: false, value: "SortDesc=Recent" },
// { sortBy: true, displayName: "Price high-low", checked: false, value: "SortDesc=Price" },
// { sortBy: true, displayName: "Price low-high", checked: false, value: "SortAsc=Price" },
// { sortBy: true, displayName: "Old", value: "SortAsc=Recent", title: "SortBy", count: 0, subData: [], subCat: "" },




export const resdataRecentShipment = {
  "headers": {},
  "body": {
      "result": {
          "items": [
              {
                  "id": 811117,
                  "description": "SEG64",
                  "shippingDate": "2023-04-28T00:00:00",
                  "deliveryMethodDescription": "Collection - SegenSolar International - Pallet",
                  "shipmentItems": [
                      // {
                      //     "id": 4811899,
                      //     "orderCode": "ThPV32699",
                      //     "productCode": "CS3L-375MS-MC4-F30",
                      //     "productDescription": "Canadian Solar 375W Super High Power Mono PERC HiKU with MC4-EVO2 SF",
                      //     "quantity": 30,
                      //     "linePrice": {
                      //         "amount": 66954,
                      //         "currency": "Zar"
                      //     },
                      //     "linePriceIncVat": {
                      //         "amount": 66954,
                      //         "currency": "Zar"
                      //     },
                      //     "orderedDate": "2023-04-21T14:56:13"
                      // },
                      {
                          "id": 4811900,
                          "orderCode": "ThPV32699",
                          "productCode": "DELIVERY",
                          "productDescription": "Delivery Charge - Automatically Calculated",
                          "quantity": 1,
                          "linePrice": {
                              "amount": 225,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 225,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-04-21T14:56:13"
                      }
                  ]
              },{
                  "id": 811117,
                  "description": "SEG64",
                  "shippingDate": "2023-04-28T00:00:00",
                  "deliveryMethodDescription": "Collection - SegenSolar International - Pallet",
                  "shipmentItems": [
                      {
                          "id": 4811899,
                          "orderCode": "ThPV32699",
                          "productCode": "CS3L-375MS-MC4-F30",
                          "productDescription": "Canadian Solar 375W Super High Power Mono PERC HiKU with MC4-EVO2 SF",
                          "quantity": 30,
                          "linePrice": {
                              "amount": 66954,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 66954,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-04-21T14:56:13"
                      },
                      {
                          "id": 4811900,
                          "orderCode": "ThPV32699",
                          "productCode": "DELIVERY",
                          "productDescription": "Delivery Charge - Automatically Calculated",
                          "quantity": 1,
                          "linePrice": {
                              "amount": 225,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 225,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-04-21T14:56:13"
                      }
                  ]
              },{
                  "id": 811117,
                  "description": "SEG64",
                  "shippingDate": "2023-04-28T00:00:00",
                  "deliveryMethodDescription": "Collection - SegenSolar International - Pallet",
                  "shipmentItems": [
                      {
                          "id": 4811899,
                          "orderCode": "ThPV32699",
                          "productCode": "CS3L-375MS-MC4-F30",
                          "productDescription": "Canadian Solar 375W Super High Power Mono PERC HiKU with MC4-EVO2 SF",
                          "quantity": 30,
                          "linePrice": {
                              "amount": 66954,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 66954,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-04-21T14:56:13"
                      },
                      {
                          "id": 4811900,
                          "orderCode": "ThPV32699",
                          "productCode": "DELIVERY",
                          "productDescription": "Delivery Charge - Automatically Calculated",
                          "quantity": 1,
                          "linePrice": {
                              "amount": 225,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 225,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-04-21T14:56:13"
                      }
                  ]
              },{
                  "id": 811117,
                  "description": "SEG64",
                  "shippingDate": "2023-04-28T00:00:00",
                  "deliveryMethodDescription": "Collection - SegenSolar International - Pallet",
                  "shipmentItems": [
                      {
                          "id": 4811899,
                          "orderCode": "ThPV32699",
                          "productCode": "CS3L-375MS-MC4-F30",
                          "productDescription": "Canadian Solar 375W Super High Power Mono PERC HiKU with MC4-EVO2 SF",
                          "quantity": 30,
                          "linePrice": {
                              "amount": 66954,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 66954,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-04-21T14:56:13"
                      },
                      {
                          "id": 4811900,
                          "orderCode": "ThPV32699",
                          "productCode": "DELIVERY",
                          "productDescription": "Delivery Charge - Automatically Calculated",
                          "quantity": 1,
                          "linePrice": {
                              "amount": 225,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 225,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-04-21T14:56:13"
                      }
                  ]
              },
              {
                  "id": 791983,
                  "description": "Pylon",
                  "shippingDate": "2023-04-24T00:00:00",
                  "deliveryMethodDescription": "Collection - SegenSolar International - Pallet",
                  "shipmentItems": [
                      {
                          "id": 4712737,
                          "orderCode": "ThPV32160",
                          "productCode": "PYLON-UP2500-CAN",
                          "productDescription": "Pylon UP2500 2.84kWh  Li-Ion Solar Battery 24V with CAN connection",
                          "quantity": 8,
                          "linePrice": {
                              "amount": 132023.04,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 132023.04,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-03-27T15:59:20"
                      },
                      {
                          "id": 4712738,
                          "orderCode": "ThPV32160",
                          "productCode": "DELIVERY",
                          "productDescription": "Delivery Charge - Collection - SegenSolar International - Pallet",
                          "quantity": 1,
                          "linePrice": {
                              "amount": 75,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 75,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-03-27T15:59:20"
                      }
                  ]
              },
              {
                  "id": 801919,
                  "description": "Canadian 370W",
                  "shippingDate": "2023-04-17T00:00:00",
                  "deliveryMethodDescription": "Collection - SegenSolar International - Pallet",
                  "shipmentItems": [
                      {
                          "id": 4764452,
                          "orderCode": "ThPV32362",
                          "productCode": "CS3L-370MS-MC4-F30",
                          "productDescription": "Canadian Solar 370W Super High Power Mono PERC HiKU with MC4-EVO2",
                          "quantity": 30,
                          "linePrice": {
                              "amount": 66061.2,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 66061.2,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-04-05T13:11:06"
                      },
                      {
                          "id": 4764453,
                          "orderCode": "ThPV32362",
                          "productCode": "DELIVERY",
                          "productDescription": "Delivery Charge - Collection - SegenSolar International - Pallet",
                          "quantity": 1,
                          "linePrice": {
                              "amount": 75,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 75,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-04-05T13:11:06"
                      }
                  ]
              },
              {
                  "id": 795111,
                  "description": "NHFU",
                  "shippingDate": "2023-04-05T00:00:00",
                  "deliveryMethodDescription": "Collection - SegenSolar International - Pallet",
                  "shipmentItems": [
                      {
                          "id": 4728914,
                          "orderCode": "ThPV31785",
                          "productCode": "F-WON-L-HOME-30-24-N",
                          "productDescription": "Freedom Won Lite Home 30/24 LiFePO4 Battery N",
                          "quantity": 1,
                          "linePrice": {
                              "amount": 173855.7,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 173855.7,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-03-31T09:50:34.433"
                      },
                      {
                          "id": 4728915,
                          "orderCode": "ThPV31785",
                          "productCode": "DELIVERY",
                          "productDescription": "Delivery Charge - Collection - SegenSolar International - Pallet",
                          "quantity": 1,
                          "linePrice": {
                              "amount": 75,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 75,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-03-31T09:50:34.433"
                      }
                  ]
              },
              {
                  "id": 791933,
                  "description": "SEG 60",
                  "shippingDate": "2023-04-03T00:00:00",
                  "deliveryMethodDescription": "Collection - SegenSolar International - Pallet",
                  "shipmentItems": [
                      {
                          "id": 4712492,
                          "orderCode": "ThPV32161",
                          "productCode": "OG3.24",
                          "productDescription": "KODAK Solar Off-Grid Inverter VMIII 3kW 24V",
                          "quantity": 5,
                          "linePrice": {
                              "amount": 39419.6,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 39419.6,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-03-27T16:49:55"
                      },
                      {
                          "id": 4712493,
                          "orderCode": "ThPV32161",
                          "productCode": "REN-400525",
                          "productDescription": "VarioSole+ Mounting rail 41 x 35 x 4400 mm",
                          "quantity": 12,
                          "linePrice": {
                              "amount": 4750.92,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 4750.92,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-03-27T16:49:55"
                      },
                      {
                          "id": 4712494,
                          "orderCode": "ThPV32161",
                          "productCode": "DELIVERY",
                          "productDescription": "Delivery Charge - Collection - SegenSolar International - Pallet",
                          "quantity": 1,
                          "linePrice": {
                              "amount": 75,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 75,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-03-27T16:49:55"
                      }
                  ]
              },
              {
                  "id": 789504,
                  "description": "100 X End Clamp+ 30 - 50mm Silver",
                  "shippingDate": "2023-03-28T00:00:00",
                  "deliveryMethodDescription": "Collection - SegenSolar International - Parcel",
                  "shipmentItems": [
                      {
                          "id": 4699020,
                          "orderCode": "ThPV32098",
                          "productCode": "REN-420081",
                          "productDescription": "End Clamp+ 30 - 50mm Silver ",
                          "quantity": 200,
                          "linePrice": {
                              "amount": 7906,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 7906,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-03-23T09:37:04"
                      },
                      {
                          "id": 4699021,
                          "orderCode": "ThPV32098",
                          "productCode": "DELIVERY",
                          "productDescription": "Delivery Charge - Automatically Calculated",
                          "quantity": 1,
                          "linePrice": {
                              "amount": 75,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 75,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-03-23T09:37:04"
                      }
                  ]
              },
              {
                  "id": 789280,
                  "description": "SEG58",
                  "shippingDate": "2023-03-27T00:00:00",
                  "deliveryMethodDescription": "Collection - SegenSolar International - Pallet",
                  "shipmentItems": [
                      // {
                      //     "id": 4698109,
                      //     "orderCode": "ThPV31930",
                      //     "productCode": "OGS5.6",
                      //     "productDescription": "KODAK Solar Off-Grid Inverter 5.6kW 48V",
                      //     "quantity": 1,
                      //     "linePrice": {
                      //         "amount": 12135.6,
                      //         "currency": "Zar"
                      //     },
                      //     "linePriceIncVat": {
                      //         "amount": 12135.6,
                      //         "currency": "Zar"
                      //     },
                      //     "orderedDate": "2023-03-23T07:01:46.847"
                      // },
                      // {
                      //     "id": 4698110,
                      //     "orderCode": "ThPV31930",
                      //     "productCode": "PYLON-UP5000",
                      //     "productDescription": "Pylon UP5000 4.8kWh  Li-Ion Solar Battery 48V",
                      //     "quantity": 5,
                      //     "linePrice": {
                      //         "amount": 117631.45,
                      //         "currency": "Zar"
                      //     },
                      //     "linePriceIncVat": {
                      //         "amount": 117631.45,
                      //         "currency": "Zar"
                      //     },
                      //     "orderedDate": "2023-03-23T07:01:46.847"
                      // },
                      // {
                      //     "id": 4698111,
                      //     "orderCode": "ThPV31930",
                      //     "productCode": "REN-400524",
                      //     "productDescription": "VarioSole+ Mounting rail 41 x 35 x 3300 mm ",
                      //     "quantity": 24,
                      //     "linePrice": {
                      //         "amount": 7076.64,
                      //         "currency": "Zar"
                      //     },
                      //     "linePriceIncVat": {
                      //         "amount": 7076.64,
                      //         "currency": "Zar"
                      //     },
                      //     "orderedDate": "2023-03-23T07:01:46.847"
                      // },
                      // {
                      //     "id": 4698112,
                      //     "orderCode": "ThPV31930",
                      //     "productCode": "REN-400525",
                      //     "productDescription": "VarioSole+ Mounting rail 41 x 35 x 4400 mm",
                      //     "quantity": 24,
                      //     "linePrice": {
                      //         "amount": 9435.36,
                      //         "currency": "Zar"
                      //     },
                      //     "linePriceIncVat": {
                      //         "amount": 9435.36,
                      //         "currency": "Zar"
                      //     },
                      //     "orderedDate": "2023-03-23T07:01:46.847"
                      // },
                      // {
                      //     "id": 4698113,
                      //     "orderCode": "ThPV31930",
                      //     "productCode": "RS232-USB-CABLE-KIT",
                      //     "productDescription": "RS232 to USB Converter Cable Kit for Pylontech",
                      //     "quantity": 2,
                      //     "linePrice": {
                      //         "amount": 540.96,
                      //         "currency": "Zar"
                      //     },
                      //     "linePriceIncVat": {
                      //         "amount": 540.96,
                      //         "currency": "Zar"
                      //     },
                      //     "orderedDate": "2023-03-23T07:01:46.847"
                      // },
                      {
                          "id": 4698114,
                          "orderCode": "ThPV31930",
                          "productCode": "DELIVERY",
                          "productDescription": "Delivery Charge - Automatically Calculated",
                          "quantity": 1,
                          "linePrice": {
                              "amount": 225,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 225,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-03-23T07:01:46.847"
                      },
                      {
                          "id": 4698801,
                          "orderCode": "ThPV32097",
                          "productCode": "OG-10",
                          "productDescription": "KODAK Solar Off-Grid Inverter 10kW 48V",
                          "quantity": 2,
                          "linePrice": {
                              "amount": 44992.82,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 44992.82,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-03-23T09:14:20.547"
                      },
                      {
                          "id": 4698802,
                          "orderCode": "ThPV32097",
                          "productCode": "SUNK-PICOPLUS-20",
                          "productDescription": "Solar LED light ,1 x box of 20PCS",
                          "quantity": 1,
                          "linePrice": {
                              "amount": 1081.37,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 1081.37,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-03-23T09:14:20.547"
                      },
                      {
                          "id": 4699476,
                          "orderCode": "ThPV32100",
                          "productCode": "BRACK-UP5000",
                          "productDescription": "2xBrackets - Pylon UP5000",
                          "quantity": 5,
                          "linePrice": {
                              "amount": 4260.5,
                              "currency": "Zar"
                          },
                          "linePriceIncVat": {
                              "amount": 4260.5,
                              "currency": "Zar"
                          },
                          "orderedDate": "2023-03-23T10:35:29.69"
                      }
                  ]
              }
          ],
          "pageCount": 1,
          "pageNumber": 1,
          "itemCount": 7
      },
      "responseTime": 583
  },
  "error": null,
  "statuses": {
      "status": 200,
      "statusText": "OK"
  }
}