import { useState, useEffect } from "react";
import Menu from "./menu";
import "../../../styles/components/menus/mainMenu.css";

/**
* 
* @param {boolean} mainMenuOpen - use for display title
* @param {string} children - for display nested menus
* @param {function} SideMenuClose - close side menu in list
* @returns  display jsx element of side main menu (home, product,news,etc...)
*/
const MainMenu = ({ mainMenuOpen, children, SideMenuClose }) => {
  const [init, setInit] = useState(false);
  // eslint-disable-next-line
  const [menuData, setMenuData] = useState([{ id: "root" }]);
  const [menuState, setMenuState] = useState();
  useEffect(() => {
    if (mainMenuOpen) {
      setInit(true);
      setMenuState((prevState) => ({ ...prevState, root: true }));
    } else {
      setMenuState((prevState) => {
        let resetState = {};
        for (const key in prevState) {
          resetState[key] = false;
        }
        return resetState;
      });
    }
    // eslint-disable-next-line
  }, [mainMenuOpen]);

  return menuData && menuState ? (
    <div
      className={`main-menu ${init
          ? menuState.root
            ? "main-menu--open main-menu1"
            : "main-menu--closed main-menu1"
          : ""
        }`}
    >
      {menuData?.map((menu, i) => (
        <Menu
          key={i}
          allMenus={menuData}
          thisMenuId={menu?.id}
          state={menuState}
          children={children}
          SideMenuClose={SideMenuClose}
        />
      ))}
    </div>
  ) : null;
};

export default MainMenu;
