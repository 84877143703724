import React, { useEffect, useState } from "react";
import AppRoute from "./routes";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { Il8Context, createContextValue } from "./contexts";
import MenuContextData from "./contexts/menuContextData";
import { languageCountryCodes } from "./utils";
// import { onLogout } from "./pages/Allmenu";
// import { useWindowFocus } from "./hooks";

/**
 * 
 * @returns for render app details.
 */
function App() {
  const [i18nContextValue, setI18nContextValue] = useState(null);
  const { accounts } = useMsal();
  // const windowFocused = useWindowFocus();

  /* useEffect(() => {
    console.log("windowFocused", windowFocused);
  }, [windowFocused]); */
  // useEffect(() => {
  //   const handleFocus = () => {
  //     const userflow = localStorage.getItem("userflow");
  //     if (userflow === null) {
  //       onLogout();
  //     }
  //   };

  //   window.addEventListener("focus", handleFocus);
  //   return () => {
  //     window.removeEventListener("focus", handleFocus);
  //   };
  // }, []);

  useEffect(() => {
    if (i18nContextValue === null) {
      setI18nContextValue(
        createContextValue(
          // change to 'de-DE' or 'af-ZA' etc. to force language change
          languageCountryCodes[accounts[0]?.idTokenClaims.extension_CultureCode]
        )
      );
    }
  }, [i18nContextValue, accounts]);

  return (
    <HelmetProvider>
      <Il8Context.Provider value={i18nContextValue}>
        <MenuContextData>
          <AuthenticatedTemplate>
            {/* if user is login then islogin pass true in app route */}
            <AppRoute isLogin={true} />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            {/* if user is not login then islogin pass false in app route */}
            <AppRoute isLogin={false} />
          </UnauthenticatedTemplate>
        </MenuContextData>
      </Il8Context.Provider>
    </HelmetProvider>
  );
}

export default App;
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
