import React from "react";
import { useNavigate } from "react-router-dom";
import { dateFormate } from "../../../../utils";
import { T } from "../../../translation";

/**
 *
 * @param {number} Id - use for indicate id of particular order id
 * @param {string} Info - use for display information about order
 * @param {string} Date - use for display order date
 * @param {number} index - use for unique key
 * @returns This component return recent order list.
 */
function RecentOrderCard({ Id, Info, Date, index }) {
  const navigate = useNavigate();
  return (
    <section
      className="recent-order-card cursor-pointer"
      key={index}
      onClick={() => navigate(`/recentorder/${Id}`)}
    >
      <div className="recent-order-card-flex">
        <span className="recent-order-card-name">{Id}</span>
        <div className="recent-order-card-date">
          <span className="_date">
            <T strProp="Date" />
          </span>
          {": "}
          <span className="_date_value">{dateFormate(Date)}</span>
        </div>
      </div>
      <div className="recent-order-card-info">
        <span>{Info}</span>
      </div>
    </section>
  );
}

export default RecentOrderCard;
