import React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

/**
 * @param {boolean} checked - display swith checked or unchecked 
 * @param {function} onChange -use for call function when change switch 
 * @returns - display switch
 */
const IOSSwitch = styled((props, { checked, onChange }) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    sx={{ m: 1 }}
    checked={checked}
    onChange={onChange}
    {...props}
  />
))(({ theme }) => ({
  width: 35,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#00AEE1",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#D6D6D6",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

/**
 * @param {boolean} checked - display swith checked or unchecked 
 * @param {function} onChange -use for call function when change switch 
 * @returns - display blue switch
 */
const IOSSwitchBlue = styled((props, { checked, onChange }) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    sx={{ m: 1 }}
    checked={checked}
    onChange={onChange}
    {...props}
  />
))(({ theme }) => ({
  width: 35,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#00AEE1",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#D8EBF1",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

/**
* 
* @param {boolean} checked - display swith checked or unchecked 
* @param {function} setOnChange - call a function base on change event
* @param {boolean} background - use for display blue switch or simple switch
* @returns  display switches
*/
export default function SwichCompo({ disabled = false, checked, setOnChange = () => { }, background }) {
  const handleChange = (e) => setOnChange(e.target.checked);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          background ? (
            <IOSSwitchBlue checked={checked} onChange={handleChange} disabled={disabled} />
          ) : (
            <IOSSwitch checked={checked} onChange={handleChange} disabled={disabled} />
          )
        }
      />
    </FormGroup>
  );
}
