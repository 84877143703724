import React from "react";
import { useNavigate } from "react-router-dom";
import { T } from "../../translation";

/**
 * 
 * @param {string} ProductNo - use for display product number for redirect to particular product page
 * @param {string} manufacturer - use for display product manufacturer name
 * @param {string} ProductInfo - use for display product information
 * @returns this function can return card view of search product.
 */
function SearchProductsCard({ ProductNo, manufacturer, ProductInfo }) {
  const navigate = useNavigate();

  const goproductDetail = () => {
    navigate(`/product/${ProductNo}`);
  };

  return (
    <section className="search-products-card cursor-pointer" onClick={goproductDetail}>
      <div className="search-products-card-productcode">
        <b> <T strProp="searchPartNumber" /> </b>
        <span> {ProductNo ? ProductNo : "--  --"} </span>
      </div>
      <div className="search-products-card-manufacturer">
        <span> {manufacturer ? manufacturer : "--  --"} </span>
      </div>
      <div className="search-products-card-description">
        <span> {ProductInfo ?? "--  --"} </span>
      </div>
    </section>
  );
}

export default SearchProductsCard;
