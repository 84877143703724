import React, { forwardRef, useContext, useState } from "react";
import { useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";
import {
  ERROR_ORANGE_ICON,
  SUCCESS_BIG_ICON,
  WARNING_ICON,
} from "../../assets";
import { Il8Context } from "../../contexts";
import { onLogout } from "../../pages/Allmenu";
import ButtonModual from "../modual/ButtonModual";

/**
 * @param {function} onClose - call function when click on particular section
 * @param {refrence} ref - handal to error message
 * @returns - alert popup element 
 */
const AlertPopup = forwardRef(({ onClose = () => { } }, ref) => {
  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [show, setShow] = useState(false);
  const [btnTitle, setBtnTitle] = useState("");
  const [isLogOut, setIsLogOut] = useState(false);
  const [isGoBack, setIsGoBack] = useState(false);
  const [navLink, setNavLink] = useState("");
  const [goTO, setGoTO] = useState(0);
  const { t } = useContext(Il8Context);
  const navigate = useNavigate();
  const [reloadPage, setReloadPage] = useState(false);

  useImperativeHandle(ref, () => ({ onErr, onSuccess, onWarn }));

  const defBody =
    status === "error"
      ? "Something went wrong"
      : status === "warning"
        ? "Something went wrong. Please try again later."
        : status === "success"
          ? "Operation successful"
          : "";

  const defMsg =
    status === "error"
      ? "Error"
      : status === "warning"
        ? "Warning"
        : status === "success"
          ? "Success"
          : "";

  const defImg =
    status === "error"
      ? ERROR_ORANGE_ICON
      : status === "success"
        ? SUCCESS_BIG_ICON
        : status === "warning"
          ? WARNING_ICON
          : null;

  /**
   * 
   * @param {string} title - use for display title
   * @param {string} body - for display body
   * @param {string} btnTxt - display button text
   * @param {string} btnTxtLocal - display button text in localozation string
   * @param {string} titleLocal - display title text in localozation string
   * @param {string} bodyLocal - display body text in localozation string
   * @param {boolean} logOut - after click on alert box button whether logout user profile or not
   * @param {boolean} goBack - after click on alert box button whether go to redirect to previous page or not
   * @param {number} goTO - after click on alert box button whether perform any action or not
   * @param {string} navTo - use for navigation
   * @returns  display error alert pop up
   */
  const onErr = ({
    title,
    body,
    btnTxt,
    btnTxtLocal,
    titleLocal,
    bodyLocal,
    logOut = false,
    goBack = false,
    goTO = 0,
    navTo,
    reload
  }) => {
    setStatus("error");
    title && setTitle(title);
    titleLocal && setTitle(t(titleLocal));
    body && setBody(body);
    bodyLocal && setBody(t(bodyLocal));
    btnTxt && setBtnTitle(btnTxt);
    btnTxtLocal && setBtnTitle(t(btnTxtLocal));
    setShow(true);
    logOut === true && setIsLogOut(true);
    goBack === true && setIsGoBack(true);
    goTO !== 0 && setGoTO(goTO);
    navTo && setNavLink(navTo);
    reload && setReloadPage(reload);
  };

  /**
   * 
   * @param {string} title - use for display title
   * @param {string} body - for display body
   * @param {string} btnTxt - display button text
   * @param {string} btnTxtLocal - display button text in localozation string
   * @param {string} titleLocal - display title text in localozation string
   * @param {string} bodyLocal - display body text in localozation string
   * @param {boolean} logOut - after click on alert box button whether logout user profile or not
   * @param {boolean} goBack - after click on alert box button whether go to redirect to previous page or not
   * @param {number} goTO - after click on alert box button whether perform any action or not
   * @param {string} navTo - use for navigation
   * @returns  display success alert pop up
   */
  const onSuccess = ({
    title,
    body,
    btnTxt,
    btnTxtLocal,
    titleLocal,
    bodyLocal,
    logOut = false,
    goBack = false,
    goTO = 0,
    navTo,
    reload
  }) => {
    setStatus("success");
    title && setTitle(title);
    titleLocal && setTitle(t(titleLocal));
    body && setBody(body);
    bodyLocal && setBody(t(bodyLocal));
    btnTxt && setBtnTitle(btnTxt);
    btnTxtLocal && setBtnTitle(t(btnTxtLocal));
    setShow(true);
    logOut === true && setIsLogOut(true);
    goBack === true && setIsGoBack(true);
    goTO !== 0 && setGoTO(goTO);
    navTo && setNavLink(navTo);
    reload && setReloadPage(reload);
  };

  /**
   * 
   * @param {string} title - use for display title
   * @param {string} body - for display body
   * @param {string} btnTxt - display button text
   * @param {string} btnTxtLocal - display button text in localozation string
   * @param {string} titleLocal - display title text in localozation string
   * @param {string} bodyLocal - display body text in localozation string
   * @param {boolean} logOut - after click on alert box button whether logout user profile or not
   * @param {boolean} goBack - after click on alert box button whether go to redirect to previous page or not
   * @param {number} goTO - after click on alert box button whether perform any action or not
   * @param {string} navTo - use for navigation
   * @returns  display warning alert pop up
   */
  const onWarn = ({
    title,
    body,
    btnTxt,
    btnTxtLocal,
    titleLocal,
    bodyLocal,
    logOut = false,
    goBack = false,
    goTO = 0,
    navTo,
    reload
  }) => {
    setStatus("warning");
    title && setTitle(title);
    titleLocal && setTitle(t(titleLocal));
    body && setBody(body);
    bodyLocal && setBody(t(bodyLocal));
    btnTxt && setBtnTitle(btnTxt);
    btnTxtLocal && setBtnTitle(t(btnTxtLocal));
    setShow(true);
    logOut === true && setIsLogOut(true);
    goBack === true && setIsGoBack(true);
    goTO !== 0 && setGoTO(goTO);
    navTo && setNavLink(navTo);
    reload && setReloadPage(reload);
  };

  /**
  * 
  * @returns  display dismiss alert pop up
  */
  const onDismiss = () => {
    setShow(false);
    isLogOut === true && onLogout();
    setStatus("");
    setTitle("");
    setBody("");
    setBtnTitle("");
    onClose(true);
    isGoBack === true && navigate(-1);
    goTO < 0 && navigate(goTO);
    navLink && navigate(navLink)
  };
  return (
    <ButtonModual Open={show} onClose={onDismiss}>
      <section className="alert-popup">
        <div className="alert-popup-icon">
          {defImg && (
            <img src={defImg} alt="icon" className="alt-popup-h-w" />
          )}
          <span className="alert-popup-title">{title || defMsg}</span>
        </div>
        <div className="alert-popup-body alt-popup-text-transform">
          <span>{body || defBody}</span>
        </div>
        <div className="alert-popup-mr">
          <button className="small-btn-blue cursor-pointer" onClick={() => { onDismiss(); if (reloadPage) { window.location.reload(); setReloadPage(false) } }}>
            {btnTitle || "Close"}
          </button>
        </div>
      </section>
    </ButtonModual>
  );
});
export default AlertPopup;
