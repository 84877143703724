import React, { useContext } from "react";
import { useCallback } from "react";
import DatePicker from "react-datepicker";
import { isPostDate } from "../../utils";
import { T } from "../translation";
import "../../styles/components/datepicker.css";
import { Il8Context } from "../../contexts";

/**
 * @param {string} selctedDate - value of date
 * @param {function} setSelectedDate - set date value in state
 * @param {boolean} chkDateValidation - if any screen no need to validate date input then use this state
 * @param {function} setIsDateValidate - set date validate or not
 * @param {boolean} isDateValidate - value of date is validate or not
 * @param {string} title - value of set title of date pikker input  (ex. dispatch date)
 * @param {Boolean} isOrder - for check whether that component render in order page or not & base on that display dispatch date.
 * @param {string} minDate - for display minimum date in calender.
 * @param {boolean} isShipment - check whether is shipment or not.
 * @returns - date pikker component and validation
 */

const DatePickerInp = ({
  showTitle = true,
  selctedDate,
  setSelectedDate,
  chkDateValidation = false,
  setIsDateValidate = () => { },
  isDateValidate = false,
  title = "",
  isOrder = false,
  minDate = new Date(),
  isShipment = false,
  placeholder,
  clearDate,
  maxDate,
  customTitle,
  datePickerRef,
  errMessage
}) => {
  const { t } = useContext(Il8Context);
  // console.log((t()))
  const dateCall = useCallback(() => {
    return (
      <>
        <div>
          <div className="text-input" /* onClick={() => console.log("dateClicked")} */>
            {showTitle && <span className="text-input__title">
              <T strProp={title ? `${title}` : isShipment ? "DeliveryDate" : "DispatchDate"} />
              {" *"}
            </span>}
            <DatePicker
              ref={datePickerRef}
              placeholderText={placeholder}
              onFocus={(e) => e.target.blur()}
              selected={selctedDate}
              onChange={(date) => {
                setSelectedDate(date);
                chkDateValidation && setIsDateValidate(dateVlidation(date));
              }}
              withPortal
              portalId="root-portal"
              className={
                isDateValidate ? "datepicker-portal-error" : "datepicker-portal"
              }
              minDate={isOrder ? new Date(minDate) : new Date()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="dd-MMM-yyyy"
              maxDate={maxDate ? maxDate : null}
              isClearable={clearDate}
            // renderDayContents={<img src={CLOSE_BUTTON_ICON} />}
            >{customTitle && <div className="customdatepicker-title"><div>{customTitle}</div></div>}</DatePicker>

          </div>
          {isDateValidate ? (
            <span className="text-input-error-title">
              {errMessage ? t(errMessage) : <T strProp="PleaseEnterFutureDate" />}
            </span>
          ) : null}
        </div>
      </>
    );
    // eslint-disable-next-line
  }, [
    isDateValidate,
    selctedDate,
    setSelectedDate,
    chkDateValidation,
    setIsDateValidate,
    title,
    isOrder,
    minDate,
    isShipment
  ]);
  return dateCall();
}
export default DatePickerInp;

/**
 * @param {string} date - value of user selected date
 * @param {function} setIsDateValidate - set date is validate or not
 * @returns - bollean (validate = false , not validate = true)
 */
export const dateVlidation = (date, setIsDateValidate = () => { }) => {
  if (isPostDate(date)) {
    setIsDateValidate(false);
    return false;
  } else {
    setIsDateValidate(true);
    return true;
  }
};
