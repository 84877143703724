import dayjs from "dayjs";
import React from "react";
import { forwardRef } from "react";
import { LOCATION_ORANGE_ICON, NO_FOUND_IMG_M } from "../../assets";
import { T } from "../translation";
import "../../styles/components/trainingEvents/trainingevents.css";

/**
 * 
 * @param {number} isGrid - use for display grid
 * @param {object} item - use for display item details
 * @param {referance} ref - use for display referance
 * @returns this functiona can display training and event in card view.
 */
const TrainingEventsCard = forwardRef(({ isGrid, item }, ref) => {
  const TrainingEventsBody = (
    <section
      className="trae-card"
      onClick={(e) => {
        e.stopPropagation();
        window.open(item?.link, "_blank");
      }}
    >
      {isGrid && (
        <>
          <div className="trae-no-found-img">
            <img
              className="default-img"
              src={item?.image?.src}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = NO_FOUND_IMG_M;
              }}
              alt="Training Events"
            />
          </div>
          <div className="border-line-1px" />
        </>
      )}
      <div className="trae-padding">
        <div className="trae-title">
          <span>{item?.title || "--  --"}</span>
        </div>
        <div className="trae-info">
          <span>{item?.description || "--  --"}</span>
        </div>
        <div className="trae-flex">
          <span className="trae-text">
            {dayjs(item?.date).format("D MMM, YYYY HH:mm")}
          </span>
          {item?.distance !== undefined && typeof item?.distance === "number" && (
            <div className="trae-location">
              <img src={LOCATION_ORANGE_ICON} alt="Location" />
              <div className="trae-location-name">
                <div className="trae-location-number">{item?.distance}</div>
                <div>
                  <T strProp="eventMilesAway" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );

  const content = ref ? (
    <div ref={ref}>{TrainingEventsBody}</div>
  ) : (
    <div>{TrainingEventsBody}</div>
  );

  return content;
});

export default TrainingEventsCard;
