import { language } from "../contexts/i18context";
import { dateOptionsConfig, defaultLocale } from "./constants";
import { getCurrency } from "./utils";

/**
 * 
 * @param {string} timestamp - for pass timestamp
 * @param {string} locale - locale defaults to en-GB
 * @param {string} dateType - for specify how formate display date
 * @returns this function can take multiple parameters and return date with proper formate.
 */
const createDate = (timestamp, locale = defaultLocale, dateType = "short") => {
  const dateOptions = dateOptionsConfig[locale]
    ? dateOptionsConfig[locale]
    : dateOptionsConfig[defaultLocale];
  return new Date(timestamp).toLocaleString(locale, dateOptions[dateType]);
};

/**
 * 
 * @param {string} locale - locale defaults to en-GB
 * @param {string} currency - for display currency type
 * @param {number} amount - for display amount
 * @returns this finction can take different parameters and display lcal price with currency.
 */
const createLocalePrice = (locale, currency, amount) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(amount);
};

/**
 * returns an object of dates
 * @param timestamp
 * @param {string} locale - locale defaults to en-GB
 * @returns {{dueDateTimestamp, dueState: string, dueStateLong: string}}
 * 
 *
 * dueDate defaults to short format which is the default required
 */
const createMultipleDates = (timestamp, locale = defaultLocale) => {
  return {
    dueDateTimestamp: timestamp,
    dueDateLong: createDate(timestamp, locale, "long"),
    dueDate: createDate(timestamp, locale),
  };
};

/***
 * used in basket we don't have locale (in basket response)
 * so we need to make an assumption based on current
 * eg { amount: 7.57, currency: 'Gbp'} => £7.57
 *
 * @param object
 * @param locale
 */
const createPriceFromObject = (obj, currencyValue) => {
  const { amount } = obj;
  const { currency, locale } = getCurrency(currencyValue);
  return createLocalePrice(locale, currency, amount);
};

const createPriceFromValue = (amount, currencyValue) => {
  const { currency, locale } = getCurrency(currencyValue);
  return createLocalePrice(locale, currency, amount);
};

const createTaxValueFromObject = (obj, currencyValue) => {
  const { value } = obj;
  const { currency, locale } = getCurrency(currencyValue);
  return createLocalePrice(locale, currency, value);
};

// todo more test
/**
 * 
 * @param {number} amount - use for calculate discount
 * @param {number} currencyValue - base on currency value calculate amount
 * @param {number} discountPercentage - for calculate final price
 * @returns this function can return discounted amount and full amount base on amount, currency value and discount percents.
 */
const createPriceWithDiscountFromObject = (
  amount,
  currencyValue,
  discountPercentage
) => {
  const { currency, locale } = getCurrency(currencyValue);
  let discountAmt = parseFloat("." + discountPercentage) * amount;
  discountAmt = discountAmt?.toPrecision(2);
  const amountWithDiscount = amount - discountAmt;
  return {
    discountAmount: createLocalePrice(locale, currency, amountWithDiscount),
    fullAmount: createLocalePrice(locale, currency, amount),
  };
};

/**
 * 
 * @param {string} date - use for formate date
 * @returns this function can display date in proper formate.
 */
const DateFormat = (date) => {
  const newDate = new Intl.DateTimeFormat(language).format(new Date(date));
  return newDate
};



export {
  createMultipleDates,
  createLocalePrice,
  createDate,
  createPriceFromValue,
  createPriceFromObject,
  createTaxValueFromObject,
  createPriceWithDiscountFromObject,
  DateFormat,
};
