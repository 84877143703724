import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { GlobalContext, Il8Context } from '../../contexts';
import { useSessionHook } from '../../hooks';
import { callApi } from '../../services';
import DropDown from '../DropDown';
import AlertPopup from '../Modals/AlertPopup';
import { T } from '../translation';

/**
 * 
 * @param {string} selectedValue - use for display selected value
 * @param {string} deliveryMethod - use for display initial delivery method
 * @param {function} setDeliveryMethod - use for display delivery method
 * @param {function} setDeliveryObj - use for display object
 * @param {referance} validateRef - use for display pop up model after api call
 * @param {referance} ref - handal to validation
 * @returns this functiona can display delivery method input dropdown component.
 */
const DeliveryMethodInp = forwardRef(({
    selectedValue,
    deliveryMethod,
    setDeliveryMethod,
    setDeliveryObj,
    validateRef = null,
}, ref) => {
    const sessionHelpers = useSessionHook();
    const { token } = sessionHelpers.getStatusAndToken();
    const [loading, setLoading] = useState(false);
    const [deliveryMethodData, setDeliveryMethodData] = useState([]);
    const [listMethodOnlyName, setListMethodOnlyName] = useState([]);
    const { state } = useContext(GlobalContext);
    const alertRef = useRef(null);

    const { t } = useContext(Il8Context);
    useImperativeHandle(ref, () => ({ getDeliveryMethod }));

    /**
     * 
     * @param {object} InpObj - use for pass initial object for fetch details of delivery method
     * @returns this function can fetch delivery method using api and set different delivery method in delivery method data state.
     */
    const getDeliveryMethod = async (InpObj) => {
        !loading && setLoading(true);
        await callApi("deliveryMethod", { token, alertRef }, InpObj)
            .then(({ res, sCode }) => {
                if (sCode === 200 || sCode === 202) {
                    setDeliveryMethodData([]);
                    setDeliveryMethod('');
                    let arrData = [{ id: '', name: t(res?.body?.result.length > 0 ? "SelectDeliveryMethod" : 'NotMatchingDeliveryMethod') }];
                    let OtherData = [{ id: '', name: t(res?.body?.result.length > 0 ? "SelectDeliveryMethod" : 'NotMatchingDeliveryMethod') }];
                    res?.body?.result.map((i) =>
                        arrData.push({
                            name: `${i?.name} (${state?.acObj?.currency?.symbol} ${i?.cost?.toFixed(2)})`,
                            id: i?.id,
                        })
                    );
                    res?.body?.result.map((i) =>
                        OtherData?.push({
                            name: `${i?.name}`,
                            id: i?.id,
                        })
                    );
                    setDeliveryMethodData(arrData);
                    setListMethodOnlyName(OtherData);
                }
            })
            .catch((err) => console.log("error readytoShipOtherItem", err))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        const updateSelectedMethod = (listData) => {
            let method = listData?.find((e) => e.name === selectedValue);
            setDeliveryMethod(method?.id);
        }
        updateSelectedMethod(listMethodOnlyName);
        // eslint-disable-next-line
    }, [listMethodOnlyName]);
    // }, [selectedValue, listMethodOnlyName]);

    const dliveryCall = useCallback(() => {
        return (
            <div className="margin_bottom">
                <DropDown
                    title={t("quoteDeliveryMethod")}
                    data={deliveryMethodData}
                    val={deliveryMethod}
                    nameKey="name"
                    valKey="id"
                    onChangeVal={setDeliveryMethod}
                    onChangeObj={(obj) => {
                        setDeliveryObj(obj);
                        validateRef !== null && validateRef.current?.handleInputBlur("", "");
                    }}
                    isDisabled={deliveryMethodData?.length === 0}
                    ref={validateRef}
                    Required
                    isLoading={loading}
                />
            </div>
        );
    }, [deliveryMethodData, loading, deliveryMethod, setDeliveryMethod, setDeliveryObj, validateRef, t]);

    return (
        <>
            {dliveryCall()}
            <AlertPopup ref={alertRef} />
        </>
    )
});

export default DeliveryMethodInp

/**
 * @param {string} value - user input value of delivery method
 * @param {refrence} ref - handal to validation
 * @returns - bollean (validate = true , not validate = false) 
 */
export const DeliveryMethodValidation = (value, ref) => {
    if (value === undefined || value?.id === null || value === "Select Delivery Method") {
    // if (value?.length === 0 || value === "Select Delivery Method") {
        ref.current?.handleInputBlur(
            true, <T strProp="PleaseSelectDeliveryMethod" />, true
        );
        return false;
    } else {
        ref.current?.handleInputBlur("", "");
        return true;
    }
};