import { useEffect, useState } from "react";
import { useGlobalActionsHook } from "./useGlobalActionHook";

/**
 * currently only used for http errors
 * @param statuses
 */
const UseHttpModalHook = () => {
  const { dispatch, globalActionsTypes, openModal } = useGlobalActionsHook();
  const [statuses, setStatuses] = useState(false);

  useEffect(() => {
    if (!statuses) {
      return;
    }

    const { status, statusText } = statuses;
    let modalType;
    let modalMessage;

    if (status === 400) {
      modalType = "ModalContentHttp400";
      modalMessage = statusText;
    } else {
      modalType = "ModalContentHttpOther";
    }

    openModal(modalType, modalMessage);
  }, [statuses, dispatch, globalActionsTypes.setModal, openModal]);

  return setStatuses;
};

export { UseHttpModalHook };
