import { T } from "../components";
import { ErrorHandlHook } from "../hooks";
import { urls } from "./urls";

/**
 * call api function used to calling api
 * @returns {Promise<res :{headers: null, body: null, error: null}, sCode: number, eMessage: string, msg: string>}
 * @param type - api name etc..
 * @param sessionData - in this data we can pan any data like token , refrence , noback ,msg404 ,etc...
 * @param value - refrence code , api input object ,etc.. are in value
 */
const headerX_Originator = process.env.REACT_APP_HEADER_X_ORIGINATOR;
const Ocp_Apim_Subscription_Key = process.env.REACT_APP_APIM_SUB_KEY;
async function callApi(type, sessionData, ...values) {
  let response = {
    headers: null,
    body: null,
    error: null,
  };
  const { token } = sessionData; // use for user authentication
  const alertRef = sessionData?.alertRef || null; // refrence used to hendal alert popup on warning or error
  const contactUs = sessionData?.contactUs || null; // refrence used to hendal alert popup on warning or error
  const signal = sessionData?.signal || null;
  // const msg404 = sessionData?.msg404;   --  404 = not found, pass to not found message to user in
  // alet popup (Ex.part not found , quote not found) || but after set response warning message on
  // alert popup this is not usefull
  const noBack = sessionData?.noBack !== undefined ? sessionData?.noBack : true; // if 404 not found
  //  in no navigate back then use this noBack
  const isEmailProformaPage = sessionData?.isEmailProformaPage || false; // in shipment detail screen
  // email proforma api success response is not good so use this boolean state to display success alert on
  // success response
  if (token || contactUs) {
    const options = urls[type](...values);
    const mode = "cors";
    const headers = {
      ...options.additionHeaders,
      Authorization: `Bearer ${contactUs ? "" : token}`,
      "Access-Control-Allow-Origin": "*",
      "X-Originator": headerX_Originator,
      "Ocp-Apim-Subscription-Key": Ocp_Apim_Subscription_Key
    };

    const res = await fetch(options.url, {
      ...options.opts,
      mode,
      headers,
      signal: signal,
    });
    if (isEmailProformaPage) {
      response.statuses = {
        status: res?.status,
        statusText: res?.statusText,
      };
    }
    if (!isEmailProformaPage) {
      if (res?.ok) {
        let body = await res?.json();
        response.body = body;
        response.headers = {};
        response.statuses = {
          status: res?.status,
          statusText: res?.statusText,
        };
      } else {
        let body = await res?.text();
        response.body = body;
        response.statuses = {
          status: res?.status || undefined,
          statusText: res?.statusText || "",
        };
        // throw `Request to Ordering API failed: ${res?.status} ${res?.statusText}`;
      }
    }
  } else {
    response.error = `No access token supplied". API request not attempted.`;
    alertRef?.current?.onErr({
      titleLocal: "errUnAuth",
      bodyLocal: "err401",
      logOut: true,
    });
  }
  /**
   * handlehook is used to hendle error or warning
   * @param {string} response?.statuses?.status - status code
   * @param {string} response?.statuses?.statusText - status text
   * @returns sCode - status code from api , eMessage - error message from api , msg  - pass user message to any
   */
  let notFound404;
  if (
    response?.statuses?.status === 404 ||
    response?.statuses?.status === 400
  ) {
    const toJSONObj = JSON.parse(response?.body);
    const dynamicKey = toJSONObj?.errors ? Object?.keys(toJSONObj?.errors)[0] : "";
    const errorMessages = toJSONObj?.errors ? toJSONObj?.errors[dynamicKey]?.join(", ") : "";
    notFound404 = errorMessages ? ({ result: errorMessages }) : toJSONObj;
  }
  const errorRes = response?.body?.result?.toString()?.replace(/,/g, " ");

  const { sCode, eMessage, msg } = ErrorHandlHook(
    response?.statuses?.status || 500,
    response?.body?.title ||
    response?.body?.result?.message ||
    response?.body?.result ||
    errorRes || notFound404?.message ||
    notFound404?.result ||
    response?.statuses?.statusText ||
    "SomethingWentWrong",
    //response?.result,
    response?.statuses?.statusText
  );

  // this condition use to hendal error or warning alert pop up
  if (sCode === 200 || sCode === 202) {
    if (isEmailProformaPage) {
      alertRef?.current?.onSuccess({
        bodyLocal: "EmailProformaSuucess",
        btnTxt: <T strProp="BtnOK" />,
      });
    }
  } else {
    alertRef?.current?.onErr({
      titleLocal: sCode === 401 ? "errUnAuth" : "error",
      body: sCode === 401 || sCode === 403 ? undefined : eMessage,
      bodyLocal:
        sCode === 401 || sCode === 403
          ? eMessage
          : /* sCode === 404 ? msg404 : */ undefined,
      logOut: sCode === 401,
      goBack: noBack === true && sCode === 404,
      btnTxtLocal: sCode === 401 && "logoutText",
    });
  }
  let res = response;
  return { res, sCode, eMessage, msg };
}

export { callApi };
