import React, { useEffect, useState } from "react";
import { useSessionHook } from "../../hooks";
import { callApi } from "../../services";
import DropDown from "../DropDown";
import { T } from "../translation";

/**
 * 
 * @param {string} deliveryPreferredData - use for display selected value in list
 * @param {function} setDeliveryPreferredData - use for set delivery method on change
 * @param {referance} deliveryPreRef - use for referance
 * @param {boolean} isRequired - use for display that field was require or not
 * @param {boolean} preferredDly - use for display title base on that value true or false
 * @returns this functiona can display delivery method preferance dropdown.
 */
const DeliveryMethodPreferenceInp = ({
  deliveryId,
  deliveryPreferredData,
  setDeliveryPreferredData,
  deliveryPreRef = null,
  isRequired = false,
  preferredDly,
}) => {
  const sessionHelpers = useSessionHook();
  const { token } = sessionHelpers.getStatusAndToken();
  const [data, setData] = useState([]);
  const [loadingPrefrence, setLoadingPrefrence] = useState(false);
  useEffect(() => {
    if (token === null) return;
    const getdeliveryPreference = async () => {
      !loadingPrefrence && setLoadingPrefrence(true);
      await callApi("deliveryMethodPreference", { token })
        .then(({ res, sCode }) => {
          if (
            (sCode === 200 || sCode === 202) &&
            res?.body?.result?.length > 0
          ) {
            let arr = res?.body?.result?.map(i => ({ 'value': i.name, "id": i.id })) || [];
            setData(arr);
            arr?.forEach((i) => {
              i.id === deliveryId && setDeliveryPreferredData(i.value)
            });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoadingPrefrence(false);
        });
    };
    getdeliveryPreference();
    // eslint-disable-next-line
  }, [token]);

  return (
    <div className="margin_bottom">
      <DropDown
        title={
          <T
            strProp={
              preferredDly
                ? "quotePreferredDelivery"
                : "quoteDeliveryPreference"
            }
          />
        }
        data={data}
        val={deliveryPreferredData}
        nameKey="value"
        valKey="value"
        onChangeVal={(e) => {
          setDeliveryPreferredData(e);
          isRequired && deliveryPreRef.current?.handleInputBlur("", "");
        }}
        isDisabled={data?.length === 0}
        Required={isRequired && true}
        ref={isRequired ? deliveryPreRef : null}
      />
    </div>
  );
};

export default DeliveryMethodPreferenceInp;
/**
 * @param {string} value - user input value of delivery prefrence
 * @param {refrence} ref - handal to validation
 * @returns - handal to  delivery prefrence validation ,
 *  bollean (validate = true , not validate = false)
 */
export const deliveryPreferenceValidation = (value, ref) => {
  if (value?.trim().length === 0 || value === undefined) {
    ref.current?.handleInputBlur(
      true,
      <T strProp="PleaseSelectDeliveryPrefrence" />,
      true
    );
    return false;
  } else {
    ref.current?.handleInputBlur("", "");
    return true;
  }
};
