import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { msalInstance } from "..";
import {
  ACCOUNT_PAYMENT_ICON,
  ACCOUNT_SUMMARY,
  CLOSE_WHITE_ICON,
  CONTACT_PREFERENCES_ICON,
  CURRENT_INVOICES_ICON,
  CURRENT_ORDERS_ICON,
  HISTORY_ICON,
  LOGOUT_ICON,
  PHONE_BLUE_ICON,
  PLANNEDSHIPMENTS_ICON,
  QUOTES_ICON,
  RIGHT_ARROW_GRAY_ICON,
  SETTINGS_ICON,
  STATEMENT_ICON,
  AC_RETURN
} from "../assets";
import { ButtonModual, Layout, T } from "../components";
import { globalActionsTypes } from "../hooks";
import Profile from "./Profile";
import "../styles/components/allMenu/allmenu.css";
import { GlobalContext } from "../contexts";
import { sendEvents } from "../utils/googleAnalytics";

/**
 * @returns onLogout function used to logout user and remove user cradential
 */
export const onLogout = () => {
  sendEvents('authentication', 'SignOut')
  msalInstance.logoutRedirect({ authority: process.env.REACT_APP_B2C_SIGNUP_SIGNIN_AUTHORITY_BASE });
  // msalInstance.logoutPopup();
  localStorage.removeItem("userflow");
  localStorage.removeItem(globalActionsTypes.setRootHistoryPath);
};

/**
 * all menu is display menus list
 * @returns - menu name and icon elements
 */
function Allmenu() {
  const [create, setCreate] = useState(false);
  const { state } = useContext(GlobalContext);


  const Open = () => setCreate(true);
  const Close = () => setCreate(false);

  const hostURL = process.env.REACT_APP_API_TYPE;

  return (
    <>
      <Layout title="acMenuAccount" id="account">
        <div className="complete-page" />
        <Profile />
        <div className="account-menu-box">
          {Menu.map((item, index) => {
            if (!state?.acObj?.accountSummary?.tasCode) {
              if (item.Title === "titleCurrentOrders" || item.Title === "titlePlannedShipments" || item.Title === "acMenuAccountSummary" || item.Title === "acMenuPayments" || item.Title === "titleHistory") return <Fragment key={index} />
            }
            return (
              <Link
                to={item?.Path}
                state={{ back: false }}
                className={`account-menu-alignment ${item?.None && `display_none`
                  }`}
                key={index}
              >
                {/*  {item?.Status === "Red" ? (
                  <div className="panding-page all-menu-margin" />
                ) : null} */}
                {/* {item?.Status === "Green" ? (
                  <div
                    className="complete-page all-menu-margin"
                  />
                ) : null} */}
                {/* {item?.Status === "Orange" ? (
                  <div
                    className="incomplete-page all-menu-margin"
                  />
                ) : null} */}
                <div className="account-menu-contant">
                  <img
                    src={item?.IMG}
                    alt="Quoatas"
                    className="account-menu__icon"
                  />
                  <span className="account-menu-title">
                    <T strProp={item?.Title} />
                  </span>
                </div>
                <div className="account-menu-Arr-icon">
                  {item?.Arrow === true ? (
                    <img src={RIGHT_ARROW_GRAY_ICON} alt=">" />
                  ) : null}
                </div>
              </Link>
            );
          })}
          {/* ================================================================== */}
          <div className="account-menu-alignment cursor-pointer" onClick={Open}>
            <div className="account-menu-contant">
              <img
                src={LOGOUT_ICON}
                alt="login icon"
                className="account-menu__icon"
              />
              <span className="account-menu-title">
                <T strProp="acMenuLogOut" />
              </span>
            </div>
            <div className="complete-page all-menu-margin" />
            <div className="account-menu-Arr-icon">
              <div className="panding-page all-menu-margin-new" />
            </div>
          </div>

          {/* ================================================================== */}
        </div>
        <span className="account-menu-title">HOST : {hostURL}</span>
        <ButtonModual Open={create}>
          <div className="popup_event">
            <div className="popup_event-flex">
              <img src={CLOSE_WHITE_ICON} alt="icon" onClick={Close} />
            </div>
            <span className="popup_event-text">
              <T strProp="acMenuLogOut" />
            </span>
            <div className="popup_event__text">
              <span>
                <T strProp="logoutinfo" />
              </span>
            </div>
            <div>
              <button
                onClick={onLogout}
                className="small-btn-blue cursor-pointer"
              >
                <T strProp="Yes" />
              </button>
              <button
                onClick={Close}
                className="small-btn-white cursor-pointer"
              >
                <T strProp="No" />
              </button>
            </div>
          </div>
        </ButtonModual>
      </Layout>
    </>
  );
}

export default Allmenu;

const Menu = [
  {
    Path: "/quotes",
    IMG: QUOTES_ICON,
    Title: "titleQuotes",
    Arrow: false,
    Status: "Green",
    None: false,
  },
  {
    Path: "/order",
    IMG: CURRENT_ORDERS_ICON,
    Title: "titleCurrentOrders",
    Arrow: false,
    Status: "Green",
    None: false,
  },
  {
    Path: "/plannedshipments",
    IMG: PLANNEDSHIPMENTS_ICON,
    Title: "titlePlannedShipments",
    Arrow: false,
    Status: "Green",
    None: false,
  },
  {
    Path: "/statement",
    IMG: STATEMENT_ICON,
    Title: "titleStatement",
    Arrow: false,
    Status: "Red",
    None: true,
  },
  {
    Path: "/accountsummary",
    IMG: ACCOUNT_SUMMARY,
    Title: "acMenuAccountSummary",
    Arrow: false,
    Status: "Green",
    None: false,
  },
  {
    Path: "/payment",
    IMG: ACCOUNT_PAYMENT_ICON,
    Title: "acMenuPayments",
    Arrow: true,
    Status: "Green",
    None: false,
  },
  {
    Path: "/currentinvoice",
    IMG: CURRENT_INVOICES_ICON,
    Title: "titleCurrentInvoices",
    Arrow: false,
    Status: "Red",
    None: true,
  },
  {
    Path: "/history",
    IMG: HISTORY_ICON,
    Title: "titleHistory",
    Arrow: true,
    Status: "Orange",
    None: false,
  },
  {
    Path: "/contactpreferences",
    IMG: CONTACT_PREFERENCES_ICON,
    Title: "titleContactPreferences",
    Arrow: false,
    Status: "Red",
    None: true,
  },
  {
    Path: "/newreturn",
    IMG: AC_RETURN,
    Title: "titleNewReturns",
    Arrow: false,
    Status: "Green",
    None: false,
  },
  {
    Path: "/setting",
    IMG: SETTINGS_ICON,
    Title: "acMenuSetting",
    Arrow: true,
    // Status: "Red",
    None: false,
  },
  {
    Path: "/contactus",
    IMG: PHONE_BLUE_ICON,
    Title: "titleContactUs",
    Arrow: false,
    Status: "Green",
    None: false,
  },
];
