import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { Link } from "react-router-dom";
import { T } from "../../translation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  outline: "none",
};

/**
 * 
 * @returns this function can dispaly general setting module.
 */
export default function GeneralSettingModual() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span className="general-settings-sticky-title" onClick={handleOpen}>
        <T strProp="OtherAccountSettings" /> &gt;
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="modual-box" sx={{ ...style, width: 300 }}>
          {GeneralData.map((item, index) => {
            return (
              <section className="modual" key={index}>
                <Link to={item?.Path} className="link">
                  <div className="modual__">
                    <span>{item?.Title}</span>
                  </div>
                </Link>
                {GeneralData.length - 1 === index ? null : (
                  <div className="border-line-1px" />
                )}
              </section>
            );
          })}
        </Box>
      </Modal>
    </div>
  );
}

const GeneralData = [
  { Title: "Delivery Address Book", Path: "/delivery_addressbook" },
  { Title: "User Security Level", Path: "/usersecuritylevel" },
  { Title: "Change Password", Path: "/changepassword" },
  { Title: "Change Stock Location", Path: "/changestocklocation" },
];
