import React from "react";

function TicketView() {
  return (
    <div className="ticket-border">
      <span className="ticket-shape" />
      <span className="ticket-shape" />
      <span className="ticket-shape" />
      <span className="ticket-shape" />
      <span className="ticket-shape" />
      <span className="ticket-shape" />
      <span className="ticket-shape" />
      <span className="ticket-shape" />
      <span className="ticket-shape" />
      <span className="ticket-shape" />
      <span className="ticket-shape" />
      <span className="ticket-shape" />
      <span className="ticket-shape" />
      <span className="ticket-shape" />
      <span className="ticket-shape" />
    </div>
  );
}

export default TicketView;
