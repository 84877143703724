import { useMsal } from "@azure/msal-react";
import React, { useContext } from "react";
import "../styles/components/navbar/profile.css";
import { LOCATION_BLUE_ICON } from "../assets";
import { T } from "../components";
import { GlobalContext } from "../contexts";
/**
 * user name and user logo return
 * @returns - user first name first character and last name first character 
 */
function Profile() {
  let { accounts } = useMsal();
  const { state } = useContext(GlobalContext);
  let location = state.acObj?.stockLocation?.description
  let fName = accounts[0]?.idTokenClaims?.given_name ?? "";
  let lName = accounts[0]?.idTokenClaims?.family_name ?? "";
  let fullName = fName + " " + lName;
  let sortName = `${fName?.charAt(0)?.toUpperCase()}${lName
    ?.charAt(0)
    ?.toUpperCase()}`;
  return (
    <section className="profile">
      <div className="profile-pic">
        <div className="user-profile">
          <span className="user-profile-letter">{sortName}</span>
        </div>
        <span className="profile-name">{fullName}</span>
        {location && <div className="locationDiv">
          <img src={LOCATION_BLUE_ICON} alt="location pin" />
          <span className="locationTitle"> <T strProp={"StockLocation"} /> <span className="locationName"> - {location}</span></span>
        </div>}
      </div>
    </section>
  );
}

export default Profile;
