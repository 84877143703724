import { GlobalContext } from '../contexts/globalContext';
import { globalActionsTypes } from './globalReducer';
import { useContext } from 'react';

/**
 * reuseable dispatch actions and can pass dispatch/state down to any component
 */
const useGlobalActionsHook = () => {
  const { state, dispatch } = useContext(GlobalContext);

  const closeModal = () => {
    dispatch({
      type: globalActionsTypes.closeModal
    });
  };

  /**
   * function for closing global modal
   * @param evt
   */
  const closeModalAction = (evt) => {
    // stop the propagation otherwise multiple events will fire
    evt.stopPropagation();
    dispatch({
      type: globalActionsTypes.closeModal
    });
  };

  /**
   * 
   * @param {string} modalType use for specify mdoel type
   * @param {string} modalMessage use for display model message
   * @param {object} modalData use for display model data
   * @returns this function can use for open model
   */
  const openModal = (modalType, modalMessage = '', modalData = '') => {
    // stop the propagation otherwise multiple events will fire
    dispatch({
      type: globalActionsTypes.setModal,
      data: {
        modalType,
        modalMessage,
        modalData
      }
    });
  };

  return {
    dispatch,
    globalActionsTypes,
    closeModalAction,
    closeModal,
    openModal,
    state
  };
};

export { useGlobalActionsHook };
