/* put in a constant file? */
const defaultLocale = "en-GB";
const defaultCurrency = "GBP";

const localeMapping = {
  "en-GB": {
    currency: "GBP",
    locale: "enGB",
    dir: "ltr",
  },
  "de-DE": {
    currency: "EUR",
    locale: "deDE",
    dir: "ltr",
  },
  "en-ZA": {
    currency: "ZAR",
    locale: "enZA",
    dir: "ltr",
  },
  "ar-AE": {
    currency: "AED",
    locale: "arAE",
    dir: "rtl",
  },
};

const currencyCodes = {
  USD: {
    currency: "US Dollars",
    locale: "en-us",
  },
  AED: {
    currency: "United Arab Emirates Dirham",
    locale: "ar-ae",
  },
  ARS: {
    currency: "Argentine Pesos",
    locale: "es-ar",
  },
  AUD: {
    currency: "Australian Dollars",
    locale: "	en-au",
  },
  BOB: {
    currency: "Bolivian Boliviano",
    locale: "es-ar",
  },
  BRL: {
    currency: "Brazilian Real",
    locale: "pt-br",
  },
  CAD: {
    currency: "Canadian Dollars",
    locale: "en-ca",
  },
  CHF: {
    currency: "Swiss Francs",
    locale: "fr-FR",
  },
  CLP: {
    currency: "Chilean Peso",
    locale: "es-cl",
  },
  CNY: {
    currency: "Yuan Renminbi",
    locale: "zh-CN",
  },
  COP: {
    currency: "Colombian Peso",
    locale: "es-co",
  },
  DKK: {
    currency: "Denmark Kroner",
    locale: "da-DK",
  },
  EGP: {
    currency: "Egyptian Pound",
    locale: "ar-EG",
  },
  EUR: {
    currency: "Euros",
    locale: "de-DE",
    // locale: "de_AT",
  },
  FRF: {
    currency: "French Francs",
    locale: "fr-FR",
  },
  GBP: {
    currency: "British Pounds",
    locale: "en-GB",
  },
  HKD: {
    currency: "Hong Kong Dollars",
    locale: "zh-hk",
  },
  HRK: {
    currency: "Croatian Kuna",
    locale: "hr",
  },
  // HUF: {
  //   currency: "Hungarian Forint",
  //   locale: "hu",
  // },
  IDR: {
    currency: "Indonesian Rupiah",
    locale: "id",
  },
  ILS: {
    currency: "Israeli Shekel",
    locale: "ar-IL",
  },
  INR: {
    currency: "Indian Rupee",
    locale: "en-in",
  },
  JPY: {
    currency: "Japanese Yen",
    locale: "ja",
  },
  KRW: {
    currency: "South Korean Won",
    locale: "ko-KP",
  },
  // LTL: {
  //   currency: "Lithuanian Litas",
  //   locale: "lt",
  // },
  MAD: {
    currency: "Moroccan Dirham",
    locale: "ar-ma",
  },
  MXN: {
    currency: "Mexican Peso",
    locale: "es-mx",
  },
  MYR: {
    currency: "Malaysian Ringgit",
    locale: "en-MY",
  },
  NOK: {
    currency: "Norway Kroner",
    locale: "no-NO",
  },
  NZD: {
    currency: "New Zealand Dollars",
    locale: "en-NZ",
  },
  PEN: {
    currency: "Peruvian Nuevo Sol",
    locale: "es-pe",
  },
  PHP: {
    currency: "Philippine Peso",
    locale: "en-PH",
  },
  PKR: {
    currency: "Pakistan Rupee",
    locale: "en-PK",
  },
  PLN: {
    currency: "Polish New Zloty",
    locale: "pl-PL",
  },
  RON: {
    currency: "New Romanian Leu",
    locale: "ro-RO",
  },
  RSD: {
    currency: "Serbian Dinar",
    locale: "sr-RS",
  },
  RUB: {
    currency: "Russian Ruble",
    locale: "ru-RU",
  },
  SAR: {
    currency: "Saudi Riyal",
    locale: "ar-SA",
  },
  SEK: {
    currency: "Sweden Kronor",
    locale: "en-SE",
  },
  SGD: {
    currency: "Singapore Dollars",
    locale: "zh-SG",
  },
  THB: {
    currency: "Thai Baht",
    locale: "th-TH",
  },
  TRY: {
    currency: "Turkish Lira",
    locale: "tr-TR",
  },
  TWD: {
    currency: "New Taiwan Dollar",
    locale: "zh-tw",
  },
  UAH: {
    currency: "Ukrainian Hryvnia",
    locale: "uk-UA",
  },
  VEF: {
    currency: "Venezuela Bolivar Fuerte",
    locale: "es-VE",
  },
  VND: {
    currency: "Vietnamese Dong",
    locale: "vi-VN",
  },
  ZAR: {
    currency: "South African Rand",
    locale: "en-ZA",
  },
  // SEK: {
  //   currency: "Swedish (Sweden)",
  //   locale: "sv-SE",
  // },
  ESP: {
    currency: "Spanish (Castilian)",
    locale: "es-ES",
  },
  SIT: {
    currency: "Slovenian (Slovenia)",
    locale: "sl-SI",
  },
  SKK: {
    currency: "Slovak (Slovakia)",
    locale: "sk-SK",
  },
  PTE: {
    currency: "Portuguese (Portugal)",
    locale: "pt-PT",
  },
  MTL: {
    currency: "Maltese (Malta)",
    locale: "mt-MT",
  },
  LTL: {
    currency: "Lithuanian (Lithuania)",
    locale: "lt-LT",
  },
  LVL: {
    currency: "Latvian (Latvia)",
    locale: "lv-LV",
  },
  ITL: {
    currency: "Italian (Italy)",
    locale: "it-IT",
  },
  IEP: {
    currency: "Irish (Ireland)",
    locale: "ga-IE",
  },
  HUF: {
    currency: "Hungarian (Hungary)",
    locale: "hu-HU",
  },
  GRD: {
    currency: "Greek (Greece)",
    locale: "el-GR",
  },
  FIM: {
    currency: "Finnish (Finland)",
    locale: "fi-FI",
  },
  EEK: {
    currency: "Estonian (Estonia)",
    locale: "et-EE",
  },
  // EUR: {
  //   currency: "Dutch (Netherlands)",
  //   locale: "nl-NL",
  // },
  CZK: {
    currency: "Czech (Czech Republic)",
    locale: "cs-CZ",
  },
  BGN: {
    currency: "Bulgarian (Bulgaria)",
    locale: "bg-BG",
  },
};

const currencyMapping = {
  GBP: {
    currency: "GBP",
    locale: "en-GB",
  },
  EUR: {
    currency: "EUR",
    locale: "de-DE",
  },
  ZAR: {
    currency: "ZAR",
    locale: "en-ZA",
  },
};

const dateOptionsConfig = {
  "en-GB": {
    long: { year: "numeric", month: "long", day: "numeric" },
    short: { year: "numeric", month: "short", day: "numeric" },
  },
  "de-DE": {
    long: { year: "numeric", month: "long", day: "numeric" },
    short: { year: "numeric", month: "short", day: "numeric" },
  },
  "en-ZA": {
    long: { year: "numeric", month: "long", day: "numeric" },
    short: { year: "numeric", month: "short", day: "numeric" },
  },
};

const deliveryAddressMap = ["contactName", "street", "town", "postCode"];

const languageCountryCodes = {
  en: "en-GB",
  de: "de-DE",
  bg: "bg-BG",
  cs: "cs-CZ",
  da: "da-DK",
  nl: "nl-NL",
  et: "et-EE",
  fi: "fi-FI",
  fr: "fr-FR",
  el: "el-GR",
  hu: "hu-HU",
  ga: "ga-IE",
  it: "it-IT",
  lv: "lv-LV",
  lt: "lt-LT",
  mt: "mt-MT",
  pl: "pl-PL",
  pt: "pt-PT",
  ro: "ro-RO",
  sk: "sk-SK",
  sl: "sl-SI",
  es: "es-ES",
  sv: "sv-SE",
  zh: "zh-CN",
  af: "af-ZA",
};

const types = {
  "[object Date]": "Date",
  "[object String]": "String",
  "[object Number]": "Number",
  "[object Boolean]": "Boolean",
  "[object Function]": "Function",
  "[object Symbol]": "Symbol",
  "[object Object]": "Object",
  "[object Null]": "Null",
  "[object Math]": "Math",
  "[object Undefined]": "Undefined",
  "[object Array]": "Array",
  "[object Window]": "Window",
  "[object NodeList]": "NodeList",
  "[object DOMTokenList]": "DOMTokenList",
  "[object DocumentFragment]": "DocumentFragment",
  "[object Map]": "Map",
  "[object Set]": "Set",
  "[object RegExp]": "RegExp",
};

// use this for the text?
const stockStatuses = {
  IN_STOCK_MORE_DUE: "In stock more due",
  IN_STOCK_NO_MORE_DUE: "In stock no more due",
  OUT_OF_STOCK_MORE_DUE: "Out of stock more due",
  OUT_OF_STOCK_NO_MORE_DUE: "Out of stock no more due",
};

const oneMin = 1 * 60 * 1000;
const fiveMins = oneMin * 5;
const oneHour = oneMin * 60;
const oneDay = oneHour * 24;
const threeDays = oneDay * 3;
/**
 * some searches are by types of products rather than a search term
 */
const searchTypes = {
  "commercial-products": "Market=Commercial",
  Clearance: "Clearance",
  Discontinued: "Discontinued",
  Bulkpacks: "BulkPacks",
  New: "New",
};

const currencySymbol = {
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BGN: "лв",
  BHD: ".د.ب",
  BIF: "FBu",
  BMD: "$",
  BND: "$",
  BOB: "$b",
  BOV: "BOV",
  BRL: "R$",
  BSD: "$",
  BTC: "₿",
  BTN: "Nu.",
  BWP: "P",
  BYN: "Br",
  BYR: "Br",
  BZD: "BZ$",
  CAD: "$",
  CDF: "FC",
  CHE: "CHE",
  CHF: "CHF",
  CHW: "CHW",
  CLF: "CLF",
  CLP: "$",
  CNH: "¥",
  CNY: "¥",
  COP: "$",
  COU: "COU",
  CRC: "₡",
  CUC: "$",
  CUP: "₱",
  CVE: "$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr",
  DOP: "RD$",
  DZD: "دج",
  EEK: "kr",
  EGP: "£",
  ERN: "Nfk",
  ETB: "Br",
  ETH: "Ξ",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  GBP: "£",
  GEL: "₾",
  GGP: "£",
  GHC: "₵",
  GHS: "GH₵",
  GIP: "£",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  IMP: "£",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "kr",
  JEP: "£",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "KSh",
  KGS: "лв",
  KHR: "៛",
  KMF: "CF",
  KPW: "₩",
  KRW: "₩",
  KWD: "KD",
  KYD: "$",
  KZT: "₸",
  LAK: "₭",
  LBP: "£",
  LKR: "₨",
  LRD: "$",
  LSL: "M",
  LTC: "Ł",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "lei",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "MOP$",
  MRO: "UM",
  MRU: "UM",
  MUR: "₨",
  MVR: "Rf",
  MWK: "MK",
  MXN: "$",
  MXV: "MXV",
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "₨",
  NZD: "$",
  OMR: "﷼",
  PAB: "B/.",
  PEN: "S/.",
  PGK: "K",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "Gs",
  QAR: "﷼",
  RMB: "￥",
  RON: "lei",
  RSD: "Дин.",
  RUB: "₽",
  RWF: "R₣",
  SAR: "﷼",
  SBD: "$",
  SCR: "₨",
  SDG: "ج.س.",
  SEK: "kr",
  SGD: "S$",
  SHP: "£",
  SLL: "Le",
  SOS: "S",
  SRD: "$",
  SSP: "£",
  STD: "Db",
  STN: "Db",
  SVC: "$",
  SYP: "£",
  SZL: "E",
  THB: "฿",
  TJS: "SM",
  TMT: "T",
  TND: "د.ت",
  TOP: "T$",
  TRL: "₤",
  TRY: "₺",
  TTD: "TT$",
  TVD: "$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  UYI: "UYI",
  UYU: "$U",
  UYW: "UYW",
  UZS: "лв",
  VEF: "Bs",
  VES: "Bs.S",
  VND: "₫",
  VUV: "VT",
  WST: "WS$",
  XAF: "FCFA",
  XBT: "Ƀ",
  XCD: "$",
  XOF: "CFA",
  XPF: "₣",
  XSU: "Sucre",
  XUA: "XUA",
  YER: "﷼",
  ZAR: "R",
  ZMW: "ZK",
  ZWD: "Z$",
  ZWL: "$",
};

const StrongPassRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})$/;
const MediumPassRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})$/ ||
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,})$/;
let strongPassword = new RegExp(
  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
);
let mediumPassword = new RegExp(
  "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
);

const WebsiteRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
const DateStringRegex = /(\d{1,2})\/(\d{1,2})\/(\d{4})/g;
const TimeStringRegex = /(\d{1,2}):(\d{1,2}):(\d{1,2})/g;
const AM_PM_Regex = /AM|PM/g;

const contactTypeArr = [
  { label: "Select Type", value: "" },
  { label: "General Enquiry", id: "1", value: "GeneralEnquiry" },
  { label: "Change Of Contact Details", id: "2", value: "ChangeOfContactDetails", },
  { label: "Order Enquiry", id: "3", value: "OrderEnquiry" },
  { label: "Conference Registration", id: "4", value: "ConferenceRegistration", },
  { label: "Delivery Issue", id: "5", value: "DeliveryIssue" },
  { label: "Delivery Request", id: "6", value: "DeliveryRequest" },
  { label: "Pricing Enquiry", id: "7", value: "PricingEnquiry" },
  { label: "Technical Enquiry", id: "8", value: "TechnicalEnquiry" },
  { label: "Warranty Claim", id: "9", value: "WarrantyClaim" },
  { label: "Trading Account Application", id: "10", value: "TradingAccountApplication", },
  { label: "Marketing Enquiry", id: "11", value: "MarketingEnquiry" },
  // { label: "Homeowner Enquiry", id: "12", value: "HomeownerEnquiry" },
  { label: "Installer Enquiry", id: "13", value: "InstallerEnquiry" },
];

const priorityArr = [
  { label: "Select Priority", value: "" },
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];

let MMM = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const SortByNews = [
  {
    sortBy: true,
    displayName: "Date Type - Future",
    checked: false,
    value: "sortBy=DisplayDate&sortOrder=Asc",
    i18nNameKey: "newsFutureDate",
  },
  {
    sortBy: true,
    displayName: "Date Type - Now",
    checked: false,
    value: "sortBy=DisplayDate&sortOrder=Desc",
    i18nNameKey: "newsNowDate",
  },
  {
    sortBy: true,
    displayName: "News Type - Ascending",
    checked: false,
    value: "sortBy=NewsType&sortOrder=Asc",
    i18nNameKey: "newsTypeAsc",
  },
  {
    sortBy: true,
    displayName: "News Type - Decending",
    checked: false,
    value: "sortBy=NewsType&sortOrder=Desc",
    i18nNameKey: "newsTypeDec",
  },
];

export {
  currencyMapping,
  dateOptionsConfig,
  defaultCurrency,
  defaultLocale,
  deliveryAddressMap,
  fiveMins,
  localeMapping,
  oneDay,
  stockStatuses,
  searchTypes,
  threeDays,
  types,
  languageCountryCodes,
  currencySymbol,
  MediumPassRegex,
  StrongPassRegex,
  mediumPassword,
  WebsiteRegex,
  strongPassword,
  currencyCodes,
  contactTypeArr,
  priorityArr,
  DateStringRegex,
  TimeStringRegex,
  AM_PM_Regex,
  MMM,
  SortByNews
};