import React, { useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { LEFT_ARROW_BLACK_ICON, RIGHT_ARROW_GRAY_ICON } from "../../../assets";
import { AlertPopup, Layout, T } from "../../../components";
import { GlobalContext } from "../../../contexts";


import DocumentationDetails from "./DocumentationDetail";
import useDocumentLoader from "../../../hooks/useDocumentLoader";



/**
 * @returns - jsx element of getting start page (documentation section)
 */


function DocumentationDetailMenu() {
  const alertRef = useRef(null);
  const { page: params } = useParams();
  const { state: { documentation } } = useContext(GlobalContext);
  useDocumentLoader(alertRef);

  let listData = [];
  documentation && documentation?.forEach((items) => {
    const { route } = items;
    if (params === route) { listData = items };
  })

  const _title = documentation?.find(i => i.route === params)?.title;

  return (
    listData?.children?.length > 0 ? <>
      <Layout title={_title || ""} id={params} translate={false}>
        <div className="complete-page" />
        <section className="bg__img">
          <section>
            <Link to="/documentation" className="bg-img__Back-icon">
              <img src={LEFT_ARROW_BLACK_ICON} alt="icon" />
              <span>
                <T strProp="acMenuDocumentation" />
              </span>
            </Link>
          </section>
          <section className="sub-menu-box">
            <section className="sub-menu-box-title">
              <div className="menu-heading-title">
                <span>
                  {listData.title}
                </span>
              </div>
            </section>
            {/* ======================== Recent Orders ================= */}
            {listData?.children?.map((items, index) => {
              const { title, route } = items;
              return (
                <Link
                  key={index}
                  to={`../${listData.route}/details/${route}`}
                  className="sub-menu-"
                >
                  <div className="sub-menu--contant">
                    <span className="sub-menu--name">
                      {title}
                    </span>
                  </div>
                  <div className="sub-menu--icon">
                    <img src={RIGHT_ARROW_GRAY_ICON} alt=">" />
                  </div>
                </Link>
              )
            })}
          </section>
        </section>
        <AlertPopup ref={alertRef} />
      </Layout>
    </> : <DocumentationDetails path={params} />
  );
}

export default DocumentationDetailMenu;
