import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react"
import { AlertPopup, BackMenuCard, ButtonModual, CheckBox, ChevronWrapper, DatePickerInp, DropDown, ItemListComponentDetail, Layout, PageLoader, ProductBlueBtn, ProductWhiteBtn, T, TextInput } from "../components"
import { GlobalContext, Il8Context } from "../contexts";
import useNewReturnHook from "../hooks/useNewReturnHook";
import { callApi } from "../services";
import { dateFormate, filterLineItems, refactoring } from "../utils/functions";
import { CLOSE_BUTTON_ICON, CLOSE_WHITE_ICON, CONFIRM_RETURN_ICON, NO_FOUND_IMG, NO_FOUND_SM_IMG } from "../assets";
import DynamicQuestions from "../components/DynamicQuestions";
import FilePicker from "../components/FilePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";





const NewReturns = () => {
    const alertRef = useRef()
    const { t } = useContext(Il8Context);
    const [pageNo, setPageNo] = useState(1);
    const [submittingReturn, setSubmitReturnLoading] = useState(false)
    const [uploadImageLoading, setUploadImageLoading] = useState(false)
    const [selectedProductForQuestion, setSelectedProductForQuestion] = useState('');
    const [refresh, setRefresh] = useState(false);
    const containerRef = useRef(null);
    const [hasMore, setHasMore] = useState(true);
    const intObserver = useRef(null);
    const navigate = useNavigate();
    const globleState = useContext(GlobalContext);
    const fromDateRef = useRef(null)
    const toDateRef = useRef(null);
    const [fromDateErrMsg, setFromDateErrMsg] = useState('');
    const [toDateErrMsg, settoDateErrMsg] = useState('');
    const questionsRef = useRef(null);
    const [questionValidationIndex, setQuestionValidationIndex] = useState('');
    const [partListLoading, setPartListLoading] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const pictureRef = useRef()



    const { setCounter, setSelectedToDate, setSelectedRadioValue, setSelectPartNoSearchedValue, setSelectedPartNo, setTempShipments, setShipmentDataLoading,
        setSelectedShipment,
        setSelectedFromDate,
        setSelectPartNoPopup,
        setSelectShipmentPopup,
        setConfirmationExpand,
        returnValidation,
        reasonValidation,
        setSelectedImage,
        setQuestionAndAnswers,
        setQuestionIsLoading,
        setQuestionsData,
        setSelectedProduct,
        setExpand,
        setDelay,
        setCheckBox, setShowQuestionPopup,
        imageValidation,
        descriptionRef, /* typeRef,  */imageRef, reasonRef, returnRef,
        setReturnDetailDescription,
        /* setType, */
        /* checkType, */ descriptionValidation, token,
        setConfirmationDetailData,
        setConfirmationDataLoading, setReasonsData,
        setReasonsLoading, setLineItems,
        setSelectedReason,
        setSelectedReasonObject, setShipmentData, checkShipmentSelection, shipmentRef, partNoRef, checkPartNoSelection, termsRef,
        state, dispatch
    } = useNewReturnHook();


    if (state?.delay) { document.getElementById("navbar-fixed").style.top = "0" }
    useEffect(() => {
        async function getConfirmationData() {
            dispatch(setConfirmationDataLoading(true));
            let { res, sCode } = await callApi("getConfirmationDesc", { token })
            if (sCode === 200 || sCode === 201) {
                dispatch(setConfirmationDetailData(refactoring(res?.body)))
                dispatch(setConfirmationDataLoading(false))
            }
        }
        token && getConfirmationData();
        // eslint-disable-next-line
    }, [token])

    useEffect(() => {
        async function getReasons() {
            dispatch(setReasonsLoading(true))
            let { res, sCode } = await callApi("getNewReturnReason", { token })
            if (sCode === 200 || sCode === 201) {
                let arr = res?.body?.result?.map((obj, index) => {
                    return (
                        { ...obj, label: obj.description, value: obj.description }
                    )
                })
                arr.unshift({ label: "Select Reason", value: '' })
                dispatch(setReasonsData(arr))
                dispatch(setReasonsLoading(false))
            }
        }
        token && getReasons()
        // eslint-disable-next-line
    }, [token])

    //For questions
    async function getQuestions(productType, productCode) {
        // dispatch(setQuestionIsLoading(true))
        let { res, sCode } = await callApi("getReturnQuestions", { token, alertRef }, productType)
        if (sCode === 200 || sCode === 201) {
            if (state.questionsAndAnswers.hasOwnProperty(productCode)) {
            } else {
                let modArray = [...res?.body?.result, { "id": "image", "question": "image", "score": "789789789avb789agva87f" }, { "id": "uploadedImage", "question": "uploadedImage", "score": "dfgsa5dg698e65rgter6g5" }];
                let obj = {};
                modArray?.forEach((qus, index) => {
                    obj[qus?.id] = { ...qus, answer: '' };
                });
                dispatch(setQuestionAndAnswers({ ...state.questionsAndAnswers, [productCode]: obj }))
            };
            dispatch(setQuestionIsLoading(false))
        }
    }

    //for submitting images
    const submitImage = async (itemId, img, productname, isNew) => {
        if (isNew) {
            if (img) {
                setUploadImageLoading(true);
                callApi('uploadImage', { token, alertRef }, itemId, img)
                    .then(({ res, sCode }) => {
                        dispatch(setShowQuestionPopup(false))
                        if (sCode === 202) {
                            dispatch(setQuestionAndAnswers({
                                ...state?.questionsAndAnswers,
                                [productname]: { ...state?.questionsAndAnswers[productname], uploadedImage: { ...state?.questionsAndAnswers[productname].uploadedImage, answer: res?.body?.result?.fileName, isFilled: true }, image: { ...state?.questionsAndAnswers[productname].image, isNew: false } }
                            }))
                            setUploadImageLoading(false);
                        }
                    }).catch(err => { setUploadImageLoading(false); dispatch(setShowQuestionPopup(false)) }).finally(() => { setUploadImageLoading(false); dispatch(setShowQuestionPopup(false)) });
            } else {
                dispatch(setShowQuestionPopup(false))
            }
        } else {
            dispatch(setShowQuestionPopup(false))
        }
    }
    let queryObject = {};


    const removeQuery = (obj, queryArray) => {
        let clone = JSON.parse(JSON.stringify(obj));
        queryArray?.forEach(Itm => {
            delete clone[Itm]
        })
        return clone
    }

    const createQuery = (obj) => Object.keys(obj)?.map((item, index) => index === 0 ? `?${item + "=" + obj[item]}` : item + "=" + obj[item]);


    //Get Shipments Function
    const getShipment = async (isNew) => {
        if (pageNo) {
            queryObject.PageNumber = pageNo;
            queryObject.ResultsPerPage = 10;
        }

        if (!(state.selectedRadioValue.id === 'OrderCode')) {

            if (state.selectedFromDate) {
                if (!(state.selectedRadioValue.id === 'SerialNumber')) {
                    queryObject.FromDate = dayjs(state?.selectedFromDate).format("YYYY-MM-DD");
                };
            };
            if (state.selectedToDate) {
                if (!(state.selectedRadioValue.id === 'SerialNumber')) {
                    queryObject.ToDate = dayjs(state.selectedToDate).format("YYYY-MM-DD");
                }
            };

        }

        if (isNew) {
            // tempdata = state.shipmentData;
            // dispatch(setShipmentData([]))
            queryObject.PageNumber = 1;
            setPageNo(1);
        }
        if (state.selectPartNoSearchedValue) {
            queryObject[state.selectedRadioValue.id] = state.selectPartNoSearchedValue;
        };
        dispatch(setShipmentDataLoading(true))
        const { res, sCode } = await callApi(`${(state.selectedRadioValue.id === 'SerialNumber' && state.selectPartNoSearchedValue !== '') ? 'shipmentAvailableToReturnForSerialNo' : 'shipmentAvailableToReturn'}`, { token, alertRef }, createQuery(queryObject).join('&'))
        if (sCode === 200 || sCode === 201) {
            // tempdata = []
            dispatch(setShipmentDataLoading(false))
            res.body.result.items = res?.body?.result?.items?.map(Itm => ({ ...Itm, query: removeQuery(queryObject, ["PageNumber", "ResultsPerPage", "SerialNumber"]) }))
            dispatch(setShipmentData(isNew ? res?.body?.result?.items : [...state.shipmentData, ...res?.body?.result?.items]))
            if (res?.body?.result?.pageNumber < res?.body?.result?.pageCount) {
                setHasMore(true)
            } else {
                setHasMore(false)
            }
        } else if (sCode === 400) {
            /* state.selectedRadioValue.id === "ShipmentId" &&  */
            // dispatch(setShipmentData(tempdata))
            // tempdata = []
            dispatch(setShipmentDataLoading(false))
        } else {
            // tempdata = []
            dispatch(setShipmentDataLoading(false))
        }
    }
    // console.log(state)
    // eslint-disable-next-line
    useEffect(() => { state.shipmentData.length < 1 && state.selectShipmentPopup && getShipment(true) }, [state.selectShipmentPopup])
    // eslint-disable-next-line
    useEffect(() => { if (refresh) { getShipment(true); setRefresh(false); setHasMore(true) } }, [state.selectPartNoSearchedValue])
    // eslint-disable-next-line
    useEffect(() => { pageNo > 1 && getShipment() }, [pageNo])

    useEffect(() => {
        if (!(state.selectedRadioValue.id === 'SerialNumber')) {
            if (state.selectShipmentPopup && state?.selectedFromDate && state?.selectedToDate) {
                getShipment(true)
            }

            if (state.selectShipmentPopup && state?.selectedFromDate === null && state?.selectedToDate === null) {
                getShipment(true)
            }


            // if (state.selectShipmentPopup && state?.selectedFromDate && state?.selectedToDate === null) {

            //     toDateRef.current.setOpen(true);

            // }

            // if (state.selectShipmentPopup && state?.selectedToDate && state?.selectedFromDate === null) {

            //     fromDateRef.current.setOpen(true);

            // }
        }
        // eslint-disable-next-line
    }, [state.selectedFromDate, state.selectedToDate])


    const lastPostRef = useCallback(
        (post) => {
            if (state.shipmentDataLoading) return;
            if (intObserver.current) intObserver.current.disconnect();
            intObserver.current = new IntersectionObserver((posts) => {
                if (posts[0].isIntersecting && hasMore) {
                    setPageNo((prev) => prev + 1);
                }
            });
            if (post) intObserver.current.observe(post);
        },
        // eslint-disable-next-line
        [state.shipmentDataLoading, hasMore]
    );


    const confirmReturn = () => {
        if (!state.checkbox) {
            dispatch(setConfirmationExpand(true))
            dispatch(setExpand(false))
            setTimeout(() => {
                window.scrollTo({
                    top: document.documentElement.scrollHeight,
                    behavior: "smooth",
                });
                alertRef.current?.onErr({
                    body: <T strProp="aggreeTandC" />,
                    btnTxt: <T strProp="close" />,
                });
            }, 0);
            return
        }
        let returnObject = { description: state.returnDetailDescription, returnShipmentId: state.selectedShipment.id, returnToCollectDate: new Date().toISOString()/* dayjs(Date.now()).format("YYYY-MM-DD") */, reasonId: state.selectedReasonObject.id, lineItems: [] }
        // eslint-disable-next-line
        state.selectedPartNo?.forEach((lineItem, index) => { returnObject.lineItems.push({ "id": lineItem?.id, "partNo": lineItem?.productCode, "quantity": state.counter[lineItem?.productCode] ? state.counter[lineItem?.productCode] : 0, "productType": lineItem.manufacturer, "questions": state.questionsAndAnswers[lineItem.productCode] ? Object.keys(state.questionsAndAnswers[lineItem.productCode]).map((items, index) => { if (items === "image" || items === "uploadedImage") { return }; let { score, answer } = state.questionsAndAnswers[lineItem.productCode][items]; return { "key": score, "value": answer } })?.filter(item => item !== undefined) : [], "uploadedFileName": state.questionsAndAnswers[lineItem.productCode]?.uploadedImage.answer ? state.questionsAndAnswers[lineItem.productCode]?.uploadedImage.answer : "" }) })
        let stopLoop = false;
        returnObject.lineItems = returnObject?.lineItems?.filter(items => items.quantity > 0);
        // console.log(returnObject)
        if (!(
           /*  (checkType(state?.type, typeRef))
            && */ (descriptionValidation(state?.returnDetailDescription, descriptionRef))
            && (reasonValidation(state?.selectedReason, reasonRef))
            && (state?.selectedReasonObject?.hasQustionsAndAnswers ? returnValidation(state?.selectedProduct, returnRef) : true)
            && (checkShipmentSelection(state?.selectedShipment?.description, shipmentRef))
            && (checkPartNoSelection(state?.selectedPartNo?.map(item => item.productCode).join(',  '), partNoRef))
        )) { return null }

        // if (returnObject?.lineItems?.length === 0) {
        //     alertRef.current?.onErr({
        //         body: t("pleaseSelectPartNo"),
        //         btnTxt: <T strProp="close" />,
        //     });
        //     return
        // }
        // return null
        let itemsToBeReturn = filterLineItems(state.selectedPartNo)
        let products = [];
        itemsToBeReturn[0].data?.forEach((items, index) => {
            if (state.counter[items.productCode] === 0 || state.counter[items.productCode] === undefined) { products.push(items.productCode) }
            // return state.counter[items.productCode] !== 0 && state.counter[items.productCode] !== undefined;
        })
        // console.log("data", products)
        if (products.length > 0) {
            alertRef.current?.onErr({
                body: t("selectRtnQunL") + " " + products.join(' , '),
                btnTxt: <T strProp="close" />,
            });
            return
        }
        let questionsToFillBeforeSubmit = [];
        itemsToBeReturn[0].data?.forEach((elem, index) => {
            if (state.counter[elem.productCode] === 0) { return }
            // if (stopLoop) { return }
            Object.keys(state.questionsAndAnswers)?.forEach((id, ind) => {
                // if (stopLoop) { return }
                Object.keys(state.questionsAndAnswers[id])?.forEach((lis, inde) => {
                    // if (stopLoop) { return }
                    if (!state.questionsAndAnswers[id]?.[lis].isFilled) {
                        // stopLoop = true;
                        questionsToFillBeforeSubmit.push(id);
                        // alertRef.current?.onErr({
                        //     body: t("fillAnswers") + " " + id,
                        //     btnTxt: <T strProp="close" />,
                        // });
                    }
                })
            })
        })
        // itemsToBeReturn[0].data?.forEach((elem, index) => {
        //     console.log("elem", elem)
        // })
        const set = new Set(questionsToFillBeforeSubmit);
        let arrayOfEmptyInformation = [];
        for (const element of set) {
            arrayOfEmptyInformation.push(element);
        }
        if (arrayOfEmptyInformation?.length > 0) {
            alertRef.current?.onErr({
                body: t("fillAnswers") + " " + arrayOfEmptyInformation.join(', '),
                btnTxt: <T strProp="close" />,
            });
            return
        }

        // return null
        if (stopLoop) { return }
        // state.selectedPartNo
        setSubmitReturnLoading(true)
        callApi('createReturn', { token, alertRef }, JSON.stringify(returnObject))
            .then(({ res, sCode }) => {
                if (sCode === 200 || sCode === 201) {
                    alertRef.current?.onSuccess({
                        body: t("returnCreatedSuccessfully"),
                        navTo: '/currentreturns',
                    });
                }
            }).catch(() => { setSubmitReturnLoading(false) }).finally(() => { setSubmitReturnLoading(false) })
    }


    useEffect(() => {
        if (!(state.selectedRadioValue.id === "PartNumber" || state.selectedRadioValue.id === "Manufacturer")) {
            setFromDateErrMsg('');
            settoDateErrMsg('');
        }
        // eslint-disable-next-line
    }, [state.selectedRadioValue])
    const onSave = () => {
        const allQuestions = JSON.parse(JSON.stringify(state.questionsAndAnswers[selectedProductForQuestion]));
        const image = allQuestions?.image?.answer;
        delete allQuestions?.image;
        delete allQuestions?.uploadedImage;
        const Idx = (Object.keys(allQuestions).findIndex(ques => { return state.questionsAndAnswers?.[selectedProductForQuestion][ques].answer.trim() === "" }));
        if (Idx > -1) {
            setTrigger(true)
            setQuestionValidationIndex(Idx)
            setTimeout(() => {
                questionsRef.current.childNodes[Idx]?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest'
                })
            }, 200);
            setTimeout(() => {
                setTrigger(false)
            }, 0);
            return;
        }
        if (image === '') {
            setTimeout(() => {
                Object.keys(allQuestions)?.length > 0 && pictureRef.current.scrollIntoView(
                    {
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'nearest'
                    });
            }, 1);
            imageValidation(undefined, imageRef);
            return;
        }
        submitImage(state.selectedPartNo.filter((item) => item.productCode === selectedProductForQuestion)[0].id, state.questionsAndAnswers?.[selectedProductForQuestion].image.answer, selectedProductForQuestion, state.questionsAndAnswers?.[selectedProductForQuestion].image.isNew);
    }

    const handleRadioChange = (data) => {
        dispatch(setSelectedRadioValue(data));
        dispatch(setSelectPartNoSearchedValue(''));
    }
    useEffect(() => {
        if (state.selectPartNoSearchedValue === '' && state.shipmentData.length === 0) {
            token && getShipment(true)
        }
        // eslint-disable-next-line
    }, [state.selectPartNoSearchedValue, state.selectedRadioValue])

    const getLineItems = async () => {
        if (!checkShipmentSelection(state?.selectedShipment?.description, shipmentRef)) { return }
        dispatch(setSelectPartNoPopup(true));
        dispatch(setTempShipments([...state?.selectedPartNo]));
        if (state.lineItems.length > 0) { return }
        setPartListLoading(true);
        callApi("getLineItems", { token, alertRef }, createQuery({ ...state.selectedShipment.query, ShipmentId: state.selectedShipment.id }).join('&'))
            .then(({ res, sCode }) => {
                setPartListLoading(false);
                dispatch(setLineItems(res.body.result.shipmentItems))
            }).catch(() => {
                setPartListLoading(false);
            }).finally(() => {
                setPartListLoading(false);
            })
    }
    return (
        <Layout title="titleNewReturns">
            <section>
                <div className={`section-m-b`}>
                    <BackMenuCard
                        BackMenu1="acMenuAccount"
                        Path1="/account"
                        BackMenu2="titleNewReturns" />
                </div>
            </section>
            {/* ================= Contact Us ================ */}
            <section>
                <div className={`section-m-b`}>
                    {/* =========Confirmation Section========== */}

                    {<ChevronWrapper
                        title={"confirmationTitle"}
                        handleClick={() => { dispatch(setConfirmationExpand(!state?.confirmationExpand)) }}
                        expand={state?.confirmationExpand}
                        applyPadding={true}
                    >
                        {state?.confirmationDataLoading ?
                            <section className="add-item-height">
                                <PageLoader isLoading isSmall={true} inPlace={true} />
                            </section>
                            :
                            <>

                                {state?.confirmationDetailData?.content?.length > 0 ?
                                    <>
                                        {/* <section className="menu-heading-title">
                                            <span>{state?.confirmationDetailData?.title || "--"}</span>
                                        </section> */}

                                        {
                                            <div>

                                                {state?.confirmationDetailData?.content?.map((contents, index) => {
                                                    const { columns } = contents;
                                                    return (
                                                        <div className="margin_bottom___" key={index}>
                                                            <p className="microgeneration-title">{contents.title}</p>
                                                            {contents?.content?.map((htmlData, indexx) => {
                                                                return (
                                                                    <div className="background-box" key={indexx}>
                                                                        {htmlData.map((items, iii) => {

                                                                            if (!items) { return <Fragment key={iii} /> }
                                                                            const { contentType, body } = items;
                                                                            switch (contentType) {
                                                                                // ====================================
                                                                                case "textBlock":
                                                                                    return (<span key={iii} className="industryguides-body" dangerouslySetInnerHTML={{ __html: body, }} />)

                                                                                // ====================================

                                                                                case "mediaLinkBlock":
                                                                                    return (
                                                                                        <Fragment key={iii}>
                                                                                            <a
                                                                                                className="media_download_block"
                                                                                                href={items?.url}
                                                                                                target="_blank"
                                                                                                rel="noreferrer"
                                                                                            >
                                                                                                <img
                                                                                                    src={items.image?.src}
                                                                                                    alt="img"
                                                                                                    className={columns === 3 || columns === 4 ? "documentation-img__" : "documentation-img"}
                                                                                                    onError={({ currentTarget }) => {
                                                                                                        currentTarget.onerror = null;
                                                                                                        currentTarget.src = NO_FOUND_SM_IMG;
                                                                                                    }}
                                                                                                />
                                                                                            </a>
                                                                                            {items?.downloadContent && (
                                                                                                <a href={items?.downloadContent?.src} target="_blank" rel="noreferrer">{items?.title}</a>
                                                                                            )}
                                                                                        </Fragment>
                                                                                    )
                                                                                // ====================================
                                                                                case "embeddedVideo":
                                                                                    return (
                                                                                        <div className="iframeDiv" key={iii}>
                                                                                            <iframe frameBorder="0" className="iframeTag" src={items.url} title={items.title} allowFullScreen></iframe>
                                                                                        </div>
                                                                                    )

                                                                                // ====================================

                                                                                case "textLinkBlock":
                                                                                    return (
                                                                                        <div key={iii}>
                                                                                            <a
                                                                                                href={items?.url}
                                                                                                target="_blank"
                                                                                                rel="noreferrer"
                                                                                                className="industryguides-text"
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: items?.text,
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    )


                                                                                // ====================================

                                                                                case "mediaDownloadBlock":
                                                                                    return (
                                                                                        <div key={iii} className='margin_bottom___'>
                                                                                            {(items?.downloadContent && items?.title) && (
                                                                                                <div className={1 % 2 ? "media_content_block_link_color_blue" : "media_content_block_link_color_orange"}>
                                                                                                    <a
                                                                                                        style={{ color: "white", padding: "2px 5px" }}
                                                                                                        className={"media_content_block_link"}
                                                                                                        href={items?.downloadContent?.src}
                                                                                                        target="_blank"
                                                                                                        rel="noreferrer"
                                                                                                    >
                                                                                                        {items?.title}
                                                                                                    </a>
                                                                                                </div>
                                                                                            )}
                                                                                            <a href={items?.url || items?.downloadContent?.src} className="media_download_block" target="_blank" rel="noreferrer" >
                                                                                                <img
                                                                                                    className={(columns === 3 || columns === 4) ? "documentation-img__" : "documentation-img"}
                                                                                                    src={items?.image?.src}
                                                                                                    alt="img"
                                                                                                    onError={({ currentTarget }) => {
                                                                                                        currentTarget.onerror = null;
                                                                                                        currentTarget.src = NO_FOUND_SM_IMG;
                                                                                                    }}
                                                                                                />
                                                                                            </a>
                                                                                            {/* // link not working because url prefix is missing in api response */}

                                                                                        </div>
                                                                                    )
                                                                                // ====================================
                                                                                case "imageBlock":
                                                                                    return (
                                                                                        <a href={items?.downloadContent?.src} target="_blank" rel="noreferrer" key={iii}>
                                                                                            <img className={items?.downloadContent?.src ? "documentation-img cursor-pointer" : "documentation-img"}
                                                                                                src={items?.image?.src}
                                                                                                onError={({ currentTarget }) => {
                                                                                                    currentTarget.onerror = null;
                                                                                                    currentTarget.src = NO_FOUND_IMG;
                                                                                                    currentTarget.style.aspectRatio = "4/1";
                                                                                                }}
                                                                                                alt="img"
                                                                                            />
                                                                                        </a>
                                                                                    )
                                                                                // ====================================
                                                                                default: return (<Fragment key={iii} />);
                                                                            }
                                                                        })}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>)
                                                }
                                                )}

                                                <div className="popup_event-text quick-add-margin-model_" ref={termsRef}>
                                                    <CheckBox
                                                        textClass="check-box-text"
                                                        label={`${t('confirmation1')} ${globleState.state?.acObj?.brand?.description} ${t('confirmation2')}`}
                                                        checked={state?.checkbox}
                                                        onChange={() => {
                                                            dispatch(setCheckBox(true));
                                                            setTimeout(() => {
                                                                dispatch(setDelay(true))
                                                                dispatch(setConfirmationExpand(false))
                                                                dispatch(setExpand(true))
                                                            }, 600);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </>
                                    :
                                    <></>}
                            </>
                        }
                    </ChevronWrapper>}

                    {<section>
                        <ChevronWrapper
                            expand={state?.expand}
                            handleClick={() => state.checkbox && dispatch(setExpand(!state?.expand))}
                            title={"returnDetails"}

                        >
                            {/* <div className="margin_bottom">
                                <DropDown
                                    Required
                                    placeholder={"Select Type Of Return"}
                                    data={state?.returnTypeArray}
                                    ref={typeRef}
                                    title={t("Type")}
                                    val={state?.type}
                                    onChange={(e) => {
                                        dispatch(setType(e.target.value));
                                        checkType(e.target.value, typeRef);
                                    }}
                                    orangeIc
                                />
                            </div> */}

                            <div className="margin_bottom">
                                <TextInput
                                    Required
                                    ref={descriptionRef}
                                    input_Title={t("contactDescription")}
                                    Placeholder={t("Typehere")}
                                    value={state?.returnDetailDescription}
                                    onChange={(e) => {
                                        dispatch(setReturnDetailDescription(e.target.value));
                                        descriptionValidation(e.target.value, descriptionRef);
                                    }}
                                />
                            </div>

                            <div className="margin_bottom">
                                <DropDown
                                    Required
                                    // data={contactTypeArr}
                                    data={state?.reasonsData}
                                    isDisabled={state?.productSelectionLoading}
                                    ref={reasonRef}
                                    placeholder={"Select Reason"}
                                    title={t("reason")}
                                    onChangeObj={(obj) => {
                                        dispatch(setSelectedReasonObject(obj))
                                    }}
                                    isLoading={state?.reasonsLoading}
                                    val={state?.selectedReason}
                                    onChange={(e) => {
                                        dispatch(setSelectedReason(e.target.value));
                                        dispatch(setSelectedProduct(''));
                                        dispatch(setSelectedImage({}));
                                        dispatch(setQuestionsData([]));
                                        dispatch(setQuestionAndAnswers({}));
                                        dispatch(setCounter({}))
                                        reasonValidation(e.target.value, reasonRef);
                                    }}
                                    orangeIc
                                />
                            </div>

                            <div className="margin_bottom" onClick={() => dispatch(setSelectShipmentPopup(true))}>
                                <TextInput
                                    input_Title={t("selectShipment")}
                                    Required
                                    value={state?.selectedShipment?.description ? `${state?.selectedShipment?.id} - ${state?.selectedShipment?.description} (Shipped On ${dateFormate(state?.selectedShipment?.shippingDate)})` : ''}
                                    Placeholder={t("selectShipment")}
                                    isDisabled={true}
                                    isLoading={false}
                                    dropdownArrow={!false}
                                    ref={shipmentRef}
                                    dropDown={true}
                                />
                            </div>

                            <div className="margin_bottom" onClick={getLineItems}>
                                <TextInput
                                    ref={partNoRef}
                                    input_Title={t("selectPartanddesc")}
                                    Required
                                    value={state?.selectedPartNo?.map(item => `${item.productCode} ${item.productDescription}`).join(',  ')}
                                    Placeholder={t("selectPartanddesc")}
                                    isDisabled={true}
                                    isLoading={false}
                                    dropdownArrow={!false}
                                    dropDown={true}
                                />
                            </div>
                            <div className="listing_parent_div_class">
                                {state.selectedPartNo.length > 0 &&
                                    <>
                                        <div className="quotationdetails-item-flex">
                                            <span className="quotationdetails-item__flex-pard-title">
                                                <T strProp="selectedItems" />
                                            </span>
                                            <span className="quotationdetails-item__flex-pard-title">
                                                <T strProp="quoteTotalPrice" />
                                            </span>
                                        </div>
                                        {state.selectedPartNo?.map((item, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <ItemListComponentDetail
                                                        newReturn={true}
                                                        getClickedIndex={(i) => { dispatch(setShowQuestionPopup(true)) }}
                                                        index={index}
                                                        key={index}
                                                        isAllocatedRow={true}
                                                        isPriceRow={true}
                                                        item={item}
                                                        counter={state?.counter[item?.productCode] || state?.counter[item?.productCode] === '' ? state?.counter[item?.productCode] : 0}
                                                        setCounter={(c) => {
                                                            dispatch(setCounter({ ...state.counter, [item.productCode]: c }));
                                                            if (state.selectedReasonObject.hasQuestionsAndAnswers) {
                                                                !state.questionsAndAnswers.hasOwnProperty(item?.productCode) && getQuestions(item?.manufacturer, item?.productCode)
                                                            }
                                                            // if (c < 1) {
                                                            //     delete state.questionsAndAnswers[item?.productCode]
                                                            // }
                                                        }}
                                                        removePart={(part) => {
                                                            dispatch(setSelectedPartNo(state.selectedPartNo?.filter(items => items.id !== part.id)));
                                                            // console.log("part", part);
                                                            let clone = JSON.parse(JSON.stringify(state.questionsAndAnswers));
                                                            let cloneCounter = JSON.parse(JSON.stringify(state.counter));
                                                            delete clone[part?.productCode];
                                                            delete cloneCounter[part?.productCode];
                                                            dispatch(setQuestionAndAnswers(clone));
                                                            dispatch(setCounter(cloneCounter));
                                                        }}
                                                        isFilled={state.questionsAndAnswers[item.productCode] ? Object.keys(state.questionsAndAnswers[item.productCode])?.map(items => state.questionsAndAnswers[item.productCode][items].isFilled)?.filter(item => item === false || item === undefined).length > 0 : true}
                                                        selectedProduct={(e) => {
                                                            setSelectedProductForQuestion(e);
                                                        }}
                                                        showQuestions={state.selectedReasonObject?.hasQuestionsAndAnswers}
                                                        // EditItemsOpen={EditItemsOpen}
                                                        // setDelAlert={setDelAlert}
                                                        isFindAlertnates={false}
                                                    // setItemIdDelete={setItemIdDelete}
                                                    // setItemIdDeleteIdx={setItemIdDeleteIdx}
                                                    // data={res?.result?.items[0]}
                                                    />
                                                    {!(index === state.selectedPartNo?.length - 1) && <div className="uni_borderline_align">
                                                        <div className="border__line_mr_tb__"></div>
                                                    </div>}
                                                </Fragment>

                                            );
                                        })}
                                    </>}
                            </div>

                        </ChevronWrapper>




                        <ButtonModual
                            Open={state?.selectShipmentPopup}
                            onClose={() => dispatch(setSelectShipmentPopup(false))}
                        >
                            <section className="">
                                <div className="Sort-by">
                                    <div className="add-item-model">
                                        <T strProp="selectShipment" />
                                    </div>
                                    <img
                                        src={CLOSE_WHITE_ICON}
                                        alt="CloseIcon"
                                        className="cursor-pointer"
                                        onClick={() => { dispatch(setSelectShipmentPopup(false)) }}
                                    />
                                </div>

                                <div className="add-item-padding-10">
                                    {/* Date Picker */}
                                    <div className="date-Picker-div">
                                        <div className="customDivForNewReturn1" >
                                            <DatePickerInp
                                                datePickerRef={fromDateRef}
                                                showTitle={false}
                                                selctedDate={state?.selectedFromDate/*  ? state?.selectedFromDate : new Date(dayjs().subtract(1, 'year')['$d']) */}
                                                placeholder={t("fromDate")}
                                                setSelectedDate={(e) => { dispatch(setSelectedFromDate(e)); setFromDateErrMsg(''); if (e) if (state.selectedToDate === null) { toDateRef.current.setOpen(true) } }}
                                                isOrder={true}
                                                minDate={dayjs().subtract(50, 'year')['$d']}
                                                maxDate={new Date()}
                                                customTitle={"From Date"}
                                                errMessage={fromDateErrMsg}
                                                isDateValidate={fromDateErrMsg}
                                                clearDate={true}
                                            />
                                        </div>
                                        <div className="customDivForNewReturn2">
                                            <DatePickerInp
                                                datePickerRef={toDateRef}
                                                showTitle={false}
                                                selctedDate={state?.selectedToDate/*  ? state?.selectedToDate : new Date(dayjs()) */}
                                                placeholder={t("toDate")}
                                                setSelectedDate={(e) => { dispatch(setSelectedToDate(e)); settoDateErrMsg(''); if (e) if (state.selectedFromDate === null) { fromDateRef.current.setOpen(true) } }}
                                                // clearDate={true}
                                                isOrder={true}
                                                minDate={state?.selectedFromDate ? dayjs(state?.selectedFromDate) : dayjs().subtract(50, 'year')['$d']}
                                                maxDate={new Date()}
                                                customTitle={"To Date"}
                                                errMessage={toDateErrMsg}
                                                isDateValidate={toDateErrMsg}
                                                clearDate={true}
                                            />
                                        </div>
                                    </div>


                                    {/* Radio Buttons  */}
                                    <div className='radio_custom'>
                                        <div className='radio_elements'>
                                            <div>
                                                <label htmlFor="ShipmentId">
                                                    <input type="radio" className="radio-input" defaultChecked={state?.selectedRadioValue.id === "ShipmentId" ? true : false} name="new_return_shipment" onChange={(e) => { handleRadioChange({ id: e.target.value, label: 'etrShipId' }) }} value="ShipmentId" id="ShipmentId" />
                                                    <span className="custom-radio"></span>
                                                    {t("Shipment")}</label>
                                            </div>
                                            <div >
                                                <label htmlFor="Manufacturer">
                                                    <input type="radio" className="radio-input" defaultChecked={state?.selectedRadioValue.id === "Manufacturer" ? true : false} name="new_return_shipment" onChange={(e) => { handleRadioChange({ id: e.target.value, label: 'etrManufacturerName' }) }} value="Manufacturer" id="Manufacturer" />
                                                    <span className="custom-radio"></span>
                                                    {t("Manufacturer")}</label>
                                            </div>
                                        </div>

                                        <div className='radio_elements'>
                                            <div>
                                                <label htmlFor="OrderCode">
                                                    <input type="radio" className="radio-input" defaultChecked={state?.selectedRadioValue.id === "OrderCode" ? true : false} name="new_return_shipment" onChange={(e) => { handleRadioChange({ id: e.target.value, label: 'etrOrdRefNo' }) }} value="OrderCode" id="OrderCode" />
                                                    <span className="custom-radio"></span>
                                                    {t("OrderCode")}</label>
                                            </div>
                                            <div>
                                                <label htmlFor="PartNumber">
                                                    <input type="radio" className="radio-input" defaultChecked={state?.selectedRadioValue.id === "PartNumber" ? true : false} name="new_return_shipment" onChange={(e) => { handleRadioChange({ id: e.target.value, label: 'etrProdNo' }) }} value="PartNumber" id="PartNumber" />
                                                    <span className="custom-radio"></span>
                                                    {t("PartNumber")}</label>
                                            </div>
                                        </div>

                                        <div className='radio_elements_'>
                                            <div>
                                                <label htmlFor="SerialNumber">
                                                    <input type="radio" className="radio-input" defaultChecked={state?.selectedRadioValue.id === "SerialNumber" ? true : false} name="new_return_shipment" onChange={(e) => { handleRadioChange({ id: e.target.value, label: 'etrSerialNo' }) }} value="SerialNumber" id="SerialNumber" />
                                                    <span className="custom-radio"></span>
                                                    {t("SerialNumber")}</label>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Search Bar */}
                                    <div className="new_return_search_parent">
                                        <input type="text" value={state?.selectPartNoSearchedValue} className="new_return_search" onChange={(e) => { if (e.target.value.length < 1) { setRefresh(true) } dispatch(setSelectPartNoSearchedValue(e.target.value)) }} placeholder={`${t(state?.selectedRadioValue.label)}`} />
                                        <div className="go_button" onClick={() => {
                                            if ((state.selectedRadioValue.id === "PartNumber" || state.selectedRadioValue.id === "Manufacturer") && (state.selectedFromDate === null || state.selectedToDate === null)) {
                                                if (state.selectedFromDate === null) {
                                                    return setFromDateErrMsg('selectFromDate')
                                                }
                                                if (state.selectedToDate === null) {
                                                    return settoDateErrMsg('selectToDate')
                                                }

                                            }
                                            if (state.selectPartNoSearchedValue) {
                                                getShipment(true)
                                            }
                                        }}>
                                            {t("go")}
                                        </div>
                                    </div>
                                </div>


                                {/* Listing of Shipment Items */}
                                {state?.shipmentData?.length > 0 ?
                                    <>
                                        <div className="item_list_div" ref={containerRef}>
                                            {state?.shipmentData?.map((items, index) => {
                                                return (
                                                    state?.shipmentData.length === index + 1 ?
                                                        <div ref={lastPostRef} className="returnable_items" onClick={() => { checkShipmentSelection(items.description, shipmentRef); dispatch(setSelectedShipment(items)); dispatch(setSelectShipmentPopup(false)); dispatch(setSelectedPartNo([])); dispatch(setQuestionAndAnswers({})); dispatch(setCounter({})); dispatch(setLineItems([])) }} key={index}>
                                                            <span>
                                                                {items.id} {` - ${items.description} (Shipped On ${dateFormate(items.shippingDate)})`}
                                                            </span>
                                                        </div>
                                                        :
                                                        <div className="returnable_items" onClick={() => { checkShipmentSelection(items.description, shipmentRef); dispatch(setSelectedShipment(items)); dispatch(setSelectShipmentPopup(false)); dispatch(setSelectedPartNo([])); dispatch(setQuestionAndAnswers({})); dispatch(setCounter({})); dispatch(setLineItems([])) }} key={index}>
                                                            <span>
                                                                {items.id} {` - ${items.description} (Shipped On ${dateFormate(items.shippingDate)})`}
                                                            </span>
                                                        </div>
                                                )
                                            })}
                                            {state.shipmentDataLoading &&
                                                <div style={{ height: "50px" }}>
                                                    <PageLoader isSmall={true} inPlace={true} isLoading={state.shipmentDataLoading} />
                                                </div>
                                            }
                                        </div>
                                    </>
                                    :
                                    <>
                                        {!state.shipmentDataLoading && <div className='NotFound' style={{ height: "300px" }} >
                                            <p className="no-data-found-sm">
                                                <T strProp="noShip" />
                                            </p>
                                        </div>}
                                        {state.shipmentDataLoading && <div className=" loader_item_list_div">
                                            <section className="loader_relative" style={{ height: "0px" }}>
                                                <PageLoader isSmall={true} inPlace isLoading={state.shipmentDataLoading} />
                                            </section>
                                        </div>}
                                    </>
                                }
                            </section>
                        </ButtonModual>

                        <ButtonModual
                            Open={state?.selectPartNoPopup}
                            onClose={() => {
                                dispatch(setTempShipments([])); dispatch(setSelectPartNoPopup(false));
                            }}
                        >
                            <section className="">
                                <div className="Sort-by">
                                    <div className="add-item-model">
                                        <T strProp="selectPartanddesc" />
                                    </div>
                                    <img
                                        src={CLOSE_WHITE_ICON}
                                        alt="CloseIcon"
                                        className="cursor-pointer"
                                        onClick={() => { dispatch(setSelectPartNoPopup(false)) }}
                                    />
                                </div>
                                <div className="item_list_div">

                                    {state.lineItems.length > 0 ?
                                        <>
                                            {filterLineItems(state.lineItems)?.map((lineItem, index) => {
                                                if (lineItem.data?.length < 1) { return <Fragment key={index}></Fragment> }
                                                return (
                                                    <Fragment key={index}>
                                                        <div className="partNoAndDescriptionTitle">
                                                            <T strProp={lineItem.title} />
                                                        </div>
                                                        {lineItem.data?.map((items, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <CheckBox
                                                                        onChange={(e) => {
                                                                            if (state.tempShipments.includes(items)) {
                                                                                state?.tempShipments?.splice(state?.tempShipments?.indexOf(items), 1)
                                                                                dispatch(setTempShipments([...state?.tempShipments]))
                                                                            } else {
                                                                                dispatch(setTempShipments([...state.tempShipments, items]))
                                                                            }
                                                                        }}
                                                                        label={`${items.productCode} / ${items.productDescription}`}
                                                                        checked={state.tempShipments.includes(items) ? true : false}
                                                                        newReturnList={true}
                                                                        textClass={"newReturn_checkbox_label"}
                                                                        topValue="calc(50% - 12px)"
                                                                    />
                                                                </Fragment>
                                                            )
                                                        })}
                                                    </Fragment>
                                                )
                                            })}
                                            {/* <div className="partNoAndDescriptionTitle">
                                                <T strProp={"canReturn"} />
                                            </div>
                                            {state.selectedShipment?.shipmentItems?.map((items, index) => {
                                                if (!Number(items.quantity - Math.abs(items.returnedQuantity))) { return <></> }
                                                return (
                                                    <Fragment key={index}>
                                                        <CheckBox
                                                            onChange={(e) => {
                                                                if (state.tempShipments.includes(items)) {
                                                                    state?.tempShipments?.splice(state?.tempShipments?.indexOf(items), 1)
                                                                    dispatch(setTempShipments([...state?.tempShipments]))
                                                                } else {
                                                                    dispatch(setTempShipments([...state.tempShipments, items]))
                                                                }
                                                            }}
                                                            label={`${items.productCode} / ${items.productDescription}`}
                                                            checked={state.tempShipments.includes(items) ? true : false}
                                                            newReturnList={true}
                                                            textClass={"newReturn_checkbox_label"}
                                                            topValue="calc(50% - 12px)"
                                                        />
                                                    </Fragment>
                                                )
                                            })}
                                            <div className="partNoAndDescriptionTitle_">
                                                <T strProp={'alreadyReturned'} />
                                            </div>
                                            {state.selectedShipment?.shipmentItems?.map((items, index) => {
                                                if (Number(items.quantity - Math.abs(items.returnedQuantity))) { return <></> }
                                                return (
                                                    <Fragment key={index}>
                                                        <CheckBox
                                                            onChange={(e) => {
                                                                if (state.tempShipments.includes(items)) {
                                                                    state?.tempShipments?.splice(state?.tempShipments?.indexOf(items), 1)
                                                                    dispatch(setTempShipments([...state?.tempShipments]))
                                                                } else {
                                                                    dispatch(setTempShipments([...state.tempShipments, items]))
                                                                }
                                                            }}
                                                            label={`${items.productCode} / ${items.productDescription}`}
                                                            checked={state.tempShipments.includes(items) ? true : false}
                                                            newReturnList={true}
                                                            textClass={"newReturn_checkbox_label"}
                                                            topValue="calc(50% - 12px)"
                                                        />
                                                    </Fragment>
                                                )
                                            })} */}
                                        </>
                                        :
                                        <>
                                            {partListLoading ?
                                                < div className='NotFound'>
                                                    <p className="no-data-found-sm">
                                                        <PageLoader isLoading={partListLoading} inPlace={true} isSmall={true} />
                                                    </p>
                                                </div>
                                                : < div className='NotFound'>
                                                    <p className="no-data-found-sm">
                                                        <T strProp="notFoundPart" />
                                                    </p>
                                                </div>}
                                        </>
                                    }

                                </div>
                                <div className="newReturn_popUp_button">
                                    <ProductBlueBtn
                                        BtnTilte={"Done"}
                                        onClick={() => {
                                            dispatch(setSelectPartNoPopup(false));
                                            dispatch(setSelectedPartNo([...state.tempShipments]));
                                            checkPartNoSelection(state.tempShipments.map(items => items.productCode).join(''), partNoRef)
                                            dispatch(setTempShipments([]))
                                        }}
                                    />
                                </div>
                            </section>
                        </ButtonModual>


                        <ButtonModual
                            Open={state?.showQuestionPopup}
                            onClose={() => dispatch(setShowQuestionPopup(false))}
                        >
                            <div className="Sort-by">
                                <div className="add-item-model">
                                    <T strProp="returnsQuestions" /> {selectedProductForQuestion}
                                </div>
                                <img
                                    src={CLOSE_WHITE_ICON}
                                    alt="CloseIcon"
                                    className="cursor-pointer"
                                    onClick={() => { dispatch(setShowQuestionPopup(false)) }}
                                />
                            </div>
                            <section className="add-item-height_new_return" >
                                <div ref={questionsRef}>
                                    {state.questionsAndAnswers[selectedProductForQuestion] && Object.keys(state.questionsAndAnswers[selectedProductForQuestion])?.map((questions, index) => {
                                        if (questions === 'image' || questions === 'uploadedImage') { return <Fragment key={index}></Fragment> }
                                        return (
                                            <div className="new_return_question_list" key={index}>
                                                <DynamicQuestions
                                                    index={index}
                                                    trigger={trigger}
                                                    validationIndex={questionValidationIndex}
                                                    title={state.questionsAndAnswers[selectedProductForQuestion][questions].question}
                                                    placeholder={t("Typehere")}
                                                    value={state.questionsAndAnswers[selectedProductForQuestion][questions].answer}
                                                    onChange={(e) => {
                                                        dispatch(setQuestionAndAnswers({ ...state?.questionsAndAnswers, [selectedProductForQuestion]: { ...state?.questionsAndAnswers[selectedProductForQuestion], [questions]: { ...state?.questionsAndAnswers[selectedProductForQuestion][questions], answer: e.target.value, isFilled: e.target.value.trim().length > 0 ? true : false } } }));
                                                    }}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="new_return_question_list" ref={pictureRef}>
                                    <FilePicker
                                        Required
                                        input_Title={t("imageField")}
                                        type={"file"}
                                        accept={".jpg, .jpeg, .png"}
                                        fileName={state.questionsAndAnswers?.[selectedProductForQuestion]?.image?.answer?.name}
                                        onChange={(e) => {
                                            if (e?.target?.files[0]) {
                                                const selectedFile = e.target.files[0];
                                                const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
                                                if (allowedTypes.includes(selectedFile?.type)) {
                                                    imageValidation(e.target.files[0].size, imageRef);
                                                    if (e.target.files[0].size < 2097152) {
                                                        dispatch(setSelectedImage(e.target.files[0]));
                                                        dispatch(setQuestionAndAnswers({ ...state?.questionsAndAnswers, [selectedProductForQuestion]: { ...state?.questionsAndAnswers[selectedProductForQuestion], image: { ...state?.questionsAndAnswers[selectedProductForQuestion]["image"], answer: e.target.files[0], isFilled: true, isNew: true } } }))
                                                    } else {
                                                        // dispatch(setQuestionAndAnswers({ ...state?.questionsAndAnswers, [selectedProductForQuestion]: { ...state?.questionsAndAnswers[selectedProductForQuestion], image: { ...state?.questionsAndAnswers[selectedProductForQuestion]["image"], answer: '', isFilled: false } } }))
                                                        // dispatch(setQuestionAndAnswers({ ...state?.questionsAndAnswers, [selectedProductForQuestion]: { ...state?.questionsAndAnswers[selectedProductForQuestion], image: { ...state?.questionsAndAnswers[selectedProductForQuestion]["uploadedImage"], answer: '', isFilled: false } } }))
                                                        dispatch(setSelectedImage({}));
                                                    }
                                                } else {
                                                    e.target.value = null;
                                                    return
                                                }


                                            } else {
                                                // dispatch(setSelectedImage({}));
                                            }
                                        }}
                                        ref={imageRef}
                                    />
                                </div>
                            </section>

                            <div className="newReturn_popUp_button">
                                <ProductBlueBtn
                                    BtnTilte={"save"}
                                    isloading={uploadImageLoading}
                                    isDesibaled={uploadImageLoading}
                                    onClick={onSave}
                                />
                            </div>
                        </ButtonModual>

                        <ProductBlueBtn
                            Img={CONFIRM_RETURN_ICON}
                            isloading={submittingReturn}
                            BtnTilte={"confirmReturn"}
                            onClick={() => { confirmReturn() }}
                        // isDesibaled={true}
                        />

                        <ProductWhiteBtn
                            Img={CLOSE_BUTTON_ICON}
                            isloading={false}
                            BtnTilte={"cancelOrder"}
                            onClick={() => { navigate('/') }}
                        />
                    </section>}
                </div>

            </section >
            <AlertPopup ref={alertRef} />
        </Layout >
    )
}

export default NewReturns