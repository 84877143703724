import React from "react";
import { T } from "../../translation";
// eslint-disable-next-line
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { dateFormate } from "../../../utils";
// import { Navigate } from "react-router-dom";

/**
 *
 * @param {string} ProjectNo - use for display project number
 * @param {string} ProjectTitle - use for display project title
 * @param {string} ProjectInfo - use for display project information
 * @param {string} PartNo - use for display part number
 * @param {string} Type - use for display type of project
 * @param {string} Shipment - use for display shipment info
 * @param {string} ShipmentDate - use for display shipment date
 * @returns this function can return card view of search project.
 */
const SearchProjectCard = ({ ProjectNo, ProjectTitle, ProjectInfo, PartNo, Type, Shipment, ShipmentDate }) => {
  const navigate = useNavigate();
  const goproductDetail = () => {
    if (Type === "Quote") {
      navigate(`/quotationdetails/${ProjectNo}`);
    } else if (Type === "Order") {
      navigate(`/order/${ProjectNo}`);
    } else { }
  };

  return (
    <section onClick={goproductDetail} className="search-project-card cursor-pointer">
      <div className="search-project-card-title">
        <span>{ProjectNo}</span>
      </div>
      <div className="search-project-card-sub-title">
        <span>{ProjectTitle}</span>
      </div>
      <div className="search-project-card-description">
        <span>{ProjectInfo}</span>
      </div>
      <div className="search-project-card-flex-row">
        <div className="search-project-card-flex-col">
          <div className="search-project-card__flex-col">
            <span className="search-project-card__title">
              <T strProp="basketPartNo" />{" "}
            </span>
            <span className="search-project-card__body">{PartNo || "-- --"}</span>
          </div>
          <div className="search-project-card__flex-col">
            <span className="search-project-card__title">
              <T strProp="Shipment" />
            </span>
            <span className="search-project-card__body">{Shipment || "-- --"}</span>
          </div>
        </div>
        <div className="search-project-card__flex__col">
          <div className="search-project-card__flex-col">
            <span className="search-project-card__title">
              <T strProp="Type" />{" "}
            </span>
            <span className="search-project-card__body">{Type || "-- --"}</span>
          </div>
          <div className="search-project-card__flex-col">
            <span className="search-project-card__title">
              <T strProp="ShipmentDate" />
            </span>
            <span className="search-project-card__body">{ShipmentDate ? dateFormate(ShipmentDate) : "-- --"}</span>
            {/* <span className="search-project-card__body">{ShipmentDate ? dayjs(ShipmentDate).format("DD/MM/YYYY") : "-- --"}</span> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SearchProjectCard;