const quote = {
  quoteReference: "Reference",
  quotereferenceShort: "Ref",
  quoteSubmitOrder: "Submit Order",
  quoteDeleteShipment: "Delete Shipment",
  quotePrintQuote: "Print Quote",
  quoteOrganisation: "Organisation",
  quoteOrganisationDetails: "Organisation Details",
  quoteDeliveryAddress: "Delivery Address",
  quoteQuoteDetails: "Quote Details",
  quoteItems: "Items",
  quoteaddItem: "Add Item",
  quoteTotalPrice: "Total Price",
  quoteTotalPricePerWP: "Total Price per Wp",
  quoteVAT: "Vat",
  quoteGrossTotal: "Gross Total",
  quotePaymentTerms: "Payment Terms",
  quoteTermsAndConditions: "Terms & Conditions",
  quoteAccountCode: "Account Code",
  quoteEnterDescription: "Enter Description",
  quoteCreatedDate: "Created Date",
  quotePreferredDelivery: "Preferred Delivery",
  quoteDeliveryPreference: "Delivery Preference",

  quoteUnitPrice: "Unit Price",
  quoteNetPrice: "Net Price",
  quoteTermsAndConditionsValueBefore: "Customer generated quotations are valid for ",
  quoteTermsAndConditionsValueAfter: " days from the created date.",
  quoteViewMore: "View More",
  quoteCutomerPO: "Customer PO",
  quoteCounty: "County",
  enterCounty: "Enter County",
  quoteStreet: "Street",
  quoteDiscontinued: "Discontinued",
  quoteissues: "issues",
  quoteFindAlternates: "Find Alternates",
  quoteCloseOrder: "Close Order",
  quoteAddOrder: "Add Note To Order",
  quoteAddOrderINfo:
    "Please enter a comment you wish to make about this order and your comment will be attaced to this order and emailed to your order processor, Chris Feeney",
  quoteEvent: "Event",
  quoteAddNote: "Add Note",
  quoteItemsList: "Items List",
  quotesave: "Save",
  quoteEditItems: "Edit Items",
  quoteManufacturer: "Manufacturer",
  quoteSubCategory: "Sub Category",
  quoteStock: "Stock",
  quotePartDescription: "Part No / Description",
  quoteSelectPartDescription: "Select Part No / Description",
  quoteAddNewItem: "Add New Item",
  quoteRecentlyViewProducts: "Recently View Products",
  quoteLastPrice: "Last Price",
  // quoteLastPrice: "Last Price (£)",
  quoteAvailableStock: "Available in Stock",
  quoteDueDate: "Due Date",
  quoteItemAddSuccessfully: "Item Added Successfully!",
  itemsAddedSuccess: "Items Added Successfully!",
  quoteViewQuote: "View Quote",
  quoteValidateItem: "Validate Item",
  quoteAddOtherDetails: "Add Other Details",
  quoteSelectKnownAddress: "Select Known Address",
  quoteComments: "Comments",
  shipmentComments: "Delivery Comments",
  quoteStockinfo: "Stock info",
  quoteRefID: "Ref ID",
  quoteViewLess: "View Less",
  quoteAllocated: "Allocated",
  quoteExpected: "Expected",
  quoteRevertQuote: "Revert to Quote",
  quoteEditCustomerOrder: "Edit Customer Order",
  quoteOtherDetails: " Other Details",
  quoteLookup: "Lookup",
  quoteSelectAddress: "Select Address",
  quoteAddorderedshipment: "Add ordered items to a shipment",
  quoteAddressType: "Address Type",
  quoteDeliveryMethod: "Delivery Method",
  quoteDeliveryLocation: "Delivery Location",
  quoteDebitCardPayment: "Make A Debit Card Payment",
  quoteSwiftOnlinepaymentsa: "Notify us of a SWIFT/On-line Payment",
  quoteSwiftOnlinepaymentuk: "Notify us of a BACS/CHAPS/On-line Payment",
  quoteEmailProforma: "Email Proforma",
  quoteCustomerReference: "Customer Reference",
  quotePayments: "Payments ( Credit account by )",
  quotePatmentInfo:
    "Leave blank or enter your own reference such as the Project Code or Proforma ID.",
  quoteAmount: "Amount",
  quoteRefernece: "Refernece",
  quoteValueDate: "Value Date",
  quoteUploadProofPayment: "Upload Proof Of Payment",
  quoteMaxfilesize: "Max file size : 2MB",
  quoteShipmentNoItem: "Please select at least one item",
  quoteOtherItems: "Other Items",
  quoteSelectAll: "Select All",
  quoteLeftToShip: "Left To Ship",
  quoteAvailable: "Available",
  quotePleaseselectPartNO: "Please select a Part No.",
  quoteEnterPartNO: "Enter a Part No / Description",
  quoteEnterAddress: "Enter Address",
  quoteOpenQuote: "Open Quote",
  quoteAddPopUpTitle:
    "Please select the quote you would like to add this item to :",
  quoteDELSuccessfully: "Quote Item Deleted Successfully!",
  QuoteDeletedSuccessfully: "Quote Deleted Successfully!",
  BasketDeletedSuccessfully: "Basket Deleted Successfully!",
  orderEditSuccessfully: "Order Edit Successfully!",
  orderDELSuccessfully: "Order Item Deleted Successfully!",
  orderPrintOrder: "Print Order",
  orderConfirmrevertQuote: "Confirm Revert to Quote",
  orderConfirmrevertQuoteMsg: "Are you sure, you want to revert to quote?",
  orderUpdate: "Update Order",
  quoteCloseOrderMsg: "Are you sure you'd like to discard unshipped line items and close this order?",
  // ===================
  quoteConfirmShipmentUpdate: "Confirm Shipment Update",
  quoteDoyouwantshipment: "Do you want shipment",
  quoteDoyouwantshipments: "Do you want shipment(s)",
  quoteDoyouwantPostMsg: "to be automatically updated with the new delivery preference?",
  quoteShipmentNotFound: "Shipment not found",
  quoteDoyouwantorder: "Do you want order",
  quotetobeautomaticallyupdated: "to be automatically updated?",
  quoteSelectPartNO: "Select Part No",
  quoteSelectSubCategory: "Select Sub Category",
  selectedItems: "Selected Items",

  //=========================
  segenSolarPty: "SegenSolar Pty",
  saCountry: "South Africa",
  saBotswana: "Botswana",
  namibia: "Namibia",
  zambia: "Zambia",
  zimbabwe: "Zimbabwe",
  segenSolarGmbH: "SegenSolar GmbH",
  germany: "Germany",
  belgium: "Belgium",
  europe: "Europe",
  netherlands: "Netherlands",
  poland: "Poland",
  segenLtd: "Segen Ltd",
  unitedKingdom: "United Kingdom",

};

export default quote;
